import { ImCross } from 'react-icons/im';

const LinkChain = ({ status = false }) => {
    const styles = {
        on: 'bg-theme-tertiary',
        off: 'bg-gray-600 opacity-60',
    };

    return (
        <div className="relative flex justify-center gap-x-1">
            <div
                className={`w-3 h-1.5 rounded ${
                    status ? styles.on : styles.off
                }`}
            ></div>
            <div
                className={`w-3 h-1.5 rounded ${
                    status ? styles.on : styles.off
                }`}
            ></div>
            <div
                className={`w-3 h-1.5 rounded ${
                    status ? styles.on : styles.off
                }`}
            ></div>
            {status === null && (
                <ImCross
                    size={20}
                    color={'#b91d1d'}
                    className="absolute -top-1.5 right-3"
                />
            )}
        </div>
    );
};

export default LinkChain;
