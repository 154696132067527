import React from 'react';

export default function WhitePanelContentWrapper({
    children,
    className = '',
    id = '',
}) {
    return (
        <div
            className={`bg-white rounded-lg py-2.5 px-5 shadow-md ${className}`}
            id={id}
        >
            {children}
        </div>
    );
}
