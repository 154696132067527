import 'rodal/lib/rodal.css';
import AddingServiceForm from './sre-form-service';
import { useTranslation } from 'react-i18next';

const ModalAddingServiceContent = ({ services, servicesDispatch, onClose }) => {
    const { t } = useTranslation();

    const handleChangeModalState = () => onClose();
    return (
        <div className="flex flex-col pb-4 pt-4 h-full">
            <AddingServiceForm
                handleChangeModalState={handleChangeModalState}
                services={services}
                servicesDispatch={servicesDispatch}
            />
        </div>
    );
};

export default ModalAddingServiceContent;
