import { customInstances, instances } from '../default-setup';

//Login
export const getRedirectLink = (params) =>
    instances.get('auth/sso_redirect/', params);

export const loginWithPassword = (data) =>
    customInstances.post('password/login/', data);

export const signupWithPassword = (params) =>
    customInstances.get('password/signup/', { params: params });

export const checkLoginOption = (data) => {
    return customInstances.post(`login/`, data);
};

export const loginWithRefreshToken = (provider, refreshToken) =>
    customInstances.get(
        `${provider}/refresh_token/?refresh_token=${refreshToken}`
    );

export const getSaml = () => instances.get(`auth/saml_redirect`);

// Reset Password
export const checkToken = () => customInstances.get(`check_token/`);
export const resendEmail = () =>
    customInstances.get(`reset_password/resend_email/`);

export const resetPassword = (data) =>
    customInstances.put('reset_password/', data);

// ENGPOINTS FOR FREE TRIAL SIGNUP
export const signupWithSocial = (social, code, org, role, endpoint, orgData) =>
    customInstances.post(
        `${social}/signup/?${
            social === 'microsoft' ? 'id_token' : 'code'
        }=${code}${org ? `&org=${org}` : ''}${
            role ? `&role=${role}` : ''
        }&endpoint=${endpoint}`,
        orgData
    );

// ENGPOINTS FOR LOGIN
export const loginWithSocial = (code, social) =>
    customInstances.get(
        `${social}/login/?${
            social === 'microsoft' ? 'id_token' : 'code'
        }=${code}`
    );
