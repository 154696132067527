import React from 'react';
import Loader from '../loader';

function ButtonPrimary({
    children,
    extraClasses,
    isLoading,
    onClick,
    variant,
    type,
    disabled,
    title,
}) {
    function getVariantStyle() {
        switch (variant) {
            case 'blue':
                return 'bg-blue-700 hover:bg-blue-600 text-white hover:text-white';
            case 'red':
                return 'bg-red-700 hover:bg-red-600 text-white hover:text-white';
            case 'white':
                return 'bg-white hover:bg-gray-100 text-blue-700';
            case 'gray':
                return 'bg-gray-300 hover:bg-gray-400 text-gray-700';
        }
    }

    return (
        <button
            className={`btn ${getVariantStyle()} max-h-14 ${extraClasses} disabled:opacity-70 disabled:cursor-not-allowed flex justify-center items-center`}
            onClick={onClick}
            type={type}
            disabled={disabled}
            title={title}
        >
            {isLoading ? null : children}
            {isLoading ? <Loader color={'white'} size={24} /> : null}
        </button>
    );
}

ButtonPrimary.defaultProps = {
    variant: 'blue',
    type: 'button',
    title: '',
};

export default ButtonPrimary;
