import React from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

const TimeSelectorNav = ({ activeTab, toggle }) => {
    const { t } = useTranslation();

    return (
        <div className="nav-tabs">
            <button
                className={classnames('quick-select nav-link', {
                    active: activeTab === 'quick',
                })}
                onClick={() => {
                    toggle('quick');
                }}
            >
                {t('header.time_period.quick')}
            </button>

            {/* <button
                className={classnames('sprint-select nav-link', {
                    active: activeTab === 'sprints',
                })}
                onClick={() => {
                    toggle('sprints');
                }}
            >
                {t('header.time_period.sprints')}
            </button> */}

            <button
                className={classnames('calendar-select nav-link', {
                    active: activeTab === 'calendar',
                })}
                onClick={() => {
                    toggle('calendar');
                }}
            >
                {t('header.time_period.global')}
            </button>
        </div>
    );
};

export default TimeSelectorNav;
