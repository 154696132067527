export const getConfidentClass = (value) => {
    if (value <= 0.5) {
        switch (true) {
            case value < 0.1225:
                return 'confidence-level-highest';
            case value >= 0.1225 && value < 0.245:
                return 'confidence-level-high';
            case value >= 0.245 && value < 0.3675:
                return 'confidence-level-medium';
            case value > 0.367:
                return 'confidence-level-low';
        }
    }

    switch (true) {
        case value > 0.5 && value < 0.6225:
            return 'confidence-level-low';
        case value >= 0.6225 && value < 0.745:
            return 'confidence-level-medium';
        case value >= 0.745 && value < 0.8675:
            return 'confidence-level-high';
        case value >= 0.8675:
            return 'confidence-level-highest';
    }
};

export const getUniqueListByParent = (arr) => {
    const uniqueListOfLatest = arr.reduce((acc, item) => {
        const isInList = acc.find(
            (ticket) => ticket.parent.key === item.parent.key
        );
        if (isInList) {
            const isLater = item.timestamp >= isInList.timestamp;
            if (isLater) {
                return acc.map((task) => {
                    if (task.parent.key === item.parent.key) {
                        return item;
                    }
                    return task;
                });
            } else {
                return acc;
            }
        }
        return [...acc, item];
    }, []);

    return uniqueListOfLatest;
};
