import React from 'react';
import { useTranslation } from 'react-i18next';

const ModalTimesheetProjectDeleteContent = ({
    project,
    handleAction,
    setModalState,
}) => {
    const { t } = useTranslation();

    const handleChangeModalState = () => {
        setModalState(false);
    };

    const handleDelete = _.debounce(() => {
        handleAction();
        setModalState(false);
    }, 1000);

    return (
        <div className="flex flex-col  py-4 h-full">
            <p className="text-xl text-gray-500">
                Are you sure you want to remove{' '}
                <span className="italic">{project}</span> project from
                Timesheet? Project data couldn't be restored
            </p>
            <div className="ml-auto mt-auto">
                <button
                    type="button"
                    className="inline-flex mr-4 justify-center px-6 py-2 text-lg leading-6	font-normal text-gray-500 bg-gray-300 border border-transparent rounded-md hover:bg-gray-200"
                    onClick={handleChangeModalState}
                    style={{ minWidth: '110px' }}
                >
                    {t('button.close')}
                </button>
                <button
                    type="button"
                    className={`inline-flex justify-center px-6 py-2 text-lg leading-6	font-normal text-white bg-red-text border border-transparent rounded-md hover:bg-red-800 disabled:cursor-not-allowed disabled:opacity-50`}
                    onClick={handleDelete}
                    style={{ minWidth: '110px' }}
                >
                    {t('button.remove')}
                </button>
            </div>
        </div>
    );
};

export default ModalTimesheetProjectDeleteContent;
