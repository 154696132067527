import React, { useReducer, createContext } from 'react';

const organisationReducer = (state, action) => {
    switch (action.type) {
        case 'SET_LOADING':
            return { data: state.data, loading: true, error: null };
        case 'SET_DATA':
            return {
                data: action.payload,
                loading: false,
                error: null,
            };
        case 'SET_ERROR':
            return { data: state.data, loading: false, error: action.payload };
        default:
            return state;
    }
};

export const OrganisationsContext = createContext();

export const OrganisationsProvider = ({ children }) => {
    const [organisationsState, organisationsDispatch] = useReducer(
        organisationReducer,
        {
            data: null,
            loading: true,
            error: null,
        }
    );

    return (
        <OrganisationsContext.Provider
            value={{ organisationsState, organisationsDispatch }}
        >
            {children}
        </OrganisationsContext.Provider>
    );
};
