import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import FormField from '../../pages/sre-page/components/sre-form-field';
import eula from '../../assets/eula';
import ButtonPrimary from '../button/ButtonPrimary';
import { ArrowRight } from '../../assets/svg';

function AuthCreateOrgForm({ onGoToAuth = () => {}, initialValues }) {
    const [isEula, setIsEula] = useState(false);

    function saveOrgData(data) {
        onGoToAuth(data);
    }

    function handleSubmit(values, helpers) {
        void handleSendData(values, helpers);
    }

    async function handleSendData(values, helpers) {
        localStorage.setItem('orgDataDraft', JSON.stringify(values));
        saveOrgData(values);
    }

    return (
        <div className="" data-testid={'form-container'}>
            <Formik
                initialValues={initialValues}
                validationSchema={Yup.object({
                    companyName: Yup.string()
                        .min(3, 'Must be at least 3 characters long')
                        .max(100, 'Maximum 100 characters')
                        .required('Required')
                        .matches(
                            /^[A-Za-z0-9 ю_-]*[A-Za-z0-9][A-Za-z0-9 ю_-]*$/,
                            'Only alphabets, numbers or _, ., - are allowed'
                        ),
                    eula: Yup.boolean()
                        .required('Required')
                        .oneOf(
                            [true],
                            'Please accept End use license agreement'
                        ),
                })}
                enableReinitialize={true}
                onSubmit={(values, helpers) => handleSubmit(values, helpers)}
            >
                {({ isSubmitting, values, errors, touched }) => {
                    return (
                        <Form
                            autoComplete="off"
                            className="h-full flex flex-col"
                        >
                            <div className="flex-grow">
                                <div className="">
                                    <FormField
                                        name={'Name your organisation'}
                                        attributeName={'companyName'}
                                        fieldType={'text'}
                                        placeholderText={'Pied Piper'}
                                        labelClassName="font-light leading-5 text-lg"
                                        className={
                                            'font-normal text-lg mt-2 text-gray-700'
                                        }
                                        ignoreErrors={true}
                                    />
                                    <ErrorMessage name="companyName">
                                        {(error) => (
                                            <div className="text-red-500 mt-1  text-xs">
                                                {error}
                                            </div>
                                        )}
                                    </ErrorMessage>
                                </div>
                                <div className="mt-2">
                                    {isEula ? (
                                        <div className="w-full h-40 mb-2 overflow-y-scroll p-2 relative rounded border border-solid border-gray-300 overflow-x-hidden text-gray-700 scrollbar-thin scrollbar-thumb-gray-300 scrollbar-thumb-rounded-full hover:scrollbar-thumb-gray-400">
                                            {eula()}
                                        </div>
                                    ) : null}
                                    <div>
                                        <div className="flex items-center gap-2">
                                            <Field
                                                type="checkbox"
                                                name="eula"
                                                className="w-4 h-4 cursor-pointer"
                                                data-testid={'eula'}
                                            />{' '}
                                            <label
                                                className="text-gray-400 text-sm mt-1  "
                                                htmlFor="eula"
                                            >
                                                I accept{' '}
                                                <button
                                                    className="underline"
                                                    type="button"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setIsEula(
                                                            (prevState) =>
                                                                !prevState
                                                        );
                                                    }}
                                                >
                                                    end use license agreement
                                                </button>
                                            </label>
                                        </div>
                                        <ErrorMessage name="eula">
                                            {(error) => (
                                                <div className="text-red-500 mt-1  text-xs">
                                                    {error}
                                                </div>
                                            )}
                                        </ErrorMessage>
                                    </div>
                                    <div className="mt-2">
                                        <div className="flex items-center gap-2">
                                            <Field
                                                type="checkbox"
                                                name="newsLetter"
                                                className="w-4 h-4 cursor-pointer flex-none"
                                                data-testid={'newsLetter'}
                                            />
                                            <label
                                                className="text-gray-400 text-sm mt-1"
                                                htmlFor="newsLetter"
                                            >
                                                Keep me informed about product
                                                updates and offers
                                            </label>
                                        </div>
                                        <ErrorMessage name="newsLetter">
                                            {(error) => (
                                                <div className="text-red-500 mt-1  text-xs">
                                                    {error}
                                                </div>
                                            )}
                                        </ErrorMessage>
                                    </div>
                                </div>
                            </div>

                            <ButtonPrimary
                                type="submit"
                                data-testid="submit"
                                extraClasses={
                                    'w-full flex space-x-4 justify-center pl-2 py-2 mt-4 items-center text-lg'
                                }
                                disabled={!values.companyName || !values.eula}
                            >
                                <span className={'mr-auto'}>Create</span>
                                <ArrowRight fill={'#ffffff'} />
                            </ButtonPrimary>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
}

export default AuthCreateOrgForm;
