import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import WhitePanelContentWrapper from '../../../components/white-panel-content-wrapper';
import { capitalizeFirstLetter } from '../../../helpers/textFormatters';
import SISelect from '../si-select';

function SIFilter({ filterOptions, onChangeFilter }) {
    const { t } = useTranslation();
    const transitionOptions = [
        {
            type: '^.*$',
            name: capitalizeFirstLetter(t('si_page.statuses.all')),
        },
        {
            type: '^Backlog$',
            name: capitalizeFirstLetter(t('si_page.statuses.backlog')),
        },
        {
            type: '^Selected for Development$',
            name: capitalizeFirstLetter(t('si_page.statuses.development')),
        },
        {
            type: '^In Progress$',
            name: capitalizeFirstLetter(t('si_page.statuses.progress')),
        },
        {
            type: '^Review$',
            name: capitalizeFirstLetter(t('si_page.statuses.review')),
        },
        {
            type: '^Done$',
            name: capitalizeFirstLetter(t('si_page.statuses.done')),
        },
        {
            type: '^CLosed$',
            name: capitalizeFirstLetter(t('si_page.statuses.closed')),
        },
    ];

    const labelOptions = [
        { type: '^.*$', name: 'All' },
        { type: '^feature$', name: 'Feature' },
        { type: '^not feature$', name: 'Not Feature' },
    ];

    const [selectedTransitionTo, setSelectedTransitionTo] = useState({
        value: filterOptions.transition_to,
        label: transitionOptions.find(
            (option) => option.type === filterOptions.transition_to
        ).name,
    });

    const [selectedTransitionFrom, setSelectedTransitionFrom] = useState({
        value: filterOptions.transition_from,
        label: transitionOptions.find(
            (option) => option.type === filterOptions.transition_from
        ).name,
    });

    const [selectedLabel, setSelectedLabel] = useState({
        value: filterOptions.label,
        label: labelOptions.find((label) => label.type === filterOptions.label)
            .name,
    });

    const [issueKey, setIssueKey] = useState(
        filterOptions.issue_key === '^.*$' ? '' : filterOptions.issue_key
    );

    useEffect(() => {
        setSelectedTransitionTo({
            value: filterOptions.transition_to,
            label: transitionOptions.find(
                (option) => option.type === filterOptions.transition_to
            ).name,
        });
        setSelectedTransitionFrom({
            value: filterOptions.transition_from,
            label: transitionOptions.find(
                (option) => option.type === filterOptions.transition_from
            ).name,
        });
        setSelectedLabel({
            value: filterOptions.label,
            label: labelOptions.find(
                (label) => label.type === filterOptions.label
            ).name,
        });
        setIssueKey(
            filterOptions.issue_key === '^.*$' ? '' : filterOptions.issue_key
        );
    }, [filterOptions]);

    function handleFilterSubmit() {
        const filterInput = {
            issue_key: issueKey || '^.*$',
            label: selectedLabel.value || '^.*$',
            transition_from: selectedTransitionFrom.value || '^.*$',
            transition_to: selectedTransitionTo.value || '^.*$',
        };
        onChangeFilter(filterInput);
        return;
    }

    return (
        <WhitePanelContentWrapper className="mt-8 flex gap-4 flex-wrap xl:flex-nowrap ">
            {/* Transition from filter */}
            <div>
                <label className="font-medium leading-6 px-2">
                    {capitalizeFirstLetter(t('si_page.transition')) +
                        ' ' +
                        t('common.from')}
                </label>
                <SISelect
                    selectedOption={selectedTransitionFrom}
                    options={transitionOptions}
                    handleOptionChange={setSelectedTransitionFrom}
                />
            </div>
            {/* Transition to filter */}
            <div>
                <label className="font-medium leading-6 px-2">
                    {capitalizeFirstLetter(t('si_page.transition')) +
                        ' ' +
                        t('common.to')}
                </label>
                <SISelect
                    selectedOption={selectedTransitionTo}
                    options={transitionOptions}
                    handleOptionChange={setSelectedTransitionTo}
                />
            </div>
            {/* Label */}
            <div>
                <label className="font-medium leading-6 px-2">Label:</label>
                <SISelect
                    selectedOption={selectedLabel}
                    options={labelOptions}
                    handleOptionChange={setSelectedLabel}
                />
            </div>
            {/* Ticket key
             */}
            <div>
                <label
                    htmlFor="issue-key"
                    className="font-medium leading-6 px-2"
                >
                    {capitalizeFirstLetter(t('si_page.ticket_key'))}
                </label>
                <input
                    className="block bg-blue-100 rounded h-12 px-2 focus:outline-none focus:border-2 focus:border-blue-700	"
                    type="text"
                    id="issue-key"
                    placeholder="Type here"
                    value={issueKey}
                    onChange={(e) => setIssueKey(e.target.value)}
                />
            </div>
            <button
                type="submit"
                className="h-12 px-4 py-2 w-24 bg-theme-tertiary rounded text-white font-display leading-6 font-medium mt-auto"
                onClick={() => handleFilterSubmit()}
            >
                {capitalizeFirstLetter(t('button.filter'))}
            </button>
        </WhitePanelContentWrapper>
    );
}

export default SIFilter;
