import React from 'react';

const ModalActionConfirmationContent = ({
    setModalState,
    actionText = 'Are you sure?',
    confirmBtnText = 'Yes',
    cancelBtnText = 'Cancel',
    sendRequest,
    onCancel,
}) => {
    const handleChangeModalState = () => {
        onCancel();
        setModalState(false);
    };

    const handleConfirm = _.debounce(() => {
        sendRequest();
        setModalState(false);
    }, 1000);

    return (
        <div className="flex flex-col py-4  h-full">
            <p className="text-xl text-gray-500">{actionText}</p>
            <div className="ml-auto mt-auto">
                <button
                    type="button"
                    className="inline-flex mr-4 justify-center px-6 py-2 text-lg leading-6	font-normal text-gray-500 bg-gray-300 border border-transparent rounded-md hover:bg-gray-200"
                    onClick={handleChangeModalState}
                    style={{ minWidth: '110px' }}
                >
                    {cancelBtnText}
                </button>
                <button
                    type="button"
                    className={`inline-flex justify-center px-6 py-2 text-lg leading-6	font-normal text-white bg-red-text border border-transparent rounded-md hover:bg-red-800 disabled:cursor-not-allowed disabled:opacity-50`}
                    onClick={handleConfirm}
                    style={{ minWidth: '110px' }}
                >
                    {confirmBtnText}
                </button>
            </div>
        </div>
    );
};

export default ModalActionConfirmationContent;
