import React from 'react';
import { useTranslation } from 'react-i18next';

const LeaderbordFooter = ({ selectedTeam, teamsState, usersState }) => {
    const { t } = useTranslation();

    const getQuantity = () => {
        if (selectedTeam) {
            if (selectedTeam === 'all') {
                const allUsers = usersState.data ? usersState.data.length : 0;

                return allUsers;
            }

            const currentTeam = teamsState?.data?.find(
                (team) => team.team_hash === selectedTeam
            );

            return currentTeam?.users_in_team &&
                currentTeam?.users_in_team[0] !== ''
                ? currentTeam.users_in_team.length
                : 0;
        }
        return null;
    };
    return (
        <p className="text-gray-500 mt-4">
            <span className="font-display font-medium">{getQuantity()}</span>{' '}
            <span className="font-display font-medium">
                {t('kudos_page.leaderboard.leaderboard.team_members')}
            </span>
        </p>
    );
};

export default LeaderbordFooter;
