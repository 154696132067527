import React from 'react';

export default function PanelTitle({
    title,
    underlineColorType = 'secondary',
    className = '',
    isLink = false,
}) {
    return isLink ? (
        <a
            className={`font-display inline-block text-28 text-gray-700 font-light border-b-2 mb-4 border-solid border-theme-${underlineColorType} ${className}`}
            href={`https://${title}`}
            target="_blank"
        >
            {title}
        </a>
    ) : (
        <h3
            className={`font-display inline-block text-28 text-gray-700 font-light border-b-2 border-solid border-theme-${underlineColorType} ${className}`}
        >
            {title}
        </h3>
    );
}
