import React from 'react';
import Loader from '../../../loader';
import LeaderbordBodyRow from './body-row';
import ErrorIndicator from '../../../error-indicator';

const LeaderbordBody = ({
    selectedTeam,
    localTeamsState,
    usersState,
    kudosState,
    kudosLeaderBoardState,
}) => {
    const createList = () => {
        if (selectedTeam === 'all') {
            const teamUsers = usersState.data.map((user) => user.hash);

            if (teamUsers.length === 0) {
                return (
                    <li>
                        <p className="font-display text-center text-gray-500 text-xl p-4">
                            No users in team
                        </p>
                    </li>
                );
            }

            const sortedList =
                kudosLeaderBoardState.data.team_members_list.length > 0
                    ? [...kudosLeaderBoardState.data.team_members_list]
                    : teamUsers.map((userHash) => ({
                          hash: userHash,
                          emoji_quantity: 0,
                          rank: 1,
                      }));

            return sortedList.map((userData, index) => {
                return (
                    <LeaderbordBodyRow
                        emoji={kudosState.data}
                        key={userData.hash}
                        person={usersState.data.find(
                            (user) => user.hash === userData.hash
                        )}
                        rank={userData.rank}
                        emojiQuantity={userData.emoji_quantity}
                        usersList={sortedList}
                        index={index}
                    />
                );
            });
        }

        const currentTeam = localTeamsState.data.find(
            (team) => team.team_hash === selectedTeam
        );

        if (currentTeam) {
            const teamUsers = currentTeam?.users_in_team;

            if (
                teamUsers.length === 0 ||
                (teamUsers.length === 1 && teamUsers[0] === '')
            ) {
                return (
                    <li className="flex-grow">
                        <p className="font-display text-center text-gray-500 text-xl p-4">
                            No users in team
                        </p>
                    </li>
                );
            }

            const sortedList =
                kudosLeaderBoardState.data.team_members_list.length > 0
                    ? [...kudosLeaderBoardState.data.team_members_list]
                    : teamUsers.map((userHash) => ({
                          hash: userHash,
                          emoji_quantity: 0,
                          rank: 1,
                      }));

            return sortedList.map((userData, index) => {
                return (
                    <LeaderbordBodyRow
                        emoji={kudosState.data}
                        key={userData.hash}
                        person={usersState.data.find(
                            (user) => user.hash === userData.hash
                        )}
                        rank={userData.rank}
                        usersList={sortedList}
                        emojiQuantity={userData.emoji_quantity}
                        index={index}
                    />
                );
            });
        }
        return null;
    };

    const createError = () => {
        if (kudosLeaderBoardState.error) {
            return (
                <li className="flex items-center">
                    <p className="text-red-text text-center w-100">
                        Sorry, can't get leaderboard data.
                    </p>
                </li>
            );
        }

        if (kudosState.error) {
            return (
                <li className="flex items-center flex-grow">
                    <p className="kudos-total__no-value">
                        <p className="text-red-text">
                            Sorry, can't get kudos emojis list.
                        </p>
                    </p>
                </li>
            );
        }

        if (usersState.error) {
            return (
                <li className="flex items-center flex-grow">
                    <p className="text-red-text">
                        Sorry, can't get users list.
                    </p>
                </li>
            );
        }
        return <ErrorIndicator error={usersState.error} />;
    };

    const hasLoading = () =>
        kudosLeaderBoardState.loading ||
        kudosState.loading ||
        usersState.loading ||
        usersState?.loadingUserpics;

    const hasError = () =>
        kudosLeaderBoardState.error || usersState.error || kudosState.error;

    return (
        <ul className="mt-2 h-230 overflow-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-thumb-rounded-full hover:scrollbar-thumb-gray-400 flex-grow">
            {hasLoading() ? (
                <li className="flex justify-center items-center">
                    <Loader color={'#C2C7D7'} size={35} speedMultiplier={0.8} />
                </li>
            ) : hasError() ? (
                createError()
            ) : (
                createList()
            )}
        </ul>
    );
};

export default LeaderbordBody;
