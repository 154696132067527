import { instances } from '../default-setup';

// Swarm Schedule
export const getSwarmSchedule = () => instances.get('schedule/jobs/');

export const setSwarmSchedule = (data) =>
    instances.post('schedule/jobs/', data);

export const updateSwarmSchedule = (data) =>
    instances.put('schedule/jobs/', data);
