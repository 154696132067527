import React from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import InputPassword from '../form/parts/InputPassword';
import { useTranslation } from 'react-i18next';
import ButtonPrimary from '../button/ButtonPrimary';
import { ArrowRight } from '../../assets/svg';
import { resetPassword } from '../../api/auth/AuthAPI';
import showNotification from '../../helpers/showNotification';

function SetPasswordForm({ setErrorMessage, navigate }) {
    const { t } = useTranslation();

    async function handleSubmit(values, setSubmittingFinished) {
        try {
            const res = await resetPassword({ new_password: values.password });
            if (res) {
                showNotification(
                    'Password has been changed, please login',
                    'success'
                );
                localStorage.removeItem('token');
                navigate('/');
            }
        } catch (error) {
            setErrorMessage(
                error.response?.data ||
                    error.message ||
                    'Oops! Something went wrong!'
            );
        }
        setSubmittingFinished();
    }

    return (
        <Formik
            initialValues={{ password: '', repeatPassword: '' }}
            validationSchema={Yup.object({
                password: Yup.string()
                    .min(6, 'Must be 6 characters or more')
                    .required('Required'),
                repeatPassword: Yup.string()
                    .min(6, 'Must be 6 characters or more')
                    .required('Required')
                    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
            })}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);
                void handleSubmit(values, () => setSubmitting(false));
            }}
        >
            {({ isSubmitting, values, errors, touched }) => (
                <Form className="flex flex-col">
                    <div className="flex flex-col mb-4">
                        <label htmlFor="password" className="text-sm">
                            Password
                        </label>
                        <InputPassword
                            name={'password'}
                            placeholder={t(
                                'set_password_page.form.placeholder.enter'
                            )}
                        />
                        <div className="text-red-700 text-sm">
                            <ErrorMessage name="password" />
                        </div>
                    </div>

                    <div className="flex flex-col">
                        <label htmlFor="repeatPassword" className=" text-sm">
                            Repeat password
                        </label>
                        <InputPassword
                            name={'repeatPassword'}
                            placeholder={t(
                                'set_password_page.form.placeholder.repeat'
                            )}
                        />
                        <div className="text-red-700 text-sm">
                            <ErrorMessage name="repeatPassword" />
                        </div>
                    </div>

                    <ButtonPrimary
                        extraClasses={
                            'w-full mt-4 flex space-x-4 justify-center py-2 items-center'
                        }
                        isLoading={isSubmitting}
                        type={'submit'}
                    >
                        <span className={'mr-auto'}>
                            {t('set_password_page.button.set')}
                        </span>
                        <ArrowRight fill={'#ffffff'} />
                    </ButtonPrimary>
                </Form>
            )}
        </Formik>
    );
}

export default SetPasswordForm;
