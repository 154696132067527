import React from 'react';

function HoursPageTimesheetOverviewFooter({ weekRange, data }) {
    let totalHours = 0;

    return (
        <div className="flex justify-between py-3">
            <div className="w-64 px-4 font-bold"></div>
            <div className="flex justify-between w-128">
                {weekRange.map((day, index) => {
                    let hours = 0;

                    if (data.length) {
                        for (const project of data) {
                            if (!project.days) continue;

                            for (const item of project.days) {
                                if (item.date === day.format('YYYY-MM-DD')) {
                                    hours += item.hours;
                                }
                            }
                        }
                    }

                    totalHours += hours;

                    return (
                        <div
                            key={index}
                            className="px-4 w-16 font-bold text-right"
                        >
                            {hours.toString().replace('.', ',') || 0}
                        </div>
                    );
                })}
                <div className="font-bold px-4 w-16 text-right">
                    {Number.isInteger(totalHours)
                        ? totalHours.toString().replace('.', ',')
                        : (+totalHours).toFixed(2).toString().replace('.', ',')}
                </div>
            </div>
            <div className="w-12" />
        </div>
    );
}

export default HoursPageTimesheetOverviewFooter;
