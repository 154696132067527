import React, { createContext } from 'react';
import useBreakpoint from '../hooks/useBreakpoint';

// Same as in Strapi (except xs)
// const screens =  {
//       'xs': {'max': '639px'},
//       'sm': {'min': '640px', 'max': '767px'},
//       'md': {'min': '768px', 'max': '1023px'},
//       'lg': {'min': '1024px', 'max': '1279px'},
//       'xl': {'min': '1280px', 'max': '1535px'},
//       '2xl': {'min': '1536px'},
//     }

export const MediaContext = createContext();

export const MediaProvider = ({ children }) => {
    const point = useBreakpoint();

    return (
        <MediaContext.Provider value={point}>{children}</MediaContext.Provider>
    );
};
