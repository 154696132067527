import { useEffect } from 'react';
import { useDefaultStateWithReducer } from './useDefaultStateWithReducer';

export function useFetchDataAndSetState(
    fetchFunction = () => new Promise((res) => res({ status: 204 })),
    useEffectInput = [],
    emptyData = [],
    setLoadingBefore = true,
    requestVariables = []
) {
    const [state, setState] = useDefaultStateWithReducer();
    useEffect(() => {
        let canceled = false;
        if (setLoadingBefore && !canceled) setState({ type: 'SET_LOADING' });
        fetchFunction(...requestVariables)
            ?.then((response) => {
                if (response === 'loading') {
                    setState({ type: 'SET_LOADING' });
                } else if (response.error) {
                    setState({
                        type: 'SET_ERROR',
                        payload: response.error.message,
                    });
                } else if (response.status === 204 && !canceled) {
                    setState({ type: 'SET_DATA', payload: emptyData });
                } else if (!canceled) {
                    setState({ type: 'SET_DATA', payload: response.data });
                }
            })
            .catch((error) => {
                if (!canceled)
                    setState({
                        type: 'SET_ERROR',
                        payload: error.response
                            ? error.response
                            : error?.message,
                    });
            });
        return () => {
            canceled = true;
        };
    }, [...useEffectInput, fetchFunction]);

    return [state, setState];
}
