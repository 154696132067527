import React, { useCallback, useContext, useState, useEffect } from 'react';
import Card from '../../../components/card';
import Loader from '../../../components/loader';
import HoursPageTimesheetOverview from './overview';
import HoursPageTimesheetToolbar from './toolbar';
import { useFetchDataAndSetState } from '../../../helpers/useFetchDataAndSetState';
import { getAllHours } from '../../../api/hours/HoursAPI';
import { UserContext } from '../../../context/UserContext';
import { OrganisationsContext } from '../../../context/OrganisationsContext';
import moment from 'moment';
import UserTimesheetSelect from '../../../components/timsheet-user-select';
import ErrorIndicator from '../../../components/error-indicator';
import { useTranslation } from 'react-i18next';
import { getAdminPermission } from '../../../helpers/getPermission';

const WEEKDAY_MONDAY = 1;
const WEEKDAY_SUNDAY = 7;

function HoursPageTimesheet() {
    const { t } = useTranslation();

    const { organisationsState } = useContext(OrganisationsContext);
    const selectedOrganisationHash = organisationsState.data
        ? organisationsState.data?.find((org) => org.active).org_hash
        : null;
    const demoOrgHash = process.env.REACT_APP_DEMO_ORG_HASH;

    const permissionForWrite = getAdminPermission(organisationsState?.data);

    const { userState } = useContext(UserContext);

    const [userHash, setUserHash] = useState(null);

    const [userProjects, setUserProjects] = useState(null);

    const [filterDate, setFilterDate] = useState(moment());

    let weekRange = [];
    let weekDay = WEEKDAY_MONDAY;
    while (weekDay <= WEEKDAY_SUNDAY) {
        weekRange.push(filterDate.clone().day(weekDay));
        weekDay++;
    }

    const [updatesCounter, setUpdatesCounter] = useState(0);

    const request = useCallback(
        () =>
            getAllHours({
                params: {
                    filterDate: filterDate.clone().day(1).format('YYYY-MM-DD'),
                    interval: 7,
                },
            }),
        [filterDate]
    );
    const [hoursState] = useFetchDataAndSetState(
        request,
        [selectedOrganisationHash, updatesCounter],
        [{ selected_projects: [], projects_data: [] }]
    );
    const { data, loading, error } = hoursState;

    const [emptyRow, setEmptyRow] = useState(null);

    useEffect(() => {
        if (selectedOrganisationHash === demoOrgHash) {
            setUserHash('URICHAR');
        } else {
            setUserHash(userState?.data?.user_hash || null);
        }
    }, [selectedOrganisationHash]);

    useEffect(() => {
        setUserProjects(filterUserProjectsData(userHash));
    }, [userHash, data]);

    useEffect(() => {
        setUserHash(userState?.data?.user_hash);
    }, [userState?.data]);

    function handleUpdateTable() {
        setUpdatesCounter(updatesCounter + 1);
    }
    function addEmptyRow() {
        setEmptyRow({ project: '', days: [] });
    }

    function renderUserSelect() {
        return (
            <UserTimesheetSelect
                isPermission={permissionForWrite}
                permission={
                    organisationsState.data
                        ? organisationsState.data?.find((org) => org.active)
                              .permissions
                        : null
                }
                setUserHash={setUserHash}
                viewerData={userState?.data}
                userHash={userHash}
            />
        );
    }

    function filterUserProjectsData(userHash) {
        const user = data
            ? data?.find((userData) => userData?.user?.hash === userHash)
            : null;
        if (user) {
            return user;
        } else {
            return { projects_data: [], selected_projects: [] };
        }
    }

    return (
        <Card
            title={t('hours_page.timesheet.title')}
            width={'w-full'}
            renderActionButtons={renderUserSelect}
        >
            {userHash ? (
                <div>
                    <HoursPageTimesheetToolbar
                        filterDate={filterDate}
                        setFilterDate={setFilterDate}
                        addRow={addEmptyRow}
                        updateDates={handleUpdateTable}
                        addDisabled={Boolean(emptyRow)}
                        userHash={userHash}
                        viewerHash={userState.data?.user_hash}
                    />
                    {loading && (
                        <div className="flex mt-8 justify-center">
                            <Loader
                                color={'#C2C7D7'}
                                size={35}
                                speedMultiplier={0.8}
                            />
                        </div>
                    )}

                    {error && !loading && <ErrorIndicator error={error} />}
                    {!loading && !error && (
                        <HoursPageTimesheetOverview
                            data={
                                emptyRow
                                    ? [...userProjects.projects_data, emptyRow]
                                    : userProjects?.projects_data
                            }
                            weekRange={weekRange}
                            triggerUpdate={handleUpdateTable}
                            selectedProjects={userProjects?.selected_projects}
                            setEmptyRow={setEmptyRow}
                            userHash={userHash}
                            viewerHash={userState.data.user_hash}
                            filterDate={filterDate}
                        />
                    )}
                </div>
            ) : (
                <p className="font-display text-center text-gray-500 text-xl p-4">
                    {' '}
                    No users in the organisation
                </p>
            )}
        </Card>
    );
}

export default HoursPageTimesheet;
