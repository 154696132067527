import React, { useState } from 'react';
import moment from 'moment';

function SreFormFilterTryRow({ row, i }) {
    const [showingFullRatio, setShowingFullRatio] = useState(false);

    function formatTimeperiod(timeperiodString) {
        let formatted = timeperiodString;
        const timestampsArray = timeperiodString?.split(' - ');

        if (timestampsArray?.length > 1) {
            formatted = timestampsArray
                ?.map((time, i) =>
                    i === 0
                        ? moment(time).format('DD-MM-YYYY HH:mm')
                        : moment(time).format('DD-MM-YYYY HH:mm')
                )
                .join(' – ');
        }

        return formatted;
    }

    function getInterval(i) {
        switch (i) {
            case 0:
                return 'Last hour';
            case 1:
                return 'Last day';
            case 2:
                return 'Last week';
            default:
                return '';
        }
    }

    return (
        <div className="grid grid-cols-12 gap-2 p-4" key={i}>
            <p className="col-span-1">{getInterval(i)}</p>
            <p className="col-span-3">{formatTimeperiod(row.time_period)}</p>
            <p className="col-span-2">{row.good_events}</p>
            <p className="col-span-2">{row.bad_events}</p>
            <p className="col-span-2">{row.total_events}</p>
            <p
                className="col-span-2 relative "
                onMouseEnter={() => setShowingFullRatio(true)}
                onMouseLeave={() => setShowingFullRatio(false)}
            >
                {+row.ratio.toFixed(3)}
                {`%`}
                {showingFullRatio ? (
                    <span className="absolute  bg-white p-1 border border-solid rounded border-gray-300 text-xs text-gray-500 left-[52px] ">
                        {+row.ratio}
                        {`%`}
                    </span>
                ) : null}
            </p>
        </div>
    );
}

export default SreFormFilterTryRow;
