const billingPlansData = [
    {
        name: 'Trial',
        value: 'trial',
        rating: 1,
        description: '',
        priceText: 'Free',
        translationKey: 'settings_page.org_tab.free',
        eurosPerMonth: '',
        eurosPerMonthYearly: '',
        eurosPerMonthMonthly: '',
        featuresPlain: [
            {
                type: 'retention',
                amount: ['2', 'month'],
            },
        ],
        featuresListItems: [
            {
                name: 'Error Budgets',
                translationKey: 'pages.error-budgets',
                description:
                    'A real-time visualisation of the service level that your team delivers. Gives you actionable data for managing the reliability of your services.',
            },
            {
                name: 'Sprint Insights',
                translationKey: 'pages.sprint-insights',
                description:
                    'Provides clear insights in the feature development balance and creates more focus in your team, by automatically registering the time spent on feature versus not-feature work.',
            },
            {
                name: 'Leaks & Stock',
                translationKey: 'settings_page.org_tab.leaks_stock',
                description:
                    'Automatically scans your Git repositories on lost keys. Also provides you with data about scheduled development changes that haven’t been finished for awhile.',
            },
            {
                name: 'Hours',
                translationKey: 'pages.hours',
                description:
                    'Use existing sources of information to track of how many hours are spent on what tasks. Hour reports are required documents for subsidies.',
            },
            {
                name: 'Kudos',
                translationKey: 'pages.kudos',
                description:
                    'Reward each other with Kudos and celebrate team achievements together. Allows you to recognize each other, have a little fun together and connect with your team.',
            },
            {
                name: 'Single Sign On',
                description:
                    'Connect with Identity Providers (IDP) like Azure AD, Google Cloud Identity or Okta to give users access to Agile Analytics without the need to do user admin. We support open standards such as SAML, OAuth2 and OIDC',
            },
            {
                name: 'Dora Metrics',
                translationKey: 'pages.dora',
                description:
                    'Want high value software and a happier team? Then start measuring the four DORA metrics to navigate continuous improvements in your software performance.',
            },
        ],
    },
    {
        name: 'Basic',
        value: 'basic',
        rating: 2,
        description: '',
        priceText: '',
        eurosPerMonth: '',
        eurosPerMonthYearly: '10',
        eurosPerMonthMonthly: '14',
        featuresPlain: [
            {
                type: 'retention',
                amount: ['2', 'month'],
            },
        ],
        featuresListItems: [
            {
                name: 'Error Budgets',
                translationKey: 'pages.error-budgets',
                description:
                    'A real-time visualisation of the service level that your team delivers. Gives you actionable data for managing the reliability of your services.',
            },
            {
                name: 'Sprint Insights',
                translationKey: 'pages.sprint-insights',
                description:
                    'Provides clear insights in the feature development balance and creates more focus in your team, by automatically registering the time spent on feature versus not-feature work.',
            },
            {
                name: 'Hours',
                translationKey: 'pages.hours',
                description:
                    'Use existing sources of information to track of how many hours are spent on what tasks. Hour reports are required documents for subsidies.',
            },
            {
                name: 'Kudos',
                translationKey: 'pages.kudos',
                description:
                    'Reward each other with Kudos and celebrate team achievements together. Allows you to recognize each other, have a little fun together and connect with your team.',
            },
        ],
    },
    {
        name: 'Professional',
        value: 'professional',
        rating: 3,
        description: '',
        priceText: '',
        eurosPerMonth: '',
        eurosPerMonthYearly: '17',
        eurosPerMonthMonthly: '22',
        featuresPlain: [
            {
                type: 'retention',
                amount: ['2', 'months'],
            },
        ],
        featuresListItems: [
            {
                name: 'Error Budgets',
                translationKey: 'pages.error-budgets',
                description:
                    'A real-time visualisation of the service level that your team delivers. Gives you actionable data for managing the reliability of your services.',
            },
            {
                name: 'Sprint Insights',
                translationKey: 'pages.sprint-insights',
                description:
                    'Provides clear insights in the feature development balance and creates more focus in your team, by automatically registering the time spent on feature versus not-feature work.',
            },
            {
                name: 'Hours',
                translationKey: 'pages.hours',
                description:
                    'Use existing sources of information to track of how many hours are spent on what tasks. Hour reports are required documents for subsidies.',
            },
            {
                name: 'Kudos',
                translationKey: 'pages.kudos',
                description:
                    'Reward each other with Kudos and celebrate team achievements together. Allows you to recognize each other, have a little fun together and connect with your team.',
            },
            {
                name: 'Single Sign On',
                description:
                    'Connect with Identity Providers (IDP) like Azure AD, Google Cloud Identity or Okta to give users access to Agile Analytics without the need to do user admin. We support open standards such as SAML, OAuth2 and OIDC',
            },
            {
                name: 'Dora Metrics',
                translationKey: 'pages.dora',
                description:
                    'Want high value software and a happier team? Then start measuring the four DORA metrics to navigate continuous improvements in your software performance.',
            },
        ],
    },
    {
        name: 'Enterprise',
        value: 'enterprise',
        rating: 4,
        description: '',
        priceText: '',
        translationKey: 'settings_page.org_tab.contact',
        eurosPerMonth: '',
        eurosPerMonthYearly: '35',
        eurosPerMonthMonthly: '44',
        featuresPlain: [
            {
                type: 'retention',
                amount: ['2', 'months'],
            },
        ],
        featuresListItems: [
            {
                name: 'Error Budgets',
                translationKey: 'pages.error-budgets',
                description:
                    'A real-time visualisation of the service level that your team delivers. Gives you actionable data for managing the reliability of your services.',
            },
            {
                name: 'Sprint Insights',
                translationKey: 'pages.sprint-insights',
                description:
                    'Provides clear insights in the feature development balance and creates more focus in your team, by automatically registering the time spent on feature versus not-feature work.',
            },
            {
                name: 'Hours',
                translationKey: 'pages.hours',
                description:
                    'Use existing sources of information to track of how many hours are spent on what tasks. Hour reports are required documents for subsidies.',
            },
            {
                name: 'Kudos',
                translationKey: 'pages.kudos',
                description:
                    'Reward each other with Kudos and celebrate team achievements together. Allows you to recognize each other, have a little fun together and connect with your team.',
            },
            {
                name: 'Single Sign On',
                description:
                    'Connect with Identity Providers (IDP) like Azure AD, Google Cloud Identity or Okta to give users access to Agile Analytics without the need to do user admin. We support open standards such as SAML, OAuth2 and OIDC',
            },
            {
                name: 'Dora Metrics',
                translationKey: 'pages.dora',
                description:
                    'Want high value software and a happier team? Then start measuring the four DORA metrics to navigate continuous improvements in your software performance.',
            },
            {
                name: 'Leaks & Stock',
                translationKey: 'settings_page.org_tab.leaks_stock',
                description:
                    'Automatically scans your Git repositories on lost keys. Also provides you with data about scheduled development changes that haven’t been finished for awhile.',
            },
        ],
    },
    {
        name: 'Enterprise +',
        value: 'enterprise-plus',
        rating: 5,
        description: '',
        priceText: 'Contact sales',
        translationKey: 'settings_page.org_tab.contact',
        eurosPerMonth: '',
        eurosPerMonthYearly: '',
        eurosPerMonthMonthly: '',
        featuresPlain: [
            {
                type: 'retention',
                amount: ['2', 'months'],
            },
        ],
        featuresListItems: [
            {
                name: 'Error Budgets',
                translationKey: 'pages.error-budgets',
                description:
                    'A real-time visualisation of the service level that your team delivers. Gives you actionable data for managing the reliability of your services.',
            },
            {
                name: 'Sprint Insights',
                translationKey: 'pages.sprint-insights',
                description:
                    'Provides clear insights in the feature development balance and creates more focus in your team, by automatically registering the time spent on feature versus not-feature work.',
            },
            {
                name: 'Hours',
                translationKey: 'pages.hours',
                description:
                    'Use existing sources of information to track of how many hours are spent on what tasks. Hour reports are required documents for subsidies.',
            },
            {
                name: 'Kudos',
                translationKey: 'pages.kudos',
                description:
                    'Reward each other with Kudos and celebrate team achievements together. Allows you to recognize each other, have a little fun together and connect with your team.',
            },
            {
                name: 'Single Sign On',
                description:
                    'Connect with Identity Providers (IDP) like Azure AD, Google Cloud Identity or Okta to give users access to Agile Analytics without the need to do user admin. We support open standards such as SAML, OAuth2 and OIDC',
            },
            {
                name: 'Dora Metrics',
                translationKey: 'pages.dora',
                description:
                    'Want high value software and a happier team? Then start measuring the four DORA metrics to navigate continuous improvements in your software performance.',
            },
            {
                name: 'Leaks & Stock',
                translationKey: 'settings_page.org_tab.leaks_stock',
                description:
                    'Automatically scans your Git repositories on lost keys. Also provides you with data about scheduled development changes that haven’t been finished for awhile.',
            },
        ],
    },
];

export default billingPlansData;
