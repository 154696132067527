import React, { useContext, useCallback } from 'react';
import KudosLast from './last';
import KudosTotal from './total';
import KudosLeaderboard from './leaderboard';
import ErrorBoundry from '../error-boundary';
import { TimePeriodContext } from '../../context/TimePeriodContext';
import { useFetchDataAndSetState } from '../../helpers/useFetchDataAndSetState';
import { getTeamKudosLeaderBoard } from '../../api/teams/TeamsAPI';
import { getEmoji } from '../../api/settings/EmojiAPI';

const KudosLeaderboardWrapper = ({
    setActiveTeam,
    selectedTeam,
    localTeamsState,
    teamsState,
    usersState,
}) => {
    const [timePeriodState] = useContext(TimePeriodContext);

    const { date_end, date_start } = timePeriodState;

    const kudosRequest = useCallback(() => getEmoji(), []);

    const leaderboardRequest = useCallback(
        () =>
            getTeamKudosLeaderBoard(selectedTeam, {
                params: { date_start, date_end },
            }),
        [selectedTeam, date_start, date_end]
    );

    const [kudosLeaderBoardState] = useFetchDataAndSetState(
        leaderboardRequest,
        [selectedTeam, timePeriodState.date_start, timePeriodState.date_end],
        {
            total_kudos: 0,
            team_members_list: [],
            last_kudo: {},
        }
    );

    const [kudosState] = useFetchDataAndSetState(kudosRequest, [selectedTeam]);

    return (
        <div className="flex gap-x-5 mb-11">
            <div className="w-full h-340 bg-white py-2 px-2 rounded-lg shadow-md">
                <ErrorBoundry>
                    <KudosLast
                        usersState={usersState}
                        kudosState={kudosState}
                        kudosLeaderBoardState={kudosLeaderBoardState}
                    />
                </ErrorBoundry>
            </div>
            <div className="w-full h-340 bg-white py-2 px-2 rounded-lg shadow-md">
                <ErrorBoundry>
                    <KudosTotal
                        kudosState={kudosState}
                        kudosLeaderBoardState={kudosLeaderBoardState}
                    />
                </ErrorBoundry>
            </div>
            <div className="w-full h-340 bg-white py-2 px-2 rounded-lg shadow-md">
                <ErrorBoundry>
                    <KudosLeaderboard
                        localTeamsState={localTeamsState}
                        selectedTeam={selectedTeam}
                        setActiveTeam={setActiveTeam}
                        teamsState={teamsState}
                        usersState={usersState}
                        kudosState={kudosState}
                        kudosLeaderBoardState={kudosLeaderBoardState}
                    />
                </ErrorBoundry>
            </div>
        </div>
    );
};

export default KudosLeaderboardWrapper;
