import { instances, customInstances } from '../default-setup';

export const getJiraWorkspaces = () => instances.get('settings/jira/');

export const deleteJiraWorkspace = (workspace, userHash) =>
    instances.delete(`settings/jira/?name=${workspace}&user=${userHash}`);

export const createJiraWebhook = (data) =>
    instances.post('settings/jira/webhooks/', data);

export const checkJiraWebhooks = (userHash) =>
    instances.get(`settings/jira/check_webhook/?user_hash=${userHash}`);
