import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const ModalDeleteOrg = ({ setModalState, sendRequest, orgName }) => {
    const [isDeletingAllowed, setIsDeletingAllowed] = useState(false);
    const { t } = useTranslation();

    const handleChangeModalState = () => {
        setModalState(false);
    };

    const handleDelete = () => {
        isDeletingAllowed && sendRequest();
        isDeletingAllowed && setModalState(false);
    };

    function handleInputChange(value) {
        setIsDeletingAllowed(value.toLowerCase() === 'delete');
    }

    return (
        <div className="flex flex-col pb-2 pt-5 h-fit text-gray-500 gap-4">
            <p className="text-xl text-gray-500">
                Are you sure you want to delete{' '}
                <span className="italic">{orgName}</span>?
            </p>
            <div>
                <label htmlFor="delete">{'Type "DELETE" to proceed'}</label>
                <input
                    id="delete"
                    type="text"
                    title={'Awaits for delete'}
                    className=" mt-2 border-solid border-2 border-gray-300 rounded-md px-2 py-1 w-full focus:outline-none focus:border-theme-tertiary disabled:cursor-not-allowed disabled:bg-gray-200"
                    onChange={(e) => handleInputChange(e.target.value)}
                />
                {isDeletingAllowed ? (
                    <p className="text-red-text text-sm h-6"></p>
                ) : (
                    <p className="text-red-text text-sm h-6">
                        Type "DELETE" to proceed
                    </p>
                )}
            </div>
            <div className="ml-auto mt-auto">
                <button
                    type="button"
                    className="inline-flex mr-4 justify-center px-6 py-2 text-lg leading-6	font-normal text-gray-500 bg-gray-300 border border-transparent rounded-md hover:bg-gray-200"
                    onClick={handleChangeModalState}
                    style={{ minWidth: '110px' }}
                >
                    {t('button.close')}
                </button>
                {isDeletingAllowed ? (
                    <button
                        type="button"
                        className={`inline-flex justify-center px-6 py-2 text-lg leading-6	font-normal text-white bg-red-text border border-transparent rounded-md hover:bg-red-800 disabled:cursor-not-allowed disabled:opacity-50`}
                        onClick={handleDelete}
                        style={{ minWidth: '110px' }}
                    >
                        {t('button.remove')}
                    </button>
                ) : (
                    <button
                        type="button"
                        className={`inline-flex justify-center px-6 py-2 text-lg leading-6	font-normal text-white bg-red-text border border-transparent rounded-md hover:bg-red-800 disabled:cursor-not-allowed disabled:opacity-50`}
                        onClick={handleDelete}
                        disabled
                        style={{ minWidth: '110px' }}
                    >
                        {t('button.remove')}
                    </button>
                )}
            </div>
        </div>
    );
};

export default ModalDeleteOrg;
