import React, { useEffect, useState, useContext } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useNavigate, useLocation, useParams } from 'react-router';
import { OrganisationsContext } from '../context/OrganisationsContext';
import ErrorBoundary from '../components/error-boundary';
import TabsNav from '../components/tabs-nav';
import {
    KudosTab,
    OrganisationTab,
    ApiKeyTab,
    VCSTab,
    SprintTab,
    SamlTab,
    ScheduleTab,
    UsersTab,
    SprintInsightsTab,
    SRETab,
    BillingTab,
} from '../components/settings/settings-tabs';
import { withUserAndOragnisations } from '../helpers/withUser';
import './settings-styles.scss';
import { getAdminPermission } from '../helpers/getPermission';
import { useTranslation } from 'react-i18next';
import { capitalizeEveryWordFirstLetter } from '../helpers/textFormatters';

const SettingsPage = () => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState('organisation');
    let navigate = useNavigate();
    let { pathname } = useLocation();
    const demoOrgHash = process.env.REACT_APP_DEMO_ORG_HASH;

    const { organisationsState } = useContext(OrganisationsContext);

    const selectedOrgHash = organisationsState.data
        ? organisationsState.data.find((org) => org.active).org_hash
        : null;

    const permissionForWrite = getAdminPermission(organisationsState?.data);

    const tabNavsArray = [
        {
            id: 'organisation',
            displayedName: capitalizeEveryWordFirstLetter(
                t('settings_page.org_tab.title')
            ),
        },
        {
            id: 'billing',
            displayedName: capitalizeEveryWordFirstLetter(
                t('settings_page.billing_tab.title')
            ),
        },
        { id: 'saml', displayedName: 'SAML' },
        {
            id: 'users',
            displayedName: capitalizeEveryWordFirstLetter(
                t('settings_page.users_tab.title')
            ),
        },
        // { id: 'teams', displayedName: 'Teams' }, // migrated to the Teams page
        { id: 'kudos', displayedName: 'Kudos' },
        // { id: 'sprint', displayedName: 'Sprint' }, // migrated to the Teams page
        { id: 'vcs', displayedName: 'Git Providers' },
        { id: 'api-key', displayedName: 'AA API Key' },
        { id: 'schedule', displayedName: 'Jobs Schedule' },
        {
            id: 'sprint-insights',
            displayedName: capitalizeEveryWordFirstLetter(
                t('settings_page.si_tab.title')
            ),
        },
        {
            id: 'error-budgets',
            displayedName: capitalizeEveryWordFirstLetter(
                t('settings_page.sre_tab.title')
            ),
        },
    ];

    useEffect(() => {
        selectedOrgHash === demoOrgHash && navigate(`/`);
    }, [selectedOrgHash]);

    useEffect(() => {
        window.location.pathname === '/settings'
            ? navigate(`${pathname}/${activeTab}`)
            : setActiveTab(window.location.pathname.replace('/settings/', ''));
    }, []);

    useEffect(() => {
        window.location.pathname === '/settings'
            ? navigate(`${pathname}/${activeTab}`)
            : setActiveTab(window.location.pathname.replace('/settings/', ''));
    }, [window.location.pathname]);

    return (
        <div className="">
            <TabsNav
                active={activeTab}
                setActiveTab={setActiveTab}
                tabNavsArray={tabNavsArray}
            />
            <div className="settings-content-wrapper">
                <Routes>
                    <Route
                        path={`/:activeTab`}
                        element={<ActiveTab permission={permissionForWrite} />}
                    />
                </Routes>
            </div>
        </div>
    );
};

function ActiveTab({ permission }) {
    const { activeTab } = useParams();

    function renderTab() {
        switch (activeTab) {
            case 'error-budgets':
                return <SRETab />;
            case 'sprint-insights':
                return <SprintInsightsTab />;
            case 'schedule':
                return <ScheduleTab />;
            case 'users':
                return <UsersTab />;
            // case 'sprint':
            //     return <SprintTab />;
            case 'saml':
                return <SamlTab />;
            case 'api-key':
                return <ApiKeyTab />;
            case 'vcs':
                return <VCSTab />;
            case 'kudos':
                return <KudosTab />;
            case 'billing':
                return <BillingTab permissionForWrite={permission} />;
            case 'organisation':
            default:
                return <OrganisationTab permissionForWrite={permission} />;
        }
    }

    return <ErrorBoundary>{renderTab()}</ErrorBoundary>;
}

export default withUserAndOragnisations(SettingsPage);
