import React, { useContext, useEffect, useState } from 'react';
import { getServices } from '../../api/settings-slo/slo';
import { Link } from 'react-router-dom';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { IoIosArrowDown } from 'react-icons/io';
import { useTranslation } from 'react-i18next';
import { OrgUserListContext } from '../../context/OrgUserListContext';
import { createTeam, deleteTeam, updateTeam } from '../../api/teams/TeamsAPI';
import { getSelectedRepositories } from '../../api/swarm/SwarmAPI';
import { useFetchDataAndSetState } from '../../helpers/useFetchDataAndSetState';
import { capitalizeFirstLetter } from '../../helpers/textFormatters';
import showNotification from '../../helpers/showNotification';
import FormField from '../../pages/sre-page/components/sre-form-field';
import ModalDeletingContent from '../../pages/sre-page/components/sre-modal-delete-feature-content';
import WhitePanelContentWrapper from '../white-panel-content-wrapper';
import TeamUsersForm from '../team-users-form';
import TeamSprintForm from '../team-sprint-form';
import TeamSelectorForm from '../team-selector-form';
import DoraMetricsFilter from '../dora-metrics-filter';
import Loader from '../loader';
import ModalWrapper from '../modal-wrapper';

function TeamEditor({
    teamData,
    teamsState,
    teamsDispatch,
    selectedOrganisationHash,
    setIsCreateNewTeam,
    setTeamInEditingMode,
}) {
    const { t } = useTranslation();
    const { orgUserListState, orgUserListDispatch } =
        useContext(OrgUserListContext);

    const [initialValues, setInitialValues] = useState({
        name: teamData?.team_name ? teamData?.team_name : '',
        users: teamData?.users_in_team ? teamData?.users_in_team : [],
        services: teamData?.team_services ? teamData?.team_services : [],
        repositories: teamData?.team_repositories
            ? formatAllTeamRepos(teamData?.team_repositories)
            : [],
        sprintStart: teamData?.sprint?.start_date
            ? teamData?.sprint?.start_date
            : 'Undefined',
        sprintModified: teamData?.sprint?.modified_date
            ? teamData?.sprint?.modified_date
            : 'Undefined',
        sprintDuration: teamData?.sprint?.duration
            ? teamData?.sprint?.duration
            : 'Undefined',
    });

    const [allServices] = useFetchDataAndSetState(getServices, [
        selectedOrganisationHash,
    ]);

    const tabsOrder = ['users', 'services', 'repositories', 'sprint'];
    const tabsTitles = {
        users: "Select team's users",
        services: "Select team's Error Budgets services",
        repositories: "Select team's repositories",
        sprint: "Set up team's sprints schedule",
    };
    const [tabsStatus, setTabsStatus] = useState({
        users: false,
        sprint: false,
        services: false,
        repositories: false,
    });

    const [selectedRepositoriesState] = useFetchDataAndSetState(
        getSelectedRepositories,
        [selectedOrganisationHash]
    );

    const [repositoriesFilter, setRepositoriesFilter] = useState([]);

    const [loading, setLoading] = useState(false);
    const [modalState, setModalState] = useState(false);
    modalState;

    useEffect(() => {
        setInitialValues({
            name: teamData?.team_name ? teamData?.team_name : '',
            users: teamData?.users_in_team ? teamData?.users_in_team : [],
            services: teamData?.team_services ? teamData?.team_services : [],
            repositories: teamData?.team_repositories
                ? formatAllTeamRepos(teamData?.team_repositories)
                : [],
            sprintStart: teamData?.sprint?.start_date
                ? teamData?.sprint?.start_date
                : 'Undefined',
            sprintModified: teamData?.sprint?.modified_date
                ? teamData?.sprint?.modified_date
                : 'Undefined',
            sprintDuration: teamData?.sprint?.duration
                ? teamData?.sprint?.duration
                : 'Undefined',
        });
    }, [teamData]);

    //formatting All repositories for the filter (adding isSelected: true/false)
    useEffect(() => {
        if (selectedRepositoriesState?.data?.length) {
            const allTeamRepos = formatAllTeamRepos(
                teamData?.team_repositories
            );
            const formatted = selectedRepositoriesState?.data.reduce(
                (acc, item) => {
                    const group = item.provider_id;
                    const options = item.repositories.map((repository) => {
                        return {
                            ...repository,
                            group: group,
                            groupInProvider: repository?.group,
                            isSelected: allTeamRepos?.find(
                                (teamRepo) => teamRepo?.url === repository?.url
                            )
                                ? true
                                : false,
                        };
                    });
                    return [...acc, ...options];
                },
                []
            );

            setRepositoriesFilter(formatted);
        } else {
            setRepositoriesFilter([]);
        }
    }, [selectedRepositoriesState?.data, teamData]);

    function formatAllTeamRepos(teamDataRepos) {
        return teamDataRepos?.reduce((acc, provider) => {
            const formattedRepos = provider?.repositories?.map((item) => {
                return {
                    ...item,
                    groupInProvider: item?.group,
                    group: provider?.provider_id,
                };
            });
            return [...acc, ...formattedRepos];
        }, []);
    }

    function updateReposList(reposList, setFieldValue) {
        setRepositoriesFilter(reposList);

        const formattedList = reposList
            .filter((repo) => repo.isSelected)
            .map((item) => {
                const formattedItem = { ...item };
                delete formattedItem.isSelected;
                return formattedItem;
            });

        setFieldValue('repositories', formattedList);
    }

    function formatUpdatedRepos(repos) {
        const formatted = selectedRepositoriesState?.data?.reduce(
            (acc, provider) => {
                if (
                    repos.find((repo) => repo?.group === provider.provider_id)
                ) {
                    const providerRepos = {
                        ...provider,
                        repositories: repos
                            .filter(
                                (repo) => repo?.group === provider.provider_id
                            )
                            .map((item) => {
                                const updatedItem = {
                                    ...item,
                                    group: item?.groupInProvider,
                                };
                                delete updatedItem['groupInProvider'];
                                return updatedItem;
                            }),
                    };
                    return [...acc, providerRepos];
                }
                return acc;
            },
            []
        );

        return formatted;
    }

    async function handleTeamUpdate(values) {
        setLoading(true);

        try {
            const updatedServices = values.services.map((service) => {
                const updated = service;
                delete updated.inTeam;
                return updated;
            });
            const updatedTeamFormattedData = {
                team_name: values.name,
                users_in_team: values.users,
                team_services: updatedServices,
                team_repositories: formatUpdatedRepos(values.repositories),
                sprint: {
                    start_date:
                        values?.sprintStart &&
                        values?.sprintStart !== 'Undefined'
                            ? values?.sprintStart
                            : null,
                    modified_date:
                        values?.sprintModified &&
                        values?.sprintModified !== 'Undefined'
                            ? values?.sprintModified
                            : null,
                    duration:
                        values?.sprintDuration &&
                        values?.sprintDuration !== 'Undefined'
                            ? values?.sprintDuration
                            : null,
                },
                modified_date: moment().unix(),
            };

            const res = await updateTeam(updatedTeamFormattedData, {
                params: { team_hash: teamData.team_hash },
            });

            if (res.status === 200) {
                const newTeamList = teamsState.data.map((team) => {
                    if (team.team_hash === teamData.team_hash) {
                        return {
                            ...updatedTeamFormattedData,
                            team_hash: teamData.team_hash,
                        };
                    }
                    return team;
                });

                teamsDispatch({
                    type: 'SET_DATA',
                    payload: newTeamList,
                });
                setTeamInEditingMode(null);

                //update orgUserListContext
                updateOrgUserList(
                    updatedTeamFormattedData,
                    teamData?.team_hash
                );
            }

            setLoading(false);
        } catch (error) {
            showNotification(`Failed to update the team. ${error?.message}`);
            setLoading(false);
        }
    }

    function updateOrgUserList(updatedTeamData, teamHash) {
        //check all users: 1) the one which in users_in_team should have org hash, and ones which not, shouldnt
        const updatedOrgUserList = orgUserListState.data?.map((user) => {
            let updatedUser = user;
            const isInCurrentTeam = updatedTeamData?.users_in_team?.find(
                (hash) => hash === user.hash
            );
            if (isInCurrentTeam) {
                updatedUser = {
                    ...user,
                    teams: [...user.teams, teamHash],
                };
            } else {
                //user is not in current team; check if it is present in their list and delete if necsesary
                updatedUser = {
                    ...user,
                    teams: user.teams?.reduce((acc, team) => {
                        if (team === teamHash) {
                            return acc;
                        }
                        return [...acc, team];
                    }, []),
                };
            }
            return updatedUser;
        });

        orgUserListDispatch({
            type: 'SET_DATA',
            payload: updatedOrgUserList,
        });
    }

    async function handleTeamCreate(values) {
        setLoading(true);

        try {
            const updatedServices = values.services.map((service) => {
                const updated = service;
                delete updated.inTeam;
                return updated;
            });

            const newTeamFormattedData = {
                team_name: values.name,
                users_in_team: values.users,
                team_services: updatedServices,
                team_repositories: formatUpdatedRepos(values.repositories),
                sprint: {
                    start_date:
                        values?.sprintStart &&
                        values?.sprintStart !== 'Undefined'
                            ? values?.sprintStart
                            : null,
                    modified_date:
                        values?.sprintModified &&
                        values?.sprintModified !== 'Undefined'
                            ? values?.sprintModified
                            : null,
                    duration:
                        values?.sprintDuration &&
                        values?.sprintDuration !== 'Undefined'
                            ? values?.sprintDuration
                            : null,
                },
                created_date: moment().unix(),
            };

            const res = await createTeam(newTeamFormattedData);

            if (res.status === 200) {
                const newTeamList = [
                    { ...newTeamFormattedData, team_hash: res?.data },
                    ...teamsState.data,
                ];

                teamsDispatch({
                    type: 'SET_DATA',
                    payload: newTeamList,
                });
                setIsCreateNewTeam(false);
                updateOrgUserList(newTeamFormattedData, res?.data);
            }

            setLoading(false);
        } catch (error) {
            showNotification(`Failed to create the team. ${error?.message}`);
            setLoading(false);
        }
    }

    function renderTabContent(
        tabName,
        values,
        setFieldTouched,
        setFieldValue,
        errors
    ) {
        switch (tabName) {
            case 'users':
                return (
                    <div>
                        <TeamUsersForm
                            users={values?.users}
                            usersState={orgUserListState}
                            setFieldTouched={setFieldTouched}
                            setFieldValue={setFieldValue}
                        />
                        {errors[tabName] ? (
                            <p className="text-sm text-red-text">
                                {errors[tabName]}
                            </p>
                        ) : null}
                    </div>
                );
            case 'sprint':
                return (
                    <div>
                        <TeamSprintForm
                            sprintStart={values?.sprintStart}
                            sprintDuration={values?.sprintDuration}
                            setFieldTouched={setFieldTouched}
                            setFieldValue={setFieldValue}
                        />
                        {errors[tabName] ? (
                            <p className="text-sm text-red-text">
                                {errors[tabName]}
                            </p>
                        ) : null}
                    </div>
                );
            case 'services':
                return (
                    <TeamSelectorForm
                        selectedItems={values?.services}
                        allItemsState={allServices}
                        setFieldTouched={setFieldTouched}
                        setFieldValue={setFieldValue}
                        fieldName={'services'}
                        uniqueItemPropertyName={'service'}
                        sortingItemPropertyName={'service'}
                        allItemsColTitle={'All services'}
                        selectedItemsColTitle={'Team services'}
                    />
                );
            case 'repositories':
                return (
                    <div>
                        <div className="w-full flex flex-col gap-1 items-end mb-8">
                            <Link
                                to="/dora#repositories-list"
                                className="text-xs text-theme-tertiary hover:text-theme-tertiary-dark hover:underline"
                            >
                                To add repositpries or manage webhooks go to
                                Repositories list
                            </Link>
                            <Link
                                to="/settings/vcs"
                                className="text-xs text-theme-tertiary hover:text-theme-tertiary-dark hover:underline"
                            >
                                To add Git provider, go to Settings
                            </Link>
                        </div>
                        <div className="flex justify-center">
                            {selectedRepositoriesState?.loading ? (
                                <Loader />
                            ) : (
                                <DoraMetricsFilter
                                    options={repositoriesFilter}
                                    setOptions={(opt) => {
                                        updateReposList(opt, setFieldValue);
                                    }}
                                    title={capitalizeFirstLetter('')}
                                    inTeam={true}
                                />
                            )}
                        </div>
                    </div>
                );
            default:
                return <div>No data</div>;
        }
    }

    async function handleDeleteTeam() {
        setLoading(true);
        let newTeamList = teamsState.data.filter(
            (team) => team.team_hash !== teamData.team_hash
        );

        try {
            const res = await deleteTeam({
                params: { team_hash: teamData.team_hash },
            });

            if (res.status === 200) {
                teamsDispatch({
                    type: 'SET_DATA',
                    payload: newTeamList,
                });

                updateOrgUserList({ users_in_team: [] }, teamData.team_hash);
            }
        } catch (error) {
            showNotification(`Failed to delete the team. ${error?.message}`);
            setLoading(false);
        }
    }

    return (
        <WhitePanelContentWrapper>
            <div className="flex flex-col h-full w-full gap-4">
                <Formik
                    initialValues={initialValues}
                    validationSchema={Yup.object({
                        name: Yup.string().required('Team name is required'),
                        users: Yup.array().min(
                            1,
                            'At least 1 user is required'
                        ),
                    })}
                    onSubmit={async (values) => {
                        teamData?.team_name
                            ? handleTeamUpdate(values)
                            : handleTeamCreate(values);
                    }}
                >
                    {({
                        errors,
                        touched,
                        values,
                        isValid,
                        dirty,
                        setFieldTouched,
                        setFieldValue,
                    }) => {
                        return (
                            <Form>
                                <div className=" mb-3 flex flex-col gap-2">
                                    <div>
                                        <div className="border-b-2 border-solid border-theme-secondary mb-4">
                                            <FormField
                                                attributeName={'name'}
                                                fieldType={'name'}
                                                placeholderText={
                                                    'Enter team name'
                                                }
                                                error={errors.name}
                                                touched={touched.name}
                                                className="font-display text-28 text-gray-700 font-light "
                                            />
                                        </div>
                                    </div>

                                    <div className="flex flex-col divide-y divide-solid divide-gray-300 rounded border border-solid border-gray-300">
                                        {tabsOrder.map((tabName, i) => (
                                            <div key={tabName}>
                                                <div
                                                    className={`cursor-pointer basic-transition  font-display text-xl  flex gap-2 items-center justify-between px-8 py-4 text-gray-700`}
                                                    onClick={() =>
                                                        setTabsStatus(
                                                            (prevState) => {
                                                                return {
                                                                    ...prevState,
                                                                    [tabName]:
                                                                        !prevState[
                                                                            tabName
                                                                        ],
                                                                };
                                                            }
                                                        )
                                                    }
                                                >
                                                    <div className="flex gap-4 items-center">
                                                        <p className="flex gap-2 items-center">
                                                            <span>
                                                                {i + 1}.
                                                            </span>
                                                            <span>
                                                                {
                                                                    tabsTitles[
                                                                        tabName
                                                                    ]
                                                                }
                                                            </span>
                                                        </p>
                                                        <p className="text-sm text-red-text mt-1">
                                                            {errors[tabName]}
                                                        </p>
                                                    </div>
                                                    <div
                                                        className={`transform  translate basic-transition ${
                                                            tabsStatus[tabName]
                                                                ? 'rotate-180'
                                                                : 'rotate-0'
                                                        }`}
                                                    >
                                                        <IoIosArrowDown />
                                                    </div>
                                                </div>

                                                <div
                                                    className={`transform translate basic-transition px-8 overflow-hidden ${
                                                        tabsStatus[tabName]
                                                            ? 'max-h-[1000px] '
                                                            : 'max-h-0'
                                                    }`}
                                                >
                                                    <div className="pb-4">
                                                        {renderTabContent(
                                                            tabName,
                                                            values,
                                                            setFieldTouched,
                                                            setFieldValue,
                                                            errors
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="flex justify-between items-center">
                                    {teamData?.team_name ? (
                                        <button
                                            type="button"
                                            className={`underline text-red-text font-medium text-xs leading-5 disabled:cursor-not-allowed disabled:text-opacity-50`}
                                            style={{ minWidth: '110px' }}
                                            onClick={() => setModalState(true)}
                                        >
                                            Delete team
                                        </button>
                                    ) : (
                                        <button
                                            type="button"
                                            className={`underline text-red-text font-medium text-xs leading-5 disabled:cursor-not-allowed disabled:text-opacity-50`}
                                            style={{ minWidth: '110px' }}
                                            onClick={() =>
                                                setTeamInEditingMode(null)
                                            }
                                        >
                                            Go back
                                        </button>
                                    )}
                                    <div className="flex justify-end">
                                        {teamData?.team_name ? (
                                            <button
                                                type="button"
                                                className="inline-flex justify-center px-6 py-2 text-lg  text-theme-tertiary hover:underline  rounded-md disabled:cursor-not-allowed"
                                                onClick={() =>
                                                    setTeamInEditingMode(null)
                                                }
                                                style={{ minWidth: '110px' }}
                                            >
                                                {capitalizeFirstLetter(
                                                    'Go back'
                                                )}
                                            </button>
                                        ) : null}
                                        <button
                                            type="submit"
                                            className={`inline-flex justify-center px-6 py-2 text-lg leading-6	font-normal text-white bg-theme-tertiary-dark border border-transparent rounded-md hover:bg-theme-tertiary-dark disabled:cursor-not-allowed disabled:opacity-50`}
                                            style={{ minWidth: '110px' }}
                                            disabled={!(isValid && dirty)}
                                        >
                                            {loading ? (
                                                <Loader />
                                            ) : (
                                                capitalizeFirstLetter(
                                                    teamData?.team_name
                                                        ? t('button.update')
                                                        : t('button.create')
                                                )
                                            )}
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
                <ModalWrapper
                    modalState={modalState}
                    setModalState={setModalState}
                    title={t('sre.modal_delete')}
                >
                    <ModalDeletingContent
                        featureName={teamData?.team_name}
                        sendRequest={handleDeleteTeam}
                        handleChangeModalState={() =>
                            setModalState(!modalState)
                        }
                    />
                </ModalWrapper>
            </div>
        </WhitePanelContentWrapper>
    );
}

export default TeamEditor;
