async function handleEmailSubscription(email, tags = [], audienceType) {
    let audienceId = null;

    switch (audienceType) {
        case 'newsletter':
            audienceId =
                process.env
                    .REACT_APP_MAILCHIMP_AGILE_ANALYTICS_NEWSLETTER_AUDIENCE_ID;
            break;
        case 'users':
            audienceId =
                process.env
                    .REACT_APP_MAILCHIMP_AGILE_ANALYTICS_USERS_AUDIENCE_ID;
            break;
        default:
            audienceId = null;
    }
    if (!email) {
        return;
    }

    try {
        const res = await fetch(
            'https://www.agileanalytics.cloud/api/send-to-mailchimp',
            {
                body: JSON.stringify({
                    email: email,
                    audienceId: audienceId,
                    tags: tags,
                }),
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json, text/plain, */*',
                },
                method: 'POST',
            }
        );

        const { error } = await res.json();

        if (error) {
            console.log(error);
            return;
        }
    } catch (error) {
        console.log(error);
    }
}

export default handleEmailSubscription;
