import _ from 'lodash';

const mimeType = (headerString, filetype) => {
    // let type = "unknown";
    let result = false;
    switch (headerString) {
        case '89504e47':
            // type = "image/png";
            result = ['image/png'].includes(filetype);
            break;
        case 'ffd8ffe0':
        case 'ffd8ffe1':
        case 'ffd8ffee':
        case 'ffd8ffdb':
            // type = "image/jpeg";
            result = ['image/jpg', 'image/jpeg'].includes(filetype);
            break;
        default:
            // type = "unknown";
            result = false;
            break;
    }

    return result;
};

const getBLOBFileHeader = async (file, headerString) => {
    return new Promise((resolve, reject) => {
        let fileReader = new FileReader();
        let header = '';

        fileReader.onloadend = (e) => {
            let arr = new Uint8Array(_.get(e, 'target.result', [])).subarray(
                0,
                4
            );
            for (let i = 0; i < arr.length; i++) {
                header += arr[i].toString(16);
            }
            resolve(headerString(header, file.type));
        };
        fileReader.readAsArrayBuffer(file);
    });
};

// const checkSimpleType = (file) => {
//     return file.name.match(/.(jpg|jpeg|png)$/i);
// };

const headerCallback = (headerString, filetype) =>
    mimeType(headerString, filetype);

const filterFile = async (file) => {
    // return checkSimpleType(file)
    //     ? true
    //     : "Incorrect file extension. Can be only .png or .jpeg";
    return (await getBLOBFileHeader(file, headerCallback))
        ? file
        : 'Incorrect file extension. Can be only .png or .jpeg';
};

export default filterFile;

// const filterFiles = async (file) => {
//     return await [...files].reduce(
//         async (acc, file) => [
//             ...(await acc),
//             ...((await getBLOBFileHeader(file, headerCallback)) ? [file] : []),
//         ],
//         []
//     );
// };

// const filterFile = async (file) => {
//     return (await await getBLOBFileHeader(file, headerCallback))
//         ? file
//         : "error";
// };

// export default filterFile;
