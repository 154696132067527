import { instances } from '../default-setup';

//Git Providers
export const getGitProviders = () => instances.get('settings/git/');

export const createGitProvider = (data) =>
    instances.post('settings/git/', data);

export const deleteGitProvider = (id) =>
    instances.delete(`settings/git/${id}/`);

export const updateGitProvider = (id, data) =>
    instances.put(`settings/git/${id}/`, data);

export const createRepositoryWebhook = (providerId, data, params) =>
    instances.post(`settings/git/${providerId}/webhook/`, data, params);
