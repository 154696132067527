import React, { useContext, useCallback } from 'react';
import Loader from '../../loader';
import AddToSlack from './add-to-slack';
import WorkspacesHeader from './workspaces-header';
import WorkspacesBody from './workspaces-list';
import { OrganisationsContext } from '../../../context/OrganisationsContext';
import { useFetchDataAndSetState } from '../../../helpers/useFetchDataAndSetState';
import { getSlackWorkspaces } from '../../../api/settings/SlackAPI';
import PanelTitle from '../../panel-title';
import { getAdminPermission } from '../../../helpers/getPermission';

const Workspaces = () => {
    const { organisationsState } = useContext(OrganisationsContext);

    const selectedOrganisationData = organisationsState.data
        ? organisationsState.data.find((org) => org.active)
        : null;

    const request = useCallback(() => getSlackWorkspaces(), []);

    const [workspacesState] = useFetchDataAndSetState(request, [
        selectedOrganisationData,
    ]);

    const permissionForWrite = getAdminPermission(organisationsState?.data);

    if (workspacesState.loading) {
        return (
            <div className="workspaces-wrapper">
                <PanelTitle title="Slack Workspaces" />
                <div className="workspaces-list">
                    <WorkspacesHeader />
                    <div className="workspaces-list__body w-100">
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <Loader
                                color={'#C2C7D7'}
                                size={35}
                                speedMultiplier={0.8}
                                css={{
                                    margin: '8px auto',
                                }}
                            />
                        </div>
                    </div>
                </div>
                {permissionForWrite ? <AddToSlack /> : null}
            </div>
        );
    }

    if (workspacesState.error) {
        return (
            <div className="workspaces-wrapper">
                <PanelTitle title="Slack Workspaces" />
                <div className="workspaces-list">
                    <WorkspacesHeader />
                    <div className="workspaces-list__body w-100">
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <p>Sorry, can't get workspaces</p>
                        </div>
                    </div>
                </div>
                {permissionForWrite ? <AddToSlack /> : null}
            </div>
        );
    }

    return (
        <div className="workspaces-wrapper">
            <PanelTitle title="Slack Workspaces" />
            <div className=" workspaces-list">
                <WorkspacesHeader />
                <div className="workspaces-list__body w-100">
                    <WorkspacesBody workspacesList={workspacesState.data} />
                </div>
            </div>
            {permissionForWrite ? <AddToSlack /> : null}
        </div>
    );
};

export default Workspaces;
