import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { OrganisationsContext } from '../../../context/OrganisationsContext';
import { capitalizeFirstLetter } from '../../../helpers/textFormatters';
import './styles.scss';

const SaveButton = ({
    saveFunc = () => {},
    disabled = false,
    text,
    withoutPermisson = false,
    style = {},
}) => {
    const { t } = useTranslation();
    const { organisationsState } = useContext(OrganisationsContext);
    const userPermission = organisationsState.data
        ? organisationsState.data.find((org) => org.active).permissions
        : null;
    if (withoutPermisson) {
        return (
            <button
                style={style}
                className="save-button"
                disabled={disabled}
                onClick={() => saveFunc()}
            >
                {text}
            </button>
        );
    } else {
        switch (userPermission) {
            case 'superadmin':
            case 'admin':
            case 'write':
                return (
                    <button
                        style={style}
                        className="save-button"
                        disabled={disabled}
                        onClick={() => saveFunc()}
                    >
                        {text ? text : capitalizeFirstLetter(t('button.save'))}
                    </button>
                );
            case 'read':
            default:
                return null;
        }
    }
};

export default SaveButton;
