import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { capitalizeFirstLetter } from '../../../helpers/textFormatters';
import { useTranslation } from 'react-i18next';

const StubChart = () => {
    const { t } = useTranslation();

    Highcharts.setOptions({
        global: {
            useUTC: false,
        },
        lang: {
            months: [
                capitalizeFirstLetter(t('highcharts.month.1')),
                capitalizeFirstLetter(t('highcharts.month.2')),
                capitalizeFirstLetter(t('highcharts.month.3')),
                capitalizeFirstLetter(t('highcharts.month.4')),
                capitalizeFirstLetter(t('highcharts.month.5')),
                capitalizeFirstLetter(t('highcharts.month.6')),
                capitalizeFirstLetter(t('highcharts.month.7')),
                capitalizeFirstLetter(t('highcharts.month.8')),
                capitalizeFirstLetter(t('highcharts.month.9')),
                capitalizeFirstLetter(t('highcharts.month.10')),
                capitalizeFirstLetter(t('highcharts.month.11')),
                capitalizeFirstLetter(t('highcharts.month.12')),
            ],
            shortMonths: [
                capitalizeFirstLetter(t('highcharts.short_month.1')),
                capitalizeFirstLetter(t('highcharts.short_month.2')),
                capitalizeFirstLetter(t('highcharts.short_month.3')),
                capitalizeFirstLetter(t('highcharts.short_month.4')),
                capitalizeFirstLetter(t('highcharts.short_month.5')),
                capitalizeFirstLetter(t('highcharts.short_month.6')),
                capitalizeFirstLetter(t('highcharts.short_month.7')),
                capitalizeFirstLetter(t('highcharts.short_month.8')),
                capitalizeFirstLetter(t('highcharts.short_month.9')),
                capitalizeFirstLetter(t('highcharts.short_month.10')),
                capitalizeFirstLetter(t('highcharts.short_month.11')),
                capitalizeFirstLetter(t('highcharts.short_month.12')),
            ],
            weekdays: [
                capitalizeFirstLetter(t('highcharts.weekday.1')),
                capitalizeFirstLetter(t('highcharts.weekday.2')),
                capitalizeFirstLetter(t('highcharts.weekday.3')),
                capitalizeFirstLetter(t('highcharts.weekday.4')),
                capitalizeFirstLetter(t('highcharts.weekday.5')),
                capitalizeFirstLetter(t('highcharts.weekday.6')),
                capitalizeFirstLetter(t('highcharts.weekday.7')),
            ],
        },
    });
    const options = {
        colors: ['#7902D7', '#F8C238', '#15A2BB'],
        chart: {
            type: 'area',
        },
        title: {
            text: '',
        },
        yAxis: {
            labels: {
                format: '{value}%',
            },
            title: {
                enabled: false,
            },
            max: 100,
            minorTickInterval: 5,
            minorTickLength: 0,
        },
        xAxis: {
            type: 'datetime',
            labels: {
                format: '{value:%d %b %H:%M}',
                rotation: -50,
                align: 'right',
            },
        },
        credits: {
            enabled: false,
        },
        plotOptions: {
            series: {
                negativeColor: '#FF6384',
                opacity: 0.6,
            },
        },
        legend: {
            labelFormatter: function () {
                return `<b>${capitalizeFirstLetter(
                    t('si_page.feature')
                )}: </b><span>${this.userOptions.feature ?? ''}</span><br>
                <b>${capitalizeFirstLetter(
                    t('hours_page.hours_report.description')
                )}: </b><span>${
                    this.userOptions.slo_description ?? ''
                }</span><br> 
                <b>${capitalizeFirstLetter(t('sre.slo_name'))}: </b><span>${
                    this.userOptions.slo_name ?? ''
                }</span><br>
                <b>${capitalizeFirstLetter(t('sre.slo_target'))}: </b><span>${
                    this.userOptions.slo_target ?? ''
                }</span><br>`;
            },
        },
        series: [{ data: [null, null] }],
    };

    return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default StubChart;
