import React from 'react';
import {
    capitalizeEveryWordFirstLetter,
    capitalizeFirstLetter,
} from '../../../helpers/textFormatters';
import SreInvalidBackendNote from './sre-form-invalid-backend';
import { Field } from 'formik';

function SreFormBackendSelector({
    t,
    backendOptions,
    values,
    setValues,
    invalidBackendsList,
    handleBackendChange,
    errors,
    touched,
}) {
    return (
        <div className="">
            <label className="text-gray-400 mb-1 text-m" htmlFor="backend">
                {capitalizeEveryWordFirstLetter(t('sre.backend'))}
            </label>
            {((!backendOptions.find(
                (backend) => backend.value === values.backend
            ) &&
                values.backend) ||
                values.backend === null) && (
                <SreInvalidBackendNote
                    isChosen={false}
                    backend={values.backend}
                />
            )}
            {invalidBackendsList.find(
                (backend) => backend.name === values.backend
            ) && (
                <SreInvalidBackendNote
                    isChosen={true}
                    backend={values.backend}
                />
            )}
            <Field name="backend">
                {({ field }) => (
                    <select
                        {...field}
                        className="w-full p-2 placeholder-gray-300 text-gray-700 relative bg-white rounded text-md border border-gray-300"
                        value={
                            backendOptions.find(
                                (backend) => backend.value === values?.backend
                            )
                                ? values.backend
                                : ''
                        }
                        onChange={(e) =>
                            handleBackendChange(e, field, values, setValues)
                        }
                        required
                    >
                        <option disabled value="" className="text-gray-300">
                            {capitalizeFirstLetter(t('button.select'))}{' '}
                            {t('sre.backend')}
                        </option>
                        {backendOptions.map((item, i) => {
                            return (
                                <option key={i} value={item.value}>
                                    {item.label}
                                </option>
                            );
                        })}
                    </select>
                )}
            </Field>
            {errors.backend && touched.backend ? (
                <div className="text-red-500 mt-1 text-xs">
                    {errors.backend}
                </div>
            ) : (
                <div className="text-red-500 mt-1 text-xs invisible">
                    hidden
                </div>
            )}
        </div>
    );
}

export default SreFormBackendSelector;
