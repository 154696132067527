import React from 'react';
import { createRoot } from 'react-dom/client';
import { FlagsProvider } from 'react-feature-flags';
import './index.css';
import 'react-day-picker/dist/style.css';
import 'react-toastify/dist/ReactToastify.css';
import App from './components/app';
import './i18n';
import { FEATURES_FLAGS } from './constants';
if (process.env.REACT_APP_PUBLIC_API_MOCKING === 'enabled') {
    require('./__mocks__');
}

const rootElement = document.getElementById('root');

const root = createRoot(rootElement); // createRoot(container!) if you use TypeScript

root.render(
    <FlagsProvider value={FEATURES_FLAGS}>
        <App tab="home" />
    </FlagsProvider>
);
