import React from 'react';
import { useField } from 'formik';

function InputText({ autoComplete, className, ...props }) {
    const [field] = useField(props);

    return (
        <input
            className={`p-2 placeholder-gray-300 text-gray-700 relative bg-white
             rounded text-md border border-gray-300 outline-none focus:outline-none focus:ring w-full disabled:bg-gray-300 ${className} `}
            id={props.id || props.name}
            data-testid={props.id || props.name}
            {...field}
            {...props}
        />
    );
}

InputText.defaultProps = {
    autoComplete: 'off',
};

export default InputText;
