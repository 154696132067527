import React, { useState, useCallback, useContext, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { OrganisationsContext } from '../../../context/OrganisationsContext';
import ErrorIndicator from '../../error-indicator';
import Loader from '../../loader';
import { useFetchDataAndSetState } from '../../../helpers/useFetchDataAndSetState';
import { getSREBackends } from '../../../api/settings/sreAPI';
import AddBackendItem from '../../add-backend-item';
import SREBackendItem from '../../sre-backend-item';
import { getAdminPermission } from '../../../helpers/getPermission';

const SRETab = () => {
    const { organisationsState } = useContext(OrganisationsContext);
    const selectedOrgHash = organisationsState.data
        ? organisationsState.data.find((org) => org.active).org_hash
        : null;
    const request = useCallback(() => getSREBackends(), []);
    const [updatesCounter, setUpdatesCounter] = useState(0);
    const [backendsState] = useFetchDataAndSetState(request, [
        selectedOrgHash,
        updatesCounter,
    ]);

    // MOCK backendsState
    // const backendsState = {
    //     data: [{ type: 'cloud_monitoring', name: 'test_backend' }],
    //     loading: false,
    //     error: null,
    // };

    const [newBackendsList, setNewBackendsList] = useState([]);
    const [permissionForWrite, setPermissionForWrite] = useState(false);

    useEffect(() => {
        setPermissionForWrite(getAdminPermission(organisationsState?.data));
    }, [organisationsState]);

    const handleAddingBackend = (backendService) => {
        const id = uuidv4();
        let backend = { type: backendService, id };
        setNewBackendsList([...newBackendsList, backend]);
    };

    if (backendsState.error) {
        return (
            <div className="container">
                <ErrorIndicator error={backendsState.error} />
            </div>
        );
    }
    if (backendsState.loading) {
        return (
            <div className="container flex justify-center">
                <Loader />
            </div>
        );
    }

    const handleDeletEmptyBackend = (id) => {
        setNewBackendsList(newBackendsList.filter((item) => item.id !== id));
    };

    const handleUpdateBackendsList = () => {
        setNewBackendsList([]);
        setUpdatesCounter(updatesCounter + 1);
    };

    return (
        <div className="container">
            <ul className="flex flex-wrap items-stretch gap-x-8 gap-y-10 mb-4">
                {backendsState.data.length > 0 &&
                    backendsState.data.map((backendData) => {
                        return (
                            <SREBackendItem
                                key={
                                    backendData.project_id ||
                                    backendData.access_key
                                }
                                sreBackendData={backendData}
                                backendsList={backendsState.data}
                                permissionForWrite={permissionForWrite}
                                onUpdateBackend={handleUpdateBackendsList}
                            />
                        );
                    })}
                {newBackendsList &&
                    newBackendsList.map((newBackend) => {
                        return (
                            <SREBackendItem
                                key={newBackend.id}
                                sreBackendData={newBackend}
                                backendsList={backendsState.data}
                                permissionForWrite={permissionForWrite}
                                onDeleteEmptyItem={handleDeletEmptyBackend}
                                onUpdateBackend={handleUpdateBackendsList}
                            />
                        );
                    })}

                {permissionForWrite &&
                    (+newBackendsList?.length + +backendsState?.data.length >=
                    5 ? (
                        <li className="w-480 h-450 rounded-lg border-tailwind border-gray-500 border-dashed flex flex-col justify-center items-center ">
                            <p className="p-4 font-display text-xl text-center text-gray-500">
                                Maximum 5 backends could be added
                            </p>
                        </li>
                    ) : (
                        <AddBackendItem addBackend={handleAddingBackend} />
                    ))}
            </ul>
        </div>
    );
};

export default SRETab;
