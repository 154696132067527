import React, { useReducer, createContext } from 'react';

const repoGroupsReducer = (state, action) => {
    switch (action.type) {
        case 'SET_LOADING':
            return { data: state.data, loading: true, error: null };
        case 'SET_NO_LOADING':
            return { data: state.data, loading: false, error: null };
        case 'SET_DATA':
            return {
                data: action.payload,
                loading: false,
                error: null,
            };
        case 'SET_EMPTY':
            return {
                data: [],
                loading: false,
                error: null,
            };
        case 'SET_ERROR':
            return { data: state.data, loading: false, error: action.payload };
        default:
            return state;
    }
};

export const RepoGroupsContext = createContext();

export const RepoGroupsProvider = ({ children }) => {
    const [repoGroupsState, repoGroupsDispatch] = useReducer(
        repoGroupsReducer,
        {
            data: null,
            loading: false,
            error: null,
        }
    );

    return (
        <RepoGroupsContext.Provider
            value={{ repoGroupsState, repoGroupsDispatch }}
        >
            {children}
        </RepoGroupsContext.Provider>
    );
};
