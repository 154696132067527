import React, { useState, useEffect } from 'react';
import { Chart } from 'react-google-charts';
import Loader from '../../../components/loader';
import ErrorIndicator from '../../../components/error-indicator';
import { useTranslation } from 'react-i18next';

const SprintInsightChart = ({
    data,
    loading,
    error,
    updateAvailable,
    setUpdateAvailable,
    customSlices = null,
    legendPosition = 'right',
    additionalOptions = {},
}) => {
    let transformedData;
    const { t } = useTranslation();
    const [updateStyles, setUpdateStyles] = useState('filter');

    useEffect(() => {
        if (updateAvailable) {
            setUpdateStyles('filter blur-sm');
            setTimeout(() => {
                setUpdateStyles('filter');
                setUpdateAvailable(false);
            }, 400);
        }
    }, [updateAvailable]);

    if (loading) {
        return (
            <div className="flex justify-center items-center">
                <Loader
                    color={'#C2C7D7'}
                    size={35}
                    speedMultiplier={0.8}
                    css={{ margin: '30px auto', display: 'block' }}
                />
            </div>
        );
    }

    if (error || !data) {
        return (
            <div className="">
                <ErrorIndicator error={error} />
            </div>
        );
    }

    const pieOptions = {
        slices: customSlices
            ? customSlices
            : [
                  {
                      color: '#7902D7',
                  },
                  {
                      color: '#F8C238',
                  },
                  {
                      color: '#15A2BB',
                  },
                  {
                      color: '#FF6384',
                  },
              ],
        pieSliceText: 'value',
        legend: {
            textStyle: {
                color: '7F8992',
                fontSize: 14,
            },
            pointShape: 'square',
            position: legendPosition ?? 'right',
            alignment:
                legendPosition === 'top' || legendPosition === 'bottom'
                    ? 'center'
                    : 'start',
        },
        tooltip: { trigger: 'selection' },
        chartArea: {
            left: 0,
            top: '10px',

            width: '100%',
            height: '80%',
        },
        fontName: 'Roboto',
        ...additionalOptions,
    };

    let isDataEmpty = !Boolean(Object.keys(data).length);

    if (
        !Object.values(data).find((entry) => {
            if (entry !== 0 || entry !== undefined || entry !== null) {
                return entry;
            }
        })
    ) {
        isDataEmpty = true;
    }

    if (isDataEmpty && !loading) {
        return (
            <div className="">
                <p className="text-base py-3 text-gray-600">
                    {t('common.no_data')}
                </p>
            </div>
        );
    }

    const transformData = (data) => {
        return Object.entries(data).map((item) => {
            return [
                item[0].charAt(0).toUpperCase() + item[0].slice(1),
                item[1],
            ];
        });
    };

    transformedData = transformData(data);

    return (
        <div className={`${updateStyles}`}>
            <Chart
                chartType="PieChart"
                data={[['Label', 'Value'], ...transformedData]}
                options={pieOptions}
            />
        </div>
    );
};

export default SprintInsightChart;
