import React, { useContext, useEffect, useCallback, useState } from 'react';
import { OrganisationsContext } from '../../../context/OrganisationsContext';
import { SaveButton } from '../../custom';
import { useDefaultStateWithReducer } from '../../../helpers/useDefaultStateWithReducer';
import { Github, Gitlab, Bitbucket } from '../../../assets/svg.js';

import Loader from '../../loader';
import ErrorIndicator from '../../error-indicator';
import { getGitProviders } from '../../../api/settings/GitProviderAPI';
import {
    getSwarmSchedule,
    setSwarmSchedule,
    updateSwarmSchedule,
} from '../../../api/swarm/SwarmScheduleAPI';
import { getRepositories } from '../../../api/swarm/SwarmAPI';
import WhitePanelContentWrapper from '../../white-panel-content-wrapper';
import { getAdminPermission } from '../../../helpers/getPermission';
import showNotification from '../../../helpers/showNotification.js';

const ScheduleTab = () => {
    const { organisationsState } = useContext(OrganisationsContext);

    const selectedOrgHash = organisationsState.data
        ? organisationsState.data.find((org) => org.active).org_hash
        : null;

    const [swarmScheduleState, swarmScheduleDispatch] =
        useDefaultStateWithReducer();

    const [gitProviderState, gitProviderDispatch] =
        useDefaultStateWithReducer();

    useEffect(() => {
        let canceled = false;

        gitProviderDispatch({ type: 'SET_LOADING' });
        swarmScheduleDispatch({ type: 'SET_LOADING' });
        getGitProviders()
            .then((response) => {
                if (!canceled) {
                    if (response.status === 204) {
                        gitProviderDispatch({
                            type: 'SET_DATA',
                            payload: [],
                        });
                    } else {
                        gitProviderDispatch({
                            type: 'SET_DATA',
                            payload: response.data,
                        });
                    }

                    getSwarmSchedule()
                        .then((scheduleResponse) => {
                            if (!canceled) {
                                if (scheduleResponse.status === 204) {
                                    const transformedData = response.data.map(
                                        (key) => ({
                                            last_run: null,
                                            provider: key.provider,
                                            provider_id: key.provider_id,
                                            schedule: null,
                                        })
                                    );

                                    swarmScheduleDispatch({
                                        type: 'SET_DATA',
                                        payload: transformedData,
                                    });
                                } else {
                                    const transformedData = response.data.map(
                                        (key) => {
                                            const existingSchedule =
                                                scheduleResponse.data.findIndex(
                                                    (schedule) =>
                                                        schedule.provider_id ===
                                                        key.provider_id
                                                );
                                            if (existingSchedule + 1) {
                                                return scheduleResponse.data[
                                                    existingSchedule
                                                ];
                                            } else {
                                                return {
                                                    last_run: null,
                                                    provider: key.provider,
                                                    provider_id:
                                                        key.provider_id,
                                                    schedule: null,
                                                };
                                            }
                                        }
                                    );

                                    swarmScheduleDispatch({
                                        type: 'SET_DATA',
                                        payload: transformedData,
                                    });
                                }
                            }
                        })
                        .catch((error) => {
                            if (!canceled)
                                swarmScheduleDispatch({
                                    type: 'SET_ERROR',
                                    payload: error,
                                });
                        });
                }
            })
            .catch((error) => {
                if (!canceled)
                    gitProviderDispatch({ type: 'SET_ERROR', payload: error });
            });

        return () => {
            canceled = true;
        };
    }, [selectedOrgHash]);

    if (gitProviderState.loading) {
        return (
            <WhitePanelContentWrapper className="settings-tab-content schedule-tab flex justify-center items-center mb-8">
                <Loader />
            </WhitePanelContentWrapper>
        );
    }

    if (gitProviderState.error) {
        return (
            <WhitePanelContentWrapper className="settings-tab-content schedule-tab flex justify-center items-center mb-8">
                <ErrorIndicator error={gitProviderState.error} />
            </WhitePanelContentWrapper>
        );
    }

    if (gitProviderState.data.length === 0) {
        return (
            <WhitePanelContentWrapper className="settings-tab-content schedule-tab mb-8">
                <p className="empty">No Git Providers</p>
            </WhitePanelContentWrapper>
        );
    }

    return (
        <WhitePanelContentWrapper className="settings-tab-content schedule-tab mb-8">
            {gitProviderState.data.map((provider) => {
                return (
                    <GitProviderSchedule
                        swarmScheduleState={swarmScheduleState}
                        swarmScheduleDispatch={swarmScheduleDispatch}
                        provider={provider}
                        key={provider.provider_id}
                    />
                );
            })}
        </WhitePanelContentWrapper>
    );
};

const GitProviderSchedule = ({
    provider,
    swarmScheduleState,
    swarmScheduleDispatch,
}) => {
    const { organisationsState } = useContext(OrganisationsContext);

    const [repositoriesData, setRepositoriesData] = useState({
        loading: true,
        error: false,
        data: null,
    });

    const [updateLoading, setUpdateLoading] = useState(false);

    useEffect(() => {
        let canceled = false;

        getRepositories(provider.provider_id)
            .then((response) => {
                if (response.status === 204) {
                    if (!canceled)
                        setRepositoriesData({
                            loading: false,
                            error: null,
                            data: [],
                        });
                } else {
                    if (!canceled)
                        setRepositoriesData({
                            loading: false,
                            error: null,
                            data: response.data,
                        });
                }
            })
            .catch((error) => {
                if (!canceled)
                    setRepositoriesData({
                        loading: false,
                        error,
                        data: null,
                    });
            });

        return () => {
            canceled = true;
        };
    }, []);

    const getLogo = (providerType) => {
        switch (providerType) {
            case 'gitlab':
                return <Gitlab />;
            case 'github':
                return <Github />;
            case 'bitbucket':
                return <Bitbucket />;
            default:
                return null;
        }
    };

    const saveSchedule = (newValue) => {
        const scheduleData = swarmScheduleState.data.find(
            (scheduleForProvider) => {
                const providerId = provider.provider_id.replace(' ', '_');
                return scheduleForProvider.provider_id === providerId;
            }
        );

        const data = {
            schedule: newValue * 60 * 60,
            provider: provider.provider,
            provider_id: provider.provider_id,
        };

        setUpdateLoading(true);

        if (scheduleData.schedule) {
            updateSwarmSchedule(data)
                .then((response) => {
                    const newScheduleList = [...swarmScheduleState.data];
                    newScheduleList.find(
                        (shedule) =>
                            shedule.provider_id === provider.provider_id
                    ).schedule = newValue * 60 * 60;
                    swarmScheduleDispatch({
                        type: 'SET_DATA',
                        payload: newScheduleList,
                    });
                    setUpdateLoading(false);
                    showNotification(
                        'Job Schedule has been updated',
                        'success'
                    );
                })
                .catch((error) => {
                    swarmScheduleDispatch({
                        type: 'SET_ERROR',
                        payload: error,
                    });
                    setUpdateLoading(false);
                    showNotification(error.response?.data || error.message);
                });
        } else {
            setSwarmSchedule(data)
                .then((response) => {
                    const newScheduleList = [...swarmScheduleState.data];
                    newScheduleList.find(
                        (shedule) =>
                            shedule.provider_id === provider.provider_id
                    ).schedule = newValue * 60 * 60;
                    swarmScheduleDispatch({
                        type: 'SET_DATA',
                        payload: newScheduleList,
                    });
                    setUpdateLoading(false);
                })
                .catch((error) => {
                    swarmScheduleDispatch({
                        type: 'SET_ERROR',
                        payload: error,
                    });
                    setUpdateLoading(false);
                });
        }
    };

    const lastRun = () => {
        const data = swarmScheduleState.data.find((scheduleForProvider) => {
            return scheduleForProvider.provider_id === provider.provider_id;
        });

        if (data.last_run)
            return (
                <p className="last-run">
                    Last run on <span className="date">{data.last_run}</span>
                </p>
            );
        return <p className="last-run">Jobs haven't started yet</p>;
    };

    return (
        <div className="git-provider-schedule">
            {updateLoading && (
                <div className="update-loading">
                    <Loader color={'#C2C7D7'} size={35} speedMultiplier={0.8} />
                </div>
            )}
            <div className="git-provider__name">
                {getLogo(provider.provider)}
                <p className="value">{provider.provider_id}</p>
                <RepositoriesQuantity repositoriesData={repositoriesData} />
            </div>
            {swarmScheduleState.loading ? (
                <Loader />
            ) : swarmScheduleState.error ? (
                <ErrorIndicator error={swarmScheduleState.error} />
            ) : (
                <div className="schedule-value-wrapper">
                    {lastRun()}
                    {getAdminPermission(organisationsState?.data) ? (
                        <ScheduleSelector
                            saveSchedule={saveSchedule}
                            provider={provider}
                            swarmScheduleState={swarmScheduleState}
                        />
                    ) : null}
                </div>
            )}
        </div>
    );
};

const ScheduleSelector = ({ provider, swarmScheduleState, saveSchedule }) => {
    const sheduleValue =
        swarmScheduleState.data.find(
            (providerSchedule) =>
                providerSchedule.provider_id === provider.provider_id
        ).schedule /
        60 /
        60;

    const [schedule, setSchedule] = useState(sheduleValue || 12);

    useEffect(() => {
        const sheduleValue =
            swarmScheduleState.data.find(
                (providerSchedule) =>
                    providerSchedule.provider_id === provider.provider_id
            ).schedule /
            60 /
            60;

        setSchedule(sheduleValue);
    }, [swarmScheduleState.data]);

    const setScheduleValue = () => {
        if (
            swarmScheduleState.data.find(
                (providerSchedule) =>
                    providerSchedule.provider_id === provider.provider_id
            ).schedule === null
        ) {
            return (
                <>
                    <p className="schedule-value">No schedule selected</p>
                    <p className="schedule-value">
                        Jobs will run every{' '}
                        <span className="value">{schedule}</span> hours
                    </p>
                </>
            );
        } else {
            return (
                <p className="schedule-value">
                    Jobs run every <span className="value">{schedule}</span>{' '}
                    hours. Please save schedule.
                </p>
            );
        }
    };

    return (
        <div className="schedule-selector">
            {setScheduleValue()}
            <div className="input-wrapper">
                <input
                    type="range"
                    id="volume"
                    name="volume"
                    min="12"
                    max="108"
                    step="12"
                    value={schedule}
                    onChange={(e) => setSchedule(e.target.value)}
                />
            </div>
            <SaveButton
                disabled={schedule === sheduleValue}
                saveFunc={() => saveSchedule(schedule)}
            />
        </div>
    );
};

const RepositoriesQuantity = ({ repositoriesData }) => {
    if (repositoriesData.loading) {
        return (
            <div className="repositories">
                <Loader />
            </div>
        );
    }

    if (repositoriesData.error) {
        return (
            <div className="repositories">
                <ErrorIndicator error={repositoriesData.error} />
            </div>
        );
    }

    return (
        <div className="repositories">
            {repositoriesData.data.length} repositories (
            {repositoriesData.data.filter((repo) => repo.selected).length}{' '}
            selected)
        </div>
    );
};

export default ScheduleTab;
