import React from 'react';
import { useState, useContext } from 'react';
import { FaSave, FaUndo } from 'react-icons/fa';
import { ImBin, ImPencil } from 'react-icons/im';
import {
    deleteProjectHours,
    updateHours,
} from '../../../../../../api/hours/HoursAPI';
import Loader from '../../../../../../components/loader';
import showNotification from '../../../../../../helpers/showNotification';
import ModalWrapper from '../../../../../../components/modal-wrapper';
import ModalTimesheetProjectDeleteContent from '../../../../../../components/timesheet-project-delete-modal-content';

function HoursPageTimesheetOverviewBodyActions({
    showEdit,
    toggleShowEdit,
    project,
    days,
    formValues,
    setFormValues,
    setEmptyRow,
    triggerUpdate,
    filterDate,
    userHash,
}) {
    const [loading, setLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);

    async function handleProjectUpdate() {
        if (!formValues.project) {
            showNotification('Select a project');
            return;
        }
        if (!formValues.days.length && formValues.project === project) {
            showNotification('Nothing to change');
            return;
        }

        if (!formValues.days.length && formValues.project !== project) {
            showNotification('No hours added');
            return;
        }

        setLoading(true);

        const formattedDays = formValues.days.map((day) => {
            const formattedDay = {
                ...day,
                hours: +day.hours.toString().replace(',', '.'),
            };
            return formattedDay;
        });
        const formattedValues = {
            ...formValues,
            days: formattedDays,
        };

        try {
            await updateHours(userHash, formattedValues);
            setEmptyRow(null);
            triggerUpdate();
            setLoading(false);
        } catch (err) {
            setLoading(false);
            showNotification(
                `${err.response?.data || err.message || 'Failed to update'}`
            );
        }
    }

    async function handleProjectDelete() {
        setLoading(true);
        try {
            const res = await deleteProjectHours(userHash, project, {
                params: {
                    filterDate: filterDate.clone().day(1).format('YYYY-MM-DD'),
                },
            });
            setLoading(false);
            res && triggerUpdate();
        } catch (err) {
            setLoading(false);
            showNotification(
                `${err.response?.data || err.message || 'Failed to delete'}`
            );
        }
    }

    return (
        <div className="flex justify-end px-2 w-12">
            {showEdit ? (
                <>
                    {loading && (
                        <div className="flex mr-8 justify-center">
                            <Loader
                                color={'#C2C7D7'}
                                size={35}
                                speedMultiplier={0.8}
                            />
                        </div>
                    )}
                    <button
                        className={`text-gray-400 transform hover:scale-110 hover:text-gray-500 disabled:opacity-50 mr-2`}
                        type="button"
                        onClick={() => {
                            setFormValues({ project, days });
                            toggleShowEdit();
                            setEmptyRow(null);
                        }}
                    >
                        <FaUndo />
                    </button>
                    <button
                        className={`text-green-700 transform hover:scale-110 hover:text-green-800 disabled:opacity-50 mr-2`}
                        type="button"
                        onClick={handleProjectUpdate}
                    >
                        <FaSave />
                    </button>
                </>
            ) : (
                <>
                    <button
                        className={`text-gray-400 transform hover:scale-110 hover:text-gray-500 disabled:opacity-50 mr-2`}
                        type="button"
                        onClick={toggleShowEdit}
                    >
                        <ImPencil />
                    </button>
                    <button
                        className={`text-red-600 transform hover:scale-110 hover:text-red-700 disabled:opacity-50`}
                        type="button"
                        onClick={() => setModalOpen(true)}
                    >
                        <ImBin />
                    </button>
                </>
            )}

            <ModalWrapper
                modalState={modalOpen}
                setModalState={setModalOpen}
                title="Remove project"
            >
                <ModalTimesheetProjectDeleteContent
                    project={project}
                    handleAction={handleProjectDelete}
                    setModalState={setModalOpen}
                />
            </ModalWrapper>
        </div>
    );
}

export default HoursPageTimesheetOverviewBodyActions;
