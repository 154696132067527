import React, { useState, useEffect } from 'react';
import { TourProvider } from '@reactour/tour';
import { TourGuideSteps } from '../../helpers/tourGuideSteps';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import RouteChangeTracker from '../../helpers/ga/RouteChangeTracker ';
import { ContentWrapper } from './content-wrapper';
import ReactGA from 'react-ga4';

import './styles.scss';

const App = () => {
    const GoogleAnalyticsID = process.env.REACT_APP_GA_TRACKING_ID;
    ReactGA.initialize(GoogleAnalyticsID);

    useEffect(() => {
        ReactGA.send('pageview');
        return () => localStorage.removeItem('q');
    }, []);

    return (
        <Router>
            <TourProvider
                steps={TourGuideSteps}
                styles={{
                    popover: (base) => ({ ...base, maxWidth: '700px' }),
                }}
                className="rounded rounded-sm"
                position={[15, 15]}
                scrollSmooth={true}
                beforeClose={() => localStorage.removeItem('autoplay')}
            >
                <RouteChangeTracker />
                <ContentWrapper />
                <ToastContainer
                    position="bottom-right"
                    hideProgressBar={true}
                    pauseOnHover={false}
                    autoClose={3000}
                    limit={5}
                    closeButton={true}
                />
            </TourProvider>
        </Router>
    );
};

export default App;
