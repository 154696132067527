export const initialSignupFormValues = {
    contactName: '',
    contactLastName: '',
    contactEmail: '',
    contactJobTitle: '',
    contactGoal: '',
    companyName: '',
    monitoringSystem: '',
    gitSystem: '',
    pmSystem: '',
    communicationSystem: '',
    eula: false,
    newsLetter: true,
};
