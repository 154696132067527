import React from 'react';

function ButtonTertiary({
    children,
    extraClasses,
    onClick,
    title,
    disabled = false,
}) {
    return (
        <button
            className={`text-left text-gray-500 hover:underline ${extraClasses}`}
            onClick={onClick}
            type={'button'}
            title={title}
            disabled={disabled}
        >
            {children}
        </button>
    );
}

ButtonTertiary.defaultProps = {
    title: '',
};

export default ButtonTertiary;
