export const getWritePermission = (orgStateData) => {
    const permission = orgStateData
        ? orgStateData.find((org) => org.active).permissions
        : null;

    switch (permission) {
        case 'superadmin':
        case 'admin':
        case 'write':
            return true;
        case 'read':
        default:
            return false;
    }
};

export const getAdminPermission = (orgStateData) => {
    const permission = orgStateData
        ? orgStateData.find((org) => org.active).permissions
        : null;

    switch (permission) {
        case 'superadmin':
        case 'admin':
            return true;
        case 'write':
        case 'read':
        default:
            return false;
    }
};

export const getSuperAdminPermission = (orgStateData) => {
    const permission = orgStateData
        ? orgStateData?.find((org) => org.active).permissions
        : null;

    switch (permission) {
        case 'superadmin':
            return true;
        case 'admin':
        case 'write':
        case 'read':
        default:
            return false;
    }
};
