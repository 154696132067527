import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    capitalizeEveryWordFirstLetter,
    capitalizeFirstLetter,
} from '../../helpers/textFormatters';

const ModalBackendUpdateContent = ({
    backendName,
    sendRequest,
    setModalState,
}) => {
    const { t } = useTranslation();

    const handleChangeModalState = () => {
        setModalState(false);
    };

    const handleUpdate = _.debounce(() => {
        sendRequest();
        setModalState(false);
    }, 1000);

    return (
        <div className="flex flex-col pt-5 h-full">
            <p className="text-xl text-gray-500">
                {capitalizeFirstLetter(
                    t('settings_page.sre_tab.update_modal_message_1', {
                        backend_name: backendName,
                    })
                )}
            </p>
            <p className="text-xl text-gray-500">
                {capitalizeFirstLetter(
                    t('settings_page.sre_tab.update_modal_message_2')
                )}
            </p>
            <div className="ml-auto mt-auto">
                <button
                    type="button"
                    className="inline-flex mr-4 justify-center px-6 py-2 text-lg leading-6	font-normal text-gray-500 bg-gray-300 border border-transparent rounded-md hover:bg-gray-200"
                    onClick={handleChangeModalState}
                    style={{ minWidth: '110px' }}
                >
                    {t('button.close')}
                </button>
                <button
                    type="button"
                    className={`inline-flex justify-center px-6 py-2 text-lg leading-6	font-normal text-white bg-theme-tertiary-dark border border-transparent rounded-md hover:bg-theme-tertiary-dark disabled:cursor-not-allowed disabled:opacity-50`}
                    onClick={handleUpdate}
                    style={{ minWidth: '110px' }}
                >
                    {t('button.update')}
                </button>
            </div>
        </div>
    );
};

export default ModalBackendUpdateContent;
