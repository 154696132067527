import { useState } from 'react';
import Rodal from 'rodal';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import FormField from '../../pages/sre-page/components/sre-form-field';
import showNotification from '../../helpers/showNotification';
import { capitalizeFirstLetter } from '../../helpers/textFormatters';

const ModalConnectUserJiraContent = ({
    sendRequest = async () => {},
    handleChangeModalState,
}) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    const handleConnect = async (values) => {
        setLoading(true);

        try {
            const responce = await sendRequest(values.email);

            if (responce?.status === 200) {
                setLoading(false);
                showNotification(`Jira profile connected`, 'success');
                handleChangeModalState();
                return 200;
            }
        } catch (err) {
            setLoading(false);
            showNotification(
                `${
                    err.response?.data ||
                    err?.message ||
                    err.data?.message ||
                    'Failed to connect'
                }`
            );
        }
    };

    return (
        <div className="flex flex-col pt-5 h-full">
            <Formik
                initialValues={{
                    email: '',
                    role: 'read',
                }}
                validationSchema={Yup.object({
                    email: Yup.string()
                        .email(t('common.form.invalid_email_address'))
                        .required(t('common.form.required')),
                    role: Yup.string().required(t('common.form.required')),
                })}
                onSubmit={async (values, { resetForm }) => {
                    const result = await handleConnect(values);
                    if (result === 200) {
                        resetForm();
                    }
                }}
            >
                {({ errors, touched }) => {
                    return (
                        <Form>
                            <div className=" mb-3 flex flex-col gap-2">
                                <div>
                                    <FormField
                                        name={capitalizeFirstLetter(
                                            t(
                                                'settings_page.users_tab.add_user_modal_jira_email'
                                            )
                                        )}
                                        attributeName={'email'}
                                        fieldType={'email'}
                                        placeholderText={
                                            'richard@piedpiper.com'
                                        }
                                        labelClassName="font-light leading-5 pb-3"
                                        error={errors.email}
                                        touched={touched.email}
                                    />
                                </div>
                            </div>
                            <div className="flex justify-end">
                                <button
                                    type="button"
                                    className="inline-flex mr-4 justify-center px-6 py-2 text-lg leading-6	font-normal text-gray-500 bg-gray-300 border border-transparent rounded-md hover:bg-gray-200"
                                    onClick={handleChangeModalState}
                                    style={{ minWidth: '110px' }}
                                >
                                    {capitalizeFirstLetter(t('button.close'))}
                                </button>
                                <button
                                    type="submit"
                                    className={`inline-flex justify-center px-6 py-2 text-lg leading-6	font-normal text-white bg-theme-tertiary-dark border border-transparent rounded-md hover:bg-theme-tertiary-dark disabled:cursor-not-allowed disabled:opacity-50`}
                                    style={{ minWidth: '110px' }}
                                >
                                    {capitalizeFirstLetter(t('button.connect'))}
                                </button>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
};

export default ModalConnectUserJiraContent;
