import { useState } from 'react';

function ToggleCheckbox({
    handleChange,
    labelOff = null,
    labelOn = null,
    additionalText = null,
}) {
    const [isChecked, setIsChecked] = useState(true);

    function onChange() {
        setIsChecked(!isChecked);
        handleChange(!isChecked);
    }

    return (
        <div className="flex justify-center gap-x-2 items-center">
            {labelOff ? (
                <span className="font-semibold text-sm	">{labelOff}</span>
            ) : null}
            <label
                className={`relative inline-block w-8 h-4 cursor-pointer select-none rounded-full transition-all duration-200 ease-in-out  ${
                    isChecked ? 'bg-tertiary-300' : 'bg-gray-300'
                }`}
            >
                <input
                    type="checkbox"
                    className="absolute block w-0 h-0 opacity-0"
                    checked={isChecked}
                    onChange={() => onChange()}
                />
                <span
                    className={`absolute top-0.5 left-0.5 w-3 h-3 rounded-full transition-transform duration-200 ease-in-out bg-white ${
                        isChecked ? 'transform translate-x-4' : ''
                    }`}
                ></span>
            </label>
            {labelOn ? (
                <span className="font-semibold text-sm	">{labelOn}</span>
            ) : null}
            {additionalText ? (
                <span className="text-tertiary-300 font-semibold text-sm">{`- ${additionalText}`}</span>
            ) : null}
        </div>
    );
}

export default ToggleCheckbox;
