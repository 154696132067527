import React, { useEffect, useState, useContext } from 'react';
import { FaSave, FaUndo } from 'react-icons/fa';
import { ImBin, ImPencil } from 'react-icons/im';
import { OrganisationsContext } from '../../../../context/OrganisationsContext';
import {
    getAdminPermission,
    getWritePermission,
} from '../../../../helpers/getPermission';

function TableEditableTbodyRow({
    row,
    prepareRow,
    validateForm,
    updateProjects,
    deleteProject,
    type,
}) {
    prepareRow(row);
    const [showEdit, setShowEdit] = useState(false);
    const [selectedRow, setSelectedRow] = useState(undefined);
    const [errorMessages, setErrorMessages] = useState({});

    const { organisationsState } = useContext(OrganisationsContext);

    function toggleEdit() {
        setShowEdit((prevState) => !prevState);
    }

    useEffect(() => {
        setSelectedRow(row);
        if (row.values.name === '') {
            setShowEdit(true);
        }
    }, [row]);

    function updateSelectedRow(name, value) {
        const updatedRow = {
            ...selectedRow,
            values: { ...selectedRow.values, [name]: value || undefined },
        };

        setSelectedRow(updatedRow);

        if (validateForm) {
            setErrorMessages(validateForm(updatedRow));
        }
    }

    function saveRow() {
        // Update data
        setErrorMessages(undefined);

        if (validateForm) {
            setErrorMessages(validateForm(selectedRow));
        }

        if (Object.keys(validateForm(selectedRow)).length === 0) {
            updateProjects(row, selectedRow);
            toggleEdit();
        }
    }

    function undoRow() {
        toggleEdit();
    }

    function doDeleteRow() {
        deleteProject(row.values);
    }

    const isValid = !Object.entries(errorMessages).length;

    return (
        <tr
            {...row.getRowProps()}
            className="bg-gray-50 odd:bg-white border-t border-solid border-gray-300"
        >
            {row.cells.map((cell) => {
                return (
                    <td {...cell.getCellProps()} className="px-4 py-3">
                        {cell.render('Cell', {
                            showEdit,
                            updateSelectedRow,
                            errorMessages,
                        })}
                    </td>
                );
            })}
            {(type === 'organisation' || type === 'private') && (
                <td>
                    {getAdminPermission(organisationsState?.data) ? (
                        <div className="flex justify-end px-2">
                            {showEdit ? (
                                <>
                                    {row.original.name ? (
                                        <button
                                            className={`text-gray-400 transform hover:scale-110 hover:text-gray-500 disabled:opacity-50 mr-2`}
                                            type="button"
                                            onClick={undoRow}
                                        >
                                            <FaUndo />
                                        </button>
                                    ) : null}
                                    <button
                                        className={`${
                                            isValid
                                                ? 'text-green-700'
                                                : 'text-gray-600'
                                        } transform hover:scale-110 hover:text-green-800 disabled:opacity-50 mr-2`}
                                        type="button"
                                        onClick={saveRow}
                                        disabled={!isValid}
                                    >
                                        <FaSave />
                                    </button>
                                    <button
                                        className={`text-red-600 transform hover:scale-110 hover:text-red-700 disabled:opacity-50`}
                                        type="button"
                                        onClick={doDeleteRow}
                                    >
                                        <ImBin />
                                    </button>
                                </>
                            ) : (
                                <>
                                    <button
                                        className={`text-gray-400 transform hover:scale-110 hover:text-gray-500 disabled:opacity-50 mr-2`}
                                        type="button"
                                        onClick={toggleEdit}
                                    >
                                        <ImPencil />
                                    </button>
                                    <button
                                        className={`text-red-600 transform hover:scale-110 hover:text-red-700 disabled:opacity-50`}
                                        type="button"
                                        onClick={doDeleteRow}
                                    >
                                        <ImBin />
                                    </button>
                                </>
                            )}
                        </div>
                    ) : null}
                </td>
            )}
        </tr>
    );
}

export default TableEditableTbodyRow;
