import React from 'react';
import './styles.scss';
import { useTranslation } from 'react-i18next';

function DoraBenchmark({ type, value }) {
    const { t } = useTranslation();
    const scores = [
        {
            title: t('repo_page.dora.benchmark.low'),
            style: 'bg-red-300 bg-opacity-30 border-red-500 text-red-500',
            metrics: {
                df: {
                    min: 0,
                    max: 0.033,
                    measure: 'p/day',
                    description: t('repo_page.dora.benchmark.df_low'),
                },
                lt: {
                    min: 2592000000,
                    max: null,
                    measure: 'ms',
                    description: t('repo_page.dora.benchmark.lt_low'),
                },
                mttr: {
                    min: 168,
                    max: null,
                    measure: 'hours',
                    description: t('repo_page.dora.benchmark.mttr_low'),
                },
                cfr: {
                    min: 46,
                    max: 100,
                    measure: '%',
                    description: t('repo_page.dora.benchmark.cfr_low'),
                },
            },
        },
        {
            title: t('repo_page.dora.benchmark.medium'),
            style: 'bg-amber-300 bg-opacity-30 border-amber-500 text-amber-500',
            metrics: {
                df: {
                    min: 0.033,
                    max: 0.143,
                    measure: 'p/day',
                    description: t('repo_page.dora.benchmark.df_medium'),
                },
                lt: {
                    min: 604800000,
                    max: 2592000000,
                    measure: 'ms',
                    description: t('repo_page.dora.benchmark.lt_medium'),
                },
                mttr: {
                    min: 24,
                    max: 168,
                    measure: 'hours',
                    description: t('repo_page.dora.benchmark.mttr_medium'),
                },
                cfr: {
                    min: 30,
                    max: 46,
                    measure: '%',
                    description: t('repo_page.dora.benchmark.cfr_medium'),
                },
            },
        },
        {
            title: t('repo_page.dora.benchmark.high'),
            style: 'bg-emerald-300 bg-opacity-30 border-emerald-500 text-emerald-500',
            metrics: {
                df: {
                    min: 0.143,
                    max: 1,
                    measure: 'p/day',
                    description: t('repo_page.dora.benchmark.df_high'),
                },
                lt: {
                    min: 86400000,
                    max: 604800000,
                    measure: 'ms',
                    description: t('repo_page.dora.benchmark.lt_high'),
                },
                mttr: {
                    min: 1,
                    max: 24,
                    measure: 'hours',
                    description: t('repo_page.dora.benchmark.mttr_high'),
                },
                cfr: {
                    min: 15,
                    max: 30,
                    measure: '%',
                    description: t('repo_page.dora.benchmark.cfr_high'),
                },
            },
        },
        {
            title: t('repo_page.dora.benchmark.elite'),
            style: 'bg-purple-300 bg-opacity-30 border-purple-500 text-purple-500',
            metrics: {
                df: {
                    min: 1,
                    max: null,
                    measure: 'p/day',
                    description: t('repo_page.dora.benchmark.df_elite'),
                },
                lt: {
                    min: 0,
                    max: 86400000,
                    measure: '',
                    description: t('repo_page.dora.benchmark.lt_elite'),
                },

                mttr: {
                    min: 0,
                    max: 1,
                    measure: 'hours',
                    description: t('repo_page.dora.benchmark.mttr_elite'),
                },

                cfr: {
                    min: 0,
                    max: 15,
                    measure: '%',
                    description: t('repo_page.dora.benchmark.cfr_elite'),
                },
            },
        },
    ];

    let score = null;

    if (value !== null && type) {
        const filteredScores = scores?.filter((item) => {
            if (item.metrics[type].max) {
                if (
                    item.metrics[type].min <= +value &&
                    item.metrics[type].max >= +value
                ) {
                    return item;
                }
            } else if (item.metrics[type].min <= +value) {
                return item;
            }
        });

        if (filteredScores?.length) {
            score = filteredScores[filteredScores?.length - 1];
        }
    }

    if (score === null) {
        return (
            <div
                className={`px-1 py-[1px] rounded  text-xs border border-solid bg-gray-300 bg-opacity-30 border-gray-500 text-gray-500`}
            >
                No data
            </div>
        );
    }

    return (
        <div
            className={`px-1 py-[1px] rounded  text-xs border border-solid ${score.style} relative dora-benchmark-badge`}
        >
            {score?.metrics[type]?.description ? (
                <p className="absolute dora-benchmark-badge-description p-1  bg-gray-bg bottom-6 left-0 rounded text-xs text-gray-500  ">
                    {score?.metrics[type]?.description}
                </p>
            ) : null}
            {score.title}
        </div>
    );
}

export default DoraBenchmark;
