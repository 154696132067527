import React from 'react';
import Select from 'react-select';

const SISelect = ({selectedOption, options, handleOptionChange}) => {
  return (
    <Select 
        isSearchable={false}
        options={
          options.map((label) => {
            return { value: label.type, label: label.name }
          })
        }
        value={selectedOption}
        onChange={handleOptionChange}
        styles={setStyle()}
      />
  )
};

export default SISelect;

const setStyle = () => ({
  control: (provided) => ({
      ...provided,
      padding: '2px 0',
      width: '216px',
      outline: 'none',
      boxShadow: 'none',
      backgroundColor: '#DBEAFE',
      height: '48px',
      borderRadius: '6px',
      borderColor: '#DBEAFE',
      '&:hover': {
          opacity: '1',
      },
  }),
  singleValue: (provided) => ({
      ...provided,
      color: '#1D4ED8',
  }),
  indicatorsContainer: (provided) => ({
      ...provided,
      color: '#1D4ED8',
      opacity: '1',
  }),
  dropdownIndicator: (provided) => ({
      ...provided,
      color: '#1D4ED8',
      opacity: '1',
      cursor: 'pointer',
      '&:hover': {
          color: '#1D4ED8',
      },
  }),
  indicatorSeparator: (provided) => ({
      ...provided,
      display: 'none',
  }),
  menu: (provided) => ({
      ...provided,
      maxWidth: '215px',
      marginTop: '2px',
  }),
  menuList: (provided) => ({
    ...provided,
    padding: '0',
}),
  valueContainer: (provided) => ({
      ...provided,
      height: '100%',
      flexWrap: 'no-wrap',
  }),
  option: (provided, state) => ({
      ...provided,
      cursor: 'pointer',
      color: '#1D4ED8',
      backgroundColor: state.isSelected ? 'rgba(219, 234, 254, 0.2)' : '#DBEAFE',
      '&:hover': {
          backgroundColor: state.isSelected
              ? 'rgba(219, 234, 254, 0.2)'
              : 'rgba(219, 234, 254, 0.7)',
      },
  }),
});
