import moment from 'moment';

export const momentLocaleSwitcher = async (locale) => {
    try {
        const momentLocale = locale === 'en' ? 'en-gb' : locale;

        await import(`moment/locale/${momentLocale}`);
        moment.locale(momentLocale);
        return;
    } catch (error) {
        console.log(error);
    }
};
