import React from 'react';
import Loader from '../loader';

function ButtonSecondary({
    children,
    extraClasses,
    isLoading,
    onClick,
    variant,
    title,
    disabled,
}) {
    function getVariantStyle() {
        switch (variant) {
            case 'blue':
                return 'border-blue-500 text-blue-500 hover:text-blue-600';
            case 'red':
                return 'border-red-500 text-red-500 hover:text-red-600';
            default:
                return 'border-blue-100 text-gray-400 hover:text-gray-500';
        }
    }

    if (disabled) {
        return (
            <button
                className={`btn border border-solid ${getVariantStyle()} max-h-14 hover:bg-gray-100 ${extraClasses}`}
                onClick={onClick}
                type={'button'}
                title={title}
                disabled={true}
            >
                {children}{' '}
                {isLoading ? <Loader color={'white'} size={17} /> : null}
            </button>
        );
    }

    return (
        <button
            className={`btn border border-solid ${getVariantStyle()} max-h-14 hover:bg-gray-100 ${extraClasses}`}
            onClick={onClick}
            type={'button'}
            title={title}
        >
            {children} {isLoading ? <Loader color={'white'} size={17} /> : null}
        </button>
    );
}

ButtonSecondary.defaultProps = {
    title: '',
};

export default ButtonSecondary;
