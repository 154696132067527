import React, { useCallback, memo, useState, useEffect } from 'react';
import AddingFeatureForm from './sre-form-feature';
import 'react-tabs/style/react-tabs.css';
import Loader from '../../../components/loader';
import { getSREBackends } from '../../../api/settings/sreAPI';
import { useFetchDataAndSetState } from '../../../helpers/useFetchDataAndSetState';
import { IoIosArrowDown } from 'react-icons/io';

const FeaturesTabs = ({
    selectedService,
    featuresState,
    featuresDispatch,
    addFeatureText,
    t,
}) => {
    const backendsRequest = useCallback(() => getSREBackends(), []);
    const [backendsState] = useFetchDataAndSetState(backendsRequest, []);
    const [tabsStatus, setTabsStatus] = useState({});
    const [isAddingSlo, setIsAddingSlo] = useState(false);

    useEffect(() => {
        setIsAddingSlo(false);
    }, [featuresState?.data]);

    if (featuresState.loading || backendsState.loading) {
        return (
            <div
                className="flex justify-center items-center"
                style={{ width: '100%', height: '300px' }}
            >
                <Loader
                    color={'#C2C7D7'}
                    size={35}
                    speedMultiplier={0.8}
                    css={{ margin: '0 auto', display: 'block' }}
                />
            </div>
        );
    }

    return (
        <div>
            <div className="flex flex-col divide-y divide-solid divide-gray-300 rounded border border-solid border-gray-300">
                {featuresState.data.features.map((feature, i) => (
                    <div key={feature?.feature_name}>
                        <div
                            className={`cursor-pointer basic-transition  font-display text-xl  flex gap-2 items-center justify-between px-8 py-4 text-gray-700`}
                            onClick={() => {
                                isAddingSlo && setIsAddingSlo(false);
                                setTabsStatus((prevState) => {
                                    return {
                                        ...prevState,
                                        [feature?.feature_name]:
                                            !prevState[feature?.feature_name],
                                    };
                                });
                            }}
                        >
                            <div className="flex gap-4 items-center">
                                <p className="flex gap-2 items-center">
                                    <span>SLO: </span>
                                    <span className="font-medium">
                                        {feature?.feature_name}
                                    </span>
                                </p>
                            </div>
                            <div
                                className={`transform  translate basic-transition ${
                                    tabsStatus[feature?.feature_name]
                                        ? 'rotate-180'
                                        : 'rotate-0'
                                }`}
                            >
                                <IoIosArrowDown />
                            </div>
                        </div>

                        <div
                            className={`transform translate basic-transition px-8 overflow-hidden ${
                                tabsStatus[feature?.feature_name]
                                    ? 'max-h-[1000px] '
                                    : 'max-h-0'
                            }`}
                        >
                            <div className="pb-4">
                                <AddingFeatureForm
                                    featuresState={featuresState}
                                    featuresDispatch={featuresDispatch}
                                    backendsList={backendsState.data}
                                    serviceName={selectedService.service}
                                    feature={feature}
                                />
                            </div>
                        </div>
                    </div>
                ))}
                {isAddingSlo ? (
                    <div className="px-8 py-4">
                        <p className="flex gap-2 items-center font-display text-xl  justify-between text-gray-700 mb-4">
                            New SLO:
                        </p>
                        <AddingFeatureForm
                            featuresState={featuresState}
                            backendsList={backendsState.data}
                            featuresDispatch={featuresDispatch}
                            serviceName={selectedService.service}
                            onGoBack={() => setIsAddingSlo(false)}
                        />
                    </div>
                ) : (
                    <div
                        key={'add-feature'}
                        className=" px-8 py-1 flex justify-center items-center gap-2 m-0 cursor-pointer hover:bg-gray-200 text-4xl text-gray-400 font-light"
                        onClick={() => setIsAddingSlo(true)}
                    >
                        +
                    </div>
                )}
            </div>
        </div>
    );
};

export default memo(FeaturesTabs);
