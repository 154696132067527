import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { createOrganisationByDemoUser } from '../api/organisation/OrganisationAPI';
import BillingPlanItem from '../components/billing-plan-item';
import UserUpdateOrganisationForm from '../components/user-update-organisation-form';
import WhitePanelContentWrapper from '../components/white-panel-content-wrapper';
import { NAVIGATION_PATHS } from '../constants';
import { OrganisationsContext } from '../context/OrganisationsContext';
import billingPlansData from '../helpers/billingPlansData';
import showNotification from '../helpers/showNotification';
import Loader from '../components/loader';

function CreateOrganisationPage() {
    const navigate = useNavigate();
    const [billingPlan, setBillingPlan] = useState('free');

    const [showCreateSuccessScreen, setShowCreateSuccessScreen] =
        useState(false);

    const { organisationsState } = useContext(OrganisationsContext);
    const demoOrgHash = process.env.REACT_APP_DEMO_ORG_HASH;

    const selectedOrganisationHash = organisationsState?.data
        ? organisationsState.data.find((org) => org.active)?.org_hash
        : null;

    useEffect(() => {
        selectedOrganisationHash !== demoOrgHash &&
            navigate(NAVIGATION_PATHS.settings);
    }, [selectedOrganisationHash]);

    async function handleCreateOrganisation(values) {
        const newOrgData = {
            ...values,
            subscription_level: billingPlan,
        };

        try {
            const res = await createOrganisationByDemoUser(newOrgData);
            //TODO: add handling successfull org creation
            if (res.status === 200) {
                showCreateSuccessScreen(true);
                localStorage.setItem('baseOrgHash', res.data.org_hash);
                localStorage.setItem('orgHash', res.data.org_hash);
                setTimeout(() => {
                    // TODO: change current organisation to the created one
                    navigate('/');
                    showCreateSuccessScreen(false);
                }, 3000);
            }
        } catch (error) {
            showNotification(error.response?.data || error.message);
        }
    }

    return (
        <div className="container mx-auto my-8">
            <WhitePanelContentWrapper className="inline-block">
                <h3 className="font-display text-32 font-light mb-0	">
                    Organisation
                </h3>
                <p className="text-gray-500 mb-8">Create your organisation</p>
                <UserUpdateOrganisationForm
                    onSubmit={(values) => handleCreateOrganisation(values)}
                    action="create"
                />
            </WhitePanelContentWrapper>
            <WhitePanelContentWrapper className=" mt-8 inline-block pb-6">
                <h3 className="font-display text-32 font-light mb-0	">
                    Manage your plan
                </h3>
                <p className="text-gray-500 mb-8">Choose your plan</p>

                <div className=" flex  gap-x-5">
                    {billingPlansData.map((planData) => (
                        <BillingPlanItem
                            planData={planData}
                            selectedPlan={billingPlan}
                            setSelectedPlan={setBillingPlan}
                            key={planData.value}
                        />
                    ))}
                </div>
            </WhitePanelContentWrapper>
            {showCreateSuccessScreen ? (
                <div className="bg-white absolute top-0 left-0 w-screen h-screen px-auto pb pt-32 text-center">
                    <p className="text-2xl text-gray-500 font-display mb-8">
                        Your organisation has been created. Start using Agile
                        Analytics in a moment...{' '}
                    </p>
                    <Loader />
                </div>
            ) : null}
        </div>
    );
}

export default CreateOrganisationPage;
