import { toast } from 'react-toastify';

function showNotification(message, type = 'error', customId) {
    switch (type) {
        case 'info':
            if (customId) {
                toast.info(message, { toastId: customId });
            } else {
                toast.info(message);
            }

            break;
        case 'success':
            if (customId) {
                toast.success(message, { toastId: customId });
            } else {
                toast.success(message);
            }
            break;
        case 'warning':
            if (customId) {
                toast.warning(message, { toastId: customId });
            } else {
                toast.warning(message);
            }
            break;
        case 'error':
            if (customId) {
                toast.error(message, { toastId: customId });
            } else {
                toast.error(message);
            }
            break;
        default:
            if (customId) {
                toast(message, { toastId: customId });
            } else {
                toast(message);
            }
    }
}
export default showNotification;
