import React, { Component } from 'react';
import moment from 'moment';
import { TimePeriodContext } from '../../context/TimePeriodContext.js';
import Loader from '../loader';
import LeaksTableRow from './leaks-table-row';
import LeaksTableHeader from './leaks-table-header';
import ReactPaginate from 'react-paginate';
import { withTranslation } from 'react-i18next';
import './styles.scss';
import { getLeaksTable, setLeakStatus } from '../../api/leak/LeakAPI';
import showNotification from '../../helpers/showNotification';
import PanelTitle from '../panel-title/index.js';

class LeaksTable extends Component {
    static contextType = TimePeriodContext;
    state = {
        leaksData: [],
        leaksList: {
            start: '0',
            end: '9',
        },
        loading: true,
        minHeight: this.getMinHeight(),
        leaksPerPage: 10,
        pageQuantity: Math.ceil(this.props.leaksQuantity / 10),
        sort: 'committer',
        direction: 'start',
    };

    getMinHeight(leakQuantity) {
        let minHeight;

        if (leakQuantity) {
            minHeight = `${49 * leakQuantity}px`;
        } else {
            minHeight =
                this.props.leaksQuantity < 10
                    ? `${49 * this.props.leaksQuantity}px`
                    : '490px';
        }
        return minHeight;
    }

    componentDidMount() {
        this.getTableData();
    }

    createTable = () => {
        if (this.state.leaksData.length) {
            return this.state.leaksData.map((leak, index) => {
                return (
                    <LeaksTableRow
                        leak={leak}
                        key={`${leak.commit}_${leak.commit_author}_${leak.file}_in_${this.props.repositoryName}_${index}`}
                        onSelectChange={this.onSelectChange}
                        repoName={this.props.repositoryName}
                    />
                );
            });
        } else {
            console.log(this.props.noDataFound);
            let text;
            if (this.props.noDataFound) {
                text = this.props.t('leaks_page.table.no_data');
            } else {
                text = this.props.t('leaks_page.table.no_leak');
            }

            return (
                <div className="no-leaks text-center">
                    <p>{text}</p>
                </div>
            );
        }
    };

    pageChange = (page) => {
        let newStart, newEnd, remainingLeaks;
        newStart = `${page.selected}0`;
        remainingLeaks = this.props.leaksQuantity - +newStart;

        if (remainingLeaks <= 10) {
            newEnd = (this.props.leaksQuantity - 1).toString();

            let leaksList = {
                start: newStart,
                end: newEnd,
            };

            this.setState(
                {
                    loading: true,
                    leaksList: leaksList,
                    minHeight: this.getMinHeight(remainingLeaks),
                },
                () => this.getTableData()
            );
        } else {
            let newEnd = `${page.selected}9`;
            let leaksList = {
                start: newStart,
                end: newEnd,
            };
            this.setState(
                {
                    loading: true,
                    leaksList: leaksList,
                    minHeight: 480,
                },
                () => this.getTableData()
            );
        }
    };

    selectFilter = (sort, direction) => {
        let setSort, setDirection;
        switch (sort) {
            case 'commit_id':
                setSort = 'commit';
                break;
            case 'committer':
                setSort = 'committer';
                break;
            case 'date':
                setSort = 'date';
                break;
            case 'leak_type':
                setSort = 'leak_type';
                break;
            case 'action':
                setSort = 'action';
                break;
            default:
                setSort = false;
        }

        setDirection = direction === true ? 'start_to_end' : 'end_to_start';

        this.setState(
            {
                loading: true,
                sort: setSort,
                direction: setDirection,
            },
            () => this.getTableData()
        );
    };

    getTableData = () => {
        let requestParams = {
            params: {
                repository_name: this.props.repositoryName,
                sort: this.state.sort,
                direction: this.state.direction,
                start: this.state.leaksList.start,
                end: this.state.leaksList.end,
                date_start: this.context[0].date_start,
                date_end: this.context[0].date_end,
            },
        };

        if (this.props.leaksQuantity !== 0) {
            getLeaksTable(requestParams)
                .then((response) => {
                    this.setState({
                        leaksData: response.data,
                        loading: false,
                    });
                })
                .catch((error) => {
                    this.setState({
                        error: error,
                        loading: false,
                    });
                });
        } else {
            this.setState({
                leaksData: [],
                loading: false,
            });
        }
    };

    findLeak = (newLeak) => {
        let newLeaksData = this.state.leaksData.map((leak) => {
            if (this.sameLeak(leak, newLeak)) {
                return {
                    ...leak,
                    key: newLeak.key,
                    marked_date: moment
                        .unix(newLeak.marked_date)
                        .format('YYYY-MM-DD HH:mm:ssZ'),
                };
            }
            return leak;
        });

        this.setState((state) => {
            return {
                ...state,
                leaksData: newLeaksData,
            };
        });
    };

    sameLeak = (leak, leakData) => {
        return (
            leak.commit === leakData.commit &&
            leak.commit_author === leakData.commit_author &&
            leak.file === leakData.file &&
            leak.tag === leakData.tag &&
            leak.date === leakData.date
        );
    };

    onSelectChange = (value, leak) => {
        let markedDate = moment().unix();

        let newLeak = {
            ...leak,
            key: value.value,
            marked_date: markedDate,
            repo: this.props.repositoryName,
        };

        setLeakStatus(newLeak)
            .then((response) => {
                if (
                    (response.status === 200 && response.data === 'OK') ||
                    response.status === 202
                ) {
                    this.findLeak(newLeak);
                    this.props.updateChart();
                }
            })
            .catch((error) => {
                showNotification(error.message);
            });
    };

    render() {
        return (
            <div className="leaks-table-wrapper">
                <PanelTitle
                    title={this.props.repositoryName}
                    underlineColorType="tertiary"
                    isLink={true}
                />
                {/* <h3 className="text-center">{this.props.repositoryName}</h3> */}
                <div className="leaks-table w-100">
                    <LeaksTableHeader
                        repositoryName={this.props.repositoryName}
                        makeSort={this.selectFilter}
                    />
                    <div
                        className="leaks-table__body divide-y divide-solid divide-gray-300"
                        style={
                            this.state.loading
                                ? { minHeight: this.state.minHeight }
                                : null
                        }
                    >
                        {this.state.loading ? (
                            <div className="m-auto">
                                <Loader
                                    color={'#C2C7D7'}
                                    size={35}
                                    speedMultiplier={0.8}
                                />
                            </div>
                        ) : (
                            this.createTable()
                        )}
                    </div>
                    <div className="flex justify-end mt-2">
                        {this.state.pageQuantity > 1 ? (
                            <>
                                <ReactPaginate
                                    previousLabel={'<'}
                                    previousLinkClassName={
                                        'block w-5 h-5 flex justify-center items-center  bg-gray-400 rounded text-white font-semibold'
                                    }
                                    nextLabel={'>'}
                                    nextLinkClassName={
                                        'block w-5 h-5 flex justify-center items-center  bg-gray-400 rounded text-white font-semibold'
                                    }
                                    breakLabel={'...'}
                                    breakClassName={'text-gray-400'}
                                    pageLinkClassName={'block w-full p-1'}
                                    pageClassName={'text-center text-gray-400'}
                                    pageCount={this.state.pageQuantity}
                                    marginPagesDisplayed={4}
                                    pageRangeDisplayed={3}
                                    containerClassName={
                                        'flex gap-x-1.5 items-center'
                                    }
                                    subContainerClassName={''}
                                    activeClassName={'bg-gray-200 rounded'}
                                    onPageChange={(page) =>
                                        this.pageChange(page)
                                    }
                                />
                            </>
                        ) : null}
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(LeaksTable);
