import React, { useEffect, useContext, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { OrganisationsContext } from '../../../context/OrganisationsContext';
import Loader from '../../loader';
import ErrorIndicator from '../../error-indicator';
import { InputGroup, TextAreaGroup, SaveButton } from '../../custom';
import { Trash } from '../../../assets/svg';
import { useDefaultStateWithReducer } from '../../../helpers/useDefaultStateWithReducer';
import _ from 'lodash';
import {
    createSAML,
    deleteSAML,
    getSAML,
    updateSAML,
} from '../../../api/settings/SamlAPI';
import WhitePanelContentWrapper from '../../white-panel-content-wrapper';
import PanelTitle from '../../panel-title';
import showNotification from '../../../helpers/showNotification';
import { getAdminPermission } from '../../../helpers/getPermission';
import { capitalizeFirstLetter } from '../../../helpers/textFormatters';

const SamlTab = () => {
    const { t } = useTranslation();
    const { organisationsState } = useContext(OrganisationsContext);
    const [SAMLState, SAMLDispatch] = useDefaultStateWithReducer();
    const initialState = useMemo(
        () => ({
            callback_url: '',
            display_name: '',
            certificates: [''],
            enabled: true,
            idp_entity_id: '',
            provider_id: '',
            rp_entity_id: '',
            sso_url: '',
            new: true,
        }),
        []
    );

    const [localSAMLState, setLocalSAMLState] = useState(initialState);
    const [validationMessage, setValidationMessage] = useState('');

    const [permissionForWrite, setPermissionForWrite] = useState(false);

    const selectedOrgHash = organisationsState.data
        ? organisationsState.data.find((org) => org.active).org_hash
        : null;

    useEffect(() => {
        let canceled = false;

        SAMLDispatch({ type: 'SET_LOADING' });

        getSAML()
            .then((response) => {
                if (!canceled) {
                    if (response.status === 204) {
                        SAMLDispatch({
                            type: 'SET_DATA',
                            payload: {
                                ...initialState,
                                certificates: [...initialState.certificates],
                            },
                        });
                        setLocalSAMLState({
                            ...initialState,
                            certificates: [...initialState.certificates],
                        });
                    } else {
                        SAMLDispatch({
                            type: 'SET_DATA',
                            payload: {
                                ...response.data,
                                certificates: response.data?.certificates
                                    ? [...response.data.certificates]
                                    : [],
                                new: response.data?.display_name ? false : true,
                            },
                        });
                        setLocalSAMLState({
                            ...response.data,
                            certificates: response.data?.certificates
                                ? [...response.data.certificates]
                                : [],
                            new: response.data?.display_name ? false : true,
                        });
                    }
                }
            })
            .catch((error) => {
                if (!canceled) {
                    SAMLDispatch({
                        type: 'SET_ERROR',
                        payload: error,
                    });
                }
            });
        return () => (canceled = true);
    }, [selectedOrgHash]);

    useEffect(() => {
        validateLocaleSAMLState();
    }, [localSAMLState]);

    useEffect(() => {
        setPermissionForWrite(getAdminPermission(organisationsState?.data));
    }, [organisationsState]);

    const validateСertificate = (e) => {
        const CERTIFICATE_START = '-----BEGIN CERTIFICATE-----';
        const CERTIFICATE_END = '----END CERTIFICATE-----';
        const CERTIFICATE_VALUE = e?.target?.value ?? e;
        const CERTIFICATE_LENGTH = e?.target?.value?.length ?? e?.length;

        if (
            CERTIFICATE_VALUE.indexOf(CERTIFICATE_START) === 0 &&
            CERTIFICATE_VALUE.lastIndexOf(CERTIFICATE_END) ===
                CERTIFICATE_LENGTH - 24
        )
            return true;

        return false;
    };

    function validateLocaleSAMLState() {
        if (!localSAMLState.display_name) {
            setValidationMessage(
                capitalizeFirstLetter(
                    t('settings_page.saml_tab.provider_name_required')
                )
            );
            return;
        }
        if (!localSAMLState.idp_entity_id) {
            setValidationMessage(
                capitalizeFirstLetter(
                    t('settings_page.saml_tab.provider_entity_required')
                )
            );
            return;
        } else if (localSAMLState.idp_entity_id.slice(0, 8) !== 'https://') {
            setValidationMessage(
                capitalizeFirstLetter(
                    t('settings_page.saml_tab.entity_must_start')
                )
            );
            return;
        }

        if (!localSAMLState.sso_url) {
            setValidationMessage(
                capitalizeFirstLetter(t('settings_page.saml_tab.sso_required'))
            );
            return;
        } else if (localSAMLState.sso_url.slice(0, 8) !== 'https://') {
            setValidationMessage(
                capitalizeFirstLetter(
                    t('settings_page.saml_tab.sso_must_start')
                )
            );
            return;
        }

        if (
            !localSAMLState.certificates?.length ||
            localSAMLState.certificates[0].length === 0
        ) {
            setValidationMessage(
                capitalizeFirstLetter(
                    t('settings_page.saml_tab.certificate_required')
                )
            );
            return;
        } else {
            const invalidCertificate = localSAMLState.certificates.find(
                (sertificate) => !validateСertificate(sertificate)
            );
            if (invalidCertificate) {
                setValidationMessage(
                    capitalizeFirstLetter(
                        t('settings_page.saml_tab.certificate_must_start')
                    )
                );
                return;
            }
        }

        if (!localSAMLState.rp_entity_id) {
            setValidationMessage(
                capitalizeFirstLetter(
                    t('settings_page.saml_tab.service_id_required')
                )
            );
            return;
        }

        setValidationMessage('');
    }

    const addAdditionalCertificate = () => {
        setLocalSAMLState((prevState) => {
            return {
                ...prevState,
                certificates: [...prevState.certificates, ''],
            };
        });
    };

    const areDisabled = () => {
        if (validationMessage) {
            return true;
        }

        const hasNameChange =
            SAMLState.data.display_name != localSAMLState.display_name;
        const hasEnableChange =
            SAMLState.data.enabled != localSAMLState.enabled;
        const hasIdenityPrividerIDChage =
            SAMLState.data.idp_entity_id != localSAMLState.idp_entity_id;
        const hasSSOUrlChange =
            SAMLState.data.sso_url != localSAMLState.sso_url;
        const hasCertificatesChange = !_.isEqual(
            SAMLState.data.certificates,
            localSAMLState.certificates
        );
        const hasServiceProdiderEntityIDChange =
            SAMLState.data.rp_entity_id != localSAMLState.rp_entity_id;

        return !(
            hasNameChange ||
            hasEnableChange ||
            hasIdenityPrividerIDChage ||
            hasSSOUrlChange ||
            hasCertificatesChange ||
            hasServiceProdiderEntityIDChange
        );
    };

    const saveChanges = () => {
        if (!areDisabled()) {
            let data = {};
            const hasNameChange =
                SAMLState.data.display_name != localSAMLState.display_name;
            const hasEnableChange =
                SAMLState.data.enabled != localSAMLState.enabled;
            const hasIdenityPrividerIDChage =
                SAMLState.data.idp_entity_id != localSAMLState.idp_entity_id;
            const hasSSOUrlChange =
                SAMLState.data.sso_url != localSAMLState.sso_url;
            const hasCertificatesChange = !_.isEqual(
                SAMLState.data.certificates,
                localSAMLState.certificates
            );

            if (hasNameChange) {
                data.display_name = localSAMLState.display_name;
            }
            if (hasEnableChange) {
                data.enabled = localSAMLState.enabled;
            }
            if (hasIdenityPrividerIDChage) {
                data.idp_entity_id = localSAMLState.idp_entity_id;
            }
            if (hasSSOUrlChange) {
                data.sso_url = localSAMLState.sso_url;
            }
            if (hasCertificatesChange) {
                data.certificates = localSAMLState.certificates;
            }

            SAMLDispatch({ type: 'SET_LOADING' });

            if (localSAMLState.new) {
                data.rp_entity_id = localSAMLState.rp_entity_id;
                !validationMessage &&
                    createSAML(data)
                        .then((response) => {
                            if (response.status === 200) {
                                const newGlobalState = {
                                    ...SAMLState.data,
                                    ...data,
                                    new: false,
                                };
                                SAMLDispatch({
                                    type: 'SET_DATA',
                                    payload: newGlobalState,
                                });
                                showNotification(
                                    'Provider has been added',
                                    'success'
                                );
                            }
                            setValidationMessage('');
                        })
                        .catch((error) => {
                            SAMLDispatch({ type: 'SET_ERROR', payload: error });
                            setValidationMessage('');
                        });
            } else {
                const hasServiceProdiderEntityIDChange =
                    SAMLState.data.rp_entity_id != localSAMLState.rp_entity_id;
                if (hasServiceProdiderEntityIDChange) {
                    data.rp_entity_id = localSAMLState.rp_entity_id;
                }

                updateSAML(data)
                    .then((response) => {
                        if (response.status === 200) {
                            const newGlobalState = {
                                ...SAMLState.data,
                                ...data,
                            };
                            SAMLDispatch({
                                type: 'SET_DATA',
                                payload: newGlobalState,
                            });
                        }
                        showNotification(
                            'Provider has been updated',
                            'success'
                        );
                    })
                    .catch((error) => {
                        SAMLDispatch({ type: 'SET_ERROR', payload: error });
                    });
            }
        }
    };

    const setInputValue = (e) => {
        const target = e.target;
        const value =
            target.type === 'checkbox' ? target.checked : target.value;
        const key = target.name;

        let stateClone = { ...localSAMLState };
        if (key.indexOf('certificate') + 1) {
            const index = key.split('-')[1];
            stateClone.certificates[index] = value;
            setLocalSAMLState(stateClone);
        } else {
            stateClone[key] = value;
            setLocalSAMLState(stateClone);
        }
    };

    const removeCertificate = (idx) => {
        setLocalSAMLState((prevState) => {
            return {
                ...prevState,
                certificates: prevState.certificates.filter(
                    (sertificate, index) => index !== idx
                ),
            };
        });
    };

    const deleteSAMLProvider = () => {
        SAMLDispatch({ type: 'SET_LOADING' });
        deleteSAML()
            .then(() => {
                SAMLDispatch({ type: 'SET_DATA', payload: initialState });
                setLocalSAMLState(initialState);
            })
            .catch((error) => {
                SAMLDispatch({
                    type: 'SET_NO_LOADING',
                    payload: error,
                });
                showNotification(
                    error.response?.data ||
                        error.message ||
                        error.data.message ||
                        'Oops! Something went wrong!'
                );
            });
    };

    if (SAMLState.loading && SAMLState.data === null) {
        return (
            <WhitePanelContentWrapper
                className="settings-tab-content saml-tab flex items-start justify-center mb-8"
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                }}
            >
                <Loader />
            </WhitePanelContentWrapper>
        );
    }

    if (SAMLState.error) {
        return (
            <WhitePanelContentWrapper
                className="settings-tab-content saml-tab flex items-start justify-center mb-8"
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                }}
            >
                <ErrorIndicator error={SAMLState.error} />
            </WhitePanelContentWrapper>
        );
    }

    return (
        <WhitePanelContentWrapper className="settings-tab-content saml-tab mb-8">
            <PanelTitle
                title={capitalizeFirstLetter(t('settings_page.saml_tab.title'))}
            />
            <div className="provider-name">
                <InputGroup
                    style={
                        permissionForWrite && SAMLState?.data?.display_name
                            ? {}
                            : { width: '100%' }
                    }
                    label={{
                        text: capitalizeFirstLetter(
                            t('settings_page.saml_tab.provider_name')
                        ),
                        for: 'display_name',
                    }}
                    input={{
                        value: localSAMLState.display_name ?? '',
                        type: 'text',
                        name: 'display_name',
                        id: 'display_name',
                        placeholder: 'Name',
                        onChange: setInputValue,
                    }}
                />
                {permissionForWrite && SAMLState?.data?.display_name ? (
                    <button
                        className="delete-provider"
                        onClick={deleteSAMLProvider}
                    >
                        <Trash width="24px" height="24px" />
                    </button>
                ) : null}
            </div>

            <div className="enable-switcher">
                <p className="switcher__title">
                    {capitalizeFirstLetter(t('settings_page.saml_tab.enabled'))}
                </p>
                <label className="switch">
                    <input
                        type="checkbox"
                        name="enabled"
                        id="enabled"
                        checked={localSAMLState.enabled ?? false}
                        onChange={permissionForWrite ? setInputValue : () => {}}
                    />
                    <span
                        style={
                            permissionForWrite ? {} : { background: '#484a53' }
                        }
                        className={`slider ${
                            permissionForWrite ? 'pointer' : ''
                        } round`}
                    ></span>
                </label>
                <p className="switcher__value">
                    {localSAMLState.enabled
                        ? capitalizeFirstLetter(t('settings_page.saml_tab.on'))
                        : capitalizeFirstLetter(
                              t('settings_page.saml_tab.off')
                          )}
                </p>
            </div>
            <InputGroup
                label={{
                    text: capitalizeFirstLetter(
                        t('settings_page.saml_tab.identity_id')
                    ),
                    for: 'idp_entity_id',
                }}
                input={{
                    value: localSAMLState.idp_entity_id ?? '',
                    type: 'text',
                    name: 'idp_entity_id',
                    id: 'idp_entity_id',
                    placeholder: 'ID',
                    onChange: setInputValue,
                }}
            />

            <InputGroup
                label={{
                    text: 'SSO Url',
                    for: 'sso_url',
                }}
                input={{
                    value: localSAMLState.sso_url ?? '',
                    type: 'text',
                    name: 'sso_url',
                    id: 'sso_url',
                    placeholder: 'URL',
                    onChange: setInputValue,
                }}
            />

            <div className="certificates">
                <p className="certificates__title">
                    {capitalizeFirstLetter(
                        t('settings_page.saml_tab.certificates')
                    )}
                </p>
                {localSAMLState.certificates.map((sertificate, index) => (
                    <div
                        className="certificate-wrapper certificate"
                        key={index}
                    >
                        <TextAreaGroup
                            style={permissionForWrite ? {} : { width: '100%' }}
                            textarea={{
                                name: `certificate-${index}`,
                                value: localSAMLState.certificates[index],
                                onChange: setInputValue,
                                placeholder: `Must start with "-----BEGIN CERTIFICATE-----", and end with "-----END CERTIFICATE-----".`,
                            }}
                            validationFunc={validateСertificate}
                        />
                        {permissionForWrite ? (
                            <button
                                className="delete-certificate"
                                onClick={() => removeCertificate(index)}
                            >
                                <Trash width="24px" height="24px" />
                            </button>
                        ) : null}
                    </div>
                ))}
                {permissionForWrite ? (
                    <button onClick={addAdditionalCertificate}>
                        {capitalizeFirstLetter(t('settings_page.saml_tab.add'))}
                    </button>
                ) : null}
            </div>
            <InputGroup
                label={{
                    text: capitalizeFirstLetter(
                        t('settings_page.saml_tab.service_id')
                    ),
                    for: 'rp_entity_id',
                }}
                input={{
                    value: localSAMLState.rp_entity_id,
                    type: 'text',
                    name: 'rp_entity_id',
                    id: 'rp_entity_id',
                    placeholder: 'ID',
                    onChange: setInputValue,
                }}
            />
            {validationMessage ? (
                <p className="mb-2 text-xs text-center text-red-text">
                    {validationMessage}
                </p>
            ) : null}
            {SAMLState.loading ? (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginBottom: '20px',
                    }}
                >
                    <Loader color={'#C2C7D7'} size={35} speedMultiplier={0.8} />
                </div>
            ) : null}
            <SaveButton disabled={areDisabled()} saveFunc={saveChanges} />
        </WhitePanelContentWrapper>
    );
};

export default SamlTab;
