import React, { useState, useContext } from 'react';
import { ProjectsContext } from '../../../context/ProjectsContext';
import EditableCell from './EditableCell';
import TableEditableThead from './thead';
import TableEditableTbody from './tbody';
import { useTable } from 'react-table';
import { createProjects, updateProjects } from '../../../api/hours/HoursAPI';

const defaultColumn = {
    Cell: EditableCell,
};

function TableEditable({
    data,
    columns,
    validateForm,
    updateProjects,
    deleteProject,
    type,
}) {
    const [selectedRow, setSelectedRow] = useState(null);
    const [errorMessages, setErrorMessages] = useState({});

    function updateSelectedRow(name, value) {
        const updatedRow = {
            ...selectedRow,
            values: { ...selectedRow.values, [name]: value || undefined },
        };

        setSelectedRow(updatedRow);

        if (validateForm) {
            setErrorMessages(validateForm(updatedRow));
        }
    }

    function saveRow() {
        // Update data
        updateData(selectedRow);

        setSelectedRow(null);
    }

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        useTable({
            columns,
            data,
            defaultColumn,
            // updateData,
            selectedRowIndex: selectedRow?.index,
            updateSelectedRow,
            saveRow,
            errorMessages,
        });

    return (
        <div className="overflow-x-auto">
            <table {...getTableProps} className="mt-5 w-full table-fixed">
                <TableEditableThead headerGroups={headerGroups} />
                <TableEditableTbody
                    getTableBodyProps={getTableBodyProps}
                    rows={rows}
                    data={data}
                    prepareRow={prepareRow}
                    errorMessages={errorMessages}
                    validateForm={validateForm}
                    updateProjects={updateProjects}
                    deleteProject={deleteProject}
                    type={type}
                />
            </table>
        </div>
    );
}

export default TableEditable;
