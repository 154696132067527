import React from 'react';
import moment from 'moment';

const WorkspacesItem = ({ workspace }) => {
    const { workspace_name } = workspace;
    const users = workspace.users.length;
    const channels =
        workspace.channels !== 'Undefined' ? workspace.channels.length : '-';
    const date = moment(workspace.modification_date).format('YYYY-MM-DD');

    return (
        <div className="workspace grid grid-cols-12">
            <p className="col-span-3 px-4">{workspace_name}</p>
            <p className="col-span-3 px-4">{users}</p>
            <p className="col-span-3 px-4">{channels}</p>
            <p className="col-span-3 px-4">{date}</p>
        </div>
    );
};

export default WorkspacesItem;
