import React, { useReducer, createContext } from 'react';

const userReducer = (state, action) => {
    switch (action.type) {
        case 'SET_LOADING':
            return { data: state.data, loading: true, error: null };
        case 'SET_DATA':
            return {
                data: action.payload,
                loading: false,
                error: null,
            };
        case 'SET_ERROR':
            return { data: state.data, loading: false, error: action.payload };
        default:
            return state;
    }
};

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [userState, userDispatch] = useReducer(userReducer, {
        data: null,
        loading: true,
        error: null,
    });

    return (
        <UserContext.Provider value={{ userState, userDispatch }}>
            {children}
        </UserContext.Provider>
    );
};
