import React from 'react';
import TableEditable from '../index';

function TableEditableThead({ headerGroups }) {
    return (
        <thead>
            {headerGroups.map((headerGroup) => (
                <tr
                    {...headerGroup.getHeaderGroupProps()}
                    className="border-b-2 border-solid border-gray-300"
                >
                    {headerGroup.headers.map((column) => (
                        <th
                            {...column.getHeaderProps([])}
                            className="px-4 py-3 text-body font-bold text-gray-800"
                            width={column.width ? column.width : ''}
                        >
                            {column.render('Header')}
                        </th>
                    ))}
                    <th className="w-20" />
                </tr>
            ))}
        </thead>
    );
}

export default TableEditableThead;
