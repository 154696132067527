import React from 'react';
import DefaultBillingPlanContent from '../billing-plan-item-default-content';
import BillingPlanConfigurationForm from '../billing-plan-item-configuration';

function BillingPlanItem({
    planData,
    selectedPlan,
    setSelectedPlan,
    permissionForWrite,
    orgData,
    isYearly,
    configuredPlan,
    setConfiguredPlan,
    users,
}) {
    const { value } = planData;

    return (
        <div
            key={value}
            className={` ${
                configuredPlan
                    ? configuredPlan === value
                        ? 'w-full'
                        : ' w-0 overflow-hidden border-none absolute -z-50'
                    : 'block w-72'
            }  flex flex-col border border-solid rounded-lg border-gray-400 text-base text-gray-500 bg-bg-gray-400 transform transition-all duration-300`}
        >
            {configuredPlan === value ? (
                <BillingPlanConfigurationForm
                    planData={planData}
                    isYearly={isYearly}
                    selectedPlan={selectedPlan}
                    setConfiguredPlan={setConfiguredPlan}
                    orgData={orgData}
                    users={users}
                />
            ) : (
                <DefaultBillingPlanContent
                    planData={planData}
                    isYearly={isYearly}
                    permissionForWrite={permissionForWrite}
                    selectedPlan={selectedPlan}
                    setConfiguredPlan={setConfiguredPlan}
                    orgData={orgData}
                />
            )}
        </div>
    );
}

export default BillingPlanItem;
