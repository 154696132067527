import jsPDF from 'jspdf';
import 'jspdf-autotable';
import showNotification from './showNotification';

export const exportPDF = (title, headers, data) => {
    console.log('headers', headers);
    const unit = 'pt';
    const size = 'A4'; // Use A1, A2, A3 or A4
    const orientation = 'portrait'; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const formattedHeaders = headers.map(
        (item) => item.Header().props.children
    );

    const formattedData = data.map((item) => {
        return [
            item.date,
            item.employee,
            item.projectKey,
            item.tickets,
            item.hours,
        ];
    });

    const papedData = formattedData.pop();
    if (papedData) {
        const footer = Object.values(papedData);

        let content = {
            startY: 50,
            head: [formattedHeaders],
            body: formattedData,
            foot: [footer],
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save('hours_report.pdf');
    } else {
        showNotification('No data to export', 'warning');
    }
};
