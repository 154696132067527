import React, { useState } from 'react';
import { DayPicker } from 'react-day-picker';
import { nl } from 'date-fns/locale';
import moment from 'moment';
import { FaRegCalendarAlt } from 'react-icons/fa';
import ButtonSecondary from '../../../../components/button/ButtonSecondary';

const HoursPageTimesheetToolbarDatePicker = ({
    filterDate,
    setFilterDate,
    updateDates,
}) => {
    const [showDayPicker, setShowDayPicker] = useState(false);

    const handleDayChange = (date) => {
        setFilterDate(moment(date));
        setShowDayPicker(false);
        updateDates();
    };

    return (
        <div className="relative">
            <ButtonSecondary
                onClick={() => setShowDayPicker(!showDayPicker)}
                variant={'gray'}
                extraClasses={'h-8'}
            >
                <FaRegCalendarAlt />
            </ButtonSecondary>
            {showDayPicker && (
                <div className="absolute w-72 left-0 top-12 bg-white shadow-lg rounded z-10">
                    <DayPicker
                        selectedDays={filterDate.toDate()}
                        showWeekNumbers={true}
                        locale={nl}
                        firstDayOfWeek={1}
                        onDayClick={handleDayChange}
                    />
                </div>
            )}
        </div>
    );
};

export default HoursPageTimesheetToolbarDatePicker;
