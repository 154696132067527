import React, {
    useState,
    useEffect,
    useContext,
    useCallback,
    useRef,
} from 'react';
import { getCronjobStatus, getServices } from '../../api/settings-slo/slo';
import { useFetchDataAndSetState } from '../../helpers/useFetchDataAndSetState';
import { withUserAndOragnisations } from '../../helpers/withUser';
import { useTranslation } from 'react-i18next';
import { OrganisationsContext } from '../../context/OrganisationsContext';
import CronjobStatus from './components/sre-cronjob-status';
import UpgradeWindow from '../../components/upgrade-window';
import { getSREBackends } from '../../api/settings/sreAPI';
import SettingsMessage from '../../components/settings-message';
import { NAVIGATION_PATHS } from '../../constants/navigationPaths';
import {
    capitalizeEveryWordFirstLetter,
    capitalizeFirstLetter,
} from '../../helpers/textFormatters';
import ModalWrapper from '../../components/modal-wrapper';
import ModalAddingServiceContent from './components/sre-modal-add-service-content';
import SREService from './components/sre-service';
import Loader from '../../components/loader';
import ErrorIndicator from '../../components/error-indicator';
import { getDeploymentFrequencyData } from '../../api/dora/DoraAPI';
import { TimePeriodContext } from '../../context/TimePeriodContext';

const SREPage = () => {
    const { t } = useTranslation();
    const { organisationsState } = useContext(OrganisationsContext);
    const selectedOrgHash = organisationsState.data
        ? organisationsState.data.find((org) => org.active).org_hash
        : null;

    const [services, servicesDispatch] = useFetchDataAndSetState(getServices, [
        selectedOrgHash,
    ]);
    const jobsRef = useRef(null);

    const [currentOrgPermission, setCurrentOrgPermission] = useState(null);

    const [jobsFetchingCounter, setJobsFetchingCounter] = useState(0);

    function createJobStatusSub() {
        return Promise.resolve({
            status: 200,
            data: {
                job_name: 'slo-of2170-27413870',
                last_run: 'Mon, 14 Feb 2022 09:50:00 GMT',
                status: 'Succeeded',
            },
        });
    }

    const cronjobRequest = useCallback(() => {
        if (selectedOrgHash === 'O27A10') {
            return createJobStatusSub();
        } else {
            return getCronjobStatus();
        }
    }, [jobsFetchingCounter, selectedOrgHash]);

    const [cronjobState] = useFetchDataAndSetState(
        cronjobRequest,
        [selectedOrgHash, jobsFetchingCounter],
        null
    );

    const backendsRequest = useCallback(() => getSREBackends(), []);
    const [backendsState] = useFetchDataAndSetState(backendsRequest, [
        selectedOrgHash,
    ]);

    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);

    const [timePeriodState] = useContext(TimePeriodContext);
    const { date_start: dateTimeStart, date_end: dateTimeEnd } =
        timePeriodState;

    const deploymentFreqRequest = useCallback(
        () =>
            getDeploymentFrequencyData({
                params: { date_start: dateTimeStart, date_end: dateTimeEnd },
            }),
        [dateTimeStart, dateTimeEnd]
    );
    const [deploymentFreqState] = useFetchDataAndSetState(
        deploymentFreqRequest,
        [selectedOrgHash, dateTimeStart, dateTimeEnd]
    );

    useEffect(() => {
        setCurrentOrgPermission(
            organisationsState.data.find(
                (org) => org.org_hash === selectedOrgHash
            ).subscription_level
        );
    }, [selectedOrgHash]);

    function renderJobsScrollBtn(jobsRef) {
        function executeJobsScroll() {
            jobsRef.current.scrollIntoView();
        }

        return (
            <div className="flex">
                <button
                    onClick={() => executeJobsScroll()}
                    className="text-yellow-700 py-1 underline"
                >
                    Go to the Last Job Status
                </button>
            </div>
        );
    }

    return (
        <div className={`container mx-auto relative`}>
            {currentOrgPermission === 'free' && (
                <UpgradeWindow
                    accessTo={capitalizeEveryWordFirstLetter(
                        t('settings_page.sre_tab.title')
                    )}
                />
            )}
            <div
                className={`${
                    currentOrgPermission === 'free' ? 'filter blur' : ''
                } flex flex-col gap-4`}
            >
                {!backendsState?.data?.length &&
                !backendsState.loading &&
                !services.loading ? (
                    <SettingsMessage
                        messageText={capitalizeFirstLetter(
                            t('settingsMessages.no_backend')
                        )}
                        link={NAVIGATION_PATHS.settingsErrorBudgets}
                        linkText={capitalizeFirstLetter(
                            t('settingsMessages.go_to_sre_settings')
                        )}
                    />
                ) : null}
                {backendsState?.data?.length &&
                !services?.data?.length &&
                !backendsState.loading &&
                !services.loading ? (
                    <SettingsMessage
                        messageText={`Add your first service and set up a feature to start measuring ${capitalizeEveryWordFirstLetter(
                            t('settings_page.sre_tab.title')
                        )}.`}
                    />
                ) : null}

                {isEditing ? null : (
                    <div className="flex gap-x-4 justify-end ">
                        <button
                            className="btn-white-hover-blue"
                            type="button"
                            onClick={() => setIsAddModalOpen(true)}
                        >
                            Configure new service
                        </button>
                    </div>
                )}

                {services?.data?.length &&
                !cronjobState?.loading &&
                cronjobState?.data &&
                cronjobState?.data?.status === 'Failed' ? (
                    <div className="mt-4">
                        <SettingsMessage
                            messageText={`Looks like the job was run unsuccessfully. Error Budgets data might be not up to date.`}
                            type="yellow"
                            renderContentFunc={() =>
                                renderJobsScrollBtn(jobsRef)
                            }
                        />
                    </div>
                ) : null}

                {services?.error ? (
                    <ErrorIndicator error={services.error} />
                ) : null}

                {services?.loading ? (
                    <div
                        className="flex justify-center items-center"
                        style={{ width: '100%', height: '394px' }}
                    >
                        <Loader
                            color={'#C2C7D7'}
                            size={35}
                            speedMultiplier={0.8}
                            css={{ margin: '30px auto', display: 'block' }}
                        />
                    </div>
                ) : null}

                {services?.data?.length && !services?.loading
                    ? services.data?.map((service, i) => {
                          if (i === 0) {
                              return (
                                  <div
                                      className="w-full "
                                      key={service.service}
                                      //   data-tour="r-step-page-4"
                                      //   data-tour-2="r-step-page-5"
                                  >
                                      <SREService
                                          service={service}
                                          services={services}
                                          key={service.service}
                                          servicesDispatch={servicesDispatch}
                                          setIsEditing={setIsEditing}
                                          sortedDeploymentFreqData={deploymentFreqState?.data?.sort(
                                              (a, b) =>
                                                  a.timestamp - b.timestamp
                                          )}
                                      />
                                  </div>
                              );
                          }
                          return (
                              <SREService
                                  service={service}
                                  services={services}
                                  key={service.service}
                                  servicesDispatch={servicesDispatch}
                                  setIsEditing={setIsEditing}
                                  sortedDeploymentFreqData={deploymentFreqState?.data?.sort(
                                      (a, b) => a.timestamp - b.timestamp
                                  )}
                              />
                          );
                      })
                    : null}

                <div className="flex gap-x-4 mb-10" ref={jobsRef}>
                    <CronjobStatus cronjobState={cronjobState} />
                </div>

                <ModalWrapper
                    modalState={isAddModalOpen}
                    setModalState={(value) => setIsAddModalOpen(value)}
                    title={t('sre.modal_add_service')}
                >
                    <ModalAddingServiceContent
                        services={services}
                        onClose={() => setIsAddModalOpen(false)}
                        servicesDispatch={servicesDispatch}
                    />
                </ModalWrapper>
            </div>
        </div>
    );
};

export default withUserAndOragnisations(SREPage);
