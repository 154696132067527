import { instances } from '../default-setup';

export const changeSubscription = (data) =>
    instances.post(`billing/purchase/`, data);

export const getInvoicesList = (paramsObj) => {
    const paramsString = Object.entries(paramsObj).reduce((acc, param, i) => {
        return acc + (i === 0 ? '' : '&') + `${param[0]}=${param[1]}`;
    }, '?');
    return instances.get(`billing/invoices/${paramsString}`);
};

export const getBillingDetails = () => instances.get(`billing/details/`);

export const getInvoiceData = (year, month) =>
    instances.get(`billing/invoice/?year=${year}&month=${month}`);
