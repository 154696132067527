import { instances } from '../default-setup';

export const getApiKey = () => instances.get('settings/api_keys/');

export const generateApiKey = () => instances.post('settings/api_keys/');

export const updateApiKey = (data) => instances.put('settings/api_keys/', data);

export const deleteApiKey = (params) =>
    instances.delete('settings/api_keys/', params);
