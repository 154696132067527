import React from 'react';
import { Emoji } from 'emoji-mart';
import data from '@emoji-mart/data';
import { init } from 'emoji-mart';
import { useTranslation } from 'react-i18next';
import ErrorBoundry from '../../error-boundary';
import PanelTitle from '../../panel-title';
import Loader from '../../loader';

init({ data });

const KudosTotal = ({ kudosState, kudosLeaderBoardState }) => {
    const { t } = useTranslation();

    const createEmojiList = () => {
        return kudosState.data.map((emoji) => (
            <em-emoji id={emoji} key={emoji} size={60} set="google"></em-emoji>
        ));
    };

    const createError = () => {
        if (kudosLeaderBoardState.error) {
            return (
                <div className="mt-4">
                    <p className="">
                        Sorry, something went wrong. Can't get kudos statistics.
                    </p>
                    <p> {kudosLeaderBoardState.error.status}</p>
                </div>
            );
        }

        if (kudosState.error) {
            return (
                <div className="mt-4">
                    <p className="">
                        Sorry, something goes wrong. Can't get emoji.
                    </p>
                </div>
            );
        }
    };

    const createContent = () => {
        if (kudosLeaderBoardState.data.total_kudos !== null) {
            if (+kudosLeaderBoardState.data.total_kudos === 0) {
                return (
                    <p className="font-display text-center text-gray-500 text-xl p-4">
                        {t('kudos_page.leaderboard.total_kudos.no_kudos')}
                    </p>
                );
            }

            return (
                <div className="mt-16 flex justify-center items-center gap-x-4">
                    <p className="font-display text-80 text-green-500">
                        +{kudosLeaderBoardState.data.total_kudos}
                    </p>
                    <div className="kudos-total__emojis">
                        {createEmojiList()}
                    </div>
                </div>
            );
        }

        return (
            <p className="text-center text-gray-500 text-xl p-4">
                {t('kudos_page.leaderboard.total_kudos.no_team_data')}
            </p>
        );
    };

    return (
        <div className="">
            <ErrorBoundry>
                <PanelTitle
                    title={t('kudos_page.leaderboard.total_kudos.title')}
                    underlineColorType={'tertiary'}
                />
                <div className="mt-4">
                    {kudosLeaderBoardState.loading || kudosState.loading ? (
                        <div className="flex justify-center items-center">
                            {' '}
                            <Loader
                                color={'#C2C7D7'}
                                size={35}
                                speedMultiplier={0.8}
                            />
                        </div>
                    ) : kudosLeaderBoardState.error || kudosState.error ? (
                        createError()
                    ) : (
                        createContent()
                    )}
                </div>
            </ErrorBoundry>
        </div>
    );
};

export default KudosTotal;
