import React, { useContext, useState, useEffect } from 'react';
import Loader from '../../../loader';
import ErrorIndicator from '../../../error-indicator';
import { OrganisationsContext } from '../../../../context/OrganisationsContext';
import { getCurrentOrganisation } from '../../../../api/organisation/OrganisationAPI';
import PaymentMethod from './payment-method';
import BillingSettings from './billing-settings';
import BillingHistory from './billing-history';

const BillingTab = ({ permissionForWrite }) => {
    const { organisationsState } = useContext(OrganisationsContext);
    const selectedOrganisationHash = organisationsState?.data
        ? organisationsState.data.find((org) => org.active)?.org_hash
        : null;
    const [currentOrganisationData, setCurrentOrganisationData] =
        useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        selectedOrganisationHash && void getCurrentOrganisationData();
    }, [selectedOrganisationHash]);

    async function getCurrentOrganisationData() {
        setLoading(true);

        try {
            const res = await getCurrentOrganisation();
            if (res.status === 200) {
                setCurrentOrganisationData(res.data);
            }

            setLoading(false);
        } catch (error) {
            setError(error);
            setLoading(false);
        }
    }

    if (error && !currentOrganisationData) {
        return (
            <div>
                <ErrorIndicator error={error} />
            </div>
        );
    }

    if (loading && !currentOrganisationData) {
        return (
            <div className="flex justify-center">
                <Loader />
            </div>
        );
    }

    if (currentOrganisationData) {
        return (
            <div className="space-y-5 pb-5">
                <PaymentMethod
                    selectedOrganisationHash={selectedOrganisationHash}
                />
                {/* <BillingSettings
                    permissionForWrite={permissionForWrite}
                    currentOrganisationData={currentOrganisationData}
                /> */}
                <BillingHistory
                    selectedOrganisationHash={selectedOrganisationHash}
                />
            </div>
        );
    }

    return (
        <div className="settings-tab-content organisation-tab">
            <p>No organisations</p>
        </div>
    );
};

export default BillingTab;
