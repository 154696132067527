const setCustomStyle = () => ({
    container: (provided) => ({
        ...provided,
        width: 280,
    }),
    control: (provided, state) => ({
        ...provided,
        outline: 'none',
        boxShadow: 'none',
        border: 'none',
        backgroundColor: 'transparent',
        cursor: 'pointer',
    }),
    singleValue: (provided) => ({
        ...provided,
        color: '#fff',
    }),

    dropdownIndicator: (provided) => ({
        ...provided,
        color: '#ffffff',
        opacity: '1',
        cursor: 'pointer',
        '&:hover': {
            color: '#ffffff',
        },
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        opacity: '0',
    }),
    menu: (provided, state) => ({
        ...provided,
        marginTop: '0',
        padding: '0',
    }),
    option: (provided, state) => ({
        ...provided,
        cursor: state.isSelected ? '' : 'pointer',
        color: state.isSelected
            ? '#C2C7D7'
            : state.isDisabled
            ? '#C9CEDC'
            : '#000',
        backgroundColor: 'transparent',
        '&:hover': {
            backgroundColor: state.isSelected ? '#fff' : '#e6e6e6',
        },
    }),
});

export default setCustomStyle;
