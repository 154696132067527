import React, { useContext } from 'react';
import Select, { components } from 'react-select';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import Loader from '../loader';
import ErrorIndicator from '../error-indicator';
import { OrganisationsContext } from '../../context/OrganisationsContext';
import setCustomStyle from './selectStyle';
import useParamsQuery from '../../hooks/useParamsQuery';

const OrganisationSelector = () => {
    const query = useParamsQuery();
    const navigate = useNavigate();
    const location = useLocation();
    const { organisationsState, organisationsDispatch } =
        useContext(OrganisationsContext);

    const { data, loading, error } = organisationsState;

    const selectActiveOrg = (selectedOrg) => {
        query?.get('tour') && navigate(location.pathname, { replace: true });
        if (
            selectedOrg?.org_hash !==
            orgList().find((org) => org.active)?.org_hash
        ) {
            const newList = organisationsState.data.map((org) => {
                return org?.org_hash === selectedOrg?.org_hash
                    ? { ...org, active: true }
                    : { ...org, active: false };
            });
            window.localStorage.setItem(
                'orgHash',
                newList.find((org) => org.active)?.org_hash
            );
            organisationsDispatch({
                type: 'SET_DATA',
                payload: newList,
            });
        }
    };

    const orgList = () => {
        return data
            .sort((a, b) => {
                const first = a.org_name.trim().toLowerCase();
                const second = b.org_name.trim().toLowerCase();
                if (first < second) {
                    return -1;
                }
                if (first > second) {
                    return 1;
                }
                return 0;
            })
            .map((item) => {
                return {
                    ...item,
                    value: item.org_hash,
                    label: item.org_name,
                };
            });
    };

    if (loading) {
        return (
            <div className="h-full organisations-list loading">
                <Loader />
            </div>
        );
    }

    if (error) {
        return (
            <div className="h-full organisations-list has-error">
                <ErrorIndicator error={error} style={{ padding: '0 10px' }} />
            </div>
        );
    }

    if (data) {
        return data.length > 1 ? (
            <div className="h-full organisations-list wrapper bg-theme-button rounded z-50">
                <GoodSelect
                    isSearchable={false}
                    options={orgList()}
                    styles={setCustomStyle()}
                    value={orgList().find((org) => org.active)}
                    onChange={(selectedOrg) => selectActiveOrg(selectedOrg)}
                />
            </div>
        ) : data.length === 0 ? (
            <div className="flex justify-center items-center p-2">
                <p className="leading-6">No Organisations</p>
            </div>
        ) : (
            <div className="bg-theme-button rounded flex justify-center items-center p-2">
                <p className="text-white leading-6	">{data[0].org_name}</p>
            </div>
        );
    } else {
        return (
            <div className="flex justify-center items-center p-2">
                <p className="leading-6">No Organisations</p>
            </div>
        );
    }
};

export default OrganisationSelector;

const Option = ({ children, ...props }) => {
    const { organisationsState } = useContext(OrganisationsContext);

    const currentOrg = organisationsState.data.find(
        (org) => org.org_name === children
    );

    const subscription = currentOrg?.subscription_level;

    return (
        <components.Option {...props}>
            <div className="flex gap-x-2 items-start">
                <p>{children} </p>
                {currentOrg?.status === 'removed' ? (
                    <p className="py-px text-10 text-red-text">
                        {currentOrg.status}
                    </p>
                ) : subscription ? (
                    <p className="py-px px-1 border border-solid border-theme-button text-10 uppercase text-theme-button">
                        {subscription}
                    </p>
                ) : null}
            </div>
        </components.Option>
    );
};

const SingleValue = ({ children, ...props }) => {
    const { organisationsState } = useContext(OrganisationsContext);

    const currentOrg = organisationsState.data.find(
        (org) => org.org_name === children
    );
    const subscription = currentOrg?.subscription_level;

    return (
        <components.SingleValue {...props}>
            <div className="flex gap-x-2 items-start cursor-pointer">
                <p className="text-white">{children} </p>
                {currentOrg?.status === 'removed' ? (
                    <span className=" py-px text-10 text-white">
                        {currentOrg.status}
                    </span>
                ) : subscription ? (
                    <span className=" py-px px-1 border border-solid border-white text-10 uppercase text-white">
                        {subscription}
                    </span>
                ) : null}
            </div>
        </components.SingleValue>
    );
};

const GoodSelect = (props) => (
    <Select {...props} components={{ Option, SingleValue }} />
);
