import React from 'react';

export default function NewsFeedDivider({ text }) {
    return (
        <div className="w-full flex gap-x-4 items-center">
            <h2 className="text-gray-500 font-display font-semibold	text-lg leading-6">
                {text}
            </h2>
            <div className="flex-grow h-px bg-gray-500 "></div>
        </div>
    );
}
