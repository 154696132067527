export const customStylesSelect = {
    placeholder: (provided) => ({
        ...provided,
        color: '#DADDE8',
        opacity: '1',
        fontFamily: `'Exo 2', sans-serif`,
    }),
    container: (provided) => ({
        ...provided,
        padding: '6px 12px',
        width: '170px',
        borderRadius: '4px',
        background: '#fff',
        border: '1px solid #DADDE8',
    }),
    control: (provided) => ({
        ...provided,
        border: 'none',
        minHeight: 'unset',
        height: '20px',
        borderColor: 'transparent',
        boxShadow: 'transparent',
        background: 'transparent',
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        display: 'none',
    }),
    valueContainer: (provided) => ({
        ...provided,
        height: '20px',
        padding: 0,
    }),
    singleValue: (provided) => ({
        ...provided,
        margin: '0',
        color: '#484A53',
        fontSize: '14px',
        lineHeight: '16px',
        fontFamily: `'Exo 2', sans-serif`,
    }),
    indicatorsContainer: (provided) => ({
        ...provided,
        padding: '0',
        height: '20px',
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        padding: '0',
        opacity: '1',
        cursor: 'pointer',
        color: '#DADDE8',
        '&:hover': {
            color: '#484A53',
        },
    }),
    menuList: (provided) => ({
        ...provided,
        padding: ' 0',
    }),
    menu: (provided) => ({
        ...provided,
        margin: '0',
        boxSizing: 'border-box',
        left: '-1px',
        width: '170px',
        borderRadius: '0',
        border: '1px solid #4C72BD',
    }),
    option: (provided, state) => ({
        ...provided,
        background: state.isSelected ? '#4C72BD' : '#fff',
        '&:hover': { background: state.isSelected ? '#4C72BD' : '#b5c4e3' },
        margin: '0',
        width: '100%',
        cursor: 'pointer',
        fontSize: '14px',
        lineHeight: '16px',
        maxWidth: '100%',
        fontFamily: `'Exo 2', sans-serif`,
    }),
};
