import { rest } from 'msw';

// let projects = [
//     {
//         name: 'React',
//         description: 'Some description',
//     },
//     {
//         name: 'Vue',
//         description: 'Some description',
//     },
// ];

// let privateProjects = [
//     {
//         name: 'React private',
//         description: 'Some description',
//     },
//     {
//         name: 'Vue private',
//         description: 'Some description',
//     },
// ];

// Database
// date: 2021-09-01
// user: {hash: '12a17', name: 'Rob Rollenberg'}
// data: [{project: 1, hours: 4}, {project: 2, hours: 8}]
// date: 2021-09-01
// user: {hash: '14c23', name: 'Arjan Franzen'}
// data: [{project: 1, hours: 4}, {project: 2, hours: 8}]
// let hours = [
//     {
//         date: '2021-09-01',
//         user: { hash: '12a17', name: 'Rob Rollenberg' },
//         data: [
//             { project: 'Agile Cloud', hours: 4 },
//             { project: 'Agile FE', hours: 2 },
//             { project: 'Logo', hours: 2 },
//         ],
//     },
//     // {
//     //     date: '2021-09-01',
//     //     user: { hash: '14c23', name: 'Arjan Franzen' },
//     //     data: [
//     //         { project: 1, hours: 4 },
//     //         { project: 2, hours: 8 },
//     //     ],
//     // },
//     {
//         date: '2021-09-02',
//         user: { hash: '14c23', name: 'Rob Rollenberg' },
//         data: [
//             { project: 'Agile FE', hours: 4 },
//             { project: 'Agile Cloud', hours: 4 },
//         ],
//     },
//     {
//         date: '2021-09-03',
//         user: { hash: '14c23', name: 'Rob Rollenberg' },
//         data: [{ project: 'Agile Cloud', hours: 8 }],
//     },
// ];

let dataTimesheet = {
    user: { hash: 'UEF591B9', name: 'Zoia Baletska' },
    selected_projects: ['Vue', 'React private', 'React'],
    projects_data: [
        {
            project: 'Vue',
            days: [
                { date: '2021-11-22', hours: 4 },
                { date: '2021-11-23', hours: 2 },
                { date: '2021-11-25', hours: 2 },
                { date: '2021-12-13', hours: 4.5 },
                { date: '2021-12-14', hours: 2.5 },
                { date: '2021-12-15', hours: 2 },
            ],
        },
        {
            project: 'React private',
            days: [
                { date: '2021-11-22', hours: 4 },
                { date: '2021-11-23', hours: 2 },
                { date: '2021-11-24', hours: 2 },
                { date: '2021-12-13', hours: 4 },
                { date: '2021-12-14', hours: 3.5 },
                { date: '2021-12-15', hours: 5.5 },
            ],
        },
        {
            project: 'React',
            days: [
                { date: '2021-11-22', hours: 4 },
                { date: '2021-11-24', hours: 4 },
                { date: '2021-11-25', hours: 6 },
                { date: '2021-11-26', hours: 8 },
                { date: '2021-12-13', hours: 4 },
                { date: '2021-12-14', hours: 4.5 },
                { date: '2021-12-15', hours: 8 },
                { date: '2021-12-16', hours: 6 },
            ],
        },
    ],
};

export const handlers = [
    // rest.get(
    //     'https://api.prod.agileanalytics.cloud/OF2170/projects',
    //     (_, res, ctx) => {
    //         return res(ctx.status(200), ctx.json(projects));
    //     }
    // ),

    // rest.post(
    //     'https://api.prod.agileanalytics.cloud/OF2170/projects',
    //     (req, res, ctx) => {
    //         projects = req.body;
    //         return res(ctx.status(200), ctx.json(projects));
    //     }
    // ),

    // rest.put(
    //     'https://api.prod.agileanalytics.cloud/OF2170/projects',
    //     (req, res, ctx) => {
    //         projects = req.body;
    //         return res(ctx.status(200), ctx.json(projects));
    //     }
    // ),

    // rest.delete(
    //     'https://api.prod.agileanalytics.cloud/OF2170/projects',
    //     (_, res, ctx) => {
    //         return res(ctx.status(200));
    //     }
    // ),

    // rest.get(
    //     'https://api.prod.agileanalytics.cloud/OF2170/projects/UEF591B9/',
    //     (_, res, ctx) => {
    //         return res(ctx.status(200), ctx.json(privateProjects));
    //     }
    // ),

    // rest.post(
    //     'https://api.prod.agileanalytics.cloud/OF2170/projects/UEF591B9/',
    //     (req, res, ctx) => {
    //         privateProjects = req.body;
    //         return res(ctx.status(200), ctx.json(privateProjects));
    //     }
    // ),

    // rest.put(
    //     'https://api.prod.agileanalytics.cloud/OF2170/projects/UEF591B9/',
    //     (req, res, ctx) => {
    //         privateProjects = req.body;
    //         return res(ctx.status(200), ctx.json(privateProjects));
    //     }
    // ),

    // rest.delete(
    //     'https://api.prod.agileanalytics.cloud/OF2170/projects/UEF591B9/',
    //     (_, res, ctx) => {
    //         return res(ctx.status(200));
    //     }
    // ),

    rest.get(
        'https://api.prod.agileanalytics.cloud/OF2170/timesheet/UEF591B9/',
        (req, res, ctx) => {
            // const month = req.url.searchParams.get('month');
            // const year = req.url.searchParams.get('year');

            // if (month) {
            //     const hoursByMonth = dataTimesheet.filter(
            //         (week) =>
            //             week.month === Number(month) &&
            //             week.year === Number(year)
            //     );

            //     return res(
            //         //ctx.delay(3000),
            //         ctx.status(200),
            //         ctx.json(hoursByMonth)
            //     );
            // }

            return res(ctx.status(200), ctx.json(dataTimesheet));
        }
    ),

    // rest.put(
    //     'https://api.prod.agileanalytics.cloud/OF2170/timesheet/UEF591B9/',
    //     (req, res, ctx) => {
    //         hours = req.body;
    //         return res(ctx.status(200), ctx.json(dataTimesheet));
    //     }
    // ),
];
