import React, { useReducer, createContext } from 'react';

const sprintsReducer = (state, action) => {
    switch (action.type) {
        case 'SET_LOADING':
            return { data: state.data, loading: true, error: null };
        case 'SET_DATA':
            return {
                data: action.payload,
                loading: false,
                error: null,
            };
        case 'SET_ERROR':
            return { data: state.data, loading: false, error: action.payload };
        default:
            return state;
    }
};

export const SprintsContext = createContext();

export const SprintsProvider = ({ children }) => {
    const [sprintsState, sprintsDispatch] = useReducer(sprintsReducer, {
        data: null,
        loading: true,
        error: null,
    });

    return (
        <SprintsContext.Provider value={[sprintsState, sprintsDispatch]}>
            {children}
        </SprintsContext.Provider>
    );
};
