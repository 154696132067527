import React from 'react';
import HoursPageTimesheetOverviewBodyRow from './row';
import { useTranslation } from "react-i18next";

function HoursPageTimesheetOverviewBody({
    data,
    selectedProjects,
    weekRange,
    setEmptyRow,
    triggerUpdate,
    userHash,
    viewerHash,
    filterDate,
}) {
    const { t } = useTranslation();

    return (
        <div className="text-gray-500">
            {!data.length && !selectedProjects.length && (
                <p className="flex my-4 justify-center text-center">
                    {t('hours_page.timesheet.no_projects')}
                </p>
            )}
            {!data.length &&
                selectedProjects &&
                selectedProjects?.map((project, index) => (
                    <HoursPageTimesheetOverviewBodyRow
                        key={index}
                        data={data}
                        project={project}
                        selectedProjects={selectedProjects}
                        days={[]}
                        weekRange={weekRange}
                        setEmptyRow={setEmptyRow}
                        triggerUpdate={triggerUpdate}
                        userHash={userHash}
                        viewerHash={viewerHash}
                        filterDate={filterDate}
                    />
                ))}
            {data?.map((item, index) => (
                <HoursPageTimesheetOverviewBodyRow
                    key={index}
                    data={data}
                    project={item.project}
                    days={item.days}
                    weekRange={weekRange}
                    setEmptyRow={setEmptyRow}
                    triggerUpdate={triggerUpdate}
                    selectedProjects={selectedProjects}
                    userHash={userHash}
                    viewerHash={viewerHash}
                    filterDate={filterDate}
                />
            ))}
        </div>
    );
}

export default HoursPageTimesheetOverviewBody;
