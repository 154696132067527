import React, { useReducer, createContext } from 'react';

const projectsReducer = (state, action) => {
    switch (action.type) {
        case 'SET_LOADING':
            return { data: state.data, loading: true, error: null };
        case 'SET_NO_LOADING':
            return { data: state.data, loading: false, error: null };
        case 'SET_DATA':
            return {
                data: action.payload,
                loading: false,
                error: null,
            };
        case 'SET_EMPTY_ROW':
            return {
                data: [...state.data, action.payload],
                loading: false,
                error: null,
            };
        case 'SET_ERROR':
            return { data: state.data, loading: false, error: action.payload };
        default:
            return state;
    }
};

export const ProjectsContext = createContext();

export const ProjectsProvider = ({ children }) => {
    const [projectsState, projectsDispatch] = useReducer(projectsReducer, {
        data: null,
        loading: true,
        error: null,
    });

    return (
        <ProjectsContext.Provider value={{ projectsState, projectsDispatch }}>
            {children}
        </ProjectsContext.Provider>
    );
};
