import React, { useState, useCallback, useContext, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { OrganisationsContext } from '../../../context/OrganisationsContext';
import ErrorIndicator from '../../error-indicator';
import Loader from '../../loader';
import VCSProviderItem from '../../VCS-provider-item';
import AddProviderItem from '../../add-provider-item';
import { useFetchDataAndSetState } from '../../../helpers/useFetchDataAndSetState';
import { getGitProviders } from '../../../api/settings/GitProviderAPI';
import { getAdminPermission } from '../../../helpers/getPermission';

const VCSTab = () => {
    const { organisationsState } = useContext(OrganisationsContext);
    const selectedOrgHash = organisationsState.data
        ? organisationsState.data.find((org) => org.active).org_hash
        : null;
    const request = useCallback(() => getGitProviders(), []);
    const [updatesCounter, setUpdatesCounter] = useState(0);
    const [gitProvidersState] = useFetchDataAndSetState(request, [
        selectedOrgHash,
        updatesCounter,
    ]);
    const [newProvidersList, setNewProvidersList] = useState([]);
    const [permissionToWrite, setPermissionToWrite] = useState(false);

    useEffect(() => {
        setPermissionToWrite(getAdminPermission(organisationsState?.data));
    }, [organisationsState]);

    const handleAddingProvider = (provider) => {
        const id = uuidv4();
        setNewProvidersList([
            ...newProvidersList,
            { provider, provider_id: '', access_token: '', id },
        ]);
    };

    if (gitProvidersState.error) {
        return (
            <div className="container mx-4">
                <ErrorIndicator error={gitProvidersState.error} />
            </div>
        );
    }
    if (gitProvidersState.loading) {
        return (
            <div className="container mx-4 flex justify-center">
                <Loader />
            </div>
        );
    }

    const handleDeletEmptyProvider = (id) => {
        setNewProvidersList(newProvidersList.filter((item) => item.id !== id));
    };

    const handleUpdateProvidersList = () => {
        setNewProvidersList([]);
        setUpdatesCounter(updatesCounter + 1);
    };

    return (
        <ul className="flex flex-wrap items-stretch gap-x-8 gap-y-10 mb-4">
            {gitProvidersState.data.length > 0 &&
                gitProvidersState.data.map((vcsProviderData) => {
                    return (
                        <VCSProviderItem
                            key={vcsProviderData.provider_id}
                            vcsProviderData={vcsProviderData}
                            permissionToWrite={permissionToWrite}
                            onUpdateProvider={handleUpdateProvidersList}
                        />
                    );
                })}
            {newProvidersList &&
                newProvidersList.map((newProvider) => {
                    return (
                        <VCSProviderItem
                            key={newProvider.id}
                            vcsProviderData={newProvider}
                            permissionToWrite={permissionToWrite}
                            onDeleteEmptyItem={handleDeletEmptyProvider}
                            onUpdateProvider={handleUpdateProvidersList}
                        />
                    );
                })}

            {permissionToWrite &&
                (+newProvidersList?.length + +gitProvidersState?.data?.length >=
                5 ? (
                    <li className="w-480 h-450 rounded-lg border-tailwind border-gray-500 border-dashed flex flex-col justify-center items-center ">
                        <p className="p-4 font-display text-xl text-center text-gray-500">
                            Maximum 5 Git providers could be added
                        </p>
                    </li>
                ) : (
                    <AddProviderItem addProvider={handleAddingProvider} />
                ))}
        </ul>
    );
};

export default VCSTab;
