import { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useFetchDataAndSetState } from '../../../../helpers/useFetchDataAndSetState.js';
import WhitePanelContentWrapper from '../../../white-panel-content-wrapper';
import {
    getInvoiceData,
    getInvoicesList,
} from '../../../../api/subscription/SubscriptionAPI.js';
import { capitalizeFirstLetter } from '../../../../helpers/textFormatters';
import Loader from '../../../loader/index.js';
import { useRef } from 'react';
import showNotification from '../../../../helpers/showNotification.js';
import { exportInvoicePDF } from '../../../../helpers/pdfInvoiceExport.js';

const BillingHistory = ({ selectedOrganisationHash }) => {
    const { t } = useTranslation();
    const loader = useRef(null);
    const limit = 25;
    const initialParams = {
        start: 0,
        end: limit - 1,
        sort: 'date',
        direction: 'start',
    };
    const [params, setParams] = useState(initialParams);
    const [invoicesList, setInvoicesList] = useState(null);
    const [isDownloading, setIsDownloading] = useState(null);

    const request = useCallback(() => getInvoicesList(params), [params.start]);

    const [invoicesState] = useFetchDataAndSetState(request, [
        selectedOrganisationHash,
        params,
    ]);

    const handleObserver = useCallback(
        (entries) => {
            const target = entries[0];
            if (
                target.isIntersecting &&
                params.end < +invoicesState?.data?.total
            ) {
                setParams((prev) => {
                    return {
                        ...prev,
                        start: prev.start + limit,
                        end: prev.end + limit,
                    };
                });
            }
        },
        [invoicesState]
    );

    useEffect(() => {
        setInvoicesList(null);
        setParams(initialParams);
    }, [selectedOrganisationHash]);

    useEffect(() => {
        if (invoicesList) {
            let invoicesToAdd = invoicesState?.data?.invoices.filter(
                (invoice) =>
                    !invoicesList.find(
                        (presentInvoice) => presentInvoice.date === invoice.date
                    )
            );
            setInvoicesList([...invoicesList, ...invoicesToAdd]);
        } else {
            setInvoicesList(invoicesState?.data?.invoices);
        }
    }, [invoicesState.data]);

    useEffect(() => {
        setIsDownloading(
            invoicesList?.reduce((acc, invoice) => {
                return { ...acc, [invoice.date]: false };
            }, {})
        );
    }, [invoicesList]);

    useEffect(() => {
        const option = {
            root: null,
            rootMargin: '20px',
            threshold: 0,
        };
        const observer = new IntersectionObserver(handleObserver, option);
        if (loader.current) observer.observe(loader.current);
    }, [handleObserver]);

    async function downloadInvoice(invoiceDate) {
        setIsDownloading((prevState) => {
            return { ...prevState, [invoiceDate]: true };
        });
        const year = moment(invoiceDate * 1000).year();
        const month = moment(invoiceDate * 1000).month() + 1;

        try {
            const res = await getInvoiceData(year, month);

            if (res.status === 200) {
                exportInvoicePDF(res?.data);
            }
        } catch (err) {
            showNotification(
                `${
                    err.response?.data ||
                    err?.message ||
                    err.data?.message ||
                    'No invoice data found'
                }`
            );
        }
        setIsDownloading((prevState) => {
            return { ...prevState, [invoiceDate]: false };
        });
    }

    console.log(invoicesList);

    return (
        <>
            <WhitePanelContentWrapper className="w-8/12 min-h-fit">
                <p className="font-display text-3xl">
                    {capitalizeFirstLetter(
                        t('settings_page.billing_tab.history')
                    )}
                </p>

                <div className="py-5">
                    <div className="grid grid-cols-7 w-full border-b-2 border-solid border-gray-400 text-left">
                        <p className="pb-2">
                            {capitalizeFirstLetter(
                                t('settings_page.billing_tab.date')
                            )}
                        </p>
                        <p className="col-span-1">Invoice #</p>
                        <p className="col-span-1">Status</p>
                        <p>Plan</p>
                        <p>
                            {capitalizeFirstLetter(
                                t('settings_page.billing_tab.amount')
                            )}
                        </p>
                        <p className="col-span-2 text-right">
                            {capitalizeFirstLetter(
                                t('settings_page.billing_tab.invoice')
                            )}
                        </p>
                    </div>

                    <div className="grid grid-cols-7 w-full  text-left text-gray-500">
                        {invoicesList?.length ? (
                            invoicesList?.map((invoice) => (
                                <div
                                    className="col-span-7 grid grid-cols-7 w-full  text-left text-gray-500 border-solid border-b-2 border-gray-300 items-center h-10"
                                    key={invoice.date}
                                >
                                    <div className="py-2">
                                        {moment(invoice.date * 1000).format(
                                            'YYYY-MM-DD'
                                        )}
                                    </div>
                                    <div className="col-span-1">
                                        {invoice?.id ?? 'no data'}
                                    </div>
                                    <div className="col-span-1">
                                        {invoice?.status ?? 'no data'}
                                    </div>
                                    <div className="py-2">
                                        {invoice?.tier ?? 'no data'}
                                    </div>
                                    <div className="col-span-2">
                                        {`€ 
                                            ${invoice?.price}`}
                                    </div>
                                    {invoice?.status === 'paid' && (
                                        <div className="flex justify-end py-2">
                                            {isDownloading &&
                                            isDownloading[invoice.date] ? (
                                                <div className="w-full h-full flex justify-end items-center">
                                                    <Loader size={24} />
                                                </div>
                                            ) : (
                                                <button
                                                    type="button"
                                                    className="  py-1 h-full text-blue-400 cursor-pointer underline"
                                                    onClick={() =>
                                                        downloadInvoice(
                                                            invoice?.date
                                                        )
                                                    }
                                                >
                                                    Download PDF
                                                </button>
                                            )}
                                        </div>
                                    )}
                                </div>
                            ))
                        ) : invoicesState?.loading ? null : (
                            <div className="col-span-7 mx-auto w-full px-4 py-4 flex justify-center ">
                                <p>No invoices</p>
                            </div>
                        )}
                    </div>
                </div>

                {invoicesState.loading ? (
                    <div className="flex w-full h-full justify-center items-center">
                        <Loader />
                    </div>
                ) : null}
                <div ref={loader} />
            </WhitePanelContentWrapper>
        </>
    );
};

export default BillingHistory;
