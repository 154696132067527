import React, { Component } from 'react';
import { UserContext } from '../context/UserContext';
import Loader from '../components/loader';
import ErrorIndicator from '../components/error-indicator';

const withUserAndOragnisations = (View) => {
    return class extends Component {
        render() {
            const r = (userProviderData) => {
                const { userState } = userProviderData;
                if (userState.loading)
                    return (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                minHeight: '200px',
                            }}
                        >
                            <Loader
                                color={'#C2C7D7'}
                                size={35}
                                speedMultiplier={0.8}
                            />
                        </div>
                    );

                if (userState.error && !userState?.data) {
                    const status = userState.error.status;

                    if (status === 401) {
                        return (
                            <div
                                className="error-message"
                                style={{
                                    textAlign: 'center',
                                    padding: '20px',
                                    fontWeight: '500',
                                    width: '100%',
                                    color: '#484A53',
                                    fontSize: '24px',
                                }}
                            >
                                Sorry, you are Unauthorized. Please, re-login or
                                contact our support team.
                            </div>
                        );
                    }

                    return (
                        <div>
                            <ErrorIndicator error={userState.error} />
                        </div>
                    );
                }

                return <View {...this.props} />;
            };

            return (
                <UserContext.Consumer>
                    {(userProviderData) => r(userProviderData)}
                </UserContext.Consumer>
            );
        }
    };
};

export { withUserAndOragnisations };
