import React, { useEffect, useContext, useState } from 'react';
import { Buffer } from 'buffer';
import { v4 as uuidv4 } from 'uuid';
import queryString from 'query-string';

import { UserContext } from '../../context/UserContext';
import Loader from '../loader';
import { SaveButton } from '../custom';
import { AUTH_BASE_URL_JIRA, STATE_PARAMS_VALUE_JIRA } from '../../constants';

const WorkspaceAuthorization = ({
    workspace,
    error,
    isInOrg = true,
    loading = false,
    permissionForWrite,
}) => {
    const { userState } = useContext(UserContext);
    const { name, source, modified_date, modified_user } = workspace;
    const [isAuthorised, setIsAuthorised] = useState(false);

    useEffect(() => {
        error && setIsAuthorised(false);
    }, [error]);

    useEffect(() => {
        modified_user && !error && setIsAuthorised(true);
    }, [modified_user]);

    const authorizeJira = () => {
        const baseUrl = AUTH_BASE_URL_JIRA;
        const clientId = process.env.REACT_APP_JIRA_CLIENT_ID;
        const redirectUri = process.env.REACT_APP_JIRA_REDIRECT_URI;

        const generatedUuid = uuidv4();
        localStorage.setItem(STATE_PARAMS_VALUE_JIRA, generatedUuid);

        const stateObj = {
            org_hash: localStorage.getItem('orgHash'),
            user_hash: userState.data.user_hash,
        };
        const state = Buffer.from(JSON.stringify(stateObj)).toString('base64');

        const stringifiedParams = queryString.stringify({
            audience: 'api.atlassian.com',
            client_id: clientId,
            scope: 'read:jira-work offline_access manage:jira-webhook read:jira-user read:email-address:jira',
            redirect_uri: redirectUri,
            state,
            response_type: 'code',
            prompt: 'consent',
        });

        const authorizeUrl = `${baseUrl}?${stringifiedParams}`;
        window.location.href = authorizeUrl;
    };

    return (
        <div className="w-full pr-4">
            <h4 className="font-normal leading-6 mb-2">
                Step 1.{' '}
                <span className="font-bold">
                    Authorize {source[0].toUpperCase()}
                    {source.slice(1)}
                </span>
            </h4>
            {error && (
                <p className="leading-4 text-sm text-red-700 mb-2">
                    Connection is lost
                </p>
            )}
            {isAuthorised ? (
                <p className="leading-4 text-sm text-gray-500">
                    <span className="font-medium">{modified_user.name}</span>{' '}
                    connected to workspace{' '}
                    <span className="font-medium">{name}</span> at{' '}
                    <span className="font-medium">{modified_date}</span>
                </p>
            ) : permissionForWrite ? (
                isInOrg ? (
                    (source === 'jira' && (
                        <SaveButton
                            saveFunc={authorizeJira}
                            text={'Authorize'}
                            style={{
                                margin: '0',
                                color: '#1FB8D3',
                                borderColor: '#1FB8D3',
                                fontFamily: `${[
                                    'Exo\\ 2',
                                    'sans-serif',
                                    'Arial',
                                ]}`,
                                fontWeight: '600',
                                lineHeight: '20px',
                            }}
                        />
                    )) ||
                    (source === 'azure' && (
                        <SaveButton
                            text={'Authorize'}
                            style={{
                                margin: '0',
                                color: '#1FB8D3',
                                borderColor: '#1FB8D3',
                                fontFamily: `${[
                                    'Exo\\ 2',
                                    'sans-serif',
                                    'Arial',
                                ]}`,
                                fontWeight: '600',
                                lineHeight: '20px',
                            }}
                        />
                    ))
                ) : loading ? (
                    <Loader color={'#C2C7D7'} size={35} speedMultiplier={0.8} />
                ) : null
            ) : (
                <p className="leading-4 text-sm text-gray-500">Unauthorized</p>
            )}
        </div>
    );
};

export default WorkspaceAuthorization;
