import { useContext, useState } from 'react';
import Select from 'react-select';
import { createOrganisation } from '../../api/organisation/OrganisationAPI';
import { OrganisationsContext } from '../../context/OrganisationsContext';
import setCustomStyle from '../../helpers/customSelectStyles';
import showNotification from '../../helpers/showNotification';
import ErrorIndicator from '../error-indicator';
import Loader from '../loader';

const CreateOrganisationAdminForm = ({ onCreate }) => {
    const { organisationsState, organisationsDispatch } =
        useContext(OrganisationsContext);

    const [newOrganisation, setNewOrganisation] = useState({
        owner: {
            error: null,
            value: '',
        },
        organisation: {
            error: null,
            value: '',
        },
        subscription: {
            error: null,
            value: '',
        },
        status: true,
        loading: false,
        error: null,
    });

    const subscriptionOptions = [
        { value: 'free', label: 'Free' },
        { value: 'basic', label: 'Basic' },
        { value: 'professional', label: 'Professional' },
        { value: 'enterprise', label: 'Enterprise' },
    ];

    const handleOrgNameChange = (event) => {
        const newValue = event.target.value;

        setNewOrganisation((state) => ({
            ...state,
            organisation: { value: newValue, error: null },
        }));
    };

    const handleOrgNameOwnerChange = (event) => {
        const newValue = event.target.value;

        setNewOrganisation((state) => ({
            ...state,
            owner: { value: newValue, error: null },
        }));
    };

    const handleOrgSubscriptionChange = (option) => {
        const newValue = option.value;

        setNewOrganisation((state) => ({
            ...state,
            subscription: { value: newValue, error: null },
        }));
    };
    const createNewOrganisation = () => {
        if (
            newOrganisation.organisation.value &&
            !newOrganisation.organisation.error &&
            newOrganisation.owner.value &&
            !newOrganisation.owner.error &&
            newOrganisation.subscription.value &&
            !newOrganisation.subscription.error
        ) {
            const data = {
                org_name: newOrganisation.organisation.value,
                contact_email: newOrganisation.owner.value,
                subscription: newOrganisation.subscription.value,
            };
            setNewOrganisation((state) => ({ ...state, loading: true }));

            createOrganisation(data)
                .then((response) => {
                    if (response.status === 200) {
                        onCreate();
                        const updatedOrganisationsList = [
                            ...organisationsState.data,
                            {
                                active: false,
                                logo_url: 'Undefined',
                                org_hash: response.data,
                                org_name: newOrganisation.organisation.value,
                                status: 'active',
                                subscription:
                                    newOrganisation.subscription.value,
                            },
                        ];
                        organisationsDispatch({
                            type: 'SET_DATA',
                            payload: updatedOrganisationsList,
                        });
                        showNotification(
                            'Organisation has been created',
                            'success'
                        );
                        setNewOrganisation((state) => ({
                            ...state,
                            loading: false,
                        }));
                    }
                })
                .catch((error) => {
                    setNewOrganisation((state) => ({
                        ...state,
                        loading: false,
                    }));
                    showNotification(
                        error.response?.data ||
                            error.message ||
                            error.data.message
                    );
                });
        }
    };

    return (
        <div className="create-organisation  col-span-12 grid grid-cols-12 ">
            <div className="create-organisation  col-span-12">
                <h3 className="text-center ">Add new organisation</h3>
                <div className="">
                    <div className="flex gap-x-8 justify-between items-center">
                        <div className="input-wrapper mb-0">
                            <input
                                type="text"
                                placeholder="Organisation name"
                                onChange={handleOrgNameChange}
                                value={newOrganisation.organisation.value}
                            />
                        </div>
                        <div className="input-wrapper mb-0">
                            <input
                                type="text"
                                placeholder="Organisation owner email"
                                onChange={handleOrgNameOwnerChange}
                                value={newOrganisation.owner.value}
                            />
                        </div>
                        <div className="input-wrapper mb-0">
                            <Select
                                isSearchable={false}
                                options={subscriptionOptions}
                                styles={setCustomStyle()}
                                value={subscriptionOptions.find(
                                    (option) =>
                                        option.value ===
                                        newOrganisation.subscription.value
                                )}
                                onChange={(option) =>
                                    handleOrgSubscriptionChange(option)
                                }
                            />
                        </div>
                    </div>
                    {newOrganisation.error ? (
                        <ErrorIndicator error={newOrganisation.error} />
                    ) : null}
                    {newOrganisation.loading ? (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <Loader
                                color={'#C2C7D7'}
                                size={35}
                                speedMultiplier={0.8}
                            />
                        </div>
                    ) : null}
                    <button
                        className="save-organisation"
                        disabled={
                            !newOrganisation.owner.value ||
                            !newOrganisation.organisation.value
                        }
                        onClick={createNewOrganisation}
                    >
                        Submit
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CreateOrganisationAdminForm;
