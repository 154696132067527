import React from 'react';
import { Navigate } from 'react-router-dom';
import { NAVIGATION_PATHS } from '../constants';

const HomePage = () => {
    const urlToNavigate = localStorage.getItem('startUrl');
    if (
        localStorage.getItem('token') &&
        urlToNavigate &&
        urlToNavigate.includes('/teams/')
    ) {
        return <Navigate to={urlToNavigate} />;
    }
    return <Navigate to={NAVIGATION_PATHS.overview} />;
};

export default HomePage;
