import React, { Fragment } from 'react';
import WorkspacesItem from './workspaces-item';

const WorkspacesBody = ({ workspacesList }) => {
    if (workspacesList.length > 0) {
        return (
            <Fragment>
                {workspacesList.map((workspace) => (
                    <WorkspacesItem
                        workspace={workspace}
                        key={workspace.workspace_name}
                    />
                ))}
            </Fragment>
        );
    }
    return (
        <div className="workspace flex justify-center items-center">
            <p className=" text-center">No workspaces</p>
        </div>
    );
};

export default WorkspacesBody;
