import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const TimeSelectorTabCalendar = ({
    onCalendarChange,
    calendarStartDate,
    calendarEndDate,
}) => {
    return (
        <div className="p-4">
            <div className="global-date-selector-content">
                <DatePicker
                    onChange={onCalendarChange}
                    startDate={calendarStartDate}
                    endDate={calendarEndDate}
                    // we need later then now dates could be picked for sprints to work
                    // maxDate={new Date()}
                    disabledKeyboardNavigation
                    selectsRange
                    inline
                />
            </div>
        </div>
    );
};

export default TimeSelectorTabCalendar;
