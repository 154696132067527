import React from 'react';
import LeaderboardHeader from './header';
import LeaderboardFooter from './footer';
import LeaderboardBody from './body';

const KudosLeaderboard = ({
    selectedTeam,
    setActiveTeam,
    localTeamsState,
    teamsState,
    usersState,
    kudosState,
    kudosLeaderBoardState,
    isInCard,
}) => {
    return (
        <div
            className={
                isInCard
                    ? 'p-4 rounded border border-solid border-gray-300 flex flex-col'
                    : ''
            }
        >
            <LeaderboardHeader
                localTeamsState={localTeamsState}
                selectedTeam={selectedTeam}
                setActiveTeam={setActiveTeam}
                isInCard={isInCard}
            />
            <LeaderboardBody
                kudosState={kudosState}
                selectedTeam={selectedTeam}
                localTeamsState={localTeamsState}
                usersState={usersState}
                kudosLeaderBoardState={kudosLeaderBoardState}
            />
            <LeaderboardFooter
                selectedTeam={selectedTeam}
                teamsState={teamsState}
                usersState={usersState}
            />
        </div>
    );
};

export default KudosLeaderboard;
