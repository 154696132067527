import { Field, FieldArray } from 'formik';
import React from 'react';
import FormTextarea from './sre-form-textarea';
import InputText from '../../../components/form/parts/InputText';
import { capitalizeFirstLetter } from '../../../helpers/textFormatters';
import { useTranslation } from 'react-i18next';

function SreFormFilters({
    isAws,
    values,
    errors,
    touched,
    thresholdOptions,
    backendsList,
    thresholdBucketOptions,
}) {
    const { t } = useTranslation();

    const handleKeyDown = (event) => {
        if (event.key === 'Tab') {
            event.preventDefault();
            const { target } = event;
            const start = target.selectionStart;
            const end = target.selectionEnd;

            // Insert tab character
            const tab = '\t';
            target.value =
                target.value.substring(0, start) +
                tab +
                target.value.substring(end);

            // Move the cursor after the inserted tab
            target.selectionStart = target.selectionEnd = start + tab.length;
        }
    };

    if (isAws) {
        return (
            <>
                <FieldArray name="methods">
                    {values?.ratio_options_aws?.length
                        ? () =>
                              values.ratio_options_aws.map((_, i) => {
                                  const ratioError =
                                      (errors.ratio_options_aws?.length &&
                                          errors.ratio_options_aws[i]) ||
                                      {};
                                  const ratioTouched =
                                      (touched.ratio_options_aws?.length &&
                                          touched.ratio_options_aws[i]) ||
                                      {};

                                  return (
                                      <fieldset
                                          key={1 + i}
                                          className="grid grid-cols-3 gap-x-8 gap-y-2"
                                      >
                                          <div className="">
                                              <FormTextarea
                                                  name={'Metric Source Good'}
                                                  attributeName={`ratio_options_aws.${i}.metric_source_good`}
                                                  rows={6}
                                                  placeholderText={
                                                      'Enter metric source good'
                                                  }
                                                  error={
                                                      ratioError.metric_source_good
                                                  }
                                                  touched={
                                                      ratioTouched.metric_source_good
                                                  }
                                                  value={
                                                      values.ratio_options_aws[
                                                          i
                                                      ]?.metric_source_good
                                                  }
                                                  onKeyDown={handleKeyDown}
                                              />
                                          </div>
                                          <div
                                              className={`relative ${
                                                  values.ratio_options_aws[i]
                                                      ?.metric_source_good &&
                                                  values.ratio_options_aws[i]
                                                      ?.metric_source_valid
                                                      ? 'filter blur-sm'
                                                      : ''
                                              }`}
                                          >
                                              <FormTextarea
                                                  name={'Metric source Bad'}
                                                  attributeName={`ratio_options_aws.${i}.metric_source_bad`}
                                                  rows={6}
                                                  placeholderText={
                                                      'Enter metric source bad'
                                                  }
                                                  error={
                                                      ratioError.metric_source_bad
                                                  }
                                                  touched={
                                                      ratioTouched.metric_source_bad
                                                  }
                                                  value={
                                                      values.ratio_options_aws[
                                                          i
                                                      ]?.metric_source_bad
                                                  }
                                                  onKeyDown={handleKeyDown}
                                              />
                                              {values.ratio_options_aws[i]
                                                  ?.metric_source_good &&
                                              values.ratio_options_aws[i]
                                                  ?.metric_source_valid ? (
                                                  <div className="absolute top-0 w-full h-full"></div>
                                              ) : null}
                                          </div>
                                          <div
                                              className={`relative ${
                                                  values.ratio_options_aws[i]
                                                      ?.metric_source_good &&
                                                  values.ratio_options_aws[i]
                                                      ?.metric_source_bad
                                                      ? 'filter blur-sm'
                                                      : ''
                                              }`}
                                          >
                                              <FormTextarea
                                                  name={'Metric source Valid'}
                                                  attributeName={`ratio_options_aws.${i}.metric_source_valid`}
                                                  rows={6}
                                                  placeholderText={
                                                      'Enter metric source valid'
                                                  }
                                                  error={
                                                      ratioError.metric_source_valid
                                                  }
                                                  touched={
                                                      ratioTouched.metric_source_valid
                                                  }
                                                  value={
                                                      values.ratio_options_aws[
                                                          i
                                                      ]?.metric_source_valid
                                                  }
                                                  onKeyDown={handleKeyDown}
                                              />
                                              {values.ratio_options_aws[i]
                                                  ?.metric_source_good &&
                                              values.ratio_options_aws[i]
                                                  ?.metric_source_bad ? (
                                                  <div className="absolute top-0 w-full h-full"></div>
                                              ) : null}
                                          </div>
                                      </fieldset>
                                  );
                              })
                        : null}
                </FieldArray>
                <FieldArray name="methods">
                    {values?.distribution_options_aws?.length
                        ? () =>
                              values.distribution_options_aws.map((_, i) => {
                                  const distributionError =
                                      (errors.distribution_options_aws
                                          ?.length &&
                                          errors.distribution_options_aws[i]) ||
                                      {};
                                  const distributionTouched =
                                      (touched.distribution_options_aws
                                          ?.length &&
                                          touched.distribution_options_aws[
                                              i
                                          ]) ||
                                      {};
                                  return (
                                      <fieldset
                                          key={2 + i}
                                          className="grid grid-cols-3 gap-x-8 gap-y-2"
                                      >
                                          <div className="col-span-3">
                                              <FormTextarea
                                                  name={'Metric Source Valid'}
                                                  attributeName={`distribution_options_aws.${i}.metric_source_valid`}
                                                  rows={6}
                                                  placeholderText={
                                                      'Enter metric source valid'
                                                  }
                                                  error={
                                                      distributionError.metric_source_valid
                                                  }
                                                  touched={
                                                      distributionTouched.metric_source_valid
                                                  }
                                                  value={
                                                      values
                                                          .distribution_options_aws[
                                                          i
                                                      ]?.metric_source_valid
                                                  }
                                                  onKeyDown={handleKeyDown}
                                              />
                                          </div>
                                          <div className="">
                                              <label
                                                  className="text-gray-400 mb-1 text-m"
                                                  htmlFor="threshold_value"
                                              >
                                                  {`${capitalizeFirstLetter(
                                                      t('sre.threshold')
                                                  )} ${capitalizeFirstLetter(
                                                      t('sre.value')
                                                  )}`}
                                              </label>
                                              <InputText
                                                  id="threshold_value"
                                                  type="number"
                                                  min="0"
                                                  step="0.005"
                                                  name={`threshold_value`}
                                                  placeholder={'0'}
                                                  value={values.threshold_value}
                                              />
                                              {distributionError.threshold_value &&
                                              distributionTouched.threshold_value ? (
                                                  <div className="text-red-500 mt-1 text-xs">
                                                      {
                                                          distributionError.threshold_value
                                                      }
                                                  </div>
                                              ) : (
                                                  <div className="text-red-500 mt-1 text-xs invisible">
                                                      hidden
                                                  </div>
                                              )}
                                          </div>
                                          <div className="col-span-2 flex items-end pb-[20px]">
                                              <label
                                                  className="text-gray-500 mr-2 text-m flex items-center gap-x-2"
                                                  htmlFor="good_below_threshold"
                                              >
                                                  <span>
                                                      A value is good when it is
                                                  </span>
                                                  <Field name="good_below_threshold">
                                                      {({ field }) => {
                                                          return (
                                                              <select
                                                                  {...field}
                                                                  className="w-24 p-2 placeholder-gray-300 text-gray-700 relative bg-white rounded text-md border border-gray-300"
                                                                  value={
                                                                      values?.good_below_threshold
                                                                  }
                                                                  required
                                                              >
                                                                  <option
                                                                      disabled
                                                                      value=""
                                                                      className="text-gray-300"
                                                                  >
                                                                      {`${capitalizeFirstLetter(
                                                                          t(
                                                                              'button.select'
                                                                          )
                                                                      )} ${capitalizeFirstLetter(
                                                                          t(
                                                                              'sre.good'
                                                                          )
                                                                      )}`}
                                                                  </option>
                                                                  {thresholdOptions.map(
                                                                      (
                                                                          threshold,
                                                                          i
                                                                      ) => (
                                                                          <option
                                                                              type="number"
                                                                              key={
                                                                                  i
                                                                              }
                                                                              value={
                                                                                  threshold.value
                                                                              }
                                                                          >
                                                                              {
                                                                                  threshold.label
                                                                              }
                                                                          </option>
                                                                      )
                                                                  )}
                                                              </select>
                                                          );
                                                      }}
                                                  </Field>

                                                  <span>
                                                      the amount of{' '}
                                                      {backendsList.find(
                                                          (backend) =>
                                                              backend.name ===
                                                              values.backend
                                                      )?.type === 'cloud_watch'
                                                          ? values.threshold_value ||
                                                            0
                                                          : thresholdBucketOptions
                                                                .find(
                                                                    (bucket) =>
                                                                        bucket.value ===
                                                                        +values.threshold_bucket
                                                                )
                                                                ?.label?.split(
                                                                    'to '
                                                                )[1]
                                                                ?.split(
                                                                    ')'
                                                                )[0] || 0}
                                                  </span>
                                              </label>
                                          </div>
                                      </fieldset>
                                  );
                              })
                        : null}
                </FieldArray>
            </>
        );
    }

    return (
        <>
            <FieldArray name="methods">
                {values?.ratio_options?.length
                    ? () =>
                          values.ratio_options.map((_, i) => {
                              const ratioError =
                                  (errors.ratio_options?.length &&
                                      errors.ratio_options[i]) ||
                                  {};
                              const ratioTouched =
                                  (touched.ratio_options?.length &&
                                      touched.ratio_options[i]) ||
                                  {};

                              return (
                                  <fieldset
                                      key={1 + i}
                                      className="grid grid-cols-3 gap-x-8 gap-y-2"
                                  >
                                      <div className="">
                                          <FormTextarea
                                              label="filter_good"
                                              name={`${capitalizeFirstLetter(
                                                  t('button.filter')
                                              )} ${capitalizeFirstLetter(
                                                  t('sre.good')
                                              )}`}
                                              attributeName={`ratio_options.${i}.filter_good`}
                                              rows={6}
                                              placeholderText={`${capitalizeFirstLetter(
                                                  t('button.enter')
                                              )} ${t('button.filter')} ${t(
                                                  'sre.good'
                                              )}`}
                                              error={ratioError.filter_good}
                                              touched={ratioTouched.filter_good}
                                              value={
                                                  values.ratio_options[i]
                                                      ?.filter_good
                                              }
                                              onKeyDown={handleKeyDown}
                                          />
                                      </div>
                                      <div
                                          className={`relative ${
                                              values.ratio_options[i]
                                                  ?.filter_good &&
                                              values.ratio_options[i]
                                                  ?.filter_valid
                                                  ? 'filter blur-sm'
                                                  : ''
                                          }`}
                                      >
                                          <FormTextarea
                                              name={`${capitalizeFirstLetter(
                                                  t('button.filter')
                                              )} ${capitalizeFirstLetter(
                                                  t('sre.bad')
                                              )}`}
                                              attributeName={`ratio_options.${i}.filter_bad`}
                                              rows={6}
                                              placeholderText={`${capitalizeFirstLetter(
                                                  t('button.enter')
                                              )} ${t('button.filter')} ${t(
                                                  'sre.bad'
                                              )}`}
                                              error={ratioError.filter_bad}
                                              touched={ratioTouched.filter_bad}
                                              value={
                                                  values.ratio_options[i]
                                                      ?.filter_bad
                                              }
                                              onKeyDown={handleKeyDown}
                                          />
                                          {values.ratio_options[i]
                                              ?.filter_good &&
                                          values.ratio_options[i]
                                              ?.filter_valid ? (
                                              <div className="absolute top-0 w-full h-full"></div>
                                          ) : null}
                                      </div>
                                      <div
                                          className={`relative ${
                                              values.ratio_options[i]
                                                  ?.filter_good &&
                                              values.ratio_options[i]
                                                  ?.filter_bad
                                                  ? 'filter blur-sm'
                                                  : ''
                                          }`}
                                      >
                                          <FormTextarea
                                              label="filter_v"
                                              name={`${capitalizeFirstLetter(
                                                  t('button.filter')
                                              )} ${capitalizeFirstLetter(
                                                  t('sre.valid')
                                              )}`}
                                              attributeName={`ratio_options.${i}.filter_valid`}
                                              rows={6}
                                              placeholderText={`${capitalizeFirstLetter(
                                                  t('button.enter')
                                              )} ${t('button.filter')} ${t(
                                                  'sre.valid'
                                              )}`}
                                              error={ratioError.filter_valid}
                                              touched={
                                                  ratioTouched.filter_valid
                                              }
                                              value={
                                                  values.ratio_options[i]
                                                      ?.filter_valid
                                              }
                                              onKeyDown={handleKeyDown}
                                          />
                                          {values.ratio_options[i]
                                              ?.filter_good &&
                                          values.ratio_options[i]
                                              ?.filter_bad ? (
                                              <div className="absolute top-0 w-full h-full"></div>
                                          ) : null}
                                      </div>
                                  </fieldset>
                              );
                          })
                    : null}
            </FieldArray>
            <FieldArray name="methods">
                {values?.distribution_options?.length
                    ? () =>
                          values.distribution_options.map((_, i) => {
                              const distributionError =
                                  (errors.distribution_options?.length &&
                                      errors.distribution_options[i]) ||
                                  {};
                              const distributionTouched =
                                  (touched.distribution_options?.length &&
                                      touched.distribution_options[i]) ||
                                  {};

                              return (
                                  <fieldset
                                      key={2 + i}
                                      className="grid grid-cols-3 gap-x-8 gap-y-2"
                                  >
                                      <div className="col-span-3">
                                          <FormTextarea
                                              name={`${capitalizeFirstLetter(
                                                  t('button.filter')
                                              )} ${capitalizeFirstLetter(
                                                  t('sre.valid')
                                              )}`}
                                              attributeName={`distribution_options.${i}.filter_valid`}
                                              rows={6}
                                              placeholderText={`${capitalizeFirstLetter(
                                                  t('button.enter')
                                              )} ${t('button.filter')} ${t(
                                                  'sre.valid'
                                              )}`}
                                              error={
                                                  distributionError.filter_valid
                                              }
                                              touched={
                                                  distributionTouched.filter_valid
                                              }
                                              value={
                                                  values.distribution_options[i]
                                                      ?.filter_valid
                                              }
                                              onKeyDown={handleKeyDown}
                                          />
                                      </div>
                                      <div className="col-span-3 flex items-end pb-[20px] ml-[auto] mr-[auto]">
                                          <label
                                              className="text-gray-500 mr-2 text-m flex items-center gap-x-2"
                                              htmlFor="good_below_threshold"
                                          >
                                              <span>
                                                  {capitalizeFirstLetter(
                                                      t('sre.value_is_good')
                                                  )}
                                              </span>
                                              <Field name="good_below_threshold">
                                                  {({ field }) => {
                                                      return (
                                                          <select
                                                              {...field}
                                                              className="w-24 p-2 placeholder-gray-300 text-gray-700 relative bg-white rounded text-md border border-gray-300"
                                                              value={
                                                                  values?.good_below_threshold
                                                              }
                                                              required
                                                          >
                                                              <option
                                                                  disabled
                                                                  value=""
                                                                  className="text-gray-300"
                                                              >
                                                                  Select good
                                                              </option>
                                                              {thresholdOptions.map(
                                                                  (
                                                                      threshold,
                                                                      i
                                                                  ) => (
                                                                      <option
                                                                          type="number"
                                                                          key={
                                                                              i
                                                                          }
                                                                          value={
                                                                              threshold.value
                                                                          }
                                                                      >
                                                                          {
                                                                              threshold.label
                                                                          }
                                                                      </option>
                                                                  )
                                                              )}
                                                          </select>
                                                      );
                                                  }}
                                              </Field>

                                              <span>{t('sre.amount_of')} </span>
                                          </label>

                                          <label
                                              className="text-gray-500 mr-2 text-m flex items-center gap-x-2"
                                              htmlFor="threshold_bucket"
                                          >
                                              <span className="w-24">
                                                  <InputText
                                                      id="threshold_bucket"
                                                      type="number"
                                                      min="1"
                                                      step="1"
                                                      name="threshold_bucket"
                                                      placeholder={'100'}
                                                      value={
                                                          values.threshold_bucket
                                                      }
                                                  />
                                              </span>

                                              <span>MS</span>
                                          </label>
                                      </div>
                                  </fieldset>
                              );
                          })
                    : null}
            </FieldArray>
        </>
    );
}

export default SreFormFilters;
