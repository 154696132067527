import React from 'react';
import { Link } from 'react-router-dom';
import { NAVIGATION_PATHS } from '../../constants/navigationPaths';

function UpgradeWindow({
    accessTo,
    route = `${NAVIGATION_PATHS.settingsOrganisation}#manage-plan`,
}) {
    return (
        <div className="absolute w-full h-full z-10 top-0 p-24">
            <div className="mx-auto bg-white rounded-lg w-1/2 p-8  shadow-lg text-center flex flex-col gap-8">
                <p className="text-gray-500 font-display font-semibold	text-lg leading-6">
                    This organisation doesn't have access to {accessTo}
                </p>

                <Link
                    to={route}
                    className="px-1 py-2 bg-theme-tertiary rounded text-white font-display leading-6 font-medium"
                >
                    Upgrade your plan
                </Link>
            </div>
        </div>
    );
}

export default UpgradeWindow;
