import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

const RouteChangeTracker = () => {
    const location = useLocation();

    useEffect(() => {
        if (location?.search && location.search.includes('utm')) {
            localStorage.setItem('q', location.search);
        }

        ReactGA.set({ page: location.pathname });
        ReactGA.event('page_view', {
            page_location:
                location.pathname +
                (location.search || localStorage.getItem('q')),
        });
    }, [location.pathname, location.search]);

    return <div></div>;
};

export default RouteChangeTracker;
