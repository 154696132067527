import React from 'react';
import jiraLogo from '../../assets/jira.png';
import agileAnalyticsLogo from '../../assets/logo-agile-analytics.png';
import {
    IoCloseCircleOutline,
    IoCheckmarkCircleOutline,
} from 'react-icons/io5';
import LinkChain from './link-chain';
import { AzureDevOpsIcon } from '../../assets/svg';
import { capitalizeFirstLetter } from '../../helpers/textFormatters';
import { useTranslation } from 'react-i18next';

const WorkspaceProgressBar = ({
    service,
    isServiceAuthorised = false,
    isServiceWebhooksAdded = false,
}) => {
    const { t } = useTranslation();
    const progressStyles = {
        logo: {
            on: 'filter-none',
            off: 'filter',
        },
        text: {
            on: 'text-theme-tertiary',
            off: 'bg-default-gray opacity-60',
        },
    };

    return (
        <div className="flex items-center gap-x-2.5 mb-8">
            <div className="w-30">
                <img src={agileAnalyticsLogo} alt="Ajile Analytics logo" />
            </div>
            <LinkChain status={isServiceAuthorised} />
            <div className="flex flex-col items-center gap-y-1 w-14">
                {isServiceAuthorised ? (
                    <>
                        <IoCheckmarkCircleOutline size={20} color={'#1FB8D3'} />
                        <p
                            className={`${progressStyles.text.on} text-center text-10 leading-3 uppercase`}
                        >
                            Authorize account
                        </p>
                    </>
                ) : (
                    <>
                        {/* <IoCloseCircleOutline size={20} color={'#919298'} /> */}
                        <IoCloseCircleOutline size={20} color={'#919298'} />
                        <p
                            className={`${progressStyles.text.off} text-center text-10 leading-3 uppercase`}
                        >
                            Authorize account
                        </p>
                    </>
                )}
            </div>
            <LinkChain status={isServiceWebhooksAdded} />
            <div className="flex flex-col items-center gap-y-1 w-14">
                {isServiceWebhooksAdded ? (
                    <>
                        <IoCheckmarkCircleOutline size={20} color={'#1FB8D3'} />
                        <p
                            className={`${progressStyles.text.on} text-center text-10 leading-3 uppercase`}
                        >
                            {capitalizeFirstLetter(
                                t('repo_page.create_webhook')
                            )}
                        </p>
                    </>
                ) : (
                    <>
                        {/* <IoCloseCircleOutline size={20} color={'#919298'} /> */}
                        <IoCloseCircleOutline size={20} color={'#919298'} />
                        <p
                            className={`${progressStyles.text.off} text-center text-10 leading-3 uppercase`}
                        >
                            {capitalizeFirstLetter(
                                t('repo_page.create_webhook')
                            )}
                        </p>
                    </>
                )}
            </div>
            <LinkChain status={isServiceWebhooksAdded} />

            <div
                className={` flex w-36 grayscale ${
                    isServiceWebhooksAdded
                        ? progressStyles.logo.on
                        : progressStyles.logo.off
                }`}
            >
                {service === 'jira' && (
                    <div className="w-16">
                        <img src={jiraLogo} alt="Jira logo" />
                    </div>
                )}
                {service === 'azure' && (
                    <div className="w-16 flex gap-x-1 items-center">
                        <AzureDevOpsIcon width={32} height={32} />
                        <span className="text-12 leading-3 text-custom-blue-azure">
                            Azure DevOps
                        </span>
                    </div>
                )}
            </div>
        </div>
    );
};

export default WorkspaceProgressBar;
