import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Arrow } from '../../../../assets/svg.js';

const LeaksTableHeaderColumn = ({ headerItem, makeSort }) => {
    const [sortDirection, setSortDirection] = useState(true);
    const { t } = useTranslation();

    const changeSort = () => {
        setSortDirection(!sortDirection);
        makeSort(headerItem.columnName, !sortDirection);
    };

    const columnName = headerItem.columnName;

    return (
        <div
            className={`flex items-center col-span-${
                headerItem.columnSize
            } text-center  ${headerItem.canSort ? 'can-sort' : ''}`}
        >
            <p
                className={`text-gray-700  ${
                    columnName === 'date' ? 'text-15 px-2' : 'px-4'
                }`}
                onClick={() => changeSort()}
            >
                {t(`leaks_page.table.${columnName}`)}
            </p>
            {headerItem.canSort ? (
                <div className="cursor-pointer " onClick={() => changeSort()}>
                    <Arrow
                        width={'10'}
                        height={'10'}
                        fill="#323236"
                        transform={
                            sortDirection ? 'scale(1, 1)' : 'scale(1, -1)'
                        }
                    />
                </div>
            ) : null}
        </div>
    );
};

export default LeaksTableHeaderColumn;
