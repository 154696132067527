import React, { useCallback, useContext, useState } from 'react';
import Loader from '../loader';
import { useFetchDataAndSetState } from '../../helpers/useFetchDataAndSetState';
import { getErrorBudgets, getServiceByName } from '../../api/settings-slo/slo';
import { useTranslation } from 'react-i18next';
import { TimePeriodContext } from '../../context/TimePeriodContext';
import SloCard from '../../pages/sre-page/components/sre-slo-card';
import ErrorBudgetsChart from '../../pages/sre-page/components/sre-chart-error-budgets';
import { useEffect } from 'react';
import { getSloColor } from '../../helpers/sreChartHelpers';

function TeamSloAndErrorBudget({
    teamServices,
    cardTimeperiod,
    deploymentsList,
}) {
    const { t } = useTranslation();
    const [timePeriodState] = useContext(TimePeriodContext);
    const { date_start: dateTimeStart, date_end: dateTimeEnd } =
        timePeriodState;

    const [loading, setLoading] = useState(true);
    const [step, setStep] = useState({
        value: '24 hours',
        label: `24 ${t('sre.hours_step')}`,
    });

    const request = useCallback(async () => {
        setLoading(true);
        let response = { data: [], status: 204 };

        if (teamServices?.length) {
            try {
                const servicesWithSlos = await Promise.allSettled(
                    teamServices.map((service) =>
                        getServiceByName(service.service)
                    )
                );

                const servicesWithSlosData = servicesWithSlos
                    .filter((item) => item.status === 'fulfilled')
                    .map((ser) => ser.value.data);

                const servicesWithErrorBudgetData = await Promise.all(
                    servicesWithSlosData.map(async (service) => {
                        let updatedService = service;

                        if (service?.features?.length) {
                            const errorBudget = await Promise.allSettled(
                                service?.features.map((feature) =>
                                    getErrorBudgets(
                                        service.service,
                                        cardTimeperiod
                                            ? cardTimeperiod.start
                                            : dateTimeStart,
                                        cardTimeperiod
                                            ? cardTimeperiod.end
                                            : dateTimeEnd,
                                        feature.feature_name,
                                        step.value
                                    )
                                )
                            );

                            const errorBudgetData = errorBudget
                                .filter((item) => item.status === 'fulfilled')
                                .map((eb) => eb?.value?.data);

                            const updatedFeatures = service?.features.map(
                                (feature) => {
                                    let updated = feature;

                                    const featureErrorBudgetData =
                                        errorBudgetData.find(
                                            (item) =>
                                                item.feature ===
                                                feature.feature_name
                                        );

                                    if (featureErrorBudgetData) {
                                        updated = {
                                            ...feature,
                                            chartData:
                                                featureErrorBudgetData?.data,
                                        };
                                    }

                                    return updated;
                                }
                            );
                            updatedService = {
                                ...service,
                                features: updatedFeatures,
                            };
                        }

                        return updatedService;
                    })
                );

                response = { data: servicesWithErrorBudgetData, status: 200 };
            } catch (err) {
                console.log(err);
            }
        }

        setLoading(false);
        return response;
    }, [teamServices, dateTimeStart, dateTimeEnd, cardTimeperiod]);

    const [sreDataState] = useFetchDataAndSetState(request, [teamServices]);
    const [chartState, setChartState] = useState({
        data: null,
        loading: true,
        error: null,
    });

    useEffect(() => {
        const chartDataSeries = compileChartDataSeries(sreDataState?.data);
        setChartState({ data: chartDataSeries, loading: false, error: null });
    }, [sreDataState?.data]);

    function compileChartDataSeries(sreData) {
        let series = [];
        const allSloSeries = sreData?.reduce((acc, service) => {
            const updatedAcc = acc;

            service?.features?.forEach(
                (slo) =>
                    slo?.chartData &&
                    updatedAcc.push({
                        data: slo.chartData.map((chart) => [
                            chart[0],
                            chart[1],
                            chart[2],
                        ]),
                        slo_name: slo.slo_name,
                        slo_target: slo.goal,
                        service: service.service,
                    })
            );
            return updatedAcc;
        }, []);

        if (!allSloSeries?.length) {
            return series;
        }

        // 1. calculate avg total
        const avgTotalDataset = allSloSeries[0]?.data.map((item, i) => {
            let emptyNumber = 0;
            let total = allSloSeries?.reduce((acc, sloChartData) => {
                if (!sloChartData?.data[i]) {
                    emptyNumber = emptyNumber + 1;
                    return acc;
                }
                return acc + sloChartData?.data[i][1];
            }, 0);
            let avg = total / (allSloSeries?.length - emptyNumber);
            return [item[0], avg];
        });

        series = [{ data: avgTotalDataset, title: 'Avg team' }];

        // 2. calculate min total
        const minTotalDataset = allSloSeries[0]?.data.map((item, i) => {
            let min = allSloSeries?.reduce((acc, sloChartData, index) => {
                if (!sloChartData?.data[i]) {
                    return acc;
                }
                if (index > 0 && sloChartData.data[i][1] > acc) {
                    return acc;
                }
                return sloChartData.data[i][1];
            }, 0);
            return [item[0], min];
        });

        series = [...series, { data: minTotalDataset, title: 'Min team' }];

        // 3. calculate max total
        const maxTotalDataset = allSloSeries[0]?.data.map((item, i) => {
            let max = allSloSeries?.reduce((acc, sloChartData, index) => {
                if (!sloChartData?.data[i]) {
                    return acc;
                }
                if (index > 0 && sloChartData.data[i][1] < acc) {
                    return acc;
                }
                return sloChartData.data[i][1];
            }, 0);
            return [item[0], max];
        });

        series = [...series, { data: maxTotalDataset, title: 'Max team' }];
        // 4. add all slos data series
        series = [...series, ...allSloSeries];

        return series;
    }

    function getSlosAmount(sreState) {
        const slosArray = sreState?.data?.reduce((acc, item) => {
            const slos = item.features?.filter((slo) => slo?.chartData);
            if (slos?.length) {
                return [...acc, ...slos];
            }
            return acc;
        }, []);

        if (!slosArray) {
            return 0;
        }

        return slosArray?.length;
    }

    return (
        <div className={`grid grid-cols-3 gap-4`}>
            <div
                className={`mt-4 ${
                    getSlosAmount(sreDataState) < 3
                        ? 'col-span-1'
                        : 'col-span-3'
                }  rounded p-4 border border-solid border-gray-300`}
            >
                <p className="font-display inline-block text-28 text-gray-700 font-light">
                    Team's SLOs
                </p>
                {loading ? (
                    <div className="flex justify-center items-center mx-4">
                        <Loader />
                    </div>
                ) : (
                    <div
                        className={`w-full grid gap-4 ${
                            getSlosAmount(sreDataState) < 3
                                ? `grid-cols-1`
                                : getSlosAmount(sreDataState) < 4
                                ? `grid-cols-${
                                      getSlosAmount(sreDataState)
                                          ? getSlosAmount(sreDataState)
                                          : '4'
                                  }`
                                : 'grid-cols-4 '
                        } mb-8 items-stretch`}
                    >
                        {sreDataState?.data?.length &&
                        sreDataState?.data.find((service) =>
                            service.features?.find((slo) => slo?.chartData)
                        ) ? (
                            sreDataState?.data.map((service) =>
                                service?.features?.map((slo, i) => {
                                    if (slo?.chartData) {
                                        return (
                                            <SloCard
                                                key={slo.slo_name}
                                                sloData={slo}
                                                chartData={{
                                                    data: slo.chartData,
                                                }}
                                                service={service?.service}
                                                step={step}
                                                showService={true}
                                                color={getSloColor(i)}
                                            />
                                        );
                                    }
                                })
                            )
                        ) : (
                            <p className="col-span-4 text-center text-gray-400 mt-4">
                                No SLOs
                            </p>
                        )}
                    </div>
                )}
            </div>
            <div
                className={`mt-4 ${
                    getSlosAmount(sreDataState) < 3
                        ? 'col-span-2'
                        : 'col-span-3'
                } rounded p-4 border border-solid border-gray-300`}
            >
                <p className="font-display inline-block text-28 text-gray-700 font-light">
                    Team's Error Budget
                </p>
                {loading ? (
                    <div className="flex justify-center items-center mx-4">
                        <Loader />
                    </div>
                ) : (
                    <>
                        <ErrorBudgetsChart
                            services={sreDataState?.data}
                            chartState={chartState}
                            step={step.value}
                            isInTeam={true}
                            cardTimeperiod={cardTimeperiod}
                            deploymentsList={deploymentsList}
                        />
                    </>
                )}
            </div>
        </div>
    );
}

export default TeamSloAndErrorBudget;
