import { instances } from '../default-setup';

//Swarm
export const getSelectedRepositories = () => instances.get('swarm/selected/');

export const getGroups = () => instances.get('swarm/selected/groups/');

export const getSelectedRepositoryStock = (id) =>
    instances.get(`swarm/selected/${id}/stock/`);

export const getSelectedRepositoryLeaks = (id) =>
    instances.get(`swarm/selected/${id}/leaks/`);

export const getSelectedRepositoryJobs = (provider) =>
    instances.get(`swarm/selected/${provider}/jobs/`);

export const getRepositories = (id) => instances.get(`swarm/selected/${id}/`);

export const addSelectedRepositories = (data, id) =>
    instances.put(`swarm/selected/${id}/`, data);

export const getSwarmRepos = (params) => {
    return instances.get(`swarm/repos/?name=${params.name}`);
};

export const getSwarmJobs = () => instances.get('swarm/jobs/');

export const getSwarmProviderJobs = (provider) =>
    instances.get(`swarm/jobs/${provider}/`);

export const runAllJobs = () => instances.put('swarm/jobs/');

export const runProviderJobs = (data) => instances.put('swarm/jobs/', data);

export const getLogs = (params) =>
    instances.get(
        `swarm/jobs/${params.git_provider}/${params.repo_name}/${params.job_id}/`
    );

export const checkRepoWebhook = (params) =>
    instances.get(
        `settings/git/${params.provider_id}/check_webhook/?repository=${params.repository_url}`
    );

export const testProvider = (data) => {
    return instances.post(`settings/git/test/`, data);
};

export const deleteRepoWebhook = (params) =>{
    return instances.delete(`settings/git/${params.provider_id}/webhook/?repository_url=${params.repository_url}`)
}