import {
    customInstances,
    formDataInstances,
    instances,
} from '../default-setup';

// Organisations Requests
// export const getOrganisations = () => instances.get("whoami");

// ADMINS endpoints
export const getAllOrganisations = () =>
    customInstances.get(`admin/organisations/`);

export const getOrganisation = () =>
    customInstances.get(
        `admin/organisations/${window.localStorage.getItem('orgHash')}/`
    );

export const createOrganisation = (data) =>
    customInstances.post('admin/organisations/', data);

export const updateOrganisation = (data, orgHash) => {
    if (orgHash) {
        return customInstances.put(`admin/organisations/${orgHash}/`, data);
    }
    return customInstances.put(
        `${window.localStorage.getItem('orgHash')}/settings/organisations/`,
        data
    );
};

export const deleteOrganisation = (orgHash) =>
    customInstances.delete(`admin/organisations/${orgHash}/`);

export const restoreOrganisation = (orgHash) =>
    customInstances.post(`admin/organisations/${orgHash}/`);

// COMMON USERS endpoints
export const getCurrentOrganisation = () =>
    instances.get(`settings/organisations/`);

export const createOrganisationByDemoUser = (data) =>
    customInstances.post(`settings/organisations/`, data);

export const updateCurrentOrganisation = (data) =>
    instances.put(`settings/organisations/`, data);

export const setOrganisationLogo = (data) =>
    formDataInstances.put(
        `${window.localStorage.getItem('orgHash')}/settings/organisations/`,
        data
    );
