import { Link } from 'react-router-dom';
import RichardAvatar from '../assets/richard-avatar.png';
import { NAVIGATION_PATHS } from '../constants';
import AudioPlayButton from '../components/audio-play-btn';

export const overviewFirstStep = () => {
    return (
        <div className="text-gray-500 leading-5">
            <div className="flex justify-center mb-4">
                <div className="relative">
                    <img
                        src={RichardAvatar}
                        alt="Richard Hendricks avatar"
                        width="100px"
                    />
                    <AudioPlayButton
                        audioFilePath={'../assets/audiotour/tour_01.mp3'}
                    />
                </div>
            </div>
            <p className="font-medium mb-4 ">
                {' '}
                Hey there, welcome to Pied Piper!
            </p>{' '}
            <p className="mb-2">
                I'm Richard Hendricks, Pied Piper's CEO, and I'll be your tour
                guide today.
            </p>{' '}
            <p className="mb-2">
                Here at Pied Piper, we're all about using data to steer our
                software development ship. Forget about following the hippo (or
                Highest Paid Person's Opinion). We make decisions based on
                insights from data.
            </p>{' '}
            <Link to={`${NAVIGATION_PATHS.teams}?tour=true`}>
                <span className="block w-28 h-12 mx-auto mt-4 px-1 py-2 bg-theme-tertiary rounded text-white font-display leading-6 font-medium flex justify-center items-center">
                    Get Started
                </span>
            </Link>
        </div>
    );
};
// export const overviewSecondStep = () => {
//     return (
//         <div className="text-gray-500 leading-5">
//             <div className="flex justify-center mb-4">
//                 <div className="relative">
//                     <img
//                         src={RichardAvatar}
//                         alt="Richard Hendricks avatar"
//                         width="100px"
//                     />
//                     <AudioPlayButton
//                         audioFilePath={'../assets/audiotour/tour_02.mp3'}
//                     />
//                 </div>
//             </div>
//             <p className="mb-4 ">
//                 These issues did not break our teams or slow down the company
//                 because we started using ZEN Software's Agile Analytics instead!
//                 Agile Analytics helped us understand team outcomes instead of
//                 the project management output. We could easily see how many
//                 hours were spent on the government R&D subsidy and knew which
//                 team had a sustainable pace. Some teams showed a misalignment on
//                 topics; they were pushing features and lost focus on the
//                 customer experience. Because Agile Analytics warned us, we
//                 prevented unhappy customers from using subpar services!
//             </p>{' '}
//             <p className="mb-2">
//                 Now, we know how the output of the teams impacts the goals we
//                 set as a company. Agile Analytics helped improve employee
//                 engagement because teams gain more autonomy. It kick-started our
//                 data-driven software operation. At Pied Piper, we use data to
//                 steer our software development ship. Forget about following the
//                 HiPPO (or Highest Paid Person's Opinion). We make decisions
//                 based on insights from data.
//             </p>{' '}
//             <Link to={`${NAVIGATION_PATHS.teams}?tour=true`}>
//                 <span className="block w-28 h-12 mx-auto mt-4 px-1 py-2 bg-theme-tertiary rounded text-white font-display leading-6 font-medium flex justify-center items-center">
//                     Get Started
//                 </span>
//             </Link>
//         </div>
//     );
// };

export const teamsFirstStep = () => {
    return (
        <div className="text-gray-500 leading-5 flex gap-x-4">
            <div className="flex justify-center items-center w-2/12">
                <div className="relative">
                    <img
                        src={RichardAvatar}
                        alt="Richard Hendricks avatar"
                        width="100px"
                    />
                    <AudioPlayButton
                        audioFilePath={'../assets/audiotour/tour_02.mp3'}
                    />
                </div>
            </div>
            <div className="w-10/12">
                <p className="font-display text-2xl font-medium mb-4 ">
                    {' '}
                    Teams Reporting{' '}
                </p>{' '}
                <p className="mb-2">
                    Alright, buckle up because we're diving into our Teams
                    Reporting.
                </p>{' '}
                <p className="mb-2">
                    This is your{' '}
                    <span className="font-medium">
                        command center for tracking team performance
                    </span>
                    . As CEO of Pied Piper, I'm interested in the collective's
                    performance, not in excellers or show-offs. Teams are the
                    foundational structure of our organisation. I believe that
                    Together, Everyone Achieves More.
                </p>{' '}
                <p className="mb-2">
                    This team report shows you all the essential metrics of the
                    team's performance. Here are the DORA productivity and risk
                    metrics, Service Level Objectives and the Team's Error
                    Budget based on real-time production information, and Sprint
                    Insights showing the fundamental topics the team was working
                    on and whether these topics were feature-related work or
                    support & maintenance work. It's like having a playbook for
                    success right at your fingertips. So, let's gear up because
                    we're diving deep into these game-changing metrics in the
                    following steps. Ready to make your teams unstoppable? Let's
                    do this!
                </p>{' '}
                <p className="mb-2">
                    Ready to make your teams unstoppable? Let's do this!
                </p>{' '}
                <div className="w-full flex justify-center ">
                    <Link to={`${NAVIGATION_PATHS.sre}?tour=true`}>
                        <span className="block flex-shrink h-12 mx-auto mt-4 px-2 py-2 bg-theme-tertiary rounded text-white font-display leading-6 font-medium flex justify-center items-center">
                            Explore
                        </span>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export const sreFirstStep = () => {
    return (
        <div className="text-gray-500 leading-5 flex gap-x-4">
            <div className="flex justify-center items-center w-2/12">
                <div className="relative">
                    <img
                        src={RichardAvatar}
                        alt="Richard Hendricks avatar"
                        width="100px"
                    />
                    <AudioPlayButton
                        audioFilePath={'../assets/audiotour/tour_04.mp3'}
                    />
                </div>
            </div>
            <div className="w-10/12">
                <p className="font-display text-2xl font-medium mb-4 ">
                    {' '}
                    Error Budgets{' '}
                </p>{' '}
                <p className="mb-2">
                    The error budgets screen is the central hub for real-time
                    reporting on Service Levels.
                </p>{' '}
                <p className="mb-2">
                    This screen gives us insights into how our services are
                    performing. Each of the services important to our
                    organisation are visible here, and with each service is a
                    corresponding Service Level Objective (or SLO) configured.
                    This will allow us to measure whether the services are
                    performing inside the objective's window or outside this
                    level.
                </p>{' '}
                <p className="mb-2">
                    Service Level Objectives give us Error Budgets that allow
                    the team responsible for this service to see how urgent
                    certain support issues are. This data is crucial{' '}
                    <span className="font-medium">
                        to catching problems before they turn into nightmares
                    </span>{' '}
                    and ensuring we're meeting our Service Level Objectives.
                    It's all about keeping our services running smoothly and our
                    customers happy.
                </p>{' '}
                <p className="mb-2">
                    Take a look at the dashboard, and you'll notice that one of
                    our services is performing poorly.
                </p>{' '}
            </div>
        </div>
    );
};

export const sreSecondStep = () => {
    return (
        <div className="text-gray-500 leading-5 flex gap-x-4">
            <div className="flex justify-center items-center w-2/12">
                <div className="relative">
                    <img
                        src={RichardAvatar}
                        alt="Richard Hendricks avatar"
                        width="100px"
                    />
                    <AudioPlayButton
                        audioFilePath={'../assets/audiotour/tour_05.mp3'}
                    />
                </div>
            </div>
            <div className="w-10/12">
                <p className="font-display text-2xl font-medium mb-4 ">
                    {' '}
                    Error Budgets{' '}
                </p>{' '}
                <p className="mb-2">
                    Yep, this service ran out of Error Budget.
                </p>{' '}
                <p className="mb-2">
                    But fear not! Our team is on it, working hard to fix the
                    service and get those Error Budget back on track.{' '}
                    <span className="font-medium">
                        It's all about knowing when to shift our focus to
                        non-functionalities
                    </span>
                    and keep things running smoothly.
                </p>{' '}
                <div className=" flex ">
                    <Link to={`${NAVIGATION_PATHS.sprintInsights}?tour=true`}>
                        <span className="block flex-shrink h-12 mx-auto mt-4 px-2 py-2 bg-theme-tertiary rounded text-white font-display leading-6 font-medium flex justify-center items-center">
                            Go to Sprint Insights
                        </span>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export const sprintInsightsFirstStep = () => {
    return (
        <div className="text-gray-500 leading-5 flex gap-x-4">
            <div className="flex justify-center items-center w-2/12">
                <div className="relative">
                    <img
                        src={RichardAvatar}
                        alt="Richard Hendricks avatar"
                        width="100px"
                    />
                    <AudioPlayButton
                        audioFilePath={'../assets/audiotour/tour_06.mp3'}
                    />
                </div>
            </div>
            <div className="w-10/12">
                <p className="font-display text-2xl font-medium mb-4 ">
                    {' '}
                    Sprint Insights{' '}
                </p>{' '}
                <p className="mb-2">
                    Are you curious to know what your team really spent its time
                    on the last sprint? for instance how the team's time and
                    effort are distributed between new feature development and
                    operations, bug fixes, or non-feature development? That's
                    where Sprint Insights comes in.
                </p>{' '}
                <p className="mb-2">
                    Our AI crunches the tickets worked on to give us a clear
                    picture of where our efforts are being spent. Check it out
                    to see our work in progress.
                </p>{' '}
                <div className=" flex gap-x-4">
                    <Link to={`${NAVIGATION_PATHS.dora}?tour=true`}>
                        <span className="block flex-shrink h-12 mx-auto mt-4 px-2 py-2 bg-theme-tertiary rounded text-white font-display leading-6 font-medium flex justify-center items-center">
                            Go to DORA Metrics
                        </span>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export const doraFirstStep = () => {
    return (
        <div className="text-gray-500 leading-5 flex gap-x-4">
            <div className="flex justify-center items-center w-2/12">
                <div className="relative">
                    <img
                        src={RichardAvatar}
                        alt="Richard Hendricks avatar"
                        width="100px"
                    />
                    <AudioPlayButton
                        audioFilePath={'../assets/audiotour/tour_07.mp3'}
                    />
                </div>
            </div>
            <div className="w-10/12">
                <p className="font-display text-2xl font-medium mb-4 ">
                    {' '}
                    DORA Metrics{' '}
                </p>{' '}
                <p className="mb-2">
                    Here, we showcase Agile Analytics integrating DORA metrics
                    for Development, SRE, and DevOps teams. Unlike other tools,
                    we seamlessly link DORA metrics with project and
                    service-level management workflows. With real-time insights,
                    we identify bottlenecks and drive continuous improvement for
                    faster software delivery.
                </p>{' '}
                <p className="mb-2">
                    Let's explore the four key DORA metrics that drive our
                    team's performance.
                </p>{' '}
                <p className="mb-2">
                    <span className="font-medium">Deployment Frequency.</span>{' '}
                    This metric measures how often our teams deploy code to
                    production. A high deployment frequency indicates agility
                    and efficiency in our software delivery process, enabling us
                    to respond quickly to customer needs and market changes.
                </p>{' '}
                <p className="mb-2">
                    <span className="font-medium">Lead Time for Changes.</span>{' '}
                    This metric tracks the time it takes for code changes to go
                    from development to production. A shorter lead time
                    signifies faster time to market and increased responsiveness
                    to business demands, allowing us to deliver value to our
                    customers more rapidly.
                </p>{' '}
            </div>
        </div>
    );
};

export const doraSecondStep = () => {
    return (
        <div className="text-gray-500 leading-5 flex gap-x-4">
            <div className="flex justify-center items-center w-2/12">
                <div className="relative">
                    <img
                        src={RichardAvatar}
                        alt="Richard Hendricks avatar"
                        width="100px"
                    />
                    <AudioPlayButton
                        audioFilePath={'../assets/audiotour/tour_08.mp3'}
                    />
                </div>
            </div>
            <div className="w-10/12">
                <p className="font-display text-2xl font-medium mb-4 ">
                    {' '}
                    DORA Metrics{' '}
                </p>{' '}
                <p className="mb-2">
                    <span className="font-medium">
                        Time to Restore Service.
                    </span>{' '}
                    When incidents occur, this metric measures how quickly our
                    teams can restore service to normal operations. A shorter
                    time to restore service indicates robust incident response
                    processes and infrastructure resilience, minimizing downtime
                    and ensuring a seamless user experience.
                </p>{' '}
                <p className="mb-2">
                    <span className="font-medium">Change Failure Rate.</span>{' '}
                    This metric quantifies the percentage of changes that result
                    in service degradation or disruption. A low change failure
                    rate reflects the stability and reliability of our software
                    delivery pipeline, reducing the risk of customer-facing
                    issues and maintaining trust in our products and services.
                </p>{' '}
                <div className=" flex gap-x-4">
                    <Link to={`${NAVIGATION_PATHS.kudos}?tour=true`}>
                        <span className="block flex-shrink h-12 mx-auto mt-4 px-2 py-2 bg-theme-tertiary rounded text-white font-display leading-6 font-medium flex justify-center items-center">
                            Go to Kudos
                        </span>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export const kudosFirstStep = () => {
    return (
        <div className="text-gray-500 leading-5 flex gap-x-4">
            <div className="flex justify-center items-center w-2/12">
                <div className="relative">
                    <img
                        src={RichardAvatar}
                        alt="Richard Hendricks avatar"
                        width="100px"
                    />
                    <AudioPlayButton
                        audioFilePath={'../assets/audiotour/tour_09.mp3'}
                    />
                </div>
            </div>
            <div className="w-10/12">
                <p className="font-display text-2xl font-medium mb-4 ">
                    {' '}
                    Kudos{' '}
                </p>{' '}
                <p className="mb-2">
                    Now we move from team based reporting to the organisations'
                    performance. Let's talk about{' '}
                    <span className="font-medium">
                        celebrating successes and fostering collaboration
                    </span>
                    .
                </p>{' '}
                <p className="mb-2">
                    Here at Pied Piper, we use Slack to give each other 'kudos'
                    in the form of emojis — think rat emojis for a job well
                    done. It's all about recognising and appreciating each
                    other's hard work.
                </p>{' '}
            </div>
        </div>
    );
};

export const kudosSecondStep = () => {
    return (
        <div className="text-gray-500 leading-5 flex gap-x-4">
            <div className="flex justify-center items-center w-2/12">
                <div className="relative">
                    <img
                        src={RichardAvatar}
                        alt="Richard Hendricks avatar"
                        width="100px"
                    />
                    <AudioPlayButton
                        audioFilePath={'../assets/audiotour/tour_10.mp3'}
                    />
                </div>
            </div>
            <div className="w-10/12">
                <p className="font-display text-2xl font-medium mb-4 ">
                    {' '}
                    Kudos{' '}
                </p>{' '}
                <p className="mb-2">
                    Take a look at this diagram —{' '}
                    <span className="font-medium">
                        it shows who's been giving kudos to whom
                    </span>
                    .
                </p>{' '}
                <p className="mb-2">
                    Hmm, looks like I'm getting the most kudos. But let's be
                    real, it's not just me — it's the incredible team behind the
                    scenes making things happen.
                </p>{' '}
                <p className="mb-2">
                    You can see the kudo leaderboard per team and for the whole
                    organisation!
                </p>{' '}
            </div>
        </div>
    );
};

export const kudosThirdStep = () => {
    return (
        <div className="text-gray-500 leading-5 flex gap-x-4 w-full">
            <div className="flex justify-center items-center w-2/12">
                <div className="relative">
                    <img
                        src={RichardAvatar}
                        alt="Richard Hendricks avatar"
                        width="100px"
                    />
                    <AudioPlayButton
                        audioFilePath={'../assets/audiotour/tour_11.mp3'}
                    />
                </div>
            </div>
            <div className="w-10/12">
                <p className="font-display text-2xl font-medium mb-4 ">
                    {' '}
                    Extended tour: Enterprise{' '}
                </p>{' '}
                <p className="mb-2">
                    As we've grown recently, we've encountered some 'fresh'
                    problems – namely, Leaks and Software Stock. Curious how we
                    tackle these challenges?
                </p>{' '}
                <p className="mb-2">Curious how we tackle these challenges?</p>{' '}
                <div className=" flex gap-x-4">
                    <Link to={`${NAVIGATION_PATHS.leaks}?tour=true`}>
                        <span className="block flex-shrink h-12 mx-auto mt-4 px-2 py-2 bg-theme-tertiary rounded text-white font-display leading-6 font-medium flex justify-center items-center">
                            Yes, go to Leaks
                        </span>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export const leaksFirstStep = () => {
    return (
        <div className="text-gray-500 leading-5 flex gap-x-4">
            <div className="flex justify-center items-center w-2/12">
                <div className="relative">
                    <img
                        src={RichardAvatar}
                        alt="Richard Hendricks avatar"
                        width="100px"
                    />
                    <AudioPlayButton
                        audioFilePath={'../assets/audiotour/tour_12.mp3'}
                    />
                </div>
            </div>
            <div className="w-10/12">
                <p className="font-display text-2xl font-medium mb-4 ">
                    {' '}
                    Leaks{' '}
                </p>{' '}
                <p className="mb-2">
                    Leaky repositories can spell trouble, especially when they
                    contain sensitive information like private keys or cloud API
                    keys.
                </p>{' '}
                <p className="mb-2">
                    Ever worry about secrets slipping through the cracks in your
                    codebase? Not on our watch.
                </p>
            </div>
        </div>
    );
};

export const leaksSecondStep = () => {
    return (
        <div className="text-gray-500 leading-5 flex gap-x-4">
            <div className="flex justify-center items-center w-2/12">
                <div className="relative">
                    <img
                        src={RichardAvatar}
                        alt="Richard Hendricks avatar"
                        width="100px"
                    />
                    <AudioPlayButton
                        audioFilePath={'../assets/audiotour/tour_13.mp3'}
                    />
                </div>
            </div>
            <div className="w-10/12">
                <p className="font-display text-2xl font-medium mb-4 ">
                    {' '}
                    Leaks{' '}
                </p>{' '}
                <p className="mb-2">
                    Agile Analytics sniffs out any hidden secrets, like private
                    keys or cloud API keys, ensuring{' '}
                    <span className="font-medium">our code stays secure</span>{' '}
                    and our operations smooth sailing. Once detected, it is easy
                    to neutralise it and mark as 'done' in the Analytics Front
                    end.
                </p>{' '}
                <div className=" flex gap-x-4">
                    <Link to={`${NAVIGATION_PATHS.stock}?tour=true`}>
                        <span className="block flex-shrink h-12 mx-auto mt-4 px-2 py-2 bg-theme-tertiary rounded text-white font-display leading-6 font-medium flex justify-center items-center">
                            Go to Stock
                        </span>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export const stockFirstStep = () => {
    return (
        <div className="text-gray-500 leading-5 flex gap-x-4">
            <div className="flex justify-center items-center w-2/12">
                <div className="relative">
                    <img
                        src={RichardAvatar}
                        alt="Richard Hendricks avatar"
                        width="100px"
                    />
                    <AudioPlayButton
                        audioFilePath={'../assets/audiotour/tour_14.mp3'}
                    />
                </div>
            </div>
            <div className="w-10/12">
                <p className="font-display text-2xl font-medium mb-4 ">
                    {' '}
                    Stock{' '}
                </p>{' '}
                <p className="mb-2">
                    In software development, having too much 'work in process'
                    can be just as problematic as having too much stock in
                    manufacturing. Agile Analytics helps us keep tabs on our
                    'stock' – work that's checked into branches but not yet
                    deployed to production. It's all about{' '}
                    <span className="font-medium">
                        maintaining a healthy flow
                    </span>{' '}
                    and keeping our development process lean and efficient.
                </p>{' '}
            </div>
        </div>
    );
};

export const stockSecondStep = () => {
    return (
        <div className="text-gray-500 leading-5 flex gap-x-4">
            <div className="flex justify-center items-center w-2/12">
                <div className="relative">
                    <img
                        src={RichardAvatar}
                        alt="Richard Hendricks avatar"
                        width="100px"
                    />
                    <AudioPlayButton
                        audioFilePath={'../assets/audiotour/tour_15.mp3'}
                    />
                </div>
            </div>
            <div className="w-10/12">
                <p className="font-display text-2xl font-medium mb-4 ">
                    {' '}
                    Stock{' '}
                </p>{' '}
                <p className="mb-2">
                    <span className="font-medium">
                        When the trend is upward, it's a sign we're pushing our
                        teams too hard.
                    </span>{' '}
                    We strive for a healthy and sustainable operation, which
                    means keeping our 'stock' levels low.
                </p>{' '}
                <p className="mb-2">
                    Here, you'll see we may have pushed the teams a bit too
                    much, but don't worry — we've dialed it back to an
                    acceptable level.
                </p>{' '}
            </div>
        </div>
    );
};

export const stockThirdStep = () => {
    const isDemoOrgOnly = localStorage.getItem('isDemoOrgOnly');

    return (
        <div className="text-gray-500 leading-5">
            <div className="flex justify-center mb-4">
                <div className="relative">
                    <img
                        src={RichardAvatar}
                        alt="Richard Hendricks avatar"
                        width="100px"
                    />
                    <AudioPlayButton
                        audioFilePath={'../assets/audiotour/tour_16.mp3'}
                    />
                </div>
            </div>
            <p className=" text-center font-medium mb-4 ">
                {' '}
                Join Agile Analytics Community!{' '}
            </p>{' '}
            {isDemoOrgOnly === 'true' ? (
                <>
                    <p className="mb-2">That's a wrap on our tour!</p>{' '}
                    <p className="mb-2">
                        If you're ready to revolutionise your software
                        development management using real-time productivity and
                        risk insights, leveraging AI to level up empowerment and
                        engagement and drive success like never before, why
                        wait? Dive right in and create your own organisation in
                        Agile Analytics.
                    </p>
                    <p className="mb-2">
                        Using your own organisation in Agile Analytics you’ll be
                        able to measure your own production services and link
                        these services to your own teams and their code
                        repositories. Using Error Budgets, Service Level
                        Objectives and Sprint Insights you’ll be able to see
                        real performance and risk data all Engineering Managers
                        and Agile Coaches need.
                    </p>{' '}
                    <p className="mb-2">
                        Plus, here's the cherry on top –
                        <span className="font-medium">
                            ZEN Software offers you a generous 60-day free trial
                            to get you started with Agile Analytics and their
                            talented Agile Coaches will make sure your teams are
                            set up in no time!
                        </span>
                        .
                    </p>
                    <p className="mb-2">
                        So, don't hesitate, seize the opportunity, and embark on
                        your journey to data-driven greatness today!
                    </p>{' '}
                    <Link to={`${NAVIGATION_PATHS.createOrganisation}`}>
                        <span className="inline-block h-12 mx-auto mt-4 px-1 py-2 bg-theme-tertiary rounded text-white font-display leading-6 font-medium flex justify-center items-center">
                            Create your own organisation
                        </span>
                    </Link>
                </>
            ) : (
                <>
                    <p className="mb-2">That's a wrap on our tour!</p>{' '}
                    <p className="mb-2">
                        If you're ready to revolutionise your software
                        development management using real-time productivity and
                        risk insights, leveraging AI to level up empowerment and
                        engagement and drive success like never before, why
                        wait? Dive right in and create your own organisation in
                        Agile Analytics.
                    </p>
                    <p className="mb-2">
                        Using your own organisation in Agile Analytics you’ll be
                        able to measure your own production services and link
                        these services to your own teams and their code
                        repositories. Using Error Budgets, Service Level
                        Objectives and Sprint Insights you’ll be able to see
                        real performance and risk data all Engineering Managers
                        and Agile Coaches need.
                    </p>{' '}
                    <p className="mb-2">
                        Plus, here's the cherry on top –
                        <span className="font-medium">
                            ZEN Software offers you a generous 60-day free trial
                            to get you started with Agile Analytics and their
                            talented Agile Coaches will make sure your teams are
                            set up in no time!
                        </span>
                        .
                    </p>
                    <p className="mb-2">
                        So, don't hesitate, seize the opportunity, and embark on
                        your journey to data-driven greatness today!
                    </p>{' '}
                    {/* <Link to={`${NAVIGATION_PATHS.createOrganisation}`}>
                        <span className="inline-block h-12 mx-auto mt-4 px-1 py-2 bg-theme-tertiary rounded text-white font-display leading-6 font-medium flex justify-center items-center">
                            Create your own organisation
                        </span>
                    </Link> */}
                    <a
                        href="https://www.agileanalytics.cloud/contact"
                        className="inline-block h-12 mx-auto mt-4 px-1 py-2 bg-theme-tertiary rounded text-white font-display leading-6 font-medium flex justify-center items-center"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Contact us
                    </a>
                </>
            )}
        </div>
    );
};

export const TourGuideSteps = [
    {
        selector: '[data-tour="r-step-page-1"]',
        content: overviewFirstStep,
    },
    // {
    //     selector: '[data-tour="r-step-page-1"]',
    //     content: overviewSecondStep,
    // },
    {
        selector: '[data-tour="r-step-page-1-1"]',
        content: teamsFirstStep,
    },
    {
        selector: '[data-tour="r-step-page-2"]',
        content: kudosFirstStep,
    },
    {
        selector: '[data-tour="r-step-page-3"]',
        content: kudosSecondStep,
    },
    {
        selector: '[data-tour="r-step-page-3-1"]',
        content: kudosThirdStep,
    },
    {
        selector: '[data-tour="r-step-page-4"]',
        content: sreFirstStep,
    },
    {
        selector: '[data-tour="r-step-page-4"]',
        content: sreSecondStep,
    },
    {
        selector: '[data-tour="r-step-page-5"]',
        content: sprintInsightsFirstStep,
    },
    {
        selector: '[data-tour="r-step-page-8"]',
        content: leaksFirstStep,
    },
    {
        selector: '[data-tour="r-step-page-9"]',
        content: leaksSecondStep,
    },
    {
        selector: '[data-tour="r-step-page-10"]',
        content: stockFirstStep,
    },
    {
        selector: '[data-tour="r-step-page-10"]',
        content: stockSecondStep,
    },
    {
        selector: '[data-tour="r-step-page-11"]',
        content: stockThirdStep,
    },
    {
        selector: '[data-tour="r-step-page-12"]',
        content: doraFirstStep,
    },
    {
        selector: '[data-tour="r-step-page-13"]',
        content: doraSecondStep,
    },
];
