import React from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { customStylesSelect } from './selectStyles';
import PanelTitle from '../../../panel-title';

const LeaderbordHeader = ({
    setActiveTeam,
    selectedTeam,
    localTeamsState,
    isInCard,
}) => {
    const { t } = useTranslation();

    const transformTeamList = () => {
        return localTeamsState.data.map((team) => {
            return {
                value: team.team_hash,
                label: team.team_name,
            };
        });
    };

    const getSelectedTeam = transformTeamList().find(
        (team) => team.value === selectedTeam
    );

    if (localTeamsState.data.length === 1) {
        return (
            <div className="flex justify-between items-center">
                {isInCard ? (
                    <p className="font-display inline-block text-28 text-gray-700 font-light mb-4">
                        Team's Kudos{' '}
                        {t('kudos_page.leaderboard.leaderboard.title')}
                    </p>
                ) : (
                    <PanelTitle
                        title={t('kudos_page.leaderboard.leaderboard.title')}
                        underlineColorType="tertiary"
                        className="mb-0"
                    />
                )}
                {isInCard ? null : (
                    <p className="inline-block py-2 px-4 border border-solid border-gray-300 rounded font-display text-gray-600">
                        {localTeamsState.data[0].team_name}
                    </p>
                )}
            </div>
        );
    }
    return (
        <div className="flex justify-between items-center">
            <h6 className="font-display inline-block text-28 text-gray-700 font-light border-b-2 border-solid border-theme-tertiary">
                {t('kudos_page.leaderboard.leaderboard.title')}
            </h6>
            <Select
                options={transformTeamList()}
                styles={customStylesSelect}
                onChange={(event) => setActiveTeam(event.value)}
                defaultValue={getSelectedTeam}
                isSearchable={false}
            />
        </div>
    );
};

export default LeaderbordHeader;
