import React, { useCallback, useContext, useEffect, useState } from 'react';
import moment from 'moment';
import WhitePanelContentWrapper from '../components/white-panel-content-wrapper';
import KudosLeaderboard from '../components/kudos-leaderboard/leaderboard';
import { getEmoji } from '../api/settings/EmojiAPI';
import { getTeamKudosLeaderBoard, getTeams } from '../api/teams/TeamsAPI';
import { useFetchDataAndSetState } from '../helpers/useFetchDataAndSetState';
import DoraMetricsPanel from '../components/dora-metrics-panel';
import TeamSloAndErrorBudget from '../components/team-slo-and-error-budget';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { NAVIGATION_PATHS } from '../constants';
import GenericPdfDownloader from '../components/generic-pdf-downloader';
import { OrganisationsContext } from '../context/OrganisationsContext';
import { fetchUsersAndInsertPhotos } from '../helpers/fetchUserPhotos';
import { OrgUserListContext } from '../context/OrgUserListContext';

function TeamsSprintReportPage() {
    // TODO: fetch all teams pr the team id data
    let params = useParams();
    const { orgId, teamId, sprintStart, sprintEnd } = params;
    const { organisationsState, organisationsDispatch } =
        useContext(OrganisationsContext);

    const kudosRequest = useCallback(() => getEmoji(), []);
    const leaderboardRequest = useCallback(
        () =>
            getTeamKudosLeaderBoard(teamId, {
                params: { date_start: sprintStart, date_end: sprintEnd },
            }),
        [teamId, sprintStart, sprintEnd]
    );

    const [kudosLeaderBoardState] = useFetchDataAndSetState(
        leaderboardRequest,
        [teamId, sprintStart, sprintEnd],
        {
            total_kudos: 0,
            team_members_list: [],
            last_kudo: {},
        }
    );

    const [kudosState] = useFetchDataAndSetState(kudosRequest, [teamId]);

    // DORA states

    const teamsRequest = useCallback(() => getTeams(), []);
    const usersRequest = useCallback(() => fetchUsersAndInsertPhotos(), []);
    const [teamsState] = useFetchDataAndSetState(teamsRequest, [orgId]);
    const [usersState] = useFetchDataAndSetState(usersRequest, [orgId]);

    const [teamData, setTeamData] = useState(null);

    // DEPLOYMENT FREQUENCY: GET, FORMAT AND FILTER DATA
    const deploymentFreqRequest = useCallback(
        () =>
            getDeploymentFrequencyData({
                params: { date_start: sprintStart, date_end: sprintEnd },
            }),
        [date_end, date_start]
    );
    const [deploymentFreqState] = useFetchDataAndSetState(
        deploymentFreqRequest,
        [selectedOrganisationHash, sprintStart, sprintEnd]
    );

    const cardTimeperiod = { start: sprintStart, end: sprintEnd };

    useEffect(() => {
        if (localStorage.getItem('startUrl')) {
            localStorage.removeItem('startUrl');
        }
        if (
            organisationsState?.data?.length &&
            !organisationsState?.data?.find(
                (org) => org.org_hash === orgId && org.active
            )
        ) {
            const orgList = organisationsState?.data.map((org) =>
                org.org_hash === orgId
                    ? { ...org, active: true }
                    : { ...org, active: false }
            );
            organisationsDispatch({
                type: 'SET_DATA',
                payload: orgList,
            });
        }
    }, []);

    useEffect(() => {
        if (teamsState?.data?.length) {
            const currentTeam = teamsState?.data?.find(
                (team) => team?.team_hash === teamId
            );
            setTeamData(currentTeam);
        }
    }, [teamsState]);

    localStorage.setItem('orgHash', orgId);

    if (teamsState?.loading) {
        return <div>LOADING</div>;
    }
    if (!teamData) {
        return <div>NO TEAM DATA</div>;
    }

    return (
        <>
            <div className="flex justify-between items-center    text-gray-700 mb-8">
                <Link
                    to={NAVIGATION_PATHS.teams}
                    className="text-theme-tertiary underline"
                >
                    <span>Back to Teams</span>
                </Link>
                <GenericPdfDownloader
                    downloadFileName={`report-${teamId}-${sprintStart}-${sprintEnd}`}
                    rootElementId={`report-${teamId}-${sprintStart}-${sprintEnd}`}
                />
            </div>

            <WhitePanelContentWrapper
                // id={`report-${teamId}-${sprintStart}-${sprintEnd}`}
                className="px-0 py-0"
            >
                <div
                    id={`report-${teamId}-${sprintStart}-${sprintEnd}`}
                    className="py-2.5 px-5"
                >
                    <div className="flex justify-between items-center    text-gray-700 mb-4">
                        <h1 className="text-4xl font-display font-semibold">
                            {teamData?.team_name} sprint report
                        </h1>
                        <p className="text-2xl font-display font-semibold">
                            <span>
                                {moment
                                    .unix(sprintStart)
                                    .format('DD MMMM YYYY')}
                            </span>{' '}
                            -{' '}
                            <span>
                                {moment.unix(sprintEnd).format('DD MMMM YYYY')}
                            </span>
                        </p>
                    </div>
                    <div className="grid grid-cols-3 gap-4">
                        <KudosLeaderboard
                            teamsState={{ data: [teamData] }}
                            usersState={usersState}
                            selectedTeam={teamId}
                            localTeamsState={{ data: [teamData] }}
                            kudosState={kudosState}
                            kudosLeaderBoardState={kudosLeaderBoardState}
                            isInCard={true}
                        />
                        <div className="col-span-2 rounded p-4 border border-solid border-gray-300">
                            <p className="font-display inline-block text-28 text-gray-700 font-light">
                                Team's DORA Metrics
                            </p>
                            <DoraMetricsPanel
                                allRepos={teamData?.team_repositories}
                                reposLoading={teamData?.team_repositories}
                                orgHash={orgId}
                                isInCard={true}
                                cardTimeperiod={cardTimeperiod}
                                deploymentFreqState={deploymentFreqState}
                            />
                        </div>
                    </div>
                    <TeamSloAndErrorBudget
                        teamServices={teamData?.team_services}
                        cardTimeperiod={cardTimeperiod}
                    />
                </div>
            </WhitePanelContentWrapper>
        </>
    );
}

const setCustomStyle = () => ({
    control: (provided) => ({
        ...provided,
        padding: '2px 0',
        width: '300px',
        outline: 'none',
        boxShadow: 'none',
        backgroundColor: 'transparent',
        opacity: '1',
        height: '42px',
    }),
    singleValue: (provided) => ({
        ...provided,
        color: '#484a53',
    }),
    indicatorsContainer: (provided) => ({
        ...provided,
        opacity: '1',
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        opacity: '1',
        cursor: 'pointer',
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        opacity: '1',
    }),
    menu: (provided) => ({
        ...provided,
        marginTop: '0',
        padding: '0',
    }),
    valueContainer: (provided) => ({
        ...provided,
        height: '100%',
        flexWrap: 'no-wrap',
    }),
    option: (provided, state) => ({
        ...provided,
        cursor: 'pointer',
        color: '#484a53',
        backgroundColor: 'transparent',
        '&:hover': {
            backgroundColor: state.isSelected
                ? '#4C72BD'
                : 'rgba(76, 114, 189, 0.5)',
        },
    }),
});

export default TeamsSprintReportPage;
