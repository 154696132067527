import React, { useState, useContext, useEffect, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { TimePeriodContext } from '../../context/TimePeriodContext';
import TimeSelectorNav from './navs';
import {
    TimeSelectorTabQuick,
    TimeSelectorTabSprints,
    TimeSelectorTabCalendar,
} from './tabs';
import moment from 'moment';
import classnames from 'classnames';
import { CalendarIcon } from '../../assets/svg';
import './styles.scss';
import { capitalizeFirstLetter } from '../../helpers/textFormatters';
import { TIMERANGE_SELECTOR_PARAMS } from '../../constants';

const TimeRangeSelectorToggler = forwardRef(
    ({ timeRangeSelectorStatus, setTimeRangeSelectorStatus }, buttonRef) => {
        const [timePeriodState] = useContext(TimePeriodContext);
        return (
            <button
                ref={buttonRef}
                className={classnames(
                    'time-range-selector-toggler flex gap-x-2 items-center mr-8 px-1 py-2 bg-theme-tertiary rounded text-white font-display leading-6 font-medium	',
                    {
                        active: timeRangeSelectorStatus,
                    }
                )}
                onClick={() =>
                    setTimeRangeSelectorStatus(!timeRangeSelectorStatus)
                }
            >
                {timePeriodState.buttonValue}
                <CalendarIcon fill={'#fff'} />
            </button>
        );
    }
);

const TimeRangeSelector = forwardRef(
    ({ timeRangeSelectorStatus, isTour, setIsTour }, ref) => {
        const { t } = useTranslation();

        const [timePeriodState, timePeriodDispatch] =
            useContext(TimePeriodContext);

        const [activeTab, setActiveTab] = useState('quick');

        const [activeQuickSelect, setActiveQuickSelect] = useState(2);
        const [activeTimePeriod, setActiveTimePeriod] = useState(2);

        const [buttonValue, setButtonValue] = useState(
            timePeriodState.buttonValue
        );

        useEffect(() => {
            const timectx = localStorage?.getItem('timectx');

            if (
                timectx &&
                JSON.parse(timectx)?.date_start &&
                JSON.parse(timectx)?.date_end
            ) {
                const parsedTimectx = JSON.parse(timectx);
                if (
                    parsedTimectx?.index &&
                    parsedTimectx?.index !== 'calendar'
                ) {
                    setActiveQuickSelect(
                        TIMERANGE_SELECTOR_PARAMS[+parsedTimectx?.index - 1]
                            ?.index
                    );
                    setActiveTimePeriod(
                        TIMERANGE_SELECTOR_PARAMS[+parsedTimectx?.index - 1]
                            ?.index
                    );
                }
                if (
                    parsedTimectx?.index &&
                    parsedTimectx?.index === 'calendar'
                ) {
                    setActiveQuickSelect(null);
                    setActiveTimePeriod(null);
                }
            }
        }, []);

        useEffect(() => {
            if (isTour) {
                setQuickTimeRange(3, 'months', 4);
            }
            if (
                isTour &&
                buttonValue ===
                    `${capitalizeFirstLetter(
                        t('header.time_period.last')
                    )} 3 ${t('header.time_period.months')}`
            ) {
                setGlobalTimePeriod();
                setIsTour(false);
            }
        }, [isTour, buttonValue]); //  3month timeperiod for tourguide

        const getDateFromUnix = (date) => new Date(moment.unix(date));

        const getDateInUnix = (date) => moment(date).unix();

        const setQuickTimeRange = (quantity, value, index) => {
            if (index !== activeQuickSelect) {
                const date_end = moment().set({
                    hours: 23,
                    minutes: 59,
                    seconds: 59,
                });
                const date_start = moment()
                    .set({ hours: 0, minutes: 0, seconds: 0 })
                    .subtract(quantity, value);

                const buttonValue = `${capitalizeFirstLetter(
                    t('header.time_period.last')
                )} ${
                    value === 'days'
                        ? `${quantity + 1} ${capitalizeFirstLetter(
                              t('header.time_period.days')
                          )}`
                        : `${quantity} ${capitalizeFirstLetter(
                              t('header.time_period.months')
                          )}`
                }`;
                setActiveQuickSelect(index);
                setButtonValue(buttonValue);
                setCalendarStartDate(new Date(date_start));
                setCalendarEndDate(new Date(date_end));
            }
        };

        const setGlobalTimePeriod = () => {
            let startDateInUnix = getDateInUnix(calendarStartDate);
            let endDateInUnix = getDateInUnix(calendarEndDate);
            setActiveTimePeriod(activeQuickSelect);
            if (activeTab === 'calendar') {
                setActiveQuickSelect(null);
                setActiveTimePeriod(null);
            } else {
                setActiveTimePeriod(activeQuickSelect);
            }
            const timePeriod = {
                date_start: startDateInUnix,
                date_end: endDateInUnix,
                buttonValue,
            };

            localStorage.setItem(
                'timectx',
                JSON.stringify({
                    ...timePeriod,
                    index:
                        activeTab === 'quick' ? activeQuickSelect : activeTab,
                })
            );
            timePeriodDispatch({ type: 'SET_TIME_RANGE', payload: timePeriod });
        };

        const [calendarStartDate, setCalendarStartDate] = useState(
            getDateFromUnix(timePeriodState.date_start)
        );

        const [calendarEndDate, setCalendarEndDate] = useState(
            getDateFromUnix(timePeriodState.date_end)
        );

        const onCalendarChange = (dates) => {
            const [start, end] = dates;
            const buttonValue = `${moment(start).format(
                'DD MMM YYYY'
            )} - ${moment(end).format('DD MMM YYYY')}`;

            setActiveQuickSelect(null);
            setCalendarStartDate(start);
            setCalendarEndDate(end && end.setHours(23, 59, 59));
            setButtonValue(buttonValue);
        };

        const toggle = (tab) => {
            if (activeTab !== tab) setActiveTab(tab);
        };

        useEffect(() => {
            let canceled = false;
            if (!canceled) {
                setCalendarStartDate(
                    getDateFromUnix(timePeriodState.date_start)
                );
                setCalendarEndDate(getDateFromUnix(timePeriodState.date_end));
            }

            return () => {
                canceled = true;
            };
        }, [timePeriodState.date_end, timePeriodState.date_start]);

        const areDisabled = () => {
            let isActiveTimePeriod = false;
            if (activeTimePeriod !== null && activeQuickSelect !== null) {
                isActiveTimePeriod = activeTimePeriod === activeQuickSelect;
            }

            return !calendarEndDate || isActiveTimePeriod;
        };

        return (
            <div
                className={`absolute z-40 right-0 time-period-wrapper ${
                    timeRangeSelectorStatus ? 'is-open' : null
                }`}
                ref={ref}
            >
                <TimeSelectorNav toggle={toggle} activeTab={activeTab} />
                {activeTab === 'quick' && (
                    <TimeSelectorTabQuick
                        activeQuickSelect={activeQuickSelect}
                        setQuickTimeRange={setQuickTimeRange}
                    />
                )}
                {/* {activeTab === 'sprints' && (
                    <TimeSelectorTabSprints
                        setCalendarStartDate={setCalendarStartDate}
                        setCalendarEndDate={setCalendarEndDate}
                        onCalendarChange={onCalendarChange}
                    />
                )} */}
                {activeTab === 'calendar' && (
                    <TimeSelectorTabCalendar
                        onCalendarChange={onCalendarChange}
                        calendarStartDate={calendarStartDate}
                        calendarEndDate={calendarEndDate}
                    />
                )}
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <button
                        className="set-time-period-button"
                        disabled={areDisabled()}
                        onClick={() => setGlobalTimePeriod()}
                    >
                        {capitalizeFirstLetter(t('button.set'))}
                    </button>
                </div>
            </div>
        );
    }
);

export { TimeRangeSelectorToggler, TimeRangeSelector };
