import React, {
    useContext,
    useState,
    useCallback,
    useRef,
    useEffect,
} from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { OrganisationsContext } from '../../../context/OrganisationsContext';
import Loader from '../../loader';
import ErrorIndicator from '../../error-indicator';
import { Copy, Trash } from '../../../assets/svg.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Rodal from 'rodal';
import { useFetchDataAndSetState } from '../../../helpers/useFetchDataAndSetState';
import showNotification from '../../../helpers/showNotification';

import 'rodal/lib/rodal.css';
import {
    deleteApiKey,
    generateApiKey,
    getApiKey,
    updateApiKey,
} from '../../../api/settings/ApiKeysAPI';
import WhitePanelContentWrapper from '../../white-panel-content-wrapper';
import PanelTitle from '../../panel-title';
import { getAdminPermission } from '../../../helpers/getPermission';
import {
    capitalizeEveryWordFirstLetter,
    capitalizeFirstLetter,
} from '../../../helpers/textFormatters';

const ApiKeyTab = () => {
    const { t } = useTranslation();
    const { organisationsState } = useContext(OrganisationsContext);

    const selectedOrgHash = organisationsState.data
        ? organisationsState.data.find((org) => org.active).org_hash
        : null;

    const request = useCallback(() => getApiKey(), []);

    const [apiKeyState, apiKeyDispatch] = useFetchDataAndSetState(request, [
        selectedOrgHash,
    ]);

    const [isKeySingle, setIsKeySingle] = useState(false);
    const [permissionForWrite, setPermissionForWrite] = useState(false);

    useEffect(() => {
        setPermissionForWrite(getAdminPermission(organisationsState?.data));
    }, [organisationsState]);

    useEffect(() => {
        apiKeyState.data && Object.keys(apiKeyState.data).length === 1
            ? setIsKeySingle(true)
            : setIsKeySingle(false);
    }, [apiKeyState]);

    const createNewKey = () => {
        apiKeyDispatch({ type: 'SET_LOADING' });
        generateApiKey()
            .then((response) => {
                const newList = { ...apiKeyState.data };
                newList[response.data] = 'valid';
                apiKeyDispatch({ type: 'SET_DATA', payload: newList });
            })
            .catch((error) => {
                apiKeyDispatch({ type: 'SET_ERROR', payload: error });
            });
    };

    const createKeysList = () => {
        const KeysList = [];

        for (let key in apiKeyState.data) {
            KeysList.push(
                <ApiKeyItem
                    permissionForWrite={permissionForWrite}
                    apiKeyState={apiKeyState}
                    apiKeyDispatch={apiKeyDispatch}
                    key={key}
                    data={key}
                    status={apiKeyState.data[key]}
                    coppyNotify={coppyNotify}
                    draggable={false}
                    single={isKeySingle}
                />
            );
        }

        if (KeysList.length === 0) {
            return <p className="no-value">No keys</p>;
        }
        return KeysList;
    };

    const coppyNotify = () => toast.success('Copied successfully!');

    if (apiKeyState.loading) {
        return (
            <div className="settings-tab-content api-key-tab flex justify-center">
                <Loader />
            </div>
        );
    }

    if (apiKeyState.error) {
        return (
            <div className="settings-tab-content api-key-tab">
                <ErrorIndicator error={apiKeyState.error} />
            </div>
        );
    }

    return (
        <WhitePanelContentWrapper className="settings-tab-content api-key-tab">
            <PanelTitle
                title={capitalizeFirstLetter(t('settings_page.key_tab.name'))}
            />

            <p className="description">
                {capitalizeFirstLetter(t('settings_page.key_tab.info'))}
            </p>
            <div className="keys-list">
                <p className="keys-list__title">
                    {capitalizeEveryWordFirstLetter(
                        t('settings_page.key_tab.list')
                    )}
                </p>
                {createKeysList()}
            </div>

            {permissionForWrite ? (
                <div className="add-new-key">
                    <p className="add-new-key__title">
                        {capitalizeFirstLetter(
                            t('settings_page.key_tab.create')
                        )}
                    </p>
                    {Object.keys(apiKeyState.data)?.length < 10 ? (
                        <button
                            className="add-new-key__button"
                            onClick={() => createNewKey()}
                        >
                            {capitalizeFirstLetter(
                                t('settings_page.key_tab.generate')
                            )}
                        </button>
                    ) : (
                        <p className="font-display text-gray-500">
                            {capitalizeFirstLetter(
                                t('settings_page.key_tab.max_warning')
                            )}
                        </p>
                    )}
                </div>
            ) : null}

            <ToastContainer
                position="bottom-right"
                hideProgressBar={true}
                pauseOnHover={false}
                autoClose={3000}
                limit={5}
                closeButton={false}
            />
        </WhitePanelContentWrapper>
    );
};

const ApiKeyItem = ({
    data,
    status,
    coppyNotify,
    permissionForWrite,
    apiKeyState,
    apiKeyDispatch,
    single,
}) => {
    const { t } = useTranslation();
    const [apiKeyStatus, setApiKeyStatus] = useState({
        loading: {
            update: false,
            delete: false,
        },
        error: false,
        status: status,
    });

    const statusInput = useRef(null);

    const [modalState, setModalState] = useState(false);

    const statusOptions = [
        {
            value: 'valid',
            label: t('settings_page.key_tab.active'),
        },
        {
            value: 'invalid',
            label: t('settings_page.key_tab.inactive'),
        },
    ];

    const changeStatus = () => {
        setApiKeyStatus((state) => ({
            ...state,
            loading: { update: true, delete: false },
        }));

        const requestData = {
            api_key: data,
            status: apiKeyStatus.status,
        };

        updateApiKey(requestData)
            .then(() => {
                setApiKeyStatus((state) => ({
                    ...state,
                    loading: { update: false, delete: false },
                }));
            })
            .catch((error) => {
                setApiKeyStatus((state) => ({
                    ...state,
                    loading: { update: false, delete: false },
                    error: error,
                }));
            });
    };

    const deleteKey = (key) => {
        const params = {
            params: {
                api_key: key,
            },
        };

        setApiKeyStatus((state) => ({
            ...state,
            loading: { update: false, delete: true },
        }));

        deleteApiKey(params)
            .then(() => {
                const newList = apiKeyState.data;
                delete newList[key];
                apiKeyDispatch((state) => ({
                    ...state,
                    loading: { update: false, delete: false },
                }));
                apiKeyDispatch({ type: 'SET_DATA', payload: newList });
            })
            .catch((error) => {
                apiKeyDispatch((state) => ({
                    ...state,
                    loading: { delete: false, update: false },
                    error: error,
                }));
                apiKeyDispatch({ type: 'SET_ERROR', payload: error });
            });
    };

    const copyToClipboard = (e) => {
        statusInput.current.select();
        document.execCommand('copy');
        e.target.focus();
        coppyNotify();
    };

    return (
        <div className="input-wrapper">
            <input
                type="text"
                ref={statusInput}
                className="settings-input"
                readOnly
                value={data}
                title={data}
            />
            <button className="copy-api-key" onClick={copyToClipboard}>
                <Copy width="20px" height="20px" />
            </button>
            <>
                {permissionForWrite ? (
                    <>
                        <div
                            onClick={() =>
                                single &&
                                showNotification(
                                    'Cannot disable the API key as it is the only one',
                                    'error'
                                )
                            }
                        >
                            <Select
                                isSearchable={false}
                                isDisabled={single}
                                options={statusOptions}
                                styles={setCustomStyle()}
                                value={statusOptions.find(
                                    (option) =>
                                        option.value === apiKeyStatus.status
                                )}
                                onChange={(option) => {
                                    setApiKeyStatus({
                                        loading: false,
                                        error: null,
                                        status: option.value,
                                    });

                                    option.value === 'invalid' &&
                                        showNotification(
                                            capitalizeFirstLetter(
                                                t(
                                                    'settings_page.key_tab.disable_warning'
                                                )
                                            )
                                        );
                                }}
                            />
                        </div>
                        <button
                            className="save-status"
                            disabled={apiKeyStatus.status === status}
                            onClick={() => changeStatus()}
                        >
                            {capitalizeFirstLetter(t('button.save'))}
                        </button>
                    </>
                ) : (
                    <p className="single-value">
                        {
                            statusOptions.find(
                                (option) => option.value === apiKeyStatus.status
                            ).label
                        }
                    </p>
                )}
            </>
            {permissionForWrite ? (
                <button
                    className={`delete-api-key ${single && 'filter grayscale'}`}
                    onClick={() => setModalState(true)}
                >
                    <Trash fill="#b21f2d" width="20" height="20" />
                </button>
            ) : (
                <div style={{ width: '42px' }}></div>
            )}

            <div className="status">
                {apiKeyStatus.loading.update ? (
                    <Loader color={'#C2C7D7'} size={35} speedMultiplier={0.8} />
                ) : null}
            </div>
            <Rodal
                visible={modalState}
                height={160}
                onClose={() => setModalState(false)}
            >
                <div className="modal-content">
                    {single ? (
                        <p className="modal-title">
                            {capitalizeFirstLetter(
                                t('settings_page.key_tab.delete_warning')
                            )}
                        </p>
                    ) : (
                        <p className="modal-title">
                            {capitalizeFirstLetter(
                                t('settings_page.key_tab.sure')
                            )}
                        </p>
                    )}
                    {apiKeyStatus.loading.delete ? (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                marginTop: '12px',
                            }}
                        >
                            <Loader
                                color={'#C2C7D7'}
                                size={35}
                                speedMultiplier={0.8}
                            />
                        </div>
                    ) : null}
                    {single ? (
                        <div className="buttons-wrapper">
                            <button
                                className="delete"
                                onClick={() => setModalState(false)}
                            >
                                Ok
                            </button>
                        </div>
                    ) : (
                        <div className="buttons-wrapper">
                            <button
                                className="delete"
                                onClick={() => deleteKey(data)}
                            >
                                {capitalizeFirstLetter(
                                    t('settings_page.key_tab.yes')
                                )}
                            </button>
                            <button
                                className="close"
                                onClick={() => setModalState(false)}
                            >
                                {capitalizeFirstLetter(
                                    t('settings_page.key_tab.no')
                                )}
                            </button>
                        </div>
                    )}
                </div>
            </Rodal>
        </div>
    );
};

const setCustomStyle = () => ({
    control: (provided) => ({
        ...provided,
        padding: '2px 0',
        width: '125px',
        outline: 'none',
        boxShadow: 'none',
        backgroundColor: 'transparent',
        opacity: '1',
        height: '42px',
        borderColor: '#484a53',
        '&:hover': {
            opacity: '1',
        },
    }),
    singleValue: (provided) => ({
        ...provided,
        color: '#484a53',
    }),
    indicatorsContainer: (provided) => ({
        ...provided,
        color: '#484a53',
        opacity: '1',
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        color: '#484a53',
        opacity: '1',
        cursor: 'pointer',
        '&:hover': {
            color: '#484a53',
        },
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        backgroundColor: '#484a53',
        opacity: '1',
    }),
    menu: (provided) => ({
        ...provided,
        marginTop: '0',
        padding: '0',
    }),
    valueContainer: (provided) => ({
        ...provided,
        height: '100%',
        flexWrap: 'no-wrap',
    }),
    option: (provided, state) => ({
        ...provided,
        cursor: 'pointer',
        color: '#484a53',
        backgroundColor: 'transparent',
        '&:hover': {
            backgroundColor: state.isSelected
                ? '#4C72BD'
                : 'rgba(76, 114, 189, 0.5)',
        },
    }),
});

export default ApiKeyTab;
