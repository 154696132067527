export const replaceSpacesToUnderscores = (name, isLowerCase) => {
    if (isLowerCase) {
        return name.toLowerCase().replace(/\s/g, '_');
    }

    return name.replace(/\s/g, '_');
};

export const replaceUnderscoresToSpaces = (name, isLowerCase) => {
    if (isLowerCase) {
        return name.toLowerCase().replace(/_/g, ' ');
    }

    return name.replace(/_/g, ' ');
};
