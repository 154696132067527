import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useParamsQuery from '../../hooks/useParamsQuery';
import AuthPage from '../auth-page';

export default function InviteAuthPage() {
    const { t } = useTranslation();
    const query = useParamsQuery();
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        const error = query.get('error');
        const org = query.get('org');
        const role = query.get('role');
        const email = query.get('email');

        if (error) {
            setErrorMessage('User already exists, please login');
            localStorage.removeItem('firstSignupSession');
            localStorage.removeItem('firstDemoSession');
        }

        if (!email || !org) {
            setErrorMessage(
                'Invalid invite link. Please try again or contact you admin.'
            );
        }

        if (email) {
            localStorage.setItem('email', email);
        }
        if (org) {
            localStorage.setItem('inviteOrg', org);
        }
        if (role) {
            localStorage.setItem('inviteRole', role);
        } else {
            localStorage.setItem('inviteRole', 'read');
        }
    }, [query]);

    return <AuthPage type="invite" errorMessageFromParent={errorMessage} />;
}
