import React, { Fragment } from 'react';
import data from '@emoji-mart/data';
import { init } from 'emoji-mart';
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';
import PanelTitle from '../../panel-title';
import Loader from '../../loader';

init({ data });

const KudosLast = ({ usersState, kudosState, kudosLeaderBoardState }) => {
    const { t } = useTranslation();

    const createFromToTitle = () => {
        const fromUser = usersState.data.find(
            (user) => user.hash === kudosLeaderBoardState.data.last_kudo.from
        );
        const from = fromUser ? fromUser.user_name : '';
        const toUser = usersState.data.find(
            (user) => user.hash === kudosLeaderBoardState.data.last_kudo.to
        );
        const to = toUser ? toUser.user_name : '';

        return (
            <Trans i18nKey="kudos_page.leaderboard.latest_kudos.from-to">
                <p className="font-medium leading-4.5 text-gray-600 mb-2">
                    {' '}
                    From <span className="person from">{{ from }}</span> to
                    <span className="person to">{{ to }}</span>
                </p>
            </Trans>
        );
    };

    const createChannelTitle = () => {
        const channel = kudosLeaderBoardState.data.last_kudo.channel;

        return (
            <Trans i18nKey="kudos_page.leaderboard.latest_kudos.channel">
                <span className="">{{ channel }}</span>
            </Trans>
        );
    };

    const getEmojisFromMessage = () => {
        let message = kudosLeaderBoardState.data.last_kudo.message
            ? kudosLeaderBoardState.data.last_kudo.message
            : '';
        let emojiInMessageQuantity = kudosLeaderBoardState.data.last_kudo.amount
            ? kudosLeaderBoardState.data.last_kudo.amount
            : '0';
        const regex = /:[^\s]*:/gm;
        const emojiInMessageArray = Array.from(message.matchAll(regex));

        const listOfEmojis = kudosState.data.filter((emoji) => {
            return emojiInMessageArray.findIndex((e) => e[0] === emoji);
        });

        return (
            <>
                <p className="font-display text-green-500 text-40">
                    +{emojiInMessageQuantity}
                </p>
                <div>
                    {listOfEmojis.map((emoji) => (
                        <em-emoji
                            id={emoji}
                            key={emoji}
                            size={40}
                            set="google"
                        ></em-emoji>
                    ))}
                </div>
            </>
        );
    };

    const transformMessageWithEmoji = () => {
        let message = kudosLeaderBoardState.data.last_kudo.message
            ? kudosLeaderBoardState.data.last_kudo.message
            : '';
        const regex = /:[^\s]*:/gm;
        const emojiInMessage = message.matchAll(regex);
        const emojiInMessageArray = Array.from(message.matchAll(regex));
        const partOfStr = [];
        let strStart = 0;

        for (const emoji of emojiInMessage) {
            const strPart = message.slice(strStart, emoji.index);
            partOfStr.push(strPart);
            strStart = emoji.index + emoji[0].length + 1;
        }
        partOfStr.push(message.slice(strStart));

        const newmess = partOfStr.map((str, index) => {
            if (index === partOfStr.length - 1) {
                return str;
            } else {
                const emoji = emojiInMessageArray[index][0];
                return (
                    <Fragment key={`${index}${emoji}`}>
                        {str}
                        <em-emoji id={emoji} size={18} set="google"></em-emoji>
                    </Fragment>
                );
            }
        });
        return newmess;
    };

    if (
        kudosLeaderBoardState.loading ||
        kudosState.loading ||
        usersState.loading
    )
        return (
            <div className="">
                <div className="">
                    <PanelTitle
                        title={t('kudos_page.leaderboard.latest_kudos.title')}
                        underlineColorType={'secondary'}
                    />
                </div>
                <div className="mt-4 flex justify-center align-center">
                    <Loader color={'#C2C7D7'} size={35} speedMultiplier={0.8} />
                </div>
            </div>
        );

    if (kudosLeaderBoardState.error || kudosState.error || usersState.error) {
        if (kudosLeaderBoardState.error) {
            return (
                <div className="">
                    <div className="">
                        <h6 className="font-display inline-block text-28 text-gray-700 font-light border-b-2 border-solid border-theme-secondary">
                            {t('kudos_page.leaderboard.latest_kudos.title')}
                        </h6>
                    </div>
                    <div className="mt-4">
                        <p className="text-red-text">
                            Sorry, something went wrong. Can't get last
                            leaderboard data
                        </p>
                    </div>
                </div>
            );
        }
        if (kudosState.error) {
            return (
                <div className="">
                    <div className="">
                        <h6 className="font-display inline-block text-28 text-gray-700 font-light border-b-2 border-solid border-theme-secondary">
                            {t('kudos_page.leaderboard.latest_kudos.title')}
                        </h6>
                    </div>
                    <div className="mt-4">
                        <p className="text-red-text">
                            Sorry, something went wrong. Can't get emoji.
                        </p>
                    </div>
                </div>
            );
        }

        if (usersState.error) {
            return (
                <div className="">
                    <div className="">
                        <h6 className="font-display inline-block text-28 text-gray-700 font-light border-b-2 border-solid border-theme-secondary">
                            {t('kudos_page.leaderboard.latest_kudos.title')}
                        </h6>
                    </div>
                    <div className="mt-4 ">
                        <p className="text-red-text">
                            Sorry, something went wrong. Can't get users list.
                        </p>
                    </div>
                </div>
            );
        }
    }

    const getContent = () => {
        if (
            +kudosLeaderBoardState.data.total_kudos === 0 ||
            Object.keys(kudosLeaderBoardState.data.last_kudo).length === 0
        ) {
            return (
                <div className="mt-4 ">
                    <p className="font-display text-center text-gray-500 text-xl p-4">
                        {t('kudos_page.leaderboard.latest_kudos.no_messages')}
                    </p>
                </div>
            );
        }

        return (
            <div className="mt-4 ">
                <div className="flex justify-between items-center">
                    <div>
                        <p className="mb-1 font-medium leading-4.5 text-gray-600">
                            {createFromToTitle()}
                        </p>
                        <p className="font-display text-gray-500 text-sm">
                            {createChannelTitle()}
                        </p>
                    </div>
                    <div className="flex justify-between items-center gap-x-4">
                        {getEmojisFromMessage()}
                    </div>
                </div>
                <div className="min-h-95 mt-8 rounded border border-solid border-gray-300 py-2 px-4 overflow-auto">
                    <div className="message">
                        <p>{transformMessageWithEmoji()}</p>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="">
            <div className="">
                <h6 className="font-display inline-block text-28 text-gray-700 font-light border-b-2 border-solid border-theme-secondary">
                    {t('kudos_page.leaderboard.latest_kudos.title')}
                </h6>
            </div>
            {getContent()}
        </div>
    );
};

export default KudosLast;
