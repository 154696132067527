import { useState, useEffect } from 'react';
import debounce from 'lodash.debounce';

// Same as in Strapi (except xs)
// const screens =  {
//       'xs': {'max': '639px'},
//       'sm': {'min': '640px', 'max': '767px'},
//       'md': {'min': '768px', 'max': '1023px'},
//       'lg': {'min': '1024px', 'max': '1279px'},
//       'xl': {'min': '1280px', 'max': '1535px'},
//       '2xl': {'min': '1536px'},
//     }

const getDeviceConfig = (width) => {
    if (width < 640) {
        return 'xs';
    } else if (width >= 640 && width < 768) {
        return 'sm';
    } else if (width >= 768 && width < 1024) {
        return 'md';
    } else if (width >= 1024 && width < 1280) {
        return 'lg';
    } else if (width >= 1280 && width < 1536) {
        return 'xl';
    } else if (width >= 1536) {
        return '2xl';
    }
};

const useBreakpoint = () => {
    const [breakpoint, setBreakpoint] = useState(() =>
        getDeviceConfig(window.innerWidth)
    );

    useEffect(() => {
        const calcInnerWidth = debounce(function () {
            setBreakpoint(getDeviceConfig(window.innerWidth));
        }, 200);
        window.addEventListener('resize', calcInnerWidth);
        return () => window.removeEventListener('resize', calcInnerWidth);
    }, []);

    return breakpoint;
};
export default useBreakpoint;
