import React from 'react';

function CardHeader({ title, renderActionButtons }) {
    return (
        <div
            className={`flex justify-between relative bg-white px-4 py-2 rounded-t-lg`}
        >
            <div className={`text-2xl font-light text-gray-700`}>{title}</div>

            {renderActionButtons && (
                <div className="flex">{renderActionButtons()}</div>
            )}
        </div>
    );
}

export default CardHeader;
