import ReactDOMServer from 'react-dom/server';
import jsPDF from 'jspdf';
import logoAgileAnalytics from '../assets/logo-new-dark-with-text.png';

export function generateHtmlFile(invoiceData) {
    return (
        <div
            className=""
            style={{
                width: 446,
                height: 630,
                padding: 24,
                paddingTop: 36,
                fontFamily:
                    'Helvetica Neue, Helvetica, Helvetica, Arial, sans-serif',
            }}
        >
            <p
                className="uppercase "
                style={{
                    marginTop: 48,
                    fontSize: 28,
                    fontWeight: 800,
                }}
            >
                Invoice
            </p>{' '}
            <div
                className="grid grid-cols-3"
                style={{
                    width: 398,
                    marginTop: 36,
                    fontSize: 10,
                }}
            >
                <div className=" flex flex-col gap-2">
                    <p
                        style={{
                            fontWeight: 700,
                        }}
                    >
                        Bill from:
                    </p>
                    <p>
                        ZEN Software B.V.
                        <br /> Oosterweezenstraat 6-E
                        <br /> 1823CN Alkmaar
                        <br /> BTW: NL859532598B01
                        <br /> KvK: 73422614
                    </p>
                </div>
                <div className=" flex flex-col gap-2">
                    <p
                        style={{
                            fontWeight: 700,
                        }}
                    >
                        Bill to:
                    </p>
                    <p>{invoiceData?.to_addr?.company_name}</p>
                    <p>{invoiceData?.to_addr?.person_name}</p>
                    <p>{invoiceData?.to_addr?.person_email}</p>
                </div>
                <div className=" grid grid-cols-2 justify-start mb-auto gap-1">
                    <p style={{ fontWeight: 700 }}>Invoice #:</p>
                    <p>{invoiceData?.invoice_number}</p>
                    <p style={{ fontWeight: 700 }}>Invoice Date:</p>
                    <p>{invoiceData?.date}</p>
                    <p style={{ fontWeight: 700 }}>Due Date:</p>
                    <p>{invoiceData?.due_date}</p>
                    <p style={{ fontWeight: 700 }}>Status:</p>
                    <p>{invoiceData?.status}</p>
                </div>
            </div>
            <div
                className="grid grid-cols-12 bg-gray-100 border-t border-l border-r border-solid border-gray-400"
                style={{
                    width: 398,
                    marginTop: 24,
                    minHeight: 24,
                    fontSize: 10,
                    fontWeight: 800,
                }}
            >
                <div
                    className="col-span-1 flex items-center  border-r border-solid border-gray-400"
                    style={{ padding: 2, paddingLeft: 4 }}
                >
                    Qty
                </div>
                <div
                    className="col-span-7 flex items-center  border-r border-solid border-gray-400"
                    style={{ padding: 2, paddingLeft: 4 }}
                >
                    Title
                </div>
                <div
                    className="col-span-2 flex items-center  border-r border-solid border-gray-400"
                    style={{ padding: 2, paddingLeft: 4 }}
                >
                    Unit price
                </div>
                <div
                    className="col-span-2 flex items-center"
                    style={{ padding: 2, paddingLeft: 4 }}
                >
                    Amount
                </div>
            </div>
            {invoiceData?.items?.map((row, i) => {
                return (
                    <div
                        className={`grid grid-cols-12 border-gray-400 border-t border-l border-r ${
                            invoiceData?.items?.length === i + 1
                                ? 'border-b'
                                : ''
                        } border-solid `}
                        style={{
                            width: 398,
                            minHeight: 36,
                            fontSize: 10,
                        }}
                        key={i}
                    >
                        <div
                            className="col-span-1 flex items-center  border-r border-solid border-gray-400"
                            style={{ padding: 2, paddingLeft: 4 }}
                        >
                            {i + 1}
                        </div>
                        <div
                            className="col-span-7 flex items-center  border-r border-solid border-gray-400"
                            style={{ padding: 2, paddingLeft: 4 }}
                        >
                            {row.title}
                        </div>
                        <div
                            className="col-span-2 flex items-center  border-r border-solid border-gray-400"
                            style={{ padding: 2, paddingLeft: 4 }}
                        >
                            {row.price}
                        </div>
                        <div
                            className="col-span-2 flex items-center"
                            style={{ padding: 2, paddingLeft: 4 }}
                        >
                            {(row.price * row.units).toFixed(2)}
                        </div>
                    </div>
                );
            })}
            <div
                className="grid grid-cols-12"
                style={{
                    width: 398,
                    minHeight: 24,
                    fontSize: 10,
                }}
            >
                <div
                    className="col-span-10 flex items-center  border-r border-solid border-gray-400 uppercase ml-auto"
                    style={{
                        padding: 2,
                        paddingRight: 16,
                        fontWeight: 700,
                        fontSize: 12,
                    }}
                >
                    Total
                </div>
                <div
                    className="col-span-2 flex items-center border-r border-b border-solid border-gray-400"
                    style={{ padding: 2, paddingLeft: 4 }}
                >
                    €{invoiceData?.total}{' '}
                </div>
            </div>
        </div>
    );
}

export const exportInvoicePDF = (invoiceData) => {
    const htmlString = ReactDOMServer.renderToString(
        generateHtmlFile(invoiceData)
    );
    const doc = new jsPDF({ unit: 'px', format: 'a4' });

    doc.html(htmlString, {
        callback: function (doc) {
            const logo = new Image();
            logo.src = logoAgileAnalytics;

            logo.onload = function () {
                doc.addImage(this, 'PNG', 24, 36, 160, 30); //base64 image, format, x-coordinate, y-coordinate, width, height
                doc.save('invoice.pdf');
            };
        },
        x: 0,
        y: 0,
    });
};
