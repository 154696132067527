//User Requests
import { blobInstances, customInstances } from '../default-setup';

export const getUserData = (orgHash) =>
    customInstances.get(
        `${
            orgHash ? orgHash : window.localStorage.getItem('baseOrgHash')
        }/whoami/`
    );

export const getUserPic = (photoPath) => blobInstances.get(photoPath.trim());
