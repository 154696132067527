import { useState } from 'react';
import { Formik, Form, ErrorMessage, Field } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import FormField from '../../pages/sre-page/components/sre-form-field';
import FormikSelect from '../form/parts/FormikSelect';
import showNotification from '../../helpers/showNotification';
import { capitalizeFirstLetter } from '../../helpers/textFormatters';

const ModalAddUserContent = ({
    sendRequest = async () => {},
    setIsUpgradeModalOpen,
    setModalState,
}) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    const handleChangeModalState = () => {
        setModalState(false);
    };

    const handleAdd = async (values) => {
        setLoading(true);

        try {
            const responce = await sendRequest(values);

            if (responce?.status === 200) {
                setLoading(false);
                showNotification(
                    `Invitation was sent to the  ${values.email}`,
                    'success'
                );
                setModalState(false);
                return 200;
            }
        } catch (err) {
            setLoading(false);
            if (err?.response?.status === 409) {
                setModalState(false);
                setIsUpgradeModalOpen(true);
            } else {
                setModalState(false);
                showNotification(
                    `${
                        err.response?.data ||
                        err?.message ||
                        err.data?.message ||
                        'Failed to add'
                    }`
                );
            }
        }
    };

    const roleOptions = [
        { label: 'read', value: t('settings_page.users_tab.roles.read') },
        { label: 'write', value: t('settings_page.users_tab.roles.write') },
        { label: 'admin', value: t('settings_page.users_tab.roles.admin') },
    ];

    return (
        <div className="flex flex-col h-fit">
            <Formik
                initialValues={{
                    name: '',
                    email: '',
                    role: 'read',
                }}
                validationSchema={Yup.object({
                    name: Yup.string(),
                    email: Yup.string()
                        .email(t('common.form.invalid_email_address'))
                        .required(t('common.form.required')),
                    role: Yup.string().required(t('common.form.required')),
                })}
                onSubmit={async (values, { resetForm }) => {
                    try {
                        const result = await handleAdd(values);
                        if (result === 200) {
                            resetForm();
                        }
                    } catch (err) {
                        console.log(err);
                    }
                }}
            >
                {({ errors, touched }) => {
                    return (
                        <Form>
                            <div className="mt-2 mb-3 flex flex-col gap-2">
                                <div>
                                    <FormField
                                        name={capitalizeFirstLetter(
                                            t('settings_page.users_tab.name')
                                        )}
                                        attributeName={'name'}
                                        fieldType={'name'}
                                        placeholderText={'Richard Hendricks'}
                                        labelClassName="font-light leading-5"
                                        error={errors.name}
                                        touched={touched.name}
                                    />
                                </div>
                                <div>
                                    <FormField
                                        name={capitalizeFirstLetter(
                                            t(
                                                'settings_page.users_tab.add_user_modal_email'
                                            )
                                        )}
                                        attributeName={'email'}
                                        fieldType={'email'}
                                        placeholderText={
                                            'richard@piedpiper.com'
                                        }
                                        labelClassName="font-light leading-5"
                                        error={errors.email}
                                        touched={touched.email}
                                    />
                                </div>
                                <div>
                                    <p className="text-gray-400 mb-1 text-mb font-light leading-5">
                                        {capitalizeFirstLetter(
                                            t(
                                                'settings_page.users_tab.add_user_modal_role'
                                            )
                                        )}
                                    </p>
                                    <Field
                                        name="role"
                                        component={FormikSelect}
                                        options={roleOptions}
                                    />

                                    <ErrorMessage name="role">
                                        {(error) => (
                                            <div className="text-red-500 mt-1  text-xs">
                                                {error}
                                            </div>
                                        )}
                                    </ErrorMessage>
                                </div>
                            </div>
                            <div className="flex justify-end">
                                <button
                                    type="button"
                                    className="inline-flex mr-4 justify-center px-6 py-2 text-lg leading-6	font-normal text-gray-500 bg-gray-300 border border-transparent rounded-md hover:bg-gray-200"
                                    onClick={handleChangeModalState}
                                    style={{ minWidth: '110px' }}
                                >
                                    {t('button.close')}
                                </button>
                                <button
                                    type="submit"
                                    className={`inline-flex justify-center px-6 py-2 text-lg leading-6	font-normal text-white bg-theme-tertiary-dark border border-transparent rounded-md hover:bg-theme-tertiary-dark disabled:cursor-not-allowed disabled:opacity-50`}
                                    style={{ minWidth: '110px' }}
                                >
                                    {capitalizeFirstLetter(t('button.invite'))}
                                </button>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
};

export default ModalAddUserContent;
