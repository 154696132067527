import React, { Component } from 'react';
import Select from 'react-select';
import moment from 'moment';
import Loader from '../../loader';

const selectOption = [
    { value: 'solve', label: 'Solved' },
    { value: "didn't solve", label: 'Not solved' },
];

export default class LeaksTableRow extends Component {
    state = {
        leak: null,
        actionLoading: true,
    };

    customStylesSelect = {
        placeholder: (provided, state) => ({
            ...provided,
            color: '#fff',
            opacity: '1',
        }),
        container: (provided, state) => ({
            ...provided,
            background:
                state.selectProps.currentValueForStyles.value === 'solve'
                    ? '#15A2BB'
                    : '#FF6384',
            width: '100px',
            borderRadius: '4px',
            display: 'inline-block',
        }),
        control: (provided, state) => ({
            ...provided,
            border: 'none',
            borderColor: 'transparent',
            boxShadow: 'transparent',
            background: 'transparent',
            minHeight: '24px',
        }),
        indicatorSeparator: (provided, state) => ({
            ...provided,
            display: 'none',
        }),

        dropdownIndicator: (provided, state) => ({
            ...provided,
            padding: '0px 2px',
            opacity: '1',
            cursor: 'pointer',
            color: '#fff',
            '&:hover': { color: '#fff' },
        }),

        singleValue: (provided, state) => ({
            ...provided,
            margin: '0',
            padding: '1px',
            color: '#fff',
            fontSize: '12px',
            lineHeight: '24px',
            maxWidth: '100%',
            textTransform: 'uppercase',
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            padding: '0',
            display: 'flex',
            justifyContent: 'center',
        }),
        menuList: (provided, state) => ({
            padding: ' 0',
        }),
        menu: (provided, state) => ({
            ...provided,
            margin: '0 0 0 -1px',
            width: 'calc(100%)',
            borderRadius: '4px',
            border:
                state.selectProps.currentValueForStyles.value === 'solve'
                    ? '1px solid rgba(21, 162, 187, 0.5)'
                    : ' 1px solid rgba(255, 99, 132, 0.5)',
        }),
        option: (provided, state) => {
            return {
                ...provided,
                background: '#fff',
                '&:hover': {
                    background:
                        state.selectProps.currentValueForStyles.value ===
                        'solve'
                            ? 'rgba(21, 162, 187, 0.5)'
                            : 'rgba(255, 99, 132, 0.5)',
                    color: '#fff',
                },
                margin: '0',
                padding: ' 0',
                cursor: 'pointer',
                minHeight: '24px',
                color:
                    state.selectProps.currentValueForStyles.value === 'solve'
                        ? '#15A2BB'
                        : '#FF6384',
                fontSize: '12px',
                lineHeight: '24px',
                maxWidth: '100%',
                textTransform: 'uppercase',
            };
        },
    };

    selectOnChange = (value) => {
        const { key } = this.state.leak;
        key !== value.value
            ? this.setState(
                  {
                      actionLoading: true,
                  },
                  () => this.props.onSelectChange(value, this.state.leak)
              )
            : this.setState({
                  ...this.state,
                  actionLoading: false,
              });
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.leak !== null) {
            if (prevState.leak.key !== nextProps.leak.key) {
                return {
                    leak: {
                        ...prevState.leak,
                        key: nextProps.leak.key,
                        marked_date: nextProps.leak.marked_date,
                    },
                    actionLoading: false,
                };
            } else {
                return prevState;
            }
        } else {
            return {
                leak: nextProps.leak,
                actionLoading: false,
            };
        }
    }

    render() {
        let { commit, commit_author, file, marked_date, tag, key, date } =
            this.state.leak;

        // link for GitHub & GitLab
        let commitLink = `https://${this.props.repoName.slice(
            0,
            -4
        )}/commit/${commit}`;

        // Link for BitBucket
        if (this.props?.repoName.includes('bitbucket.org')) {
            commitLink = `https://${this.props.repoName.slice(
                0,
                -4
            )}/commits/${commit}`;
        }
        return (
            <div className="grid grid-cols-11 text-gray-500">
                <div className="col-span-2 commit-id flex  items-center">
                    <p
                        className="truncate px-4 py-2.5"
                        title={commit}
                        id={`commit_id_${commit}_${file}`}
                    >
                        <a href={commitLink} target="_blank">
                            {commit}
                        </a>
                    </p>

                    {/* <Tooltip placement="top" isOpen={tooltipOpen} autohide={false} toggle={toggle} target={`commit_id_${commit}_${file}`}>{commit}</Tooltip> */}
                </div>
                <div className="col-span-2 commit-author flex  items-center">
                    <p className="truncate px-4 py-2" title={commit_author}>
                        {commit_author}
                    </p>
                </div>

                <div className="col-span-2 file flex  items-center">
                    <p className="truncate px-4 py-2" title={file}>
                        {file}
                    </p>
                </div>
                <div className="col-span-1 date flex  items-center">
                    <p
                        className="truncate px-4 py-2"
                        title={moment(date).format('YYYY MMM DD')}
                    >
                        {moment(date).format('YYYY MMM DD')}
                    </p>
                </div>
                <div className="col-span-1 flex  items-center">
                    <p
                        className="truncate px-4
                        py-2"
                        title={
                            marked_date
                                ? moment(marked_date).format('YYYY MMM DD')
                                : '-'
                        }
                    >
                        {marked_date
                            ? moment(marked_date).format('YYYY MMM DD')
                            : '-'}
                    </p>
                </div>
                <div className="col-span-2 flex  items-center">
                    <p className="truncate px-4 py-2" title={tag}>
                        {tag}
                    </p>
                </div>
                <div className="col-span-1 text-center select-cell p-0 flex  items-center">
                    {!this.state.actionLoading ? (
                        <Select
                            options={selectOption}
                            isSearchable={false}
                            placeholder="Action"
                            styles={this.customStylesSelect}
                            currentValueForStyles={
                                key === 'solve'
                                    ? { label: 'Solved', value: 'solve' }
                                    : {
                                          value: "didn't solve",
                                          label: 'Not solved',
                                      }
                            }
                            defaultValue={
                                key === 'solve'
                                    ? { label: 'Solved', value: 'solve' }
                                    : {
                                          value: "didn't solve",
                                          label: 'Not solved',
                                      }
                            }
                            onChange={(value) => this.selectOnChange(value)}
                        />
                    ) : (
                        <div className="m-auto">
                            <Loader
                                color={'#C2C7D7'}
                                size={35}
                                speedMultiplier={0.8}
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
