import React, { useCallback, useContext, useEffect, useState } from 'react';
import moment from 'moment';
import Select from 'react-select';
import WhitePanelContentWrapper from '../white-panel-content-wrapper';
import PanelTitle from '../panel-title';
import KudosLeaderboard from '../kudos-leaderboard/leaderboard';
import { TimePeriodContext } from '../../context/TimePeriodContext';
import { getEmoji } from '../../api/settings/EmojiAPI';
import {
    getTeamDeploymentFrequencyData,
    getTeamKudosLeaderBoard,
} from '../../api/teams/TeamsAPI';
import { useFetchDataAndSetState } from '../../helpers/useFetchDataAndSetState';
import { OrganisationsContext } from '../../context/OrganisationsContext';
import DoraMetricsPanel from '../dora-metrics-panel';
import TeamSloAndErrorBudget from '../team-slo-and-error-budget';
import { createSprintsArr } from '../../helpers/sprintsDataTransform';
import { useTour } from '@reactour/tour';
import useParamsQuery from '../../hooks/useParamsQuery';
import TeamSprintInsights from '../team-sprint-insights';

function TeamCard({ teamData, usersState, setTeamInEditingMode, isFirst }) {
    const { setIsOpen } = useTour();
    const query = useParamsQuery();

    // KudosStates
    const [timePeriodState] = useContext(TimePeriodContext);

    const [cardTimeperiod, setCardTimeperiod] = useState(null);
    const { date_end, date_start } = timePeriodState;
    const kudosRequest = useCallback(() => getEmoji(), []);
    const leaderboardRequest = useCallback(
        () =>
            getTeamKudosLeaderBoard(teamData.team_hash, {
                params: cardTimeperiod
                    ? {
                          date_start: cardTimeperiod.start,
                          date_end: cardTimeperiod.end,
                      }
                    : { date_start, date_end },
            }),
        [teamData.team_hash, date_start, date_end, cardTimeperiod]
    );

    const [kudosLeaderBoardState] = useFetchDataAndSetState(
        leaderboardRequest,
        [
            teamData.team_hash,
            timePeriodState.date_start,
            timePeriodState.date_end,
            cardTimeperiod,
        ],
        {
            total_kudos: 0,
            team_members_list: [],
            last_kudo: {},
        }
    );

    const [kudosState] = useFetchDataAndSetState(kudosRequest, [
        teamData.team_hash,
    ]);

    // DORA states
    const { organisationsState } = useContext(OrganisationsContext);

    const selectedOrganisationHash = organisationsState.data
        ? organisationsState.data.find((org) => org.active).org_hash
        : null;

    const [sprintsArray, setSprintsArray] = useState(null);
    const [sprintsValue, setSprintsValue] = useState(null);

    // DEPLOYMENT FREQUENCY: GET, FORMAT AND FILTER DATA
    const deploymentFreqRequest = useCallback(
        () =>
            getTeamDeploymentFrequencyData(teamData.team_hash, {
                params: { date_start, date_end },
            }),
        [date_end, date_start, cardTimeperiod]
    );
    const [deploymentFreqState] = useFetchDataAndSetState(
        deploymentFreqRequest,
        [selectedOrganisationHash, date_end, date_start, cardTimeperiod]
    );
    const [filteredDeploymentFreqData, setFilteredDeploymentFreqData] =
        useState([]);

    useEffect(() => {
        query.get('tour') === 'true' && setIsOpen(true);
        return () => setIsOpen(false);
    }, []);

    useEffect(() => {
        if (teamData?.sprint?.start_date && teamData?.sprint?.duration) {
            const sprints = createSprintsArr(
                teamData?.sprint.start_date,
                teamData?.sprint.duration
            );
            setSprintsArray(sprints);
        }
    }, [teamData?.sprint]);

    useEffect(() => {
        if (
            deploymentFreqState?.data?.length &&
            teamData?.team_repositories?.length &&
            teamData?.team_repositories.find(
                (provider) => provider?.repositories?.length
            )
        ) {
            const sorted = deploymentFreqState.data.sort(
                (a, b) => a.timestamp - b.timestamp
            );
            const filteredData = filterDeploymentFreq(
                sorted,
                teamData?.team_repositories
            );
            setFilteredDeploymentFreqData(filteredData);
        } else {
            filteredDeploymentFreqData?.length &&
                setFilteredDeploymentFreqData([]);
        }
    }, [deploymentFreqState.data, teamData]);

    function countSprintDaysLeft() {
        if (!sprintsArray?.length) {
            return;
        }

        const dateNow = moment();
        const sprintEnd = moment(
            sprintsArray[sprintsArray.length - 1][
                sprintsArray[sprintsArray.length - 1].length - 1
            ]
        );
        return Math.ceil(sprintEnd.diff(dateNow, 'days', true));
    }

    function getSprintsOptions() {
        return sprintsArray?.map((sprint, index) => {
            return {
                ...sprint,
                value: index,
                label: `Sprint ${index + 1} (
                        ${moment(sprint[0]).format('DD MMM YYYY')}
                        -
                        ${moment(sprint[sprint.length - 1]).format(
                            'DD MMM YYYY'
                        )}
                        )`,
            };
        });
    }

    function handleSettingSprintTimerange(sprint) {
        setSprintsValue(sprint);
        const sprintData = sprintsArray[sprint?.value];
        const sprintStart = sprintData[0];
        const sprintEnd = sprintData[sprintData.length - 1];

        setCardTimeperiod({
            start: moment(sprintStart).unix(),
            end: moment(sprintEnd).unix(),
        });
    }

    function filterDeploymentFreq(deploymentFrequencyData, repos) {
        const formattedRepoList = repos.reduce((acc, provider) => {
            const providerRepos = provider?.repositories.map((repo) => {
                return { ...repo, provider: provider?.provider };
            });
            return [...acc, ...providerRepos];
        }, []);
        const filtered = deploymentFrequencyData.filter((deploymentItem) =>
            formattedRepoList.find(
                (formattedRepo) =>
                    formattedRepo.provider === deploymentItem.provider &&
                    formattedRepo?.url?.includes(deploymentItem.repository)
            )
        );
        return filtered;
    }

    return (
        <WhitePanelContentWrapper>
            <TourWrapper isFirst={isFirst} step="r-step-page-1-1">
                <div className="flex justify-between items-start">
                    <PanelTitle
                        title={teamData?.team_name}
                        underlineColorType={'secondary'}
                    />
                    <button
                        className="mt-2 btn-white-hover-blue"
                        onClick={() =>
                            setTeamInEditingMode(teamData?.team_name)
                        }
                    >
                        Edit
                    </button>
                </div>
                {teamData?.sprint?.duration ? (
                    <div className="flex items-center justify-between mb-4 text-gray-400 text-xs">
                        <p>
                            Days of the current sprint left:{' '}
                            {countSprintDaysLeft()}
                        </p>
                        <div className="flex gap-2 items-stretch">
                            <Select
                                value={sprintsValue}
                                isSearchable={false}
                                options={getSprintsOptions()}
                                styles={setCustomStyle()}
                                placeholder={'Filter data by sprint'}
                                onChange={(selectedSprint) => {
                                    handleSettingSprintTimerange(
                                        selectedSprint
                                    );
                                }}
                            />
                            {sprintsValue ? (
                                <button
                                    onClick={() => {
                                        setSprintsValue(null);
                                        setCardTimeperiod(null);
                                    }}
                                >
                                    Reset
                                </button>
                            ) : null}
                        </div>
                    </div>
                ) : (
                    <p className="mb-4 text-gray-400 text-xs">
                        There is no sprints schedule for this team.{' '}
                    </p>
                )}
                <div className="grid grid-cols-3 gap-4">
                    <KudosLeaderboard
                        teamsState={{ data: [teamData] }}
                        usersState={usersState}
                        selectedTeam={teamData.team_hash}
                        localTeamsState={{ data: [teamData] }}
                        kudosState={kudosState}
                        kudosLeaderBoardState={kudosLeaderBoardState}
                        isInCard={true}
                    />
                    <div className="col-span-2 rounded p-4 border border-solid border-gray-300">
                        <p className="font-display inline-block text-28 text-gray-700 font-light">
                            Team's DORA Metrics
                        </p>
                        <DoraMetricsPanel
                            allRepos={
                                teamData?.team_repositories
                                    ? teamData?.team_repositories
                                    : null
                            }
                            reposLoading={
                                teamData?.team_repositories ? false : null
                            }
                            orgHash={selectedOrganisationHash}
                            isInCard={true}
                            cardTimeperiod={cardTimeperiod}
                            deploymentFreqState={deploymentFreqState}
                        />
                    </div>
                </div>
                <TourWrapper isFirst={isFirst} step="r-step-page-1-2">
                    <TeamSloAndErrorBudget
                        teamServices={
                            teamData?.team_services
                                ? teamData?.team_services
                                : null
                        }
                        cardTimeperiod={cardTimeperiod}
                        deploymentsList={filteredDeploymentFreqData}
                    />
                </TourWrapper>
                <TeamSprintInsights
                    sprintsArray={sprintsArray}
                    dateTimeStart={
                        cardTimeperiod?.start
                            ? cardTimeperiod.start
                            : date_start
                    }
                    dateTimeEnd={
                        cardTimeperiod?.end ? cardTimeperiod.end : date_end
                    }
                    selectedOrganisationHash={selectedOrganisationHash}
                    teamHash={teamData?.team_hash}
                />
            </TourWrapper>
        </WhitePanelContentWrapper>
    );
}

function TourWrapper({ children, isFirst, step }) {
    if (!isFirst) {
        return <>{children}</>;
    }
    return <div data-tour={step}>{children}</div>;
}

const setCustomStyle = () => ({
    control: (provided) => ({
        ...provided,
        padding: '2px 0',
        width: '300px',
        outline: 'none',
        boxShadow: 'none',
        backgroundColor: 'transparent',
        opacity: '1',
        height: '42px',
    }),
    singleValue: (provided) => ({
        ...provided,
        color: '#484a53',
    }),
    indicatorsContainer: (provided) => ({
        ...provided,
        opacity: '1',
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        opacity: '1',
        cursor: 'pointer',
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        opacity: '1',
    }),
    menu: (provided) => ({
        ...provided,
        marginTop: '0',
        padding: '0',
    }),
    valueContainer: (provided) => ({
        ...provided,
        height: '100%',
        flexWrap: 'no-wrap',
    }),
    option: (provided, state) => ({
        ...provided,
        cursor: 'pointer',
        color: '#484a53',
        backgroundColor: 'transparent',
        '&:hover': {
            backgroundColor: state.isSelected
                ? '#4C72BD'
                : 'rgba(76, 114, 189, 0.5)',
        },
    }),
});

export default TeamCard;
