import { instances } from '../default-setup';

//Overview
export const getOverview = (params) => instances.get('overview/', params);

export const getHighlights = (params) =>
    instances.get('overview/highlights/', params);

export const getRepositoriesChanges = (params) =>
    instances.get('stock/branches/', params);
