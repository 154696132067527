import React from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    LineElement,
    PointElement,
    LinearScale,
    Title,
} from 'chart.js';
import moment from 'moment';
import './styles.scss';
import PanelTitle from '../panel-title';

ChartJS.register(LineElement, PointElement, LinearScale, Title);

const LeaksChart = ({ LeaksState }) => {
    const { t } = useTranslation();
    let _chartWrapper = React.createRef();

    const transformData = () => {
        let chartLabels = [];
        let leaksQuantity = [];
        let leaksFixed = [];
        let repositorysQuantity = [];

        LeaksState.data.statistics?.forEach((chartItem) => {
            chartLabels.push(moment(chartItem.date).format('YYYY MMM DD'));
            leaksQuantity.push(chartItem.leaks_quantity);
            leaksFixed.push(chartItem.leaks_fixed);
            repositorysQuantity.push(chartItem.repositories_quantity);
        });

        return {
            labels: chartLabels,
            datasets: [
                {
                    label: `${t('leaks_page.chart.leaks')} `,
                    fill: '1',
                    lineTension: 0,
                    backgroundColor: '#FF6384',
                    borderColor: '#FF6384',
                    borderWidth: 2,
                    pointBackgroundColor: '#FF6384',
                    pointBorderColor: '#FF6384',
                    pointBorderWidth: 0,
                    pointRadius: 0,
                    pointHoverRadius: 4,
                    data: leaksQuantity,
                    repositorysQuantity,
                },
                {
                    label: `${t('leaks_page.chart.solved')} `,
                    fill: 'origin',
                    lineTension: 0,
                    backgroundColor: '#15A2BB',
                    borderColor: '#15A2BB',
                    borderWidth: 2,
                    pointBackgroundColor: '#15A2BB',
                    pointBorderColor: '#15A2BB',
                    pointBorderWidth: 0,
                    pointRadius: 0,
                    pointHoverRadius: 4,
                    data: leaksFixed,
                },
            ],
        };
    };

    const getChartLabel = (repositorys_quantity) =>
        t('leaks_page.chart.chart_label', {
            repositorys_quantity: repositorys_quantity,
        });

    const getOption = () => {
        let label = (repositorys_quantity) =>
            getChartLabel(repositorys_quantity);
        return {
            scales: {
                x: {
                    ticks: {
                        fontFamily: "'Roboto', sans-serif",
                        fontColor: '#484A53',
                    },
                },

                y: {
                    ticks: {
                        fontFamily: "'Roboto', sans-serif",
                        fontColor: '#484A53',
                    },
                },
            },

            tooltips: {
                titleFontFamily: "'Roboto', sans-serif",
                bodyFontFamily: "'Roboto', sans-serif",

                callbacks: {
                    afterLabel: function (tooltipItem, data) {
                        let repositorys_quantity =
                            data.datasets[0].repositorysQuantity[
                                tooltipItem.index
                            ];

                        return label(repositorys_quantity);
                    },
                },
            },
            legend: {
                display: true,
            },
        };
    };

    return (
        <div className="leaks-chart">
            <PanelTitle
                title={t('leaks_page.chart.title')}
                underlineColorType="tertiary"
            />
            <div
                className="chart-wrapper"
                style={{
                    minHeight:
                        LeaksState.data?.statistics?.length === 0
                            ? 'auto'
                            : '560px',
                }}
            >
                <div className="chart-canvas-wrapper" ref={_chartWrapper}>
                    {LeaksState.data.statistics.length === 0 ? (
                        <p className="text-center text-gray-500 text-xl p-4">
                            No leaks
                        </p>
                    ) : (
                        <Line data={transformData()} options={getOption()} />
                    )}
                </div>
            </div>
        </div>
    );
};

export default withTranslation()(LeaksChart);
