import React, { useEffect, useState } from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next';
import Loader from '../loader';
import { createSprintsArr } from '../../helpers/sprintsDataTransform';
import ErrorBoundary from '../error-boundary';
import { capitalizeFirstLetter } from '../../helpers/textFormatters';
import './index.scss';

const TeamSprintForm = ({
    sprintStart,
    sprintDuration,
    setFieldTouched,
    setFieldValue,
}) => {
    const { t } = useTranslation();

    const [startDate, setStartDate] = useState(
        sprintStart && sprintStart !== 'Undefined' ? sprintStart * 1000 : null
    );
    const [endDate, setEndDate] = useState(null);

    const [sprintsArray, setSprintsArray] = useState(
        createSprintsArr(sprintStart, sprintDuration)
    );

    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);

        start && setFieldValue('sprintStart', moment(start).unix());
        start && setFieldValue('sprintModified', moment().unix());

        if (start && end) {
            const duration = (end - start) / 1000 + 86400;
            setFieldValue('sprintDuration', duration);
            const sprints = createSprintsArr(moment(start).unix(), duration);
            setSprintsArray(sprints);
        }
    };

    const [updateLoading, setUpdateLoading] = useState(false);

    useEffect(() => {
        createSprintsArr(sprintStart, sprintDuration);
    }, [sprintStart, sprintDuration]);

    const renderSelectedSprint = () => {
        if (
            sprintStart === 'Undefined' ||
            sprintDuration === 'Undefined' ||
            !sprintStart ||
            !sprintDuration
        ) {
            return (
                <p className="text-red-text text-sm">
                    Sprints settings not set.
                </p>
            );
        }

        const highlightedDates = () => {
            let color = 0;
            return sprintsArray?.map((sprint) => {
                if (color > 2) color = 0;
                color = color + 1;
                const sprintClassName = `sprint-day color-${color}`;
                const sprintObj = {};

                sprintObj[sprintClassName] = sprint;

                return sprintObj;
            });
        };

        return (
            <>
                <div className="calenar-wrapper">
                    <DatePicker
                        highlightDates={highlightedDates()}
                        disabledKeyboardNavigation
                        inline
                        readOnly
                    />
                </div>
                <div className="calendar-values">
                    <div className="data-value">
                        <p className="values-title">
                            {capitalizeFirstLetter(
                                t('settings_page.sprint_tab.selected_start')
                            )}
                        </p>
                        <p className="value">
                            {sprintStart &&
                                moment.unix(sprintStart).format('DD MMMM YYYY')}
                        </p>
                    </div>
                    <div className="duration-value">
                        <p className="values-title">
                            {capitalizeFirstLetter(
                                t('settings_page.sprint_tab.selected_duration')
                            )}
                        </p>
                        <p className="value">
                            {Math.round(sprintDuration / 86400)} days
                        </p>
                    </div>
                    <div className="sprints-quantity-value">
                        <p className="values-title">
                            {capitalizeFirstLetter(
                                t('settings_page.sprint_tab.done')
                            )}
                        </p>
                        <p className="value">
                            {sprintsArray?.length > 1
                                ? sprintsArray?.length - 1
                                : 0}
                        </p>
                    </div>
                    {updateLoading ? (
                        <Loader
                            color={'#C2C7D7'}
                            size={35}
                            speedMultiplier={0.8}
                        />
                    ) : null}
                </div>
            </>
        );
    };

    const renderSprintSettingsDates = () => {
        const start = startDate ? (
            <span className="date-value start-value ">
                {moment(startDate).format('DD MMMM YYYY')}
            </span>
        ) : (
            <span className="date-value start-value no-value">
                Set start date
            </span>
        );
        const end = endDate ? (
            <span className="date-value end-value">
                {moment(endDate).format('DD MMMM YYYY')}
            </span>
        ) : (
            <span className="date-value end-value no-value">
                {capitalizeFirstLetter(t('settings_page.sprint_tab.set_end'))}
            </span>
        );

        return (
            <>
                <div className="mb-4 flex flex-col gap-2">
                    <p className="font-medium">
                        {capitalizeFirstLetter(
                            t('settings_page.sprint_tab.selected_start_date')
                        )}
                        :
                    </p>
                    <p className="text-green-500">{start}</p>
                </div>
                <div className="flex flex-col gap-2">
                    <p className="font-medium">
                        {capitalizeFirstLetter(
                            t('settings_page.sprint_tab.selected_end_date')
                        )}
                        :
                    </p>
                    <p className="text-green-500">{end}</p>
                </div>
            </>
        );
    };

    return (
        <div className="selected-sprints flex flex-col 2xl:flex-row gap-8">
            <div className="w-full">
                <p className=" text-xl font-medium">
                    {capitalizeFirstLetter(
                        t('settings_page.sprint_tab.change')
                    )}
                </p>
                <p className="mb-1 text-sm text-gray-500">
                    {capitalizeFirstLetter(
                        t('settings_page.sprint_tab.set_dates')
                    )}
                </p>
                <div className="flex gap-6 mt-4">
                    <div className="calenar-wrapper">
                        <DatePicker
                            selected={startDate}
                            onChange={onChange}
                            startDate={startDate}
                            endDate={endDate}
                            selectsRange
                            disabledKeyboardNavigation
                            inline
                        />
                    </div>
                    <div className="calendar-values">
                        {renderSprintSettingsDates()}
                    </div>
                </div>
            </div>
            <div className="w-full">
                <p className=" text-xl font-medium">
                    {capitalizeFirstLetter(
                        t('settings_page.sprint_tab.selected_schedule')
                    )}
                </p>
                <p className="mb-1 text-sm text-gray-500">
                    {' '}
                    Selected team's schedule
                </p>
                <div className="selected-sprints-content mt-4">
                    <ErrorBoundary> {renderSelectedSprint()}</ErrorBoundary>
                </div>
            </div>
        </div>
    );
};

export default TeamSprintForm;
