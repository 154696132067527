import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { getApiKey } from '../../../api/settings/ApiKeysAPI';
import { NAVIGATION_PATHS } from '../../../constants/navigationPaths';
import { OrganisationsContext } from '../../../context/OrganisationsContext';
import { capitalizeFirstLetter } from '../../../helpers/textFormatters';
import { useFetchDataAndSetState } from '../../../helpers/useFetchDataAndSetState';

const AddToSlack = () => {
    const { t } = useTranslation();
    const { REACT_APP_SLACK_CLIENT_ID: clientId, REACT_APP_ENV: environment } =
        process.env;

    const AddLink = `https://zensoftwarenl.slack.com/oauth?client_id=${clientId}&scope=calls:write,channels:read,chat:write,commands,dnd:read,files:read,groups:read,im:history,im:read,im:write,mpim:history,mpim:read,mpim:write,pins:write,reactions:read,reactions:write,remote_files:read,remote_files:share,remote_files:write,team:read,users:read,users:read.email,users:write&user_scope=channels:read,im:read,im:write,team:read,users.profile:read,users:read&redirect_uri=https://bot.${environment}.agileanalytics.cloud/${window.localStorage.getItem(
        'orgHash'
    )}/slack/add_workspace&state=&granular_bot_scope=1&install_redirect=&team=&single_channel=0&tracked=1`;

    const { organisationsState } = useContext(OrganisationsContext);

    const selectedOrganisationData = organisationsState.data
        ? organisationsState.data.find((org) => org.active)
        : null;

    const apiKeysRequest = useCallback(() => getApiKey(), []);
    const [apiKeyState] = useFetchDataAndSetState(apiKeysRequest, [
        selectedOrganisationData,
    ]);

    // for testing case with no API keys
    // const apiKeyState = {};

    return (
        <div className="add-workspace mb-8">
            <p className="add-workspace__description">
                {capitalizeFirstLetter(t('settings_page.kudos_tab.info'))}
            </p>
            <a
                className={`add-slack-button ${
                    !apiKeyState.data && 'filter grayscale pointer-events-none'
                }`}
                target="_blank"
                href={AddLink}
                rel="noopener noreferrer"
                onMouseEnter={() => {
                    !apiKeyState.data && setIsNotification(true);
                }}
                onMouseLeave={() => {
                    !apiKeyState.data && setIsNotification(false);
                }}
            >
                <img
                    alt="Add to Slack"
                    height="40"
                    width="139"
                    src="https://platform.slack-edge.com/img/add_to_slack.png"
                    srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
                />
            </a>
            {!apiKeyState.data && (
                <NavLink
                    to={NAVIGATION_PATHS.settingsApiKey}
                    className="text-red-500 text-sm"
                >
                    {capitalizeFirstLetter(
                        t('settings_page.kudos_tab.validation')
                    )}
                </NavLink>
            )}
        </div>
    );
};

export default AddToSlack;
