import { instances } from '../default-setup';

// timesheet API
export const getAllHours = (params) => instances.get(`timesheet/`, params);

export const getInferredHours = (params) =>
    instances.get(`hours/report/`, params);

export const updateHours = (userHash, data) =>
    instances.put(`timesheet/${userHash}/`, data);

export const deleteProjectHours = (userHash, projectName, params) =>
    instances.delete(`timesheet/${userHash}/${projectName}/`, params);

// Organistion Projects API
export const getProjects = () => instances.get(`projects/`);

export const createProjects = (data) => instances.post(`projects/`, data);

export const updateProjects = (data) => instances.put(`projects/`, data);

export const deleteProjects = () => instances.delete(`projects/`);

// Private Projects API
export const getPrivateProjects = (userHash, params) =>
    instances.get(`projects/${userHash}/`, params);

export const createPrivateProjects = (userHash, data) =>
    instances.post(`projects/${userHash}/`, data);

export const updatePrivateProjects = (userHash, data) =>
    instances.put(`projects/${userHash}/`, data);

export const deletePrivateProjects = (userHash) =>
    instances.delete(`projects/${userHash}/`);

// Jira Projects

export const getJiraProjects = () => instances.get(`filter/projects/`);
