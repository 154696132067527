import React from 'react';
import { useTranslation } from 'react-i18next';

const ModalDeleteUser = ({ setModalState, userData, sendRequest }) => {
    const { t } = useTranslation();

    const handleChangeModalState = () => {
        setModalState(false);
    };

    const handleDelete = () => {
        sendRequest(userData);
        setModalState(false);
    };

    return (
        <div className="flex flex-col pb-2 pt-5 h-full">
            <p className="text-xl text-gray-500">
                Are you sure you want to remove{' '}
                <span className="italic">{userData.user_name}</span> from your
                organisation?
            </p>
            <div className="ml-auto mt-auto">
                <button
                    type="button"
                    className="inline-flex mr-4 justify-center px-6 py-2 text-lg leading-6	font-normal text-gray-500 bg-gray-300 border border-transparent rounded-md hover:bg-gray-200"
                    onClick={handleChangeModalState}
                    style={{ minWidth: '110px' }}
                >
                    {t('button.close')}
                </button>
                <button
                    type="button"
                    className={`inline-flex justify-center px-6 py-2 text-lg leading-6	font-normal text-white bg-red-text border border-transparent rounded-md hover:bg-red-800 disabled:cursor-not-allowed disabled:opacity-50`}
                    onClick={handleDelete}
                    style={{ minWidth: '110px' }}
                >
                    {t('button.remove')}
                </button>
            </div>
        </div>
    );
};

export default ModalDeleteUser;
