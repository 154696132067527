import React, { useMemo, memo } from 'react';
import moment from 'moment';
import { useTable, useRowSelect } from 'react-table';
import ErrorIndicator from '../error-indicator';
import Loader from '../../components/loader';
import { useTranslation } from 'react-i18next';

const colsAmountPerIndex = {
    0: '2',
    1: '2',
    2: '2',
    3: '5',
    4: '1',
};

function HoursReportTable({ data, headers, loading, error }) {
    const columns = useMemo(() => headers, []);

    return (
        <Table columns={columns} data={data} loading={loading} error={error} />
    );
}

function Table({ columns, data, loading, error }) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        footerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data,
    });

    const { t } = useTranslation();

    const tableHeader = (
        <thead className="w-full">
            {headerGroups.map((headerGroup, i) => (
                <tr
                    {...headerGroup.getHeaderGroupProps()}
                    className="grid grid-cols-12"
                    key={i}
                >
                    {headerGroup.headers.map((column, i) => (
                        <th
                            {...column.getHeaderProps([
                                { className: column.className },
                            ])}
                            key={i}
                        >
                            {column.render('Header')}
                        </th>
                    ))}
                </tr>
            ))}
        </thead>
    );

    if (loading) {
        return (
            <table
                {...getTableProps}
                className="mx-auto  w-full border-collapse"
            >
                {tableHeader}
                <tbody className="text-gray-700 text-base">
                    <tr>
                        <td className=" py-3 text-center">
                            <div
                                className="mr-5 flex justify-center items-center"
                                style={{ width: '100%', height: '394px' }}
                            >
                                <Loader
                                    color={'#C2C7D7'}
                                    size={35}
                                    speedMultiplier={0.8}
                                    css={{
                                        margin: '30px auto',
                                        display: 'block',
                                    }}
                                />
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        );
    }

    if (error) {
        return (
            <table
                {...getTableProps}
                className="mx-auto  w-full border-collapse"
            >
                {tableHeader}
                <tbody className="text-gray-700 text-base">
                    <tr>
                        <td className=" py-3 text-center">
                            <ErrorIndicator error={error} />
                        </td>
                    </tr>
                </tbody>
            </table>
        );
    }

    if (data.total_hours) {
        return (
            <table
                {...getTableProps}
                className="mx-auto  w-full border-collapse"
            >
                {tableHeader}
                <tbody className="text-gray-700 text-base"></tbody>
                <tfoot>
                    {footerGroups.map((group, i) => (
                        <tr
                            {...group.getFooterGroupProps()}
                            className="grid grid-cols-12"
                            key={i}
                        >
                            {group.headers.map((column) => (
                                <td
                                    key={i}
                                    {...column.getFooterProps([
                                        {
                                            className: `${column.className} py-4`,
                                        },
                                    ])}
                                >
                                    {column.render('Footer')}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tfoot>
            </table>
        );
    }

    if (!data.length) {
        return (
            <table
                {...getTableProps}
                className="mx-auto  w-full border-collapse"
            >
                {tableHeader}
                <tbody className="text-gray-700 text-base">
                    <tr>
                        <td className=" py-3 text-center">
                            {t('common.no_data')}
                        </td>
                    </tr>
                </tbody>
            </table>
        );
    }

    return (
        <table {...getTableProps} className="mx-auto  w-full border-collapse">
            {tableHeader}
            <tbody {...getTableBodyProps} className="text-gray-500">
                {rows.map((row, i) => {
                    prepareRow(row);

                    let weekStartDay = moment()
                        .day(1)
                        .week(data[i].week)
                        .format('D');

                    let weekEndDay = moment()
                        .day(0)
                        .week(data[i].week + 1)
                        .format('D');

                    let total = null;

                    if (+weekStartDay > +weekEndDay) {
                        weekStartDay = '1';
                    }

                    weekEndDay = moment()
                        .day(0)
                        .week(data[i].week + 1)
                        .format('D MMMM');

                    if (data[i]?.week !== data[i + 1]?.week) {
                        total = data
                            .filter((date) => date.week === data[i]?.week)
                            .reduce((acc, date) => {
                                return acc + date.hours;
                            }, 0);
                    }

                    return (
                        <>
                            <tr
                                {...row.getRowProps()}
                                className={`border-solid border-b border-gray-300 align-middle grid grid-cols-12 `}
                                key={i}
                            >
                                {row.cells.map((cell, i) => {
                                    const alignText =
                                        cell.column.extraClass ?? '';
                                    return (
                                        <td
                                            key={i}
                                            {...cell.getCellProps()}
                                            className={`py-2 pr-2 leading-6 align-middle break-words ${alignText} col-span-${colsAmountPerIndex[i]}`}
                                        >
                                            {cell.render('Cell')}
                                        </td>
                                    );
                                })}
                            </tr>
                            {data[i]?.week !== data[i + 1]?.week ? (
                                <tr
                                    className={`border-solid border-b border-gray-300 align-middle grid grid-cols-12 text-gray-700 bg-custom-blue-200`}
                                >
                                    <td
                                        className={`py-2 leading-6 align-middle  col-start-9 col-end-12 text-right  mr-8 font-medium leading-6`}
                                    >
                                        {`Week ${data[i].week} (${weekStartDay}-${weekEndDay}): `}
                                    </td>
                                    <td
                                        className={`py-2 leading-6 align-middle col-start-12 font-medium leading-6`}
                                    >
                                        {total}
                                    </td>
                                </tr>
                            ) : null}
                        </>
                    );
                })}
            </tbody>
            <tfoot>
                {footerGroups.map((group, i) => (
                    <tr
                        {...group.getFooterGroupProps()}
                        className="grid grid-cols-12"
                        key={i}
                    >
                        {group.headers.map((column) => (
                            <td
                                key={i}
                                {...column.getFooterProps([
                                    {
                                        className: `${column.className} py-4`,
                                    },
                                ])}
                            >
                                {column.render('Footer')}
                            </td>
                        ))}
                    </tr>
                ))}
            </tfoot>
        </table>
    );
}

export default memo(HoursReportTable);
