import React, { useContext } from 'react';
import { IoIosAdd, IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import ButtonSecondary from '../../../../components/button/ButtonSecondary';
import moment from 'moment';
import ButtonPrimary from '../../../../components/button/ButtonPrimary';
import HoursPageTimesheetToolbarDatePicker from './DatePicker';
import { useTranslation } from 'react-i18next';
import { OrganisationsContext } from '../../../../context/OrganisationsContext';
import { getWritePermission } from '../../../../helpers/getPermission';

function HoursPageTimesheetToolbar({
    filterDate,
    setFilterDate,
    addRow,
    addDisabled,
    updateDates,
    userHash,
    viewerHash,
}) {
    const { t } = useTranslation();
    const { organisationsState } = useContext(OrganisationsContext);

    function formatFilterString() {
        const startDate = filterDate.clone().startOf('isoWeek');
        const endDate = filterDate.clone().endOf('isoWeek');

        return `${startDate.format('D')} ${
            startDate.format('M') !== endDate.format('M')
                ? startDate.format('MMMM')
                : ''
        } - ${endDate.format('D')} ${endDate.format('MMMM')} ${endDate.format(
            'YYYY'
        )} (Week ${startDate.format('W')})`;
    }

    function subtractWeek() {
        setFilterDate((prev) => prev.clone().subtract(1, 'week'));
        updateDates();
    }

    function addWeek() {
        setFilterDate((prev) => prev.clone().add(1, 'week'));
        updateDates();
    }

    function setFilterDateNow() {
        setFilterDate(moment());
        updateDates();
    }

    return (
        <div className="flex justify-between items-center ">
            <div className="text-lg font-light text-gray-500">
                {formatFilterString()}
            </div>
            <div className="flex justify-between items-center space-x-4">
                <HoursPageTimesheetToolbarDatePicker
                    filterDate={filterDate}
                    setFilterDate={setFilterDate}
                    updateDates={updateDates}
                />
                <div className="flex justify-between items-center ">
                    <ButtonSecondary
                        variant={'gray'}
                        extraClasses={'h-8'}
                        onClick={subtractWeek}
                    >
                        <IoIosArrowBack />
                    </ButtonSecondary>
                    <ButtonSecondary
                        variant={'gray'}
                        extraClasses={'h-8'}
                        onClick={setFilterDateNow}
                    >
                        {t('hours_page.timesheet.this_week')}
                    </ButtonSecondary>
                    <ButtonSecondary
                        variant={'gray'}
                        extraClasses={'h-8'}
                        onClick={addWeek}
                    >
                        <IoIosArrowForward />
                    </ButtonSecondary>
                </div>

                {getWritePermission(organisationsState?.data) ? (
                    <ButtonPrimary
                        onClick={addRow}
                        extraClasses={'h-8'}
                        title={'Add hours'}
                        disabled={addDisabled}
                    >
                        <IoIosAdd size={20} /> {t('button.add')}
                    </ButtonPrimary>
                ) : null}
            </div>
        </div>
    );
}

export default HoursPageTimesheetToolbar;
