import React from 'react';
import LeaksTableHeaderColumn from './leaks-table-header-column';

const LeaksTableHeader = ({ repositoryName, makeSort }) => {
    const columns = [
        { columnName: 'commit_id', columnSize: 2, canSort: false },
        { columnName: 'committer', columnSize: 2, canSort: true },
        { columnName: 'file', columnSize: 2, canSort: false },
        { columnName: 'date', columnSize: 1, canSort: true },
        { columnName: 'marked_date', columnSize: 1, canSort: true },
        { columnName: 'leak_type', columnSize: 2, canSort: true },
        { columnName: 'action', columnSize: 1, canSort: true },
    ];

    return (
        <div className="leaks-table__header grid grid-cols-11">
            {columns.map((headerItem) => {
                return (
                    <LeaksTableHeaderColumn
                        key={`${repositoryName}_${headerItem.columnName}`}
                        headerItem={headerItem}
                        makeSort={makeSort}
                    />
                );
            })}
        </div>
    );
};

export default LeaksTableHeader;
