import React from 'react';
import { Field } from 'formik';

const FormTextarea = ({
    name,
    attributeName,
    rows,
    placeholderText,
    error,
    touched,
    value,
    onKeyDown,
}) => {
    return (
        <>
            <label
                className="text-gray-400 mb-1 text-m"
                htmlFor={attributeName}
            >
                {name}
            </label>
            <Field
                id={attributeName}
                name={attributeName}
                component="textarea"
                rows={rows}
                placeholder={placeholderText}
                className="p-2 placeholder-gray-300 text-gray-700 relative bg-white
      rounded text-md border border-gray-300 outline-none focus:outline-none focus:ring w-full font-mono text-xs min-h-40"
                value={value}
                onKeyDown={onKeyDown}
            />
            {error && touched ? (
                <div className="text-red-500 mt-1 text-xs">{error}</div>
            ) : (
                <div className="text-red-500 mt-1 text-xs invisible">
                    hidden
                </div>
            )}
        </>
    );
};

export default FormTextarea;
