import React from 'react';
import { useTranslation } from 'react-i18next';
import 'chart.js/auto';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    BarElement,
    PointElement,
    LinearScale,
    Title,
} from 'chart.js';

import './styles.scss';

ChartJS.register(BarElement, PointElement, LinearScale, Title);

const ModifiedLinesChart = ({ data, isModifiedLines }) => {
    const { t } = useTranslation();

    const getOption = () => {
        let chart_label = (modified_lines, current_repo) =>
            t('stock_page.chart.chart_label', {
                modified_lines,
                current_repo,
            });
        let afterLabel = (branches_quantity) =>
            t('stock_page.chart.chart_after_label', {
                branches_quantity,
            });
        return {
            scales: {
                x: {
                    stacked: true,
                },

                y: {
                    stacked: true,
                },
            },
            tooltips: {
                callbacks: {
                    label: function (tooltipItem, data) {
                        let modified_lines =
                            (+tooltipItem.value).toFixed(2) % 1 === 0
                                ? (+tooltipItem.value).toFixed(0)
                                : (+tooltipItem.value).toFixed(2);

                        let current_repo =
                            data.datasets[tooltipItem.datasetIndex].label;
                        return chart_label(modified_lines, current_repo);
                    },
                    afterLabel: function (tooltipItem, data) {
                        let value = tooltipItem.value;
                        let branchecs_quantity_index = data.datasets[
                            tooltipItem.datasetIndex
                        ].data.indexOf(+value);
                        let branches_quantity =
                            data.datasets[tooltipItem.datasetIndex].branches[
                                branchecs_quantity_index
                            ];

                        return afterLabel(branches_quantity);
                    },
                },
            },
            legend: {
                display: true,
            },
        };
    };

    return (
        <div className="modified-lines-chart">
            {isModifiedLines ? (
                <div>
                    <Bar data={data} options={getOption()} />
                </div>
            ) : (
                <div className="text-center text-gray-500 text-xl p-4">
                    No modified lines found for this timeperiod
                </div>
            )}
        </div>
    );
};

export { ModifiedLinesChart };

export default ModifiedLinesChart;
