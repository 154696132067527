import React, { useEffect, useState } from 'react';

import Loader from '../../loader';
import ErrorIndicator from '../../error-indicator';
import './styles.scss';
import { getLogs } from '../../../api/swarm/SwarmAPI';

const LogsList = ({ data, name, provider_id, logStatus }) => {
    const [logsState, setLogsState] = useState({
        data: null,
        error: null,
        loading: true,
    });

    useEffect(() => {
        let canceled = false;

        const path = {
            job_id: data.job_name,
            git_provider: provider_id,
            repo_name: name,
        };

        getLogs(path)
            .then((response) => {
                if (!canceled)
                    setLogsState({
                        loading: false,
                        error: false,
                        data: response.data,
                    });
            })
            .catch((error) => {
                if (!canceled)
                    setLogsState({
                        loading: false,
                        error,
                        data: null,
                    });
            });
        return () => {
            canceled = true;
        };
    }, []);

    if (logsState.loading) {
        return (
            <tr
                className={`logs-list ${
                    logStatus ? 'border-solid border-b-2 border-gray-300' : ''
                }`}
            >
                <td className="pb-2" align="center" colSpan="3">
                    <Loader />
                </td>
            </tr>
        );
    }

    if (logsState.error) {
        return (
            <div className="logs-list">
                <ErrorIndicator error={logsState.error} />
            </div>
        );
    }

    if (logsState.data.length === 0) {
        return (
            <div className="logs-list">
                <p className="empty">No Logs</p>
            </div>
        );
    }

    return (
        <tr
            className={`logs-list ${
                logStatus ? 'border-solid border-b-2 border-gray-300' : ''
            }`}
        >
            <td className="pb-2" colSpan="3">
                {logsState.data.map((log) => (
                    <Log
                        data={log}
                        key={`${log.timestamp}-${log.textPayload}`}
                    />
                ))}
            </td>
        </tr>
    );
};

const Log = ({ data }) => {
    const {
        'httpRequest.requestMethod': requestMethod,
        'httpRequest.status': status,
        'sourceLocation.file': file,
        'sourceLocation.line': line,
        severity,
        textPayload,
        timestamp,
    } = data;
    return (
        <div className="log">
            <p>
                httpRequest.requestMethod :
                {requestMethod ? requestMethod : 'null'}
            </p>
            <p>httpRequest.status : {status ? status : 'null'}</p>
            <p>sourceLocation.file : {file ? file : 'null'}</p>
            <p>sourceLocation.line : {line ? line : 'null'}</p>
            <p>severity : {severity ? severity : 'null'}</p>
            <p>textPayload : {textPayload ? textPayload : 'null'}</p>
            <p>timestamp : {timestamp ? timestamp : 'null'}</p>
        </div>
    );
};

export default LogsList;
