import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { signupWithPassword } from '../../api/auth/AuthAPI';
import renderLoginOptions from '../../helpers/auth/renderLoginOptions';

function AuthExploreForm({ endpoint }) {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    function handlePasswordSignup(values, setSubmittingFinished) {
        setIsLoading(true);
        const params = {
            ...values,
            endpoint: endpoint,
        };
        signupWithPassword(params)
            .then((response) => {
                localStorage.setItem('token', response.data.idToken);
                localStorage.setItem(
                    'refreshToken',
                    response.data.refreshToken
                );
                setSubmittingFinished(true);
                window.location.replace(response.data?.reset_password_link);
            })
            .catch((error) => {
                console.log(error);
                if (error?.response?.status == 400) {
                    setErrorMessage(
                        error?.response?.data
                            ? error?.response?.data
                            : 'Failed to signup'
                    );
                } else {
                    setErrorMessage('Oops! Something went wrong!');
                }

                setSubmittingFinished(true);
            });
        setIsLoading(false);
    }

    return (
        <div>
            <p className="text-center mb-20 font-display text-6xl text-gray-700 font-bold ">
                Explore
            </p>
            {errorMessage && (
                <div className="rounded bg-red-200 text-red-600 px-2 py-2 mb-8">
                    {errorMessage}
                </div>
            )}
            {renderLoginOptions(
                endpoint,
                'all',
                null,
                null,
                null,
                setErrorMessage,
                handlePasswordSignup
            )}
        </div>
    );
}

export default AuthExploreForm;
