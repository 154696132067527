export const NAVIGATION_PATHS = {
    admin: '/superadmin',
    createOrganisation: '/overview?create=true',
    home: '/',
    hours: '/hours',
    kudos: '/kudos',
    overview: '/overview',
    repositories: '/repositories',
    dora: '/dora',
    leaks: '/leaks',
    settings: '/settings',
    sprintInsights: '/sprint-insights',
    sre: '/error-budgets',
    stock: '/stock',
    teams: '/teams',
    userSettings: '/user-settings',
    settingsOrganisation: '/settings/organisation',
    settingsBilling: '/settings/billing',
    settingsSaml: '/settings/saml',
    settingsUsers: '/settings/users',
    settingsKudos: '/settings/kudos',
    settingsSprint: '/settings/sprint',
    settingsGitProviders: '/settings/vcs',
    settingsApiKey: '/settings/api-key',
    settingsJobsSchedule: '/settings/schedule',
    settingsSprintInsights: '/settings/sprint-insights',
    settingsErrorBudgets: '/settings/error-budgets',
};
