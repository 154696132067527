import * as React from 'react';

function ErrorMessage({ children, className }) {
    return (
        <small className={`text-sm text-red-700 ${className}`}>
            {children}
        </small>
    );
}

export default ErrorMessage;
