import React, { Component } from 'react';
import moment from 'moment';
import { TimePeriodContext } from '../../context/TimePeriodContext.js';
import Loader from '../loader';
import GenericTableRow from './generic-table-row';
import GenericTableHeader from './generic-table-header';
import ReactPaginate from 'react-paginate';
import { withTranslation } from 'react-i18next';
import './styles.scss';
import showNotification from '../../helpers/showNotification';
import PanelTitle from '../panel-title/index.js';

class GenericTable extends Component {
    static contextType = TimePeriodContext;
    state = {
        rowsData: [],
        rowList: {
            start: '0',
            end: '9',
        },
        loading: false,
        minHeight: 0,
        rowsPerPage: 10,
        pageQuantity: Math.ceil(this.props.rowsQuantity / 10),
        sort: this.lowerColumn(this.props.columns[0]['columnId']),
        direction: 'start',
    };

    lowerColumn(column) {
        return column.toLowerCase().replace(' ', '_');
    }

    computeColsSpan() {
        let spans = 0;
        this.props.columns?.forEach((col) => (spans += col.columnSize));
        return spans;
    }

    getColumnTypes() {
        return this.props.columns.map((column) => column.type);
    }

    getColumnSpans() {
        return this.props.columns.map((column) => column.columnSize);
    }

    getLevelColorScheme() {
        return this.props.columns.filter((column) => column.colorScheme)[0]
            ?.colorScheme;
    }

    getSelectOptions() {
        return this.props.columns.filter((column) => column.options)[0]
            ?.options;
    }

    getMinHeight(rowsQuantity) {
        let minHeight;

        if (rowsQuantity) {
            minHeight = `${49 * rowsQuantity}px`;
        } else {
            minHeight =
                this.props.rowsQuantity < 10
                    ? `${49 * this.props.rowsQuantity}px`
                    : '490px';
        }
        return minHeight;
    }

    componentDidMount() {
        this.getTableData();
    }

    createTable = () => {
        if (this.state.rowsData.length) {
            return this.state.rowsData.map((row, index) => {
                return (
                    <GenericTableRow
                        row={row}
                        columns={this.props.columns.map(
                            (col) => col['columnId']
                        )}
                        key={`${this.props.repositoryName}_${index}`}
                        onSelectChange={this.onSelectChange}
                        selectOptions={this.getSelectOptions()}
                        colsSpan={this.computeColsSpan()}
                        cellTypes={this.getColumnTypes()}
                        cellSpans={this.getColumnSpans()}
                        levelColorScheme={this.getLevelColorScheme()}
                    />
                );
            });
        } else {
            let text;
            if (this.props.noDataFound) {
                text = this.props.t('stock_page.table.no_data');
            } else {
                text = this.props.t('stock_page.table.no_stock');
            }

            return (
                <div className="no-data text-center">
                    <p>{text}</p>
                </div>
            );
        }
    };

    pageChange = (page) => {
        let newStart, newEnd, remainingRows;
        newStart = `${page.selected}0`;
        remainingRows = this.props.rowsQuantity - +newStart;
        if (remainingRows <= 10) {
            newEnd = (this.props.rowsQuantity - 1).toString();

            let rowsList = {
                start: newStart,
                end: newEnd,
            };

            this.setState(
                {
                    rowList: rowsList,
                    minHeight: this.getMinHeight(remainingRows),
                },
                () => this.getTableData()
            );
        } else {
            let newEnd = `${page.selected}9`;
            let rowsList = {
                start: newStart,
                end: newEnd,
            };
            this.setState(
                {
                    rowList: rowsList,
                    minHeight: 480,
                },
                () => this.getTableData()
            );
        }
    };

    selectFilter = (sort, direction) => {
        let setSort = false,
            setDirection;
        sort = this.lowerColumn(sort);
        const columnsSort = this.props.columns.map((col) =>
            this.lowerColumn(col.columnId)
        );
        if (columnsSort.includes(sort)) setSort = sort;

        setDirection = direction === true ? 'start_to_end' : 'end_to_start';

        this.setState(
            {
                sort: setSort,
                direction: setDirection,
            },
            () => this.getTableData()
        );
    };

    getTableData = async () => {
        this.setState({
            loading: true,
        });

        let requestParams = {
            params: {
                repository: this.props.repositoryName,
                sort: this.state.sort,
                direction: this.state.direction,
                start: this.state.rowList.start,
                end: this.state.rowList.end,
                date_start: this.context[0].date_start,
                date_end: this.context[0].date_end,
            },
        };

        if (this.props.rowsQuantity !== 0) {
            try {
                const response = await this.props.updateTable(requestParams);
                this.setState({
                    rowsData: response.data,
                    loading: false,
                });
            } catch (error) {
                this.setState({
                    error: error,
                    loading: false,
                });
            }
        } else {
            this.setState({
                rowsData: [],
                loading: false,
            });
        }
    };

    findRow = (newRow) => {
        let newRowsData = this.state.rowsData.map((row) => {
            if (this.sameRow(row, newRow)) {
                return {
                    ...row,
                    key: newRow.key,
                    marked_date: moment
                        .unix(newRow.marked_date)
                        .format('YYYY-MM-DD HH:mm:ssZ'),
                };
            }
            return row;
        });

        this.setState((state) => {
            return {
                ...state,
                rowsData: newRowsData,
            };
        });
    };

    sameRow = (row, leakData) => {
        return (
            row.commit === leakData.commit &&
            row.commit_author === leakData.commit_author &&
            row.file === leakData.file &&
            row.tag === leakData.tag &&
            row.date === leakData.date
        );
    };

    onSelectChange = (value, row) => {
        let markedDate = moment().unix();

        let newRow = {
            ...row,
            key: value.value,
        };

        //  create generic updateRowStatus
        // setLeakStatus(newRow)
        //     .then((response) => {
        //         if (response.status === 200 && response.data === 'OK') {
        //             this.findRow(newRow);
        //             this.props.updateChart();
        //         }
        //     })
        //     .catch((error) => {
        //         showNotification(error.message);
        //     });
    };

    render() {
        return (
            <div className="generic-table-wrapper">
                <PanelTitle
                    title={this.props.title}
                    underlineColorType="tertiary"
                    isLink={true}
                />
                <div className="generic-table w-100">
                    <GenericTableHeader
                        repositoryName={this.props.repositoryName}
                        makeSort={this.selectFilter}
                        columns={this.props.columns}
                        colsSpan={this.computeColsSpan()}
                    />
                    <div
                        className="generic-table__body divide-y divide-solid divide-gray-300"
                        style={
                            this.state.loading
                                ? { minHeight: this.state.minHeight }
                                : null
                        }
                    >
                        {this.state.loading ? (
                            <div className="m-auto">
                                <Loader
                                    color={'#C2C7D7'}
                                    size={35}
                                    speedMultiplier={0.8}
                                />
                            </div>
                        ) : (
                            this.createTable()
                        )}
                    </div>
                    <div className="flex justify-end mt-2">
                        {this.state.pageQuantity > 1 ? (
                            <>
                                <ReactPaginate
                                    previousLabel={'<'}
                                    previousLinkClassName={
                                        'block w-5 h-5 flex justify-center items-center  bg-gray-400 rounded text-white font-semibold'
                                    }
                                    nextLabel={'>'}
                                    nextLinkClassName={
                                        'block w-5 h-5 flex justify-center items-center  bg-gray-400 rounded text-white font-semibold'
                                    }
                                    breakLabel={'...'}
                                    breakClassName={'text-gray-400'}
                                    pageLinkClassName={'block w-full p-1'}
                                    pageClassName={'text-center text-gray-400'}
                                    pageCount={this.state.pageQuantity}
                                    marginPagesDisplayed={4}
                                    pageRangeDisplayed={3}
                                    containerClassName={
                                        'flex gap-x-1.5 items-center'
                                    }
                                    subContainerClassName={''}
                                    activeClassName={'bg-gray-200 rounded'}
                                    onPageChange={(page) =>
                                        this.pageChange(page)
                                    }
                                />
                            </>
                        ) : null}
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(GenericTable);
