import React, { useCallback, useEffect, useState } from 'react';
import { useFetchDataAndSetState } from '../../helpers/useFetchDataAndSetState';
import { getCredsInfo, tryAuth } from '../../api/superadmin/SuperadminAPI';
import WhitePanelContentWrapper from '../white-panel-content-wrapper';
import Loader from '../loader';
import PanelTitle from '../panel-title';
import ErrorIndicator from '../error-indicator';
import moment from 'moment';
import { SaveButton } from '../custom';
import useParamsQuery from '../../hooks/useParamsQuery';

function AuthTokenCard() {
    const query = useParamsQuery();
    const request = useCallback(() => getCredsInfo(), []);
    const [credsState, credsDispatch] = useFetchDataAndSetState(request, []);
    const [credsErrors, setCredsErrors] = useState({});
    const [lastChecked, setLastChecked] = useState(moment().unix());
    const TYPES = ['mailer', 'noreply'];

    useEffect(() => {
        const error = query.get('error');
        const type = query.get('type');
        const creds = query.get('creds');

        if (error) {
            setCredsErrors((prevState) => {
                return { ...prevState, [type]: error };
            });
        }
        if (creds) {
            const updatedData = credsState?.data?.map((item) => {
                if (creds?.type === item?.type) {
                    return creds;
                } else {
                    return item;
                }
            });
            credsDispatch({ type: 'SET_DATA', payload: updatedData });
            setCredsErrors((prevState) => {
                return { ...prevState, [type]: null };
            });
        }
    }, []);

    async function handleAuth(typeName) {
        credsDispatch({ type: 'SET_LOADING' });
        try {
            const res = await tryAuth(typeName);
            if (res) {
                window.location.replace(res?.data);
            }
        } catch (error) {
            credsDispatch({ type: 'SET_ERROR', payload: error });
        }
    }

    async function handleCheck() {
        credsDispatch({ type: 'SET_LOADING' });
        try {
            const res = await getCredsInfo();
            if (res) {
                credsDispatch({ type: 'SET_DATA', payload: res?.data });
                setLastChecked(moment().unix());
            }
        } catch (error) {
            credsDispatch({ type: 'SET_ERROR', payload: error });
        }
    }

    return (
        <WhitePanelContentWrapper className={'w-full col-span-12 h-fit mb-4'}>
            <PanelTitle
                title={'Email sending auth token status'}
                underlineColorType="tertiary"
            />
            {credsState?.loading ? (
                <div className="flex h-full w-full justify-center items-center">
                    <Loader size={28} />
                </div>
            ) : (
                <div>
                    {credsState?.error ? (
                        <div>
                            {<ErrorIndicator error={credsState?.error} />}
                        </div>
                    ) : (
                        <div className="w-full grid grid-cols-2 gap-4">
                            {credsState?.data?.length ? (
                                <>
                                    {credsState?.data.map((cred) => (
                                        <div
                                            className="flex flex-col gap-2 rounded border border-solid border-gray-400 p-4"
                                            key={cred?.client_id}
                                        >
                                            <div className="grow">
                                                <p className="flex   gap-x-2 block mb-4  text-gray-500 text-3xl  leading-5">
                                                    {cred?.type}
                                                </p>
                                                <p className="flex   gap-x-2 block mb-1  text-gray-500 leading-5">
                                                    <span className="font-light">
                                                        Token status:
                                                    </span>
                                                    <span className="font-medium">
                                                        {cred?.status
                                                            ? 'valid'
                                                            : 'invalid'}
                                                    </span>
                                                </p>
                                                <p className="flex   gap-x-2 block mb-1  text-gray-500 leading-5">
                                                    <span className="font-light">
                                                        Last checked:
                                                    </span>
                                                    <span className="font-medium">
                                                        {moment
                                                            .unix(lastChecked)
                                                            .format(
                                                                'YYYY-MM-DD HH:mm:ss Z'
                                                            )}
                                                    </span>
                                                </p>
                                                <p className="flex   gap-x-2 block mb-1  text-gray-500 leading-5">
                                                    <span className="font-light">
                                                        Email:
                                                    </span>
                                                    <span className="font-medium">
                                                        {cred?.email}
                                                    </span>
                                                </p>
                                                <p className="flex  gap-x-2 block mb-1  text-gray-500 leading-5">
                                                    <span className="font-light">
                                                        Expiration date:
                                                    </span>
                                                    <span className="font-medium">
                                                        {cred?.expiry
                                                            ? moment(
                                                                  cred?.expiry
                                                              ).format(
                                                                  'YYYY-MM-DD HH:mm:ss Z'
                                                              )
                                                            : '-'}
                                                    </span>
                                                </p>
                                                <p className="flex  gap-x-2 block mb-1  text-gray-500 leading-5">
                                                    <span className="font-light">
                                                        Client ID:
                                                    </span>
                                                    <span className="font-medium">
                                                        {cred?.client_id
                                                            ? cred?.client_id
                                                            : '-'}
                                                    </span>
                                                </p>
                                                <div className="flex gap-2 text-gray-500 leading-5">
                                                    <p className="font-light">
                                                        Scopes:
                                                    </p>
                                                    <div className="flex-grow">
                                                        {cred?.scopes?.map(
                                                            (scope, i) => (
                                                                <p
                                                                    key={
                                                                        i +
                                                                        scope
                                                                    }
                                                                    className="font-medium"
                                                                >
                                                                    {scope}
                                                                </p>
                                                            )
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            {credsErrors[cred?.type] ? (
                                                <p className="text-red-text mt-2 ">
                                                    Authorization error:{' '}
                                                    {credsErrors[cred?.type]}
                                                </p>
                                            ) : null}
                                            {cred?.error ? (
                                                <p className="text-red-text mt-2 ">
                                                    Validation error:{' '}
                                                    {cred?.error}
                                                </p>
                                            ) : null}
                                            <div className="mt-6">
                                                <SaveButton
                                                    saveFunc={() =>
                                                        cred?.status
                                                            ? handleCheck()
                                                            : handleAuth(
                                                                  cred?.type
                                                              )
                                                    }
                                                    text={
                                                        cred?.status
                                                            ? 'Check validity'
                                                            : 'Authorize'
                                                    }
                                                    style={{
                                                        margin: '0',
                                                        color: '#1FB8D3',
                                                        borderColor: '#1FB8D3',
                                                        fontFamily: `${[
                                                            'Exo\\ 2',
                                                            'sans-serif',
                                                            'Arial',
                                                        ]}`,
                                                        fontWeight: '600',
                                                        lineHeight: '20px',
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    ))}
                                    {credsState?.data?.length < 2 ? (
                                        <div className="flex items-center justify-center gap-2 rounded border border-solid border-gray-400 p-4">
                                            {credsErrors[
                                                TYPES?.find(
                                                    (typeName) =>
                                                        typeName !==
                                                        credsState?.data[0]
                                                            ?.type
                                                )
                                            ] ? (
                                                <p className="text-red-text mt-2 ">
                                                    Authorization error:{' '}
                                                    {
                                                        credsErrors[
                                                            TYPES?.find(
                                                                (typeName) =>
                                                                    typeName !==
                                                                    credsState
                                                                        ?.data[0]
                                                                        ?.type
                                                            )
                                                        ]
                                                    }
                                                </p>
                                            ) : null}
                                            <SaveButton
                                                saveFunc={() =>
                                                    handleAuth(
                                                        TYPES?.find(
                                                            (typeName) =>
                                                                typeName !==
                                                                credsState
                                                                    ?.data[0]
                                                                    ?.type
                                                        )
                                                    )
                                                }
                                                text={
                                                    'Authorize ' +
                                                    TYPES?.find(
                                                        (typeName) =>
                                                            typeName !==
                                                            credsState?.data[0]
                                                                ?.type
                                                    )
                                                }
                                                style={{
                                                    margin: '0',
                                                    color: '#1FB8D3',
                                                    borderColor: '#1FB8D3',
                                                    fontFamily: `${[
                                                        'Exo\\ 2',
                                                        'sans-serif',
                                                        'Arial',
                                                    ]}`,
                                                    fontWeight: '600',
                                                    lineHeight: '20px',
                                                }}
                                            />
                                        </div>
                                    ) : null}
                                </>
                            ) : (
                                <div className="col-span-2 text-center py-8 text-gray-500 text-lg">
                                    <p>There are no authorized credentials.</p>
                                    <div className="flex justify-center items-center gap-4 mt-8">
                                        {TYPES.map((typeName) => (
                                            <SaveButton
                                                saveFunc={() =>
                                                    handleAuth(typeName)
                                                }
                                                text={'Authorize ' + typeName}
                                                style={{
                                                    margin: '0',
                                                    color: '#1FB8D3',
                                                    borderColor: '#1FB8D3',
                                                    fontFamily: `${[
                                                        'Exo\\ 2',
                                                        'sans-serif',
                                                        'Arial',
                                                    ]}`,
                                                    fontWeight: '600',
                                                    lineHeight: '20px',
                                                }}
                                            />
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            )}
        </WhitePanelContentWrapper>
    );
}

export default AuthTokenCard;
