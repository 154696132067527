import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import AuthPasswordFormFields from '../auth-password-form-fields';
import useParamsQuery from '../../hooks/useParamsQuery';

function AuthPasswordForm({ endpoint, onSubmitFunc }) {
    const { t } = useTranslation();
    const query = useParamsQuery();

    switch (endpoint) {
        case 'login':
            return <></>;
        case 'invite':
        case 'signup':
        case 'trial':
        case 'explore':
        case 'demo':
        default:
            return (
                <Formik
                    initialValues={{
                        email: query.get('email'),
                        // password: '',
                        first_name: query.get('first_name'),
                        last_name: query.get('last_name'),
                    }}
                    validationSchema={Yup.object({
                        email: Yup.string()
                            .email(t('common.form.invalid_email_address'))
                            .required(t('common.form.required')),
                        // password: Yup.string()
                        //     .min(6, t('common.form.too_short'))
                        //     .required(t('common.form.required')),
                    })}
                    enableReinitialize={true}
                    onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(true);
                        onSubmitFunc(values, () => setSubmitting(false));
                    }}
                >
                    {({ isSubmitting, values, errors, touched }) => {
                        return (
                            <Form>
                                <AuthPasswordFormFields
                                    endpoint={endpoint}
                                    values={values}
                                    errors={errors}
                                    isSubmitting={isSubmitting}
                                />
                            </Form>
                        );
                    }}
                </Formik>
            );
    }
}

export default AuthPasswordForm;
