import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import './styles.scss';
import OverviewRatingBlock from '../overview-rating-block';

const OverviewInfoBlock = ({ data }) => {
    const {
        datetime,
        previousRating,
        previousTimeperiod,
        rating,
        timeperiod,
        type,
    } = data;
    const { t } = useTranslation();
    const title = `overview_page.${type}.title`;
    const description = `overview_page.${type}.description`;

    return (
        <>
            <div className="flex justify-between mb-1.5">
                <h3 className="font-display text-gray-700 font-bold text-lg	leading-5">
                    {t(title)}
                </h3>
                <p className="text-gray-500 leading-6 whitespace-normal">
                    {`${moment(datetime)
                        .calendar(null, {
                            sameDay: function () {
                                if (this.locale() === 'nl') {
                                    return '[vandaag om] H:mm';
                                }
                                return '[Today] H:mm';
                            },
                            nextDay: function () {
                                if (this.locale() === 'nl') {
                                    return '[morgen om] H:mm';
                                }
                                return '[Tomorrow] H:mm';
                            },
                            nextWeek: function () {
                                if (this.locale() === 'nl') {
                                    return 'dddd [om] H:mm';
                                }
                                return 'dddd H:mm';
                            },
                            lastDay: function () {
                                if (this.locale() === 'nl') {
                                    return '[gisteren om] H:mm';
                                }
                                return '[Yesterday] H:mm';
                            },
                            lastWeek: function () {
                                if (this.locale() === 'nl') {
                                    return '[afgelopen] dddd [om] H:mm';
                                }
                                return '[Last] dddd H:mm';
                            },
                            sameElse: 'D MMM H:mm',
                        })
                        .slice(0, 1)
                        .toUpperCase()}${moment(datetime)
                        .calendar(null, {
                            sameDay: function () {
                                if (this.locale() === 'nl') {
                                    return '[Vandaag om] H:mm';
                                }
                                return '[Today] H:mm';
                            },
                            nextDay: function () {
                                if (this.locale() === 'nl') {
                                    return '[morgen om] H:mm';
                                }
                                return '[Tomorrow] H:mm';
                            },
                            nextWeek: function () {
                                if (this.locale() === 'nl') {
                                    return 'dddd [om] H:mm';
                                }
                                return 'dddd H:mm';
                            },
                            lastDay: function () {
                                if (this.locale() === 'nl') {
                                    return '[gisteren om] H:mm';
                                }
                                return '[Yesterday] H:mm';
                            },
                            lastWeek: function () {
                                if (this.locale() === 'nl') {
                                    return '[afgelopen] dddd [om] H:mm';
                                }
                                return '[Last] dddd H:mm';
                            },
                            sameElse: 'D MMM H:mm',
                        })
                        .slice(1)}`}
                </p>
            </div>
            <p className="text-gray-500 leading-6 text-sm mb-1">
                {t(description)}
            </p>
            <OverviewRatingBlock
                previousRating={previousRating}
                previousTimeperiod={previousTimeperiod}
                rating={rating}
                timeperiod={timeperiod}
            />
        </>
    );
};

export default OverviewInfoBlock;
