import * as Yup from 'yup';
import showNotification from './showNotification';

// CONSTANTS
export const METHODS_OPTIONS_MAP = {
    ratio: 'good_bad_ratio',
    distribution: 'distribution_cut',
};

export const methodOptions = [
    { value: METHODS_OPTIONS_MAP.ratio, label: 'Good Bad Ratio' },
    { value: METHODS_OPTIONS_MAP.distribution, label: 'Distribution Cut' },
];

export const validationSchema = Yup.object({
    feature_name: Yup.string().required('Service name is required'),
    slo_name: Yup.string().required('Description is required'),
    env: Yup.string().required('Enviroment is required'),
    goal: Yup.number()
        .required('Goal is required')
        .min(0, 'Goal must be greater than 0')
        .max(1, 'Goal must be less than 1'),
    backend: Yup.string().required('Backend is required'),
    project_id: Yup.string(), //TODO: update when API is ready for prod to Yup.string().required('Project ID is required')
    method: Yup.string().required('Method is required'),
    ratio_options: Yup.array().of(
        Yup.object().shape({
            filter_bad: Yup.string(),
            filter_good: Yup.string(),
            filter_valid: Yup.string(),
        })
    ),
    ratio_options_aws: Yup.array().of(
        Yup.object().shape({
            metric_source_bad: Yup.string(),
            metric_source_good: Yup.string(),
            metric_source_valid: Yup.string(),
        })
    ),
    threshold_bucket: Yup.number()
        .integer('Threshold Bucket must be an integer')
        .nullable(true),
    threshold_value: Yup.number()
        .min(0, 'Threshold value must be greater than 0')
        .nullable(true),
    good_below_threshold: Yup.boolean().default(true),
});

// FUNCTIONS
export function handlerChangeMethod(
    evt,
    field,
    values,
    setValues,
    backendsList
) {
    const isAWS =
        backendsList?.find((backend) => backend.name === values.backend)
            ?.type === 'cloud_watch';

    let options = {};
    const method = evt.target.value;

    switch (method) {
        case METHODS_OPTIONS_MAP.ratio: {
            if (isAWS) {
                options = {
                    distribution_options_aws: [],
                    ratio_options_aws: [
                        {
                            metric_source_good: '',
                            metric_source_bad: '',
                            metric_source_valid: '',
                        },
                    ],
                };
            } else {
                options = {
                    distribution_options: [],
                    ratio_options: [
                        {
                            filter_good: '',
                            filter_bad: '',
                            filter_valid: '',
                        },
                    ],
                };
            }
            break;
        }
        case METHODS_OPTIONS_MAP.distribution: {
            if (isAWS) {
                options = {
                    ratio_options_aws: [],
                    distribution_options_aws: [
                        {
                            metric_source_valid: '',
                        },
                    ],
                    threshold_value: '',
                    good_below_threshold: true,
                };
            } else {
                options = {
                    ratio_options: [],
                    distribution_options: [
                        {
                            filter_valid: '',
                        },
                    ],
                    threshold_bucket: '',
                    good_below_threshold: true,
                };
            }
            break;
        }
        case 'default': {
            options = {
                ratio_options: [],
                distribution_options: [],
                ratio_options_aws: [],
                distribution_options_aws: [],
                threshold_bucket: '',
                threshold_value: '',
                good_below_threshold: true,
            };
            break;
        }
    }

    setValues({ ...values, ...options });

    field.onChange(evt);
}

export function handleBackendChange(evt, field, values, setValues) {
    let options = {};
    switch (values.method) {
        case METHODS_OPTIONS_MAP.ratio: {
            if (
                !values.ratio_options?.length &&
                values.ratio_options_aws?.length
            ) {
                options = {
                    ratio_options: [
                        {
                            filter_good:
                                values?.ratio_options_aws[0]
                                    ?.metric_source_good,
                            filter_bad:
                                values?.ratio_options_aws[0]?.metric_source_bad,
                            filter_valid:
                                values?.ratio_options_aws[0]
                                    ?.metric_source_valid,
                        },
                    ],
                };
            } else {
                options = {
                    ratio_options_aws: [
                        {
                            metric_source_good:
                                values?.ratio_options[0]?.filter_good,
                            metric_source_bad:
                                values?.ratio_options[0]?.filter_bad,
                            metric_source_valid:
                                values?.ratio_options[0]?.filter_valid,
                        },
                    ],
                };
            }
            break;
        }
        case METHODS_OPTIONS_MAP.distribution: {
            if (
                !values.distribution_options?.length &&
                values.distribution_options_aws?.length
            ) {
                options = {
                    distribution_options: [
                        {
                            filter_valid:
                                values?.distribution_options_aws[0]
                                    ?.metric_source_valid,
                        },
                    ],
                    threshold_bucket: 0,
                };
            } else {
                options = {
                    distribution_options_aws: [
                        {
                            metric_source_valid:
                                values?.distribution_options[0]?.filter_valid,
                        },
                    ],
                    threshold_value: 0,
                };
            }
            break;
        }
        case 'default': {
            break;
        }
    }
    setValues({ ...values, ...options });

    field.onChange(evt);
}

export function getFeaturesData(values) {
    const options = values.ratio_options?.length
        ? { ratio_options: { ...values.ratio_options[0] } }
        : {
              distribution_options: { ...values.distribution_options[0] },
              threshold_bucket: values.threshold_bucket,
              threshold_value: values.threshold_value,
              good_below_threshold: values.good_below_threshold,
          };

    // validating filters for Ratio
    if (
        values.ratio_options?.length &&
        !Object.values(values.ratio_options[0]).find((item) => item !== '')
    ) {
        showNotification('Please add at least one filter', 'error');
        return;
    }

    if (values.distribution_options?.length && !values.threshold_bucket) {
        showNotification('Threshold Bucket is required', 'error');
        return;
    }

    const data = {
        feature_name: values.feature_name,
        slo_name: values.slo_name,
        team_name: values.team_name,
        env: values.env,
        goal: values.goal,
        backend: values.backend,
        method: values.method,
        ...options,
    };

    return { data, options };
}

export function getFeaturesDataAWS(values) {
    const options = values.ratio_options_aws?.length
        ? { ratio_options: { ...values.ratio_options_aws[0] } }
        : {
              distribution_options: {
                  ...values.distribution_options_aws[0],
              },
              threshold_bucket: values.threshold_bucket,
              threshold_value: values.threshold_value,
              good_below_threshold: values.good_below_threshold,
          };

    // validating filters for Ratio
    if (
        values.ratio_options_aws?.length &&
        !Object.values(values.ratio_options_aws[0]).find((item) => item !== '')
    ) {
        showNotification('Please add at least one metric source', 'error');
        return;
    }

    if (values.distribution_options_aws?.length && !values.threshold_value) {
        showNotification('Threshold Value is required', 'error');
        return;
    }

    const data = {
        feature_name: values.feature_name,
        slo_name: values.slo_name,
        team_name: values.team_name,
        env: values.env,
        goal: values.goal,
        backend: values.backend,
        method: values.method,
        ...options,
    };

    return { data, options };
}
