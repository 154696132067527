import GenericTableHeaderColumn from './generic-table-header-column';

const GenericTableHeader = ({
    repositoryName,
    makeSort,
    columns,
    colsSpan,
}) => {
    return (
        <div className={`generic-table__header grid grid-cols-${colsSpan}`}>
            {columns.map((headerItem, index) => {
                return (
                    <GenericTableHeaderColumn
                        key={`${repositoryName}_${headerItem.columnId}_${index}`}
                        headerItem={headerItem}
                        makeSort={makeSort}
                    />
                );
            })}
        </div>
    );
};

export default GenericTableHeader;
