import React, { useReducer, createContext } from 'react';

const orgUserListReducer = (state, action) => {
    switch (action.type) {
        case 'SET_LOADING':
            return {
                data: state.data,
                loading: true,
                loadingUserpics: true,
                error: null,
            };
        case 'SET_NO_LOADING':
            return {
                data: state.data,
                loading: false,
                loadingUserpics: false,
                error: null,
            };

        case 'SET_LOADING_USERPICS':
            return {
                data: state.data,
                loading: state.loading,
                loadingUserpics: true,
                error: null,
            };
        case 'SET_NO_LOADING_USERPICS':
            return {
                data: state.data,
                loading: false,
                loadingUserpics: false,
                error: null,
            };
        case 'SET_DATA':
            return {
                data: action.payload,
                loading: false,
                loadingUserpics: state.loadingUserpics,
                error: null,
            };
        case 'SET_DATA_WITH_USERPICS':
            return {
                data: action.payload,
                loading: false,
                loadingUserpics: false,
                error: null,
            };
        case 'SET_ERROR':
            return {
                data: state.data,
                loading: false,
                loadingUserpics: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const OrgUserListContext = createContext();

export const OrgUserListProvider = ({ children }) => {
    const [orgUserListState, orgUserListDispatch] = useReducer(
        orgUserListReducer,
        {
            data: null,
            loading: true,
            loadingUserpics: false,
            error: null,
        }
    );

    return (
        <OrgUserListContext.Provider
            value={{ orgUserListState, orgUserListDispatch }}
        >
            {children}
        </OrgUserListContext.Provider>
    );
};
