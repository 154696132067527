import { useContext, useState } from 'react';
import Select from 'react-select';
import { FaSave, FaUndo } from 'react-icons/fa';
import { ImPencil } from 'react-icons/im';

import { OrganisationsContext } from '../../context/OrganisationsContext';
import ErrorIndicator from '../error-indicator';
import Loader from '../loader';
import {
    deleteOrganisation,
    updateOrganisation,
} from '../../api/organisation/OrganisationAPI';
import { Arrow, CrossInCircle } from '../../assets/svg';
import OrganizationsListRowDetails from '../organizations-list-row-details';
import showNotification from '../../helpers/showNotification';
import setCustomStyle from '../../helpers/customSelectStyles';
import toDays from '../../helpers/convertSeconds';
import ModalWrapper from '../modal-wrapper';
import ModalDeleteOrg from '../organizations-list-row-delete-modal';

export default function OrganisationsListRow({ data, onUpdate }) {
    const {
        org_name,
        status,
        org_hash,
        subscription_level,
        retention_period,
        number_of_users,
    } = data;
    const [infoOpen, setInfoOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    const { organisationsState, organisationsDispatch } =
        useContext(OrganisationsContext);

    const [name, setName] = useState(org_name);
    const [orgStatus, setOrgStatus] = useState(status);
    const [subscription, setSubscription] = useState(subscription_level);
    const statusOptions = [
        { value: 'active', label: 'active' },
        { value: 'inactive', label: 'inactive' },
    ];

    const subscriptionOptions = [
        { value: 'free', label: 'Free' },
        { value: 'basic', label: 'Basic' },
        { value: 'professional', label: 'Professional' },
        { value: 'enterprise', label: 'Enterprise' },
    ];

    const handleUpdateOrganisation = () => {
        // validation
        if (!name) {
            setError('Name is required');
            return;
        }
        if (name.length < 3) {
            setError('Name must be at least 3 characters');
            return;
        }
        if (
            organisationsState.data
                .filter(
                    (org) =>
                        org.org_hash.toLowerCase() !== org_hash.toLowerCase()
                )
                .find((org) => org.org_name === name.trim())
        ) {
            setError('Name must be unique');
            return;
        }

        if (
            org_name === name.trim() &&
            status === orgStatus &&
            subscription_level === subscription
        ) {
            setError('No changes');
            return;
        }

        const updatedData = {
            ...data,
            org_name: name.trim(),
            status: orgStatus,
            subscription_level: subscription,
        };

        // TODO: check if Works

        setIsLoading(true);

        updateOrganisation(updatedData, org_hash)
            .then((response) => {
                if (response.status === 200) {
                    onUpdate();
                    const newOrgsData = organisationsState.data.map(
                        (organisation) => {
                            if (
                                organisation.org_hash.toLowerCase() ===
                                org_hash.toLowerCase()
                            ) {
                                const updatedOrg = {
                                    ...organisation,
                                    org_name: name,
                                    status: orgStatus,
                                    subscription_level: subscription,
                                };
                                return updatedOrg;
                            } else {
                                return organisation;
                            }
                        }
                    );

                    organisationsDispatch({
                        type: 'SET_DATA',
                        payload: newOrgsData,
                    });
                    setIsLoading(false);
                    setIsEditing(false);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                showNotification(
                    error.response?.data ||
                        error.data?.message ||
                        error.data ||
                        'Oops! Something went wrong'
                );
            });
    };

    const makeDeleteRequest = () => {
        // showNotification('This will delete organisation permanently, disabled');
        setIsLoading(true);
        setOrgStatus((state) => ({ ...state, loading: true }));
        deleteOrganisation(org_hash)
            .then((response) => {
                if (response.status === 200) {
                    onUpdate();
                    const newOrgData = organisationsState.data.filter(
                        (org) =>
                            org.org_hash.toLowerCase() !==
                            org_hash.toLowerCase()
                    );

                    organisationsDispatch({
                        type: 'SET_DATA',
                        payload: newOrgData,
                    });
                    setOrgStatus((state) => ({ ...state, loading: false }));
                }
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
            });
    };

    const handleEdit = () => {
        // for DROPDOWN
        // !isEditing && setInfoOpen(true);
        if (isEditing) {
            setError(null);
            setName(org_name);
            setOrgStatus(status);
            setSubscription(subscription_level);
        }
        setIsEditing(!isEditing);
    };

    return (
        <div className="body__row">
            <div className=" Organisation grid grid-cols-8">
                <div className="col-span-1 px-4 Organisation-name">
                    {isEditing ? (
                        <input
                            className="w-full p-1.5 leading-5 text-gray-700 placeholder-gray-400 border border-gray-300 focus:border-gray-300 rounded focus:outline-gray"
                            id="name"
                            type="text"
                            placeholder="Organisation name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    ) : (
                        org_name
                    )}
                </div>
                <div
                    className={`col-span-1 px-4 Organisation-status ${
                        !isEditing && 'flex items-center'
                    }`}
                >
                    {isEditing ? (
                        <Select
                            isSearchable={false}
                            options={statusOptions}
                            styles={setCustomStyle()}
                            value={statusOptions.find(
                                (option) => option.value === orgStatus
                            )}
                            onChange={(option) => setOrgStatus(option.value)}
                        />
                    ) : (
                        status
                    )}
                </div>
                <div
                    className={`col-span-1 px-4 ${
                        !isEditing && 'flex items-center'
                    }`}
                >
                    {isEditing ? (
                        <Select
                            isSearchable={false}
                            options={subscriptionOptions}
                            styles={setCustomStyle()}
                            value={subscriptionOptions.find(
                                (option) => option.value === subscription
                            )}
                            onChange={(option) => setSubscription(option.value)}
                        />
                    ) : (
                        subscription_level
                    )}
                </div>
                <div className="col-span-1 px-4 flex items-center">
                    {toDays(retention_period)}
                </div>
                <div className="col-span-1 px-4 flex items-center">
                    {number_of_users}
                </div>
                <div className="col-span-3 px-4 flex justify-end gap-x-4 items-center">
                    {isLoading ? (
                        <Loader
                            color={'#C2C7D7'}
                            size={35}
                            speedMultiplier={0.8}
                        />
                    ) : error ? (
                        <div className="text-sm text-center text-red-text">
                            {error.response?.data ||
                                error.data?.message ||
                                error.data ||
                                error}
                        </div>
                    ) : null}
                    <button onClick={() => handleEdit()}>
                        {isEditing ? <FaUndo /> : <ImPencil />}
                    </button>
                    {isEditing && (
                        <button onClick={() => handleUpdateOrganisation()}>
                            <FaSave />
                        </button>
                    )}
                    {/* DELETE ORGANIZATION BUTTON */}
                    <button onClick={() => setIsDeleteModalOpen(true)}>
                        <CrossInCircle fill="#bd2130" width={25} />
                    </button>
                </div>
                {/* DROPDOWN */}
                {/* <div className="col-span-1 px-4 flex justify-end gap-x-4 items-center">
                    <button className="" onClick={() => setInfoOpen(!infoOpen)}>
                        <Arrow
                            width="16px"
                            height="16px"
                            fill="#484a53"
                            transform={infoOpen ? 'rotate(180)' : 'rotate(0)'}
                        />
                    </button>
                </div> */}
            </div>
            {/* DROPDOWN */}
            {/* {infoOpen && <OrganizationsListRowDetails />} */}
            <ModalWrapper
                modalState={isDeleteModalOpen}
                setModalState={setIsDeleteModalOpen}
                title={`Delete ${org_name ? org_name : 'organisation'}`}
                height={300}
            >
                <ModalDeleteOrg
                    setModalState={setIsDeleteModalOpen}
                    sendRequest={makeDeleteRequest}
                    orgName={org_name}
                />
            </ModalWrapper>
        </div>
    );
}
