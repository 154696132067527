export const colors = [
    { main: 'rgba(21, 162, 187, 1)', hover: 'rgba(21, 162, 187, 0.7)' },
    { main: 'rgba(84, 175, 196, 1)', hover: 'rgba(84, 175, 196, 0.7)' },
    { main: 'rgba(121, 188, 205, 1)', hover: 'rgba(121, 188, 205, 0.7)' },
    { main: 'rgba(153, 201, 214, 1)', hover: 'rgba(153, 201, 214, 0.7)' },
    { main: 'rgba(183, 214, 223, 1)', hover: 'rgba(183, 214, 223, 0.7)' },
    { main: 'rgba(212, 227, 232, 1)', hover: 'rgba(212, 227, 232, 0.7)' },
    { main: 'rgba(241, 241, 241, 1)', hover: 'rgba(241, 241, 241, 0.7)' },
    { main: 'rgba(247, 219, 222, 1)', hover: 'rgba(247, 219, 222, 0.7)' },
    { main: 'rgba(252, 197, 203, 1)', hover: 'rgba(252, 197, 203, 0.7)' },
    { main: 'rgba(255, 174, 185, 1)', hover: 'rgba(255, 174, 185, 0.7)' },
    { main: 'rgba(255, 151, 167, 1)', hover: 'rgba(255, 151, 167, 0.7)' },
    { main: 'rgba(255, 126, 149, 1)', hover: 'rgba(255, 126, 149, 0.7)' },
];

//website for generating color sets https://learnui.design/tools/data-color-picker.html#divergent
// hex to rgba  https://rgbacolorpicker.com/hex-to-rgba
// using Divergent, 13 colors, from #15a2bb to #ff6384

// SET #1:
// [
//     { main: 'rgba(21, 162, 187, 1)', hover: 'rgba(21, 162, 187, 0.7)' },
//     { main: 'rgba(84, 175, 196, 1)', hover: 'rgba(84, 175, 196, 0.7)' },
//     { main: 'rgba(121, 188, 205, 1)', hover: 'rgba(121, 188, 205, 0.7)' },
//     { main: 'rgba(153, 201, 214, 1)', hover: 'rgba(153, 201, 214, 0.7)' },
//     { main: 'rgba(183, 214, 223, 1)', hover: 'rgba(183, 214, 223, 0.7)' },
//     { main: 'rgba(212, 227, 232, 1)', hover: 'rgba(212, 227, 232, 0.7)' },
//     { main: 'rgba(241, 241, 241, 1)', hover: 'rgba(241, 241, 241, 0.7)' },
//     { main: 'rgba(247, 219, 222, 1)', hover: 'rgba(247, 219, 222, 0.7)' },
//     { main: 'rgba(252, 197, 203, 1)', hover: 'rgba(252, 197, 203, 0.7)' },
//     { main: 'rgba(255, 174, 185, 1)', hover: 'rgba(255, 174, 185, 0.7)' },
//     { main: 'rgba(255, 151, 167, 1)', hover: 'rgba(255, 151, 167, 0.7)' },
//     { main: 'rgba(255, 126, 149, 1)', hover: 'rgba(255, 126, 149, 0.7)' },
// ];

//SET #2
// [
//     { main: 'rgba(21, 162, 187, 1)', hover: 'rgba(21, 162, 187, 0.7)' },
//     { main: 'rgba(255, 99, 132, 1)', hover: 'rgba(255, 99, 132, 1)' },
//     { main: 'rgba(248, 194, 56, 1)', hover: 'rgba(248, 194, 56, 1)' },
//     { main: 'rgba(121, 2, 215, 1)', hover: 'rgba(121, 2, 215, 1)' },
//     { main: 'rgba(98, 191, 208, 1)', hover: 'rgba(98, 191, 208, 1)' },
//     { main: 'rgba(255, 146, 167, 1)', hover: 'rgba(255, 146, 167, 1)' },
//     { main: 'rgba(252, 209, 110, 1)', hover: 'rgba(252, 209, 110, 1)' },
//     { main: 'rgba(181, 95, 225, 1)', hover: 'rgba(181, 95, 225, 1)' },
//     { main: 'rgba(148, 220, 231, 1)', hover: 'rgba(148, 220, 231, 1)' },
//     { main: 'rgba(255, 188, 201, 1)', hover: 'rgba(255, 188, 201, 1)' },
//     { main: 'rgba(255, 223, 157, 1)', hover: 'rgba(255, 223, 157, 1)' },
//     { main: 'rgba(223, 164, 238, 1)', hover: 'rgba(223, 164, 238, 1)' },
//     { main: 'rgba(195, 250, 255, 1)', hover: 'rgba(195, 250, 255, 1)' },
//     { main: 'rgba(255, 228, 233, 1)', hover: 'rgba(255, 228, 233, 1)' },
//     { main: 'rgba(255, 238, 204, 1)', hover: 'rgba(255, 238, 204, 1)' },
//     { main: 'rgba(254, 233, 255, 1)', hover: 'rgba(254, 233, 255, 1)' },
// ];

// SET # 3
// [
//     { main: 'rgba(48, 144, 179, 1)', hover: 'rgba(48, 144, 179, 0.7)' },
//     { main: 'rgba(128, 195, 215, 1)', hover: 'rgba(128, 195, 215, 0.7)' },
//     { main: 'rgba(201, 248, 255, 1)', hover: 'rgba(201, 248, 255, 0.7)' },
//     { main: 'rgba(255, 99, 132, 1)', hover: 'rgba(255, 99, 132, 0.7)' },
//     { main: 'rgba(255, 167, 184, 1)', hover: 'rgba(255, 167, 184, 0.7)' },
//     { main: 'rgba(255, 228, 233, 1)', hover: 'rgba(255, 228, 233, 0.7)' },
//     { main: 'rgba(248, 194, 56, 1)', hover: 'rgba(248, 194, 56, 0.7)' },
//     { main: 'rgba(254, 216, 134, 1)', hover: 'rgba(254, 216, 134, 0.7)' },
//     { main: 'rgba(255, 238, 204, 1)', hover: 'rgba(255, 238, 204, 0.7)' },
//     { main: 'rgba(121, 2, 215, 1)', hover: 'rgba(121, 2, 215, 0.7)' },
//     { main: 'rgba(204, 130, 231, 1)', hover: 'rgba(204, 130, 231, 0.7)' },
//     { main: 'rgba(254, 233, 255, 1)', hover: 'rgba(254, 233, 255, 0.7)' },
// ];

export const groupedColors = [
    //blue
    [
        {
            main: 'rgba(21, 162, 187, 1)',
            hover: 'rgba(21, 162, 187, 0.7)',
            hex: '15a2bb',
        },
        {
            main: 'rgba(68, 181, 201, 1)',
            hover: 'rgba(68, 181, 201, 0.7)',
            hex: '44b5c9',
        },
        {
            main: 'rgba(115, 199, 214, 1)',
            hover: 'rgba(115, 199, 214, 0.7)',
            hex: '73c7d6',
        },
        {
            main: 'rgba(161, 218, 228, 1)',
            hover: 'rgba(161, 218, 228, 0.7)',
            hex: 'a1dae4',
        },
        {
            main: 'rgba(208, 236, 241, 1)',
            hover: 'rgba(208, 236, 241, 0.7)',
            hex: 'd0ecf1',
        },
        {
            main: 'rgba(241, 241, 241, 1)',
            hover: 'rgba(241, 241, 241, 0.7)',
            hex: '118296',
        },
        {
            main: 'rgba(17, 130, 150, 1)',
            hover: 'rgba(17, 130, 150, 0.7)',
            hex: '0d6170',
        },
        {
            main: 'rgba(8, 65, 75, 1)',
            hover: 'rgba(8, 65, 75, 0.7)',
            hex: '08414b',
        },
        // ======
        {
            main: 'rgba(44, 171, 194, 1)',
            hover: 'rgba(44, 171, 194, 0.7)',
            hex: '2cabc2',
        },
        {
            main: 'rgba(91, 190, 207, 1)',
            hover: 'rgba(91, 190, 207, 0.7)',
            hex: '5bbecf',
        },
        {
            main: 'rgba(138, 209, 221, 1)',
            hover: 'rgba(138, 209, 221, 0.7)',
            hex: '8ad1dd',
        },
        {
            main: 'rgba(185, 227, 235, 1)',
            hover: 'rgba(185, 227, 235, 0.7)',
            hex: 'b9e3eb',
        },
        {
            main: 'rgba(232, 246, 248, 1)',
            hover: 'rgba(232, 246, 248, 0.7)',
            hex: 'e8f6f8',
        },
        {
            main: 'rgba(19, 146, 168, 1)',
            hover: 'rgba(19, 146, 168, 0.7)',
            hex: '1392a8',
        },
        {
            main: 'rgba(15, 113, 131, 1)',
            hover: 'rgba(15, 113, 131, 0.7)',
            hex: '0f7183',
        },
        {
            main: 'rgba(11, 81, 94, 1)',
            hover: 'rgba(11, 81, 94, 0.7)',
            hex: '0b515e',
        },
    ],
    //pink
    [
        {
            main: 'rgba(255, 99, 132, 1)',
            hover: 'rgba(255, 99, 132, 0.7)',
            hex: 'ff6384',
        },
        {
            main: 'rgba(255, 130, 157, 1)',
            hover: 'rgba(255, 130, 157, 0.7)',
            hex: 'ff829d',
        },
        {
            main: 'rgba(115, 199, 214, 1)',
            hover: 'rgba(115, 199, 214, 0.7)',
            hex: 'ffa1b5',
        },
        {
            main: 'rgba(255, 161, 181, 1)',
            hover: 'rgba(255, 161, 181, 0.7)',
            hex: 'ffc1ce',
        },
        {
            main: 'rgba(255, 224, 230, 1)',
            hover: 'rgba(255, 224, 230, 0.7)',
            hex: 'ffe0e6',
        },
        {
            main: 'rgba(204, 79, 106, 1)',
            hover: 'rgba(204, 79, 106, 0.7)',
            hex: 'cc4f6a',
        },
        {
            main: 'rgba(153, 59, 79, 1)',
            hover: 'rgba(153, 59, 79, 0.7)',
            hex: '993b4f',
        },
        {
            main: 'rgba(102, 40, 53, 1)',
            hover: 'rgba(102, 40, 53, 0.7)',
            hex: '662835',
        },
        // ======
        {
            main: 'rgba(255, 115, 144, 1)',
            hover: 'rgba(255, 115, 144, 0.7)',
            hex: 'ff7390',
        },
        {
            main: 'rgba(255, 146, 169, 1)',
            hover: 'rgba(255, 146, 169, 0.7)',
            hex: 'ff92a9',
        },
        {
            main: 'rgba(255, 177, 194, 1)',
            hover: 'rgba(255, 177, 194, 0.7)',
            hex: 'ffb1c2',
        },
        {
            main: 'rgba(255, 208, 218, 1)',
            hover: 'rgba(255, 208, 218, 0.7)',
            hex: 'ffd0da',
        },
        {
            main: 'rgba(255, 239, 243, 1)',
            hover: 'rgba(255, 239, 243, 0.7)',
            hex: 'ffeff3',
        },
        {
            main: 'rgba(230, 89, 119, 1)',
            hover: 'rgba(230, 89, 119, 0.7)',
            hex: 'e65977',
        },
        {
            main: 'rgba(179, 69, 92, 1)',
            hover: 'rgba(179, 69, 92, 0.7)',
            hex: 'b3455c',
        },
        {
            main: 'rgba(128, 50, 66, 1)',
            hover: 'rgba(128, 50, 66, 0.7)',
            hex: '803242',
        },
    ],
    //light-green
    [
        {
            main: 'rgba(214, 218, 51, 1)',
            hover: 'rgba(214, 218, 51, 0.7)',
            hex: 'd6da33',
        },
        {
            main: 'rgba(222, 225, 92, 1)',
            hover: 'rgba(222, 225, 92, 0.7)',
            hex: 'dee15c',
        },
        {
            main: 'rgba(230, 233, 133, 1)',
            hover: 'rgba(230, 233, 133, 0.7)',
            hex: 'e6e985',
        },
        {
            main: 'rgba(239, 240, 173, 1)',
            hover: 'rgba(239, 240, 173, 0.7)',
            hex: 'eff0ad',
        },
        {
            main: 'rgba(247, 248, 214, 1)',
            hover: 'rgba(247, 248, 214, 0.7)',
            hex: 'f7f8d6',
        },
        {
            main: 'rgba(171, 174, 41, 1)',
            hover: 'rgba(171, 174, 41, 0.7)',
            hex: 'abae29',
        },
        {
            main: 'rgba(128, 131, 31, 1)',
            hover: 'rgba(128, 131, 31, 0.7)',
            hex: '80831f',
        },
        {
            main: 'rgba(86, 87, 20, 1)',
            hover: 'rgba(86, 87, 20, 0.7)',
            hex: '565714',
        },
        // ======
        {
            main: 'rgba(218, 222, 71, 1)',
            hover: 'rgba(218, 222, 71, 0.7)',
            hex: 'dade47',
        },
        {
            main: 'rgba(226, 229, 112, 1)',
            hover: 'rgba(226, 229, 112, 0.7)',
            hex: 'e2e570',
        },
        {
            main: 'rgba(235, 237, 153, 1)',
            hover: 'rgba(235, 237, 153, 0.7)',
            hex: 'ebed99',
        },
        {
            main: 'rgba(243, 244, 194, 1)',
            hover: 'rgba(243, 244, 194, 0.7)',
            hex: 'f3f4c2',
        },
        {
            main: 'rgba(251, 251, 235, 1)',
            hover: 'rgba(251, 251, 235, 0.7)',
            hex: 'fbfbeb',
        },
        {
            main: 'rgba(193, 196, 46, 1)',
            hover: 'rgba(193, 196, 46, 0.7)',
            hex: 'c1c42e',
        },
        {
            main: 'rgba(150, 153, 36, 1)',
            hover: 'rgba(150, 153, 36, 0.7)',
            hex: '969924',
        },
        {
            main: 'rgba(107, 109, 26, 1)',
            hover: 'rgba(107, 109, 26, 0.7)',
            hex: '6b6d1a',
        },
    ],
    // green
    [
        {
            main: 'rgba(129, 188, 66, 1)',
            hover: 'rgba(129, 188, 66, 0.7)',
            hex: '81bc42',
        },
        {
            main: 'rgba(154, 201, 104, 1)',
            hover: 'rgba(154, 201, 104, 0.7)',
            hex: '9ac968',
        },
        {
            main: 'rgba(179, 215, 142, 1)',
            hover: 'rgba(179, 215, 142, 0.7)',
            hex: 'b3d78e',
        },
        {
            main: 'rgba(205, 228, 179, 1)',
            hover: 'rgba(205, 228, 179, 0.7)',
            hex: 'cde4b3',
        },
        {
            main: 'rgba(230, 242, 217, 1)',
            hover: 'rgba(230, 242, 217, 0.7)',
            hex: 'e6f2d9',
        },
        {
            main: 'rgba(103, 150, 53, 1)',
            hover: 'rgba(103, 150, 53, 0.7)',
            hex: '679635',
        },
        {
            main: 'rgba(77, 113, 40, 1)',
            hover: 'rgba(77, 113, 40, 0.7)',
            hex: '4d7128',
        },
        {
            main: 'rgba(52, 75, 26, 1)',
            hover: 'rgba(52, 75, 26, 0.7)',
            hex: '344b1a',
        },
        // ======
        {
            main: 'rgba(142, 195, 85, 1)',
            hover: 'rgba(142, 195, 85, 0.7)',
            hex: '8ec355',
        },
        {
            main: 'rgba(167, 208, 123, 1)',
            hover: 'rgba(167, 208, 123, 0.7)',
            hex: 'a7d07b',
        },
        {
            main: 'rgba(192, 222, 161, 1)',
            hover: 'rgba(192, 222, 161, 0.7)',
            hex: 'c0dea1',
        },
        {
            main: 'rgba(217, 235, 198, 1)',
            hover: 'rgba(217, 235, 198, 0.7)',
            hex: 'd9ebc6',
        },
        {
            main: 'rgba(242, 248, 236, 1)',
            hover: 'rgba(242, 248, 236, 0.7)',
            hex: 'f2f8ec',
        },
        {
            main: 'rgba(116, 169, 59, 1)',
            hover: 'rgba(116, 169, 59, 0.7)',
            hex: '74a93b',
        },
        {
            main: 'rgba(90, 132, 46, 1)',
            hover: 'rgba(90, 132, 46, 0.7)',
            hex: '5a842e',
        },
        {
            main: 'rgba(65, 94, 33, 1)',
            hover: 'rgba(65, 94, 33, 0.7)',
            hex: '415e21',
        },
    ],
    // yellow
    [
        {
            main: 'rgba(248, 194, 56, 1)',
            hover: 'rgba(248, 194, 56, 0.7)',
            hex: 'f8c238',
        },
        {
            main: 'rgba(249, 206, 96, 1)',
            hover: 'rgba(249, 206, 96, 0.7)',
            hex: 'f9ce60',
        },
        {
            main: 'rgba(251, 218, 136, 1)',
            hover: 'rgba(251, 218, 136, 0.7)',
            hex: 'fbda88',
        },
        {
            main: 'rgba(252, 231, 175, 1)',
            hover: 'rgba(252, 231, 175, 0.7)',
            hex: 'fce7af',
        },
        {
            main: 'rgba(254, 243, 215, 1)',
            hover: 'rgba(254, 243, 215, 0.7)',
            hex: 'fef3d7',
        },
        {
            main: 'rgba(198, 155, 45, 1)',
            hover: 'rgba(198, 155, 45, 0.7)',
            hex: 'c69b2d',
        },
        {
            main: 'rgba(149, 116, 34, 1)',
            hover: 'rgba(149, 116, 34, 0.7)',
            hex: '957422',
        },
        {
            main: 'rgba(99, 78, 22, 1)',
            hover: 'rgba(99, 78, 22, 0.7)',
            hex: '634e16',
        },
        // ======
        {
            main: 'rgba(249, 200, 76, 1)',
            hover: 'rgba(249, 200, 76, 0.7)',
            hex: 'f9c84c',
        },
        {
            main: 'rgba(250, 212, 116, 1)',
            hover: 'rgba(250, 212, 116, 0.7)',
            hex: 'fad474',
        },
        {
            main: 'rgba(252, 225, 156, 1)',
            hover: 'rgba(252, 225, 156, 0.7)',
            hex: 'fce19c',
        },
        {
            main: 'rgba(253, 237, 195, 1)',
            hover: 'rgba(253, 237, 195, 0.7)',
            hex: 'fdedc3',
        },
        {
            main: 'rgba(254, 249, 235, 1)',
            hover: 'rgba(254, 249, 235, 0.7)',
            hex: 'fef9eb',
        },
        {
            main: 'rgba(223, 175, 50, 1)',
            hover: 'rgba(223, 175, 50, 0.7)',
            hex: 'dfaf32',
        },
        {
            main: 'rgba(174, 136, 39, 1)',
            hover: 'rgba(174, 136, 39, 0.7)',
            hex: 'ae8827',
        },
        {
            main: 'rgba(124, 97, 28, 1)',
            hover: 'rgba(124, 97, 28, 0.7)',
            hex: '7c611c',
        },
    ],
    // purple
    [
        {
            main: 'rgba(148, 53, 224, 1)',
            hover: 'rgba(148, 53, 224, 0.7)',
            hex: '9435e0',
        },
        {
            main: 'rgba(169, 93, 230, 1)',
            hover: 'rgba(169, 93, 230, 0.7)',
            hex: 'a95de6',
        },
        {
            main: 'rgba(191, 134, 236, 1)',
            hover: 'rgba(191, 134, 236, 0.7)',
            hex: 'bf86ec',
        },
        {
            main: 'rgba(212, 174, 243, 1)',
            hover: 'rgba(212, 174, 243, 0.7)',
            hex: 'd4aef3',
        },
        {
            main: 'rgba(234, 215, 249, 1)',
            hover: 'rgba(234, 215, 249, 0.7)',
            hex: 'ead7f9',
        },
        {
            main: 'rgba(118, 42, 179, 1)',
            hover: 'rgba(118, 42, 179, 0.7)',
            hex: '762ab3',
        },
        {
            main: 'rgba(89, 32, 134, 1)',
            hover: 'rgba(89, 32, 134, 0.7)',
            hex: '592086',
        },
        {
            main: 'rgba(59, 21, 90, 1)',
            hover: 'rgba(59, 21, 90, 0.7)',
            hex: '3b155a',
        },
        // ======
        {
            main: 'rgba(159, 73, 227, 1)',
            hover: 'rgba(159, 73, 227, 0.7)',
            hex: '9f49e3',
        },
        {
            main: 'rgba(180, 114, 233, 1)',
            hover: 'rgba(180, 114, 233, 0.7)',
            hex: 'b472e9',
        },
        {
            main: 'rgba(202, 154, 240, 1)',
            hover: 'rgba(202, 154, 240, 0.7)',
            hex: 'ca9af0',
        },
        {
            main: 'rgba(223, 194, 246, 1)',
            hover: 'rgba(223, 194, 246, 0.7)',
            hex: 'dfc2f6',
        },
        {
            main: 'rgba(244, 235, 252, 1)',
            hover: 'rgba(244, 235, 252, 0.7)',
            hex: 'f4ebfc',
        },
        {
            main: 'rgba(133, 48, 202, 1)',
            hover: 'rgba(133, 48, 202, 0.7)',
            hex: '8530ca',
        },
        {
            main: 'rgba(104, 37, 157, 1)',
            hover: 'rgba(104, 37, 157, 0.7)',
            hex: '68259d',
        },
        {
            main: 'rgba(74, 27, 112, 1)',
            hover: 'rgba(74, 27, 112, 0.7)',
            hex: '4a1b70',
        },
    ],
    // gray
    [
        {
            main: 'rgba(72, 74, 83, 1)',
            hover: 'rgba(72, 74, 83, 0.7)',
            hex: '484a53',
        },
        {
            main: 'rgba(109, 110, 117, 1)',
            hover: 'rgba(109, 110, 117, 0.7)',
            hex: '6d6e75',
        },
        {
            main: 'rgba(145, 146, 152, 1)',
            hover: 'rgba(145, 146, 152, 0.7)',
            hex: '919298',
        },
        {
            main: 'rgba(182, 183, 186, 1)',
            hover: 'rgba(182, 183, 186, 0.7)',
            hex: 'b6b7ba',
        },
        {
            main: 'rgba(218, 219, 221, 1)',
            hover: 'rgba(218, 219, 221, 0.7)',
            hex: 'dadbdd',
        },
        {
            main: 'rgba(58, 59, 66, 1)',
            hover: 'rgba(58, 59, 66, 0.7)',
            hex: '3a3b42',
        },
        {
            main: 'rgba(43, 44, 50, 1)',
            hover: 'rgba(43, 44, 50, 0.7)',
            hex: '2b2c32',
        },
        {
            main: 'rgba(29, 30, 33, 1)',
            hover: 'rgba(29, 30, 33, 0.7)',
            hex: '1d1e21',
        },
        // ======
        {
            main: 'rgba(90, 92, 100, 1)',
            hover: 'rgba(90, 92, 100, 0.7)',
            hex: '5a5c64',
        },
        {
            main: 'rgba(127, 128, 135, 1)',
            hover: 'rgba(127, 128, 135, 0.7)',
            hex: '7f8087',
        },
        {
            main: 'rgba(164, 165, 169, 1)',
            hover: 'rgba(164, 165, 169, 0.7)',
            hex: 'a4a5a9',
        },
        {
            main: 'rgba(200, 201, 203, 1)',
            hover: 'rgba(200, 201, 203, 0.7)',
            hex: 'c8c9cb',
        },
        {
            main: 'rgba(237, 237, 238, 1)',
            hover: 'rgba(237, 237, 238, 0.7)',
            hex: 'ededee',
        },
        {
            main: 'rgba(65, 67, 75, 1)',
            hover: 'rgba(65, 67, 75, 0.7)',
            hex: '41434b',
        },
        {
            main: 'rgba(50, 52, 58, 1)',
            hover: 'rgba(50, 52, 58, 0.7)',
            hex: '32343a',
        },
        {
            main: 'rgba(36, 37, 42, 1)',
            hover: 'rgba(36, 37, 42, 0.7)',
            hex: '24252a',
        },
    ],
    // dark orange
    [
        {
            main: 'rgba(227, 127, 34, 1)',
            hover: 'rgba(227, 127, 34, 0.7)',
            hex: 'e37f22',
        },
        {
            main: 'rgba(233, 153, 78, 1)',
            hover: 'rgba(233, 153, 78, 0.7)',
            hex: 'e9994e',
        },
        {
            main: 'rgba(238, 178, 122, 1)',
            hover: 'rgba(238, 178, 122, 0.7)',
            hex: 'eeb27a',
        },
        {
            main: 'rgba(244, 204, 167, 1)',
            hover: 'rgba(244, 204, 167, 0.7)',
            hex: 'f4cca7',
        },
        {
            main: 'rgba(249, 229, 211, 1)',
            hover: 'rgba(249, 229, 211, 0.7)',
            hex: 'f9e5d3',
        },
        {
            main: 'rgba(182, 102, 27, 1)',
            hover: 'rgba(182, 102, 27, 0.7)',
            hex: 'b6661b',
        },
        {
            main: 'rgba(136, 76, 20, 1)',
            hover: 'rgba(136, 76, 20, 0.7)',
            hex: '884c14',
        },
        {
            main: 'rgba(91, 51, 14, 1)',
            hover: 'rgba(91, 51, 14, 0.7)',
            hex: '5b330e',
        },
        // ======
        {
            main: 'rgba(230, 140, 56, 1)',
            hover: 'rgba(230, 140, 56, 0.7)',
            hex: 'e68c38',
        },
        {
            main: 'rgba(235, 165, 100, 1)',
            hover: 'rgba(235, 165, 100, 0.7)',
            hex: 'eba564',
        },
        {
            main: 'rgba(241, 191, 145, 1)',
            hover: 'rgba(241, 191, 145, 0.7)',
            hex: 'f1bf91',
        },
        {
            main: 'rgba(247, 217, 189, 1)',
            hover: 'rgba(247, 217, 189, 0.7)',
            hex: 'f7d9bd',
        },
        {
            main: 'rgba(252, 242, 233, 1)',
            hover: 'rgba(252, 242, 233, 0.7)',
            hex: 'fcf2e9',
        },
        {
            main: 'rgba(204, 114, 31, 1)',
            hover: 'rgba(204, 114, 31, 0.7)',
            hex: 'cc721f',
        },
        {
            main: 'rgba(159, 89, 24, 1)',
            hover: 'rgba(159, 89, 24, 0.7)',
            hex: '9f5918',
        },
        {
            main: 'rgba(114, 64, 17, 1)',
            hover: 'rgba(114, 64, 17, 0.7)',
            hex: '724011',
        },
    ],
];
