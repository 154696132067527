import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Chart from 'react-google-charts';
import ErrorIndicator from '../error-indicator';
import { TimePeriodContext } from '../../context/TimePeriodContext';
import { useDefaultStateWithReducer } from '../../helpers/useDefaultStateWithReducer';
import { getTeamKudosSankeyData } from '../../api/teams/TeamsAPI';
import Loader from '../loader';

const KudosSankeyChart = ({ selectedTeam, usersState }) => {
    const { t } = useTranslation();
    const [kudosSankeyState, kudosSankeyDispatch] =
        useDefaultStateWithReducer();

    const [timePeriodState] = useContext(TimePeriodContext);

    useEffect(() => {
        let canceled = false;
        kudosSankeyDispatch({ type: 'SET_LOADING' });
        const { date_end, date_start } = timePeriodState;
        const params = {
            params: { date_start, date_end },
        };
        if (selectedTeam) {
            getTeamKudosSankeyData(selectedTeam, params)
                .then((response) => {
                    if (!canceled) {
                        if (response.status === 204) {
                            kudosSankeyDispatch({
                                type: 'SET_DATA',
                                payload: [],
                            });
                        } else {
                            kudosSankeyDispatch({
                                type: 'SET_DATA',
                                payload: response.data,
                            });
                        }
                    }
                })
                .catch((error) => {
                    if (!canceled)
                        kudosSankeyDispatch({
                            type: 'SET_ERROR',
                            payload: error,
                        });
                });
        }
        return () => (canceled = true);
    }, [selectedTeam, timePeriodState.date_start, timePeriodState.date_end]);

    if (kudosSankeyState.loading || usersState.loading)
        return (
            <div className="flex justify-center items-center ">
                <Loader />
            </div>
        );
    if (kudosSankeyState.error)
        return (
            <div className="flex justify-center items-center ">
                <ErrorIndicator error={kudosSankeyState.error} />
            </div>
        );

    const getChartHeight = () => {
        const senders = new Set(
            kudosSankeyState.data.map((chartDataItem) => chartDataItem[0])
        );
        const recipient = new Set(
            kudosSankeyState.data.map((chartDataItem) => chartDataItem[1])
        );

        if (senders.size === recipient.size || senders.size > recipient.size) {
            return `${senders.size}00px`;
        } else {
            return `${recipient.size}00px`;
        }
    };

    const renderChart = () => {
        if (kudosSankeyState.data.length === 0) {
            return (
                <p className="block w-full text-center font-display text-gray-500 text-xl p-4">
                    {t('kudos_page.leaderboard.sankey.no_kudos')}
                </p>
            );
        } else {
            return (
                <Chart
                    width={'100%'}
                    height={getChartHeight()}
                    chartType="Sankey"
                    options={{
                        sankey: {
                            fontName: "'Roboto', sans-serif",
                            node: {
                                colors: [
                                    '#D8D95C',
                                    '#8EC358',
                                    '#EE8282',
                                    '#4C72BD',
                                    '#484A53',
                                ],

                                label: {
                                    color: '#484A53',
                                    fontSize: '16',
                                },
                            },

                            link: {
                                colorMode: 'gradient',
                                colors: [
                                    '#D8D95C',
                                    '#8EC358',
                                    '#EE8282',
                                    '#4C72BD',
                                    '#484A53',
                                ],
                            },
                        },
                    }}
                    loader={<div>Loading Chart</div>}
                    data={transformationData()}
                    rootProps={{ 'data-testid': '1' }}
                />
            );
        }
    };
    const getUserName = (userHash) => {
        let hash = userHash.split(' ').join('');

        return usersState.data.find((user) => user.hash === hash).user_name;
    };

    const transformationData = () => {
        const newData = kudosSankeyState.data.map((item) => {
            const nameFrom = getUserName(item[0]);
            const nameTo = `${getUserName(item[1])} `;
            const tooltipStr = t('kudos_page.leaderboard.sankey.tooltip', {
                from: getUserName(item[0]),
                kudos_quantity: item[2],
                to: getUserName(item[1]),
            });

            return [nameFrom, nameTo, item[2], tooltipStr];
        });

        return [
            ['From', 'To', 'Quantity', { type: 'string', role: 'tooltip' }],
            ...newData,
        ];
    };

    return (
        <div className="">
            <div className="flex justify-between mb-2 font-bold">
                <p className="font-medium">{t('kudos_page.leaderboard.sankey.gave_kudos')}</p>
                <p className="font-medium">{t('kudos_page.leaderboard.sankey.received_kudos')}</p>
            </div>
            {renderChart()}
        </div>
    );
};

export default KudosSankeyChart;
