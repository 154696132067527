import moment from 'moment';

function createSprintsArr(start, sprintDuration) {
    const oneDayDuration = 86400;

    const sprintDurationOnDays = Math.round(sprintDuration / oneDayDuration);

    const currentDate = moment().unix();

    const sprintStartDate = moment.unix(start);

    const timePassed = currentDate - sprintStartDate / 1000;

    const daysQuantity = Math.floor(timePassed / oneDayDuration) + 1;

    const sprintsQuantity = Math.ceil(daysQuantity / sprintDurationOnDays);

    function createSprintDatesArr(startDate) {
        const sprintDatesArr = [];
        let currentSprintDate = startDate;

        for (let i = 0; i < sprintDurationOnDays; i++) {
            sprintDatesArr.push(new Date(currentSprintDate));
            currentSprintDate = moment(currentSprintDate).add(1, 'd');
        }

        return sprintDatesArr;
    }

    function createSprintsArr() {
        let sprintsArr = [];

        let currentSprintStartDate = moment(sprintStartDate);
        for (let i = 0; i < sprintsQuantity; i++) {
            sprintsArr.push(createSprintDatesArr(currentSprintStartDate));
            currentSprintStartDate = moment(currentSprintStartDate).add(
                sprintDurationOnDays,
                'd'
            );
        }

        return sprintsArr;
    }

    return createSprintsArr();
}

export { createSprintsArr };
