function toDays(totalSeconds) {
    const days = Math.floor(totalSeconds / (3600 * 24));
    const daysStr = makeHumanReadable(days, 'day');

    return `${daysStr}`.replace(/,\s*$/, '');
}

function makeHumanReadable(num, singular) {
    return num > 0
        ? num + (num === 1 ? ` ${singular}, ` : ` ${singular}s, `)
        : '';
}

export default toDays;
