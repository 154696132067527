import React, { useState, useContext } from 'react';
import { InProgress, CrossInCircle, Confirm } from '../../../assets/svg';
import LogsList from './logs';
import Loader from '../../loader';
import moment from 'moment';
import ErrorIndicator from '../../error-indicator';
import { addSelectedRepositories } from '../../../api/swarm/SwarmAPI';
import { OrganisationsContext } from '../../../context/OrganisationsContext';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from '../../../helpers/textFormatters';

const SelectedRepositoryInfo = ({
    data,
    stockState,
    jobsState,
    provider,
    index,
    selectedRepositoriesDispatch,
    setAddRepositoryLoading,
    allRepositories,
    prevReposLength,
}) => {
    const { t } = useTranslation();
    const { organisationsState } = useContext(OrganisationsContext);
    const demoOrgHash = process.env.REACT_APP_DEMO_ORG_HASH;

    const selectedOrganisationHash = organisationsState?.data
        ? organisationsState.data.find((org) => org.active)?.org_hash
        : null;

    const createBranchesList = () => {
        if (stockState.loading) {
            return (
                <tr>
                    <td className="pt-2" align="center" colSpan="3">
                        <Loader />
                    </td>
                </tr>
            );
        }

        if (stockState.error) {
            return (
                <tr>
                    <td colSpan="3">
                        <ErrorIndicator error={stockState.error} />
                    </td>
                </tr>
            );
        }
        const list = [];
        for (const [key, value] of Object.entries(
            stockState.data[data.name]?.branches
        )) {
            // value["stock"] = "risky";
            // value["status"] = "success";

            // TODO: data from api
            list.push(
                <tr
                    className="border-solid border-b-2 border-gray-300"
                    key={key}
                >
                    <td className="py-3">{key}</td>
                    <td className="flex justify-center">
                        -{/*{value.stock === "risky" && (*/}
                        {/*    <span className="bg-yellow-400 rounded-md text-white text-xs w-20 h-6 flex justify-center items-center">RISKY</span>*/}
                        {/*)}*/}
                        {/*{value.stock === "heavy" && (*/}
                        {/*    <span className="bg-red-700 rounded-md text-white text-xs w-20 h-6 flex justify-center items-center">HEAVY</span>*/}
                        {/*)}*/}
                    </td>
                    <td className="text-right">-</td>
                    <td className="text-right">{value.ahead}</td>
                    <td className="text-right pr-8">{value.behind}</td>
                    <td className="flex justify-center">
                        -{/*{value.status === "success" ? (*/}
                        {/*    <Confirm width="20px" height="20px" fill="#8ec358" />*/}
                        {/*) : (*/}
                        {/*    // <WarningCircle />*/}
                        {/*    <CrossInCircle width="20px" height="20px" fill="#ee8282" />*/}
                        {/*)}*/}
                    </td>
                </tr>
            );
        }

        if (list.length === 0) {
            return (
                <tr>
                    <td colSpan="6">
                        <p className="border-solid border-b-2 border-gray-300 py-3">
                            <i>No branches</i>
                        </p>
                    </td>
                </tr>
            );
        }

        return list;
    };

    const createJobsList = (providerId) => {
        if (jobsState.loading) {
            return (
                <tr>
                    <td className="pt-2" align="center" colSpan="3">
                        <Loader />
                    </td>
                </tr>
            );
        }

        if (jobsState.error) {
            return (
                <tr>
                    <td colSpan="3">
                        <ErrorIndicator error={jobsState.error} />
                    </td>
                </tr>
            );
        }

        if (!jobsState.data[data.name]?.length) {
            return (
                <tr>
                    <td colSpan="3">
                        <p className="border-solid border-b-2 border-gray-300 py-3">
                            <i>No jobs</i>
                        </p>
                    </td>
                </tr>
            );
        }

        return jobsState.data[data.name]?.map((job) => (
            <Job
                key={job.job_name}
                data={job}
                provider_id={providerId}
                name={data.name}
            />
        ));
    };

    function removeRepo() {
        const newRepositories = { repos: {} };

        allRepositories.map((repo) => {
            if (repo.url !== data.url) {
                newRepositories.repos[repo.name] = repo.url;
            }
        });

        setAddRepositoryLoading(true);

        addSelectedRepositories(newRepositories, provider.provider_id)
            .then((response) => {
                setAddRepositoryLoading(false);
                if (response.status === 200) {
                    selectedRepositoriesDispatch({
                        type: 'SET_DATA',
                        payload: response.data,
                    });
                }
            })
            .catch((error) => {
                setAddRepositoryLoading(false);

                selectedRepositoriesDispatch({
                    type: 'SET_ERROR',
                    payload: error,
                });
            });
    }

    return (
        <div
            className={`arrow-box border-solid border-2 border-gray-400 rounded-lg grid grid-cols-12 col-start-1 col-end-4 ${
                (index - prevReposLength) % 3 === 0 ? 'arrow-left' : ''
            } ${(index - prevReposLength) % 3 === 1 ? 'arrow-center' : ''} ${
                (index - prevReposLength) % 3 === 2 ? 'arrow-right' : ''
            }`}
        >
            <p className="col-span-6 text-center font-display text-lg">
                {capitalizeFirstLetter(t('repo_page.branches'))}
            </p>
            <p className="col-span-6 text-center font-display text-lg">
                {capitalizeFirstLetter(t('repo_page.last_job'))}
            </p>

            <div className="col-span-6 p-2 max-h-64 overflow-y-scroll scrollbar-thin scrollbar-thumb-gray-300 scrollbar-thumb-rounded-full">
                <table className="w-full">
                    <thead>
                        <tr className="border-b-2 border-solid border-gray-400">
                            <th className="text-left pb-2">
                                {capitalizeFirstLetter(t('repo_page.branch'))}
                            </th>
                            <th className="text-center">
                                {capitalizeFirstLetter(t('repo_page.stock'))}
                            </th>
                            <th className="text-right">
                                {capitalizeFirstLetter(t('repo_page.leaks'))}
                            </th>
                            <th className="text-right">
                                {capitalizeFirstLetter(t('repo_page.ahead'))}
                            </th>
                            <th className="text-right pr-8">
                                {capitalizeFirstLetter(t('repo_page.behind'))}
                            </th>
                            <th className="text-center">
                                {capitalizeFirstLetter(t('repo_page.status'))}
                            </th>
                        </tr>
                    </thead>

                    <tbody className="text-gray-500">
                        {createBranchesList()}
                    </tbody>
                </table>
            </div>

            <div className="col-span-6 p-2 max-h-64 overflow-y-scroll scrollbar-thin scrollbar-thumb-gray-300 scrollbar-thumb-rounded-full">
                <table className="w-full">
                    <thead>
                        <tr className="border-b-2 border-solid border-gray-400">
                            <th className="text-left pb-2">
                                {capitalizeFirstLetter(t('repo_page.job'))}
                            </th>
                            <th className="text-center pr-8">
                                {capitalizeFirstLetter(t('repo_page.date'))}
                            </th>
                            <th className="text-center">
                                {capitalizeFirstLetter(t('repo_page.status'))}
                            </th>
                        </tr>
                    </thead>

                    <tbody className="text-gray-500">
                        {createJobsList(provider?.provider_id)}
                    </tbody>
                </table>
            </div>

            {selectedOrganisationHash !== demoOrgHash ? (
                <div className="col-span-12 mt-5">
                    <span
                        className="text-red-600 underline font-display"
                        role="button"
                        onClick={() => {
                            removeRepo();
                        }}
                    >
                        {capitalizeFirstLetter(t('repo_page.remove_repo'))}
                    </span>
                </div>
            ) : null}
        </div>
    );
};

const Job = ({ data, provider_id, name }) => {
    const [logStatus, setLogStatus] = useState(false);

    const getStatus = (status) => {
        switch (status) {
            case 'Running':
                return (
                    <div className="job__status">
                        <InProgress width="20px" height="20px" fill="#4c72bd" />
                    </div>
                );
            case 'Failed':
                return (
                    <div className="job__status">
                        <CrossInCircle
                            width="20px"
                            height="20px"
                            fill="#ee8282"
                        />
                    </div>
                );
            case 'Succeeded':
            default:
                return (
                    <div className="job__status">
                        <Confirm width="20px" height="20px" fill="#8ec358" />
                    </div>
                );
        }
    };

    return (
        <>
            <tr
                className={` ${
                    logStatus ? '' : 'border-solid border-b-2 border-gray-300'
                }`}
                key={data.job_name}
            >
                <td className="py-3">
                    {data.status !== 'Failed' ? (
                        <p>{data.job_name}</p>
                    ) : (
                        <button
                            className="text-blue-400 text-left"
                            onClick={() =>
                                setLogStatus((prevState) => !prevState)
                            }
                        >
                            {data.job_name}
                        </button>
                    )}
                </td>
                <td className="text-center pr-8">
                    {moment.unix(data.date).format('YYYY-MM-DD')}
                </td>
                <td className="flex justify-center">
                    {getStatus(data.status)}
                </td>
            </tr>

            {logStatus && (
                <LogsList
                    data={data}
                    name={name}
                    provider_id={provider_id}
                    logStatus={logStatus}
                />
            )}
        </>
    );
};

export default SelectedRepositoryInfo;
