import React, { useEffect, useState, useRef } from 'react';
import { Transition } from 'react-transition-group';
import logoAgileAnalytics from '../../assets/logo-new-dark-with-text.png';
import { NavLink } from 'react-router-dom';
import AuthLoginForm from '../../components/auth-login-form';
import ButtonPrimary from '../../components/button/ButtonPrimary';
import AuthSignUpForm from '../../components/auth-signup-form';
import AuthInviteForm from '../../components/auth-invite-form';
import AuthExploreForm from '../../components/auth-explore-form';

function AuthPage({ type, errorMessageFromParent }) {
    const nodeRef = useRef(null);
    const [bgOnLeft, setBgOnLeft] = useState(!(type === 'login'));

    useEffect(() => {
        setBgOnLeft(!(type === 'login'));
    }, [type]);

    const duration = 500;

    const defaultStyle = {
        transition: `opacity ${duration}ms ease-in-out`,
        opacity: 0,
        width: '100%',
    };

    const transitionStyles = {
        entering: { opacity: 1 },
        entered: { opacity: 1 },
        exiting: { opacity: 0 },
        exited: { opacity: 0 },
    };

    function renderLeftPrimaryContent(type) {
        switch (type) {
            case 'explore':
            case 'demo':
            case 'invite':
            case 'signup':
            case 'login':
            default:
                return <AuthLoginForm />;
        }
    }

    function renderLeftSecondaryContent(type) {
        switch (type) {
            case 'explore':
            case 'demo':
            case 'invite':
            case 'signup':
            case 'login':
            default:
                return (
                    <div className="absolute">
                        <p className="text-center mb-20 font-display text-6xl text-secondary-300 font-bold ">
                            Login
                        </p>
                        <p className=" font-display text-2xl text-gray-300 font-light ">
                            Already have an account? We're glad to see you back
                            here!
                        </p>

                        <ButtonPrimary
                            extraClasses={
                                'mt-4 w-full flex space-x-4 justify-center py-2 items-center text-xl '
                            }
                            onClick={() => {
                                setBgOnLeft(false);
                            }}
                            type={'submit'}
                            variant={'gray'}
                        >
                            Login
                        </ButtonPrimary>
                    </div>
                );
        }
    }

    function renderRightPrimaryContent(type) {
        switch (type) {
            case 'explore':
            case 'demo':
                return <AuthExploreForm endpoint={type} />;
            case 'invite':
                return (
                    <div className="w-full">
                        <AuthInviteForm
                            errorMessageFromParent={errorMessageFromParent}
                        />
                    </div>
                );
            case 'signup':
            case 'login':
            default:
                return (
                    <div className="w-full">
                        <AuthSignUpForm />
                    </div>
                );
        }
    }

    function renderRightSecondaryContent(type) {
        switch (type) {
            case 'explore':
            case 'demo':
                return (
                    <div className="absolute">
                        <p className="text-center mb-20 font-display text-6xl text-secondary-300 font-bold ">
                            Explore
                        </p>
                        <p className=" font-display text-2xl text-gray-300 font-light ">
                            Check out Agile Analytics in one click
                        </p>

                        <ButtonPrimary
                            extraClasses={
                                'mt-4 w-full flex space-x-4 justify-center py-2 items-center text-xl '
                            }
                            onClick={() => {
                                setBgOnLeft(true);
                            }}
                            type={'submit'}
                            variant={'gray'}
                        >
                            Create demo account
                        </ButtonPrimary>
                    </div>
                );
            case 'invite':
                return (
                    <div className="absolute">
                        <p className="text-center mb-20 font-display text-6xl text-secondary-300 font-bold ">
                            Sign up
                        </p>
                        <p className=" font-display text-2xl text-gray-300 font-light ">
                            Go back to signing up with an invite
                        </p>

                        <ButtonPrimary
                            extraClasses={
                                'mt-4 w-full flex space-x-4 justify-center py-2 items-center text-xl '
                            }
                            onClick={() => {
                                setBgOnLeft(true);
                            }}
                            type={'submit'}
                            variant={'gray'}
                        >
                            Signup with an invite
                        </ButtonPrimary>
                    </div>
                );
            case 'signup':
            case 'login':
            default:
                return (
                    <div className="absolute">
                        <p className="text-center mb-20 font-display text-6xl text-secondary-300 font-bold ">
                            Sign up
                        </p>
                        <p className=" font-display text-2xl text-gray-300 font-light ">
                            Don't have an account? Signup and explore Agile
                            Analytics with 60-day free trial.
                        </p>

                        <ButtonPrimary
                            extraClasses={
                                'mt-4 w-full flex space-x-4 justify-center py-2 items-center text-xl '
                            }
                            onClick={() => {
                                setBgOnLeft(true);
                            }}
                            type={'submit'}
                            variant={'gray'}
                        >
                            Signup
                        </ButtonPrimary>
                    </div>
                );
        }
    }

    return (
        <section
            id="auth"
            className={`overflow-hidden h-fit min-h-[600px] relative slow-transition bg-gray-bg after:content-[''] after:absolute after:transform after:translate after:slow-transition  after:w-1/2 after:top-0 after:-z-10 ${
                bgOnLeft ? 'after:inset-x-0' : 'after:inset-x-1/2 '
            } after:h-full after:min-h-screen after:bg-gray-600  `}
        >
            <div
                className={`absolute top-4 w-72 transform translate slow-transition ${
                    bgOnLeft ? 'left-logo' : 'left-4'
                }`}
            >
                <NavLink to="/" className="" data-testid="logo">
                    <img
                        src={logoAgileAnalytics}
                        alt="Agile Analytics logo"
                        className="w-72"
                    />
                </NavLink>
            </div>
            <div className="w-full h-fit min-h-screen flex">
                <div
                    className={`relative w-1/2 h-fit min-h-screen px-12 pt-40 pb-12 flex  justify-center h-full`}
                >
                    <div className="w-full sm:w-80 relative">
                        <Transition
                            nodeRef={nodeRef}
                            in={bgOnLeft}
                            timeout={duration}
                            unmountOnExit={true}
                        >
                            {(state) => (
                                <div
                                    ref={nodeRef}
                                    style={{
                                        ...defaultStyle,
                                        ...transitionStyles[state],
                                    }}
                                >
                                    {renderLeftSecondaryContent(type)}
                                </div>
                            )}
                        </Transition>
                        <Transition
                            nodeRef={nodeRef}
                            in={!bgOnLeft}
                            timeout={duration}
                            unmountOnExit={true}
                        >
                            {(state) => (
                                <div
                                    ref={nodeRef}
                                    style={{
                                        ...defaultStyle,
                                        ...transitionStyles[state],
                                    }}
                                >
                                    {renderLeftPrimaryContent(type)}
                                </div>
                            )}
                        </Transition>
                    </div>
                </div>
                <div
                    className={`w-1/2 h-fit min-h-screen px-12 pt-40 pb-12 flex  justify-center`}
                >
                    <div className="w-full sm:w-80 relative">
                        <Transition
                            nodeRef={nodeRef}
                            in={!bgOnLeft}
                            timeout={duration}
                            unmountOnExit={true}
                        >
                            {(state) => (
                                <div
                                    ref={nodeRef}
                                    style={{
                                        ...defaultStyle,
                                        ...transitionStyles[state],
                                    }}
                                >
                                    {renderRightSecondaryContent(type)}
                                </div>
                            )}
                        </Transition>
                        <Transition
                            nodeRef={nodeRef}
                            in={bgOnLeft}
                            timeout={duration}
                            unmountOnExit={true}
                        >
                            {(state) => (
                                <div
                                    ref={nodeRef}
                                    style={{
                                        ...defaultStyle,
                                        ...transitionStyles[state],
                                    }}
                                >
                                    {renderRightPrimaryContent(type)}
                                </div>
                            )}
                        </Transition>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default AuthPage;
