import React from 'react';
import HoursPageTimesheetOverviewHeader from './header';
import HoursPageTimesheetOverviewBody from './body';
import HoursPageTimesheetOverviewFooter from './footer';

function HoursPageTimesheetOverview({
    data,
    weekRange,
    selectedProjects,
    setEmptyRow,
    triggerUpdate,
    userHash,
    viewerHash,
    filterDate,
}) {
    return (
        <div>
            <HoursPageTimesheetOverviewHeader weekRange={weekRange} />
            <HoursPageTimesheetOverviewBody
                data={data}
                weekRange={weekRange}
                triggerUpdate={triggerUpdate}
                selectedProjects={selectedProjects}
                setEmptyRow={setEmptyRow}
                userHash={userHash}
                viewerHash={viewerHash}
                filterDate={filterDate}
            />
            {data.length ? (
                <HoursPageTimesheetOverviewFooter
                    weekRange={weekRange}
                    data={data}
                />
            ) : (
                ''
            )}
        </div>
    );
}

export default HoursPageTimesheetOverview;
