import React, { useContext, useState } from 'react';
import HoursPageTimesheetOverviewBodyRowView from './View';
import HoursPageTimesheetOverviewBodyRowEdit from './Edit';
import HoursPageTimesheetOverviewBodyActions from './ActionButtons';
import { getWritePermission } from '../../../../../../helpers/getPermission';
import { OrganisationsContext } from '../../../../../../context/OrganisationsContext';

function HoursPageTimesheetOverviewBodyRow({
    data,
    project,
    selectedProjects,
    days,
    weekRange,
    setEmptyRow,
    triggerUpdate,
    userHash,
    viewerHash,
    filterDate,
}) {
    const { organisationsState } = useContext(OrganisationsContext);
    const [showEdit, setShowEdit] = useState(!project);

    const [formValues, setFormValues] = useState({
        project,
        days,
    });

    function toggleShowEdit() {
        setShowEdit((prevState) => !prevState);
    }

    return (
        <div className="flex justify-between border-b border-solid border-gray-300 py-3 bg-gray-100 odd:bg-white">
            {showEdit ? (
                <HoursPageTimesheetOverviewBodyRowEdit
                    data={data}
                    weekRange={weekRange}
                    formValues={formValues}
                    setFormValues={setFormValues}
                    selectedProjects={selectedProjects}
                    currentProject={project}
                    userHash={userHash}
                    viewerHash={viewerHash}
                />
            ) : (
                <HoursPageTimesheetOverviewBodyRowView
                    project={project}
                    days={days}
                    weekRange={weekRange}
                />
            )}

            {getWritePermission(organisationsState?.data) ? (
                <HoursPageTimesheetOverviewBodyActions
                    showEdit={showEdit}
                    toggleShowEdit={toggleShowEdit}
                    project={project}
                    days={days}
                    formValues={formValues}
                    setFormValues={setFormValues}
                    setEmptyRow={setEmptyRow}
                    triggerUpdate={triggerUpdate}
                    filterDate={filterDate}
                    userHash={userHash}
                />
            ) : (
                <div className="w-12"></div>
            )}
        </div>
    );
}

export default HoursPageTimesheetOverviewBodyRow;
