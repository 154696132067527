const setCustomStyle = () => ({
    control: (provided) => ({
        ...provided,
        outline: 'none',
        boxShadow: 'none',
        backgroundColor: 'transparent',
        opacity: '1',
        // borderColor: state.isSelected ? "red" : "rgba(0,0,0,.5)",
        borderColor: '#484a53',
        '&:hover': {
            opacity: '1',
        },
    }),
    singleValue: (provided) => ({
        ...provided,
        color: '#484a53',
    }),
    indicatorsContainer: (provided) => ({
        ...provided,
        color: '#484a53',
        opacity: '1',
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        color: '#484a53',
        opacity: '1',
        cursor: 'pointer',
        '&:hover': {
            color: '#484a53',
        },
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        backgroundColor: '#484a53',
        opacity: '1',
    }),
    menu: (provided) => ({
        ...provided,
        marginTop: '0',
        padding: '0',
    }),
    valueContainer: (provided) => ({
        ...provided,
        flexWrap: 'no-wrap',
    }),
    option: (provided, state) => ({
        ...provided,
        cursor: 'pointer',
        color: '#484a53',
        backgroundColor: 'transparent',
        '&:hover': {
            backgroundColor: state.isSelected
                ? '#4C72BD'
                : 'rgba(76, 114, 189, 0.5)',
        },
    }),
});

export default setCustomStyle;
