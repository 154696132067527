import { TaskIcon, BugIcon, StoryIcon, SubtaskIocn, EpicIcon } from '../../../assets/svg';

export const TYPE_ICON_MAP = {
  Story: () => <StoryIcon title="Story" />,
  Task: () => <TaskIcon title="Task" />,
  Bug: () => <BugIcon title="Bug" />,
  Epic: () => <EpicIcon title="Epic" />,
  'Sub-task': () => <SubtaskIocn title="Sub-Task" />,
};

export const TYPE_TICKET_MAP = {
  default: 'bg-blue-200 text-blue-700',
  'in-progress': `bg-yellow-200 text-yellow-700`,
  backlog: `bg-purple-200 text-purple-700`,
  done: `bg-green-200 text-green-700`,
  closed: `bg-green-900 text-white`,
  test: `bg-indigo-900 text-indigo-700`,
  review: `bg-blue-700 text-white`,
  'selected-for-development': 'bg-gray-200 text-gray-700',
};