import React from 'react';
import { useTranslation } from 'react-i18next';
import InputText from '../form/parts/InputText';

const UpdateOrganisationFormField = ({
    name,
    attributeName,
    fieldType,
    placeholderText,
    error,
    touched,
    value,
    disabled,
    required,
    className,
    showErrors = true,
}) => {
    const { t } = useTranslation();
    return (
        <div
            className={`flex flex-col justify-between gap-y-1 mb-2 ${className}`}
        >
            <label
                className="text-gray-500 text-m font-light"
                htmlFor={attributeName}
            >
                {name}
                {!required && (
                    <span className="text-xs">
                        {` (${t('settings_page.org_tab.optional')})`}
                    </span>
                )}
            </label>
            <div>
                <InputText
                    id={attributeName}
                    type={fieldType}
                    name={attributeName}
                    placeholder={placeholderText}
                    value={value}
                    disabled={disabled}
                />
                {showErrors ? (
                    error && touched ? (
                        <div className="text-red-500 mt-1 text-xs">{error}</div>
                    ) : (
                        <div className="text-red-500 mt-1 text-xs invisible">
                            hidden
                        </div>
                    )
                ) : null}
            </div>
        </div>
    );
};

export default UpdateOrganisationFormField;
