const bucketsAmount = 66;
const growthFactor = 1.4142135623731;
const scale = 1;

const googleThresholdBucketList = [];

function numberWithCommas(x) {
    var parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
}

let i = 0;
while (i < bucketsAmount) {
    let bucket = {};
    if (i === 0) {
        bucket = {
            number: i,
            lowerBound: 'Infinity small',
            upperBound: numberWithCommas(
                (scale * Math.pow(growthFactor, i)).toFixed(2)
            ),
        };
    } else if (i === bucketsAmount - 1) {
        bucket = {
            number: i,
            lowerBound: numberWithCommas(
                Math.round(scale * Math.pow(growthFactor, i - 1))
            ),
            upperBound: 'Infinity big',
        };
    } else {
        if (
            scale * Math.pow(growthFactor, i - 1) >= 1000 ||
            scale * Math.pow(growthFactor, i) >= 1000
        ) {
            bucket = {
                number: i,
                lowerBound: numberWithCommas(
                    Math.round(scale * Math.pow(growthFactor, i - 1))
                ),
                upperBound: numberWithCommas(
                    Math.round(scale * Math.pow(growthFactor, i))
                ),
            };
        } else {
            bucket = {
                number: i,
                lowerBound: numberWithCommas(
                    (scale * Math.pow(growthFactor, i - 1)).toFixed(2)
                ),
                upperBound: numberWithCommas(
                    (scale * Math.pow(growthFactor, i)).toFixed(2)
                ),
            };
        }
    }
    googleThresholdBucketList.push(bucket);
    i++;
}

export default googleThresholdBucketList;
