import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { NAVIGATION_PATHS } from '../../../constants/navigationPaths';
import { capitalizeEveryWordFirstLetter } from '../../../helpers/textFormatters';

const SreInvalidBackendNote = ({ backendValue, isChosen = false }) => {
    const { t } = useTranslation();
    return (
        <p className="text-red-text mb-1 text-m">
            {isChosen ? 'Chosen backend' : 'Backend'}{' '}
            <span className="font-medium">{backendValue}</span>{' '}
            {isChosen
                ? 'is invalid. You can test or fix it on'
                : 'is not available as it probably was deleted. Please pick one of configured backends or configure a new one on'}{' '}
            <Link
                to={NAVIGATION_PATHS.settingsErrorBudgets}
                className="font-medium underline"
            >
                {`${capitalizeEveryWordFirstLetter(
                    t('settings_page.sre_tab.title')
                )} settings page`}
            </Link>{' '}
        </p>
    );
};

export default SreInvalidBackendNote;
