import React from 'react';
import { Google, ArrowRight } from '../../assets/svg';
import { getSaml } from '../../api/auth/AuthAPI';
import { useTranslation } from 'react-i18next';
import ButtonPrimary from '../../components/button/ButtonPrimary';
import showNotification from '../../helpers/showNotification';

function SSOAuthGoogle({ setErrorMessage }) {
    const { t } = useTranslation();

    function ssoLogin() {
        getSaml()
            .then((response) => {
                const { authUri } = response.data;
                window.location.href = authUri;
            })
            .catch((error) => {
                showNotification(
                    `${error?.response?.data || error.message}`,
                    'error'
                );
                setErrorMessage(`${error?.response?.data || error.message}`);
                console.log(error);
            });
    }

    return (
        <ButtonPrimary
            extraClasses={'w-full flex space-x-4 py-2 items-center'}
            onClick={ssoLogin}
        >
            <Google width="24px" height="24px" />
            <span className={'ml-4 flex-grow text-left'}>
                {t('login_page.button.sso_google')}
            </span>
            <ArrowRight fill={'#ffffff'} />
        </ButtonPrimary>
    );
}

export default SSOAuthGoogle;
