export function capitalizeFirstLetter(string) {
    if (!string) {
        return string;
    }
    const capitalized =
        string[0].toUpperCase() + string.substring(1).toLowerCase();
    return capitalized;
}

export function capitalizeEveryWordFirstLetter(string) {
    if (!string) {
        return string;
    }
    const capitalized = string
        .split(' ')
        .reduce(
            (acc, word, i, arr) =>
                acc +
                word[0].toUpperCase() +
                word.substring(1).toLowerCase() +
                `${i === arr.length - 1 ? '' : ' '}`,
            ''
        );
    return capitalized;
}
