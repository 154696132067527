import { getGroups } from '../api/swarm/SwarmAPI';

export async function fetchRepoGroups(dispatchFunc) {
    dispatchFunc({ type: 'SET_LOADING' });
    try {
        const res = await getGroups();
        if (res?.status === 204) {
            dispatchFunc({
                type: 'SET_EMPTY',
            });
        } else if (res.status === 200) {
            dispatchFunc({
                type: 'SET_DATA',
                payload: res.data,
            });
        } else {
            dispatchFunc({ type: 'SET_NO_LOADING' });
        }
    } catch (err) {
        dispatchFunc({
            type: 'SET_ERROR',
            payload: err,
        });
    }
}
