export default function eula() {
    return (
        <div className="container mx-auto px-4 md:px-8 ">
            <h2 className="text-custom-gray-700 font-bold text-center text-xl leading-58 font-display mb-2">
                END USE LICENSE AGREEMENT (EULA)
            </h2>
            <p className="mx-auto text-center text-lg leading-7 max-w-lg text-custom-gray-700">
                AGILE ANALYTICS
            </p>
            <div className="flex flex-wrap my-8 markdown-wrapper">
                <div className="text-custom-gray-700">
                    <h4>Introduction</h4>
                    <p>
                        This End User License Agreement (<strong>"EULA"</strong>
                        ) applies to any agreement concerning the use by a
                        customer (<strong>"Client"</strong>) of software
                        developed or provided by ZEN Software or its affiliates,
                        more specifically the use of the SaaS-solution Agile
                        Analytics.
                    </p>
                    <p>
                        Please read this EULA carefully. The terms of the EULA
                        govern the Clients use of the software and any future
                        upgrades or addenda thereto, unless otherwise stated and
                        agreed in writing with ZEN Software at the time.
                    </p>
                </div>
                <div className="text-custom-gray-700">
                    <p>
                        <strong>
                            IMPORTANT! BY DOWNLOADING, INSTALLING, ACCESSING OR
                            USING THE SOFTWARE ACCOMPANYING THIS EULA, YOU AGREE
                            TO BE BOUND BY THE TERMS AND CONDITIONS SET FORTH
                            HEREIN. IF YOU DO NOT AGREE TO THE STATED TERMS AND
                            CONDITIONS, YOU MAY NOT DOWNLOAD, INSTALL, ACCESS,
                            USE OR RETAIN ANY COPY OF THE SOFTWARE..
                        </strong>
                    </p>
                </div>
                <div className="text-custom-gray-700">
                    <h4>1. Article 1: Licensing</h4>
                    <p>
                        1.1. The Client receives a non-exclusive right to use
                        Agile Analytics, the SaaS-service developed and provided
                        by ZEN Software.
                    </p>
                    <p>
                        1.2. The Client receives one copy of an executable
                        object code of Agile Analytics.
                    </p>
                    <p>
                        1.3. Except with express permission, the Client is not
                        allowed to:
                    </p>
                    <ul>
                        <li>
                            distribute, sell or lease Agile Analytics itself or
                            any documentation to a third party; or-
                        </li>
                        <li>
                            Sublicense, outsource, rent or otherwise provide
                            Agile Analytics services to any third party.
                        </li>
                    </ul>
                    <p>
                        1.4. Except as expressly permitted herein or as
                        expressly authorized by mandatory legal provisions of
                        applicable law, the Client is not allowed to:
                    </p>
                    <ul>
                        <li>
                            Copy Agile Analytics in whole or in part, other than
                            for backup purposes; or
                        </li>
                        <li>
                            reverse engineer or otherwise derive product source
                            code from the licensed executable.
                        </li>
                    </ul>
                    <p>
                        1.5. In the case of third party software, which is not
                        built into Agile Analytics, the Client’s rights and
                        obligations in respect of such third party software are
                        subject to the relevant third party's license terms.
                    </p>
                </div>
                <div className="text-custom-gray-700">
                    <h4>Article 2: Documentation, use and editing</h4>
                    <p>
                        2.1. The Client may copy the accompanying documentation
                        and standard training materials, if any, but only for
                        internal purposes.
                    </p>
                    <p>
                        2.2. Documentation and standard training materials from
                        ZEN Software may not be edited unless written permission
                        has been granted. This also applies to documentation
                        developed specifically for the Client.
                    </p>
                </div>
                <div className="text-custom-gray-700">
                    <h4>Article 3: Intellectual property rights</h4>
                    <p>
                        3.1. The Client acknowledges that Agile Analytics and
                        all intellectual property rights, including copyrights,
                        are owned by (or licensed by third parties to) ZEN
                        Software and that nothing in this EULA is intended to
                        transfer ownership of Agile Analytics and its
                        intellectual property rights to the Client. The Client
                        is only granted a limited license to use Agile Analytics
                        as set out in this EULA.
                    </p>
                </div>
                <div className="text-custom-gray-700">
                    <h4>Article 4: Faults</h4>
                    <p>
                        4.1. The Client acknowledges that software in general is
                        not error-free or flawless and agrees that the existence
                        of such errors or defects in Agile Analytics or any
                        other software provided to the Client shall not
                        constitute a breach of this EULA.
                    </p>
                    <p>
                        4.2. ZEN Software warrants that Agile Analytics will not
                        infringe any third party rights, including copyrights.
                        This warranty is subject to the condition that ZEN
                        Software shall be notified promptly of any such claim of
                        infringement made against the Customer; ZEN Software
                        shall have the right to defend and settle any such claim
                        as it sees fit and the Customer shall provide such
                        assistance as ZEN Software may reasonably require to
                        settle or oppose such claim.
                    </p>
                    <p>
                        4.3. ZEN Software disclaims all warranties, express or
                        implied, with respect to Agile Analytics, including, but
                        not limited to, warranties of merchantability or fitness
                        for a particular purpose.
                    </p>
                    <p>
                        4.4. ZEN Software may at its sole discretion choose to
                        1) correct or replace the part of Agile Analytics that
                        is defective or infringes any third party rights, or 2)
                        terminate the Client's Agile Analytics right of use with
                        immediate effect. ZEN Software shall not be obliged to
                        remedy the following under any circumstances:
                    </p>
                    <ul>
                        <li>altered, damaged or modified software;</li>
                        <li>
                            software which is not from the current or previous
                            consecutive release;
                        </li>
                        <li>
                            software problems due to the Client’s negligence,
                            hardware failure or other causes beyond ZEN
                            Software's control;
                        </li>
                        <li>flawed data;</li>
                        <li>
                            problems caused by an incorrect interface with other
                            software or data sources; or
                        </li>
                        <li>
                            software installed in an operating environment that
                            is not supported by Agile Analytics.
                        </li>
                    </ul>
                    <p>
                        4.5. Third party software, if any, is provided "as is"
                        without warranties of any kind; however, the original
                        manufacturers of third party software or third party
                        providers of such software may provide their own
                        warranties
                    </p>
                </div>
                <div className="text-custom-gray-700">
                    <h4>Article 5: Limitation of liability</h4>
                    <p>
                        5.1. ZEN Software shall not be liable to the Client for
                        any loss or damage, regardless of cause, caused directly
                        or indirectly in connection with this EULA, Agile
                        Analytics or any other software, its use or otherwise,
                        except to the extent it is unlawful to exclude such
                        liability under applicable law.
                    </p>
                    <p>
                        5.2. Notwithstanding the generality of the above, ZEN
                        Software expressly excludes liability for any indirect,
                        special, incidental or consequential damages that may
                        arise in connection with Agile Analytics, its use or in
                        connection with equipment or property, or for loss of
                        profits, business, revenue, goodwill, data or
                        anticipated savings.
                    </p>
                    <p>
                        5.3. In the event that any exclusion contained in this
                        EULA is invalidated for any reason, and ZEN Software
                        becomes liable for loss or damage that can be
                        legitimately mitigated, such liability shall be limited
                        to the annual or one-time license fee paid by the Client
                        and shall not exceed [EUR 500].
                    </p>
                </div>
                <div className="text-custom-gray-700">
                    <h4>Article 6: Confidentiality</h4>
                    <p>
                        6.1. All information, including - but not limited to -
                        data, drawings, specifications, documentation, software
                        lists, object or source code, prices that the Client may
                        have received in relation to Agile Analytics (except for
                        the ideas and principles underlying the software) is
                        protected and confidential. The Client agrees that it
                        will only use such information in accordance with the
                        provisions of this EULA and that during or after the
                        expiration or termination of this EULA, it will not -
                        directly or indirectly - disclose the information to any
                        third party without the prior written consent of ZEN
                        Software.
                    </p>
                    <p>
                        6.2. The above shall not prevent the use or disclosure
                        by the Client of any information which, whether now or
                        hereafter, becomes public knowledge through no fault of
                        the Client or to the extent permitted by law.
                    </p>
                </div>
                <div className="text-custom-gray-700">
                    <h4>Article 7: Audit</h4>
                    <p>
                        7.1. ZEN Software reserves the right, at ZEN Software's
                        expense, to audit the Client's implementation and use of
                        Agile Analytics for compliance with this EULA at any
                        mutually agreeable time during normal business hours at
                        the Client's premises.
                    </p>
                    <p>
                        7.2. The Client shall reasonably verify that the rights
                        of use of the software granted in this EULA are not
                        violated.
                    </p>
                </div>
                <div className="text-custom-gray-700">
                    <h4>Article 8: Termination</h4>
                    <p>
                        8.1. Each party shall be entitled to terminate the EULA
                        on account of an attributable shortcoming in the
                        implementation of the EULA if the other party fails
                        attributably in the fulfilment of essential obligations
                        under the EULA. Termination shall only be possible
                        following an as detailed notice of default as possible,
                        containing a reasonable term within which the
                        shortcoming can be corrected. Payment obligations of the
                        Client and all other obligations to cooperate on the
                        part of the Client or a third party engaged by the
                        Client shall always be considered essential obligations
                        under the EULA.
                    </p>
                    <p>
                        8.2. If the Client has already been rendered performance
                        in the implementation of the EULA at the moment of the
                        termination referred to in article 8.1, that performance
                        and the corresponding payment obligations can never be
                        subject of undoing, unless the Client is able to prove
                        that ZEN Software is in default in respect of an
                        essential part of that performance. Amounts already
                        invoiced by ZEN Software prior to termination in
                        connection with what it has already performed or
                        delivered in the implementation of the EULA, shall, with
                        due regard for the provisions of the previous sentence,
                        remain payable and fall due immediately as at the moment
                        of termination.
                    </p>
                    <p>
                        8.3. If the EULA, which due its nature and substance
                        does not end by completion, is effected for an
                        unspecified period of time, then each of the parties may
                        cancel the EULA subject to mutual consultation and a
                        statement of reasons. If no notice period has been
                        agreed between the parties, then a reasonable period for
                        termination of the agreement shall be allowed. The
                        parties shall never be liable for any form of
                        compensation on account of termination.
                    </p>
                    <p>
                        8.4. The Client shall not be entitled to early
                        termination of the EULA which has been effected for a
                        specified period of time.
                    </p>
                    <p>
                        8.5. Each of the parties shall be entitled to
                        immediately fully or partially terminate the EULA
                        without prior notice if the other party, whether or not
                        provisionally, is granted a moratorium, if a petition is
                        filed for the bankruptcy of the other party, if the
                        other party is declared bankrupt, or if the business of
                        the other party is liquidated or discontinued other than
                        for the purpose of reconstructing or merging companies.
                        ZEN Software shall never be bound to the reimbursement
                        of already received payments, nor to any compensation
                        for damages as a result of termination. The right of the
                        Client to use Agile Analytics and the like made
                        available to him by ZEN Software shall expire by
                        operation of law in the event of bankruptcy of the
                        Client.
                    </p>
                </div>
                <div className="text-custom-gray-700">
                    <h4>Article 9: Miscellaneous</h4>
                    <p>
                        9.1. All negotiations, offers, quotations, agreements
                        and other (legal) acts between ZEN Software and the
                        Client will be governed by the laws of the Netherlands,
                        irrespective of the place where the agreement is
                        performed.
                    </p>
                    <p>
                        9.2. Any disputes between ZEN Software and the Client as
                        a result of, or in relation to, negotiations, offers,
                        quotations, agreements and other (legal) acts will be
                        submitted to the exclusive jurisdiction of the competent
                        judge with the District Court for North Holland, the
                        Netherlands, including its preliminary relief judge.
                    </p>
                </div>
            </div>
        </div>
    );
}
