import React, { useContext, useEffect, useState } from 'react';
import ProjectsTableDefault from '../default/table';
import Card from '../../../components/card';
import Loader from '../../../components/loader';
import { IoIosAdd } from 'react-icons/io';
import ButtonPrimary from '../../../components/button/ButtonPrimary';
import { PrivateProjectsContext } from '../../../context/PrivateProjectsContext';
import { UserContext } from '../../../context/UserContext';
import { OrganisationsContext } from '../../../context/OrganisationsContext';
import {
    createPrivateProjects,
    deletePrivateProjects,
    getPrivateProjects,
    updatePrivateProjects,
} from '../../../api/hours/HoursAPI';
import { useTranslation } from 'react-i18next';
import { getWritePermission } from '../../../helpers/getPermission';

function HoursPagePrivateProjects({ type }) {
    const { t } = useTranslation();

    const { userState } = useContext(UserContext);
    const { privateProjectsState, privateProjectsDispatch } = useContext(
        PrivateProjectsContext
    );
    const { data, loading, error } = privateProjectsState;

    const { organisationsState } = useContext(OrganisationsContext);
    const selectedOrgHash = organisationsState.data
        ? organisationsState.data.find((org) => org.active).org_hash
        : null;
    const demoOrgHash = process.env.REACT_APP_DEMO_ORG_HASH;
    const [userHash, setUserHash] = useState(
        selectedOrgHash === demoOrgHash
            ? 'URICHAR'
            : userState?.data?.user_hash || null
    );

    useEffect(() => {
        if (selectedOrgHash === demoOrgHash) {
            setUserHash('URICHAR');
        } else {
            setUserHash(userState?.data?.user_hash || null);
        }
        void fetchPrivateProjects();
    }, [selectedOrgHash]);

    async function fetchPrivateProjects() {
        privateProjectsDispatch({ type: 'SET_LOADING' });
        try {
            const res = await getPrivateProjects(userHash);
            if (res.status === 202) {
                privateProjectsDispatch({ type: 'SET_NO_LOADING' });
            } else if (res.status === 204) {
                privateProjectsDispatch({ type: 'SET_DATA', payload: [] });
            } else {
                privateProjectsDispatch({
                    type: 'SET_DATA',
                    payload: res.data,
                });
            }
        } catch (err) {
            privateProjectsDispatch({ type: 'SET_ERROR', payload: err });
        }
    }

    function addRow() {
        privateProjectsDispatch({
            type: 'SET_EMPTY_ROW',
            payload: { name: '', description: '' },
        });
    }

    async function addFirstProject(project) {
        privateProjectsDispatch({ type: 'SET_LOADING' });
        try {
            const res = await createPrivateProjects(userHash, [project]);
            if (res.status === 202) {
                privateProjectsDispatch({ type: 'SET_NO_LOADING' });
            } else if (res) {
                privateProjectsDispatch({
                    type: 'SET_DATA',
                    payload: res.data,
                });
            }
        } catch (err) {
            privateProjectsDispatch({ type: 'SET_ERROR', payload: err });
        }
    }

    async function handleUpdateProjects(prevRowState, updatedRow) {
        const updatedProject = updatedRow.values;
        const filteredProjects = data.filter((project) => project.name);

        // check if there is a list of projects
        if (!filteredProjects.length) {
            await addFirstProject(updatedProject);
        } else {
            // check if it is an updated project or a new one
            const isUpdatedRow = filteredProjects.find(
                (project) => project.name === prevRowState.values.name
            );
            let updatedProjects = filteredProjects;
            if (isUpdatedRow) {
                updatedProjects = filteredProjects.map((project) => {
                    if (project.name !== prevRowState.values.name) {
                        return project;
                    } else {
                        return updatedProject;
                    }
                });
            } else {
                updatedProjects = [...filteredProjects, updatedProject];
            }

            privateProjectsDispatch({ type: 'SET_LOADING' });
            try {
                const res = await updatePrivateProjects(
                    userHash,
                    updatedProjects
                );
                if (res.status === 202) {
                    privateProjectsDispatch({ type: 'SET_NO_LOADING' });
                } else if (res) {
                    privateProjectsDispatch({
                        type: 'SET_DATA',
                        payload: res.data,
                    });
                }
            } catch (err) {
                privateProjectsDispatch({ type: 'SET_ERROR', payload: err });
            }
        }
    }

    async function handleDeleteProject(projectToDelete) {
        const updatedProjects = data.filter(
            (project) => project.name !== projectToDelete.name
        );

        privateProjectsDispatch({ type: 'SET_LOADING' });
        if (!updatedProjects.length) {
            try {
                const res = await deletePrivateProjects(userHash);
                if (res.status === 202) {
                    privateProjectsDispatch({ type: 'SET_NO_LOADING' });
                } else if (res) {
                    privateProjectsDispatch({ type: 'SET_DATA', payload: [] });
                }
            } catch (err) {
                privateProjectsDispatch({ type: 'SET_ERROR', payload: err });
            }
        } else {
            try {
                const res = await updatePrivateProjects(
                    userHash,
                    updatedProjects
                );
                if (res.status === 202) {
                    privateProjectsDispatch({ type: 'SET_NO_LOADING' });
                } else if (res) {
                    privateProjectsDispatch({
                        type: 'SET_DATA',
                        payload: res.data,
                    });
                }
            } catch (err) {
                privateProjectsDispatch({ type: 'SET_ERROR', payload: err });
            }
        }
    }

    function renderActionButtons() {
        if (getWritePermission(organisationsState?.data)) {
            return (
                <ButtonPrimary
                    onClick={addRow}
                    extraClasses={'h-8'}
                    variant={'blue'}
                    title={'Add private project'}
                    disabled={data?.find((item) => item.name === '')}
                >
                    <IoIosAdd size={20} /> {t('button.add')}
                </ButtonPrimary>
            );
        }
    }

    if (loading)
        return (
            <Card title={t('hours_page.private_projects.title')}>
                <div className="flex mt-8 justify-center">
                    <Loader color={'#C2C7D7'} size={35} speedMultiplier={0.8} />
                </div>
            </Card>
        );

    if (error)
        return (
            <Card title={t('hours_page.private_projects.title')}>
                <p className="flex mt-8 justify-center text-center">
                    {error.response?.data ||
                        error.message ||
                        'Oops! Something went wrong!'}
                </p>
            </Card>
        );

    return (
        <Card
            title={t('hours_page.private_projects.title')}
            renderActionButtons={renderActionButtons}
        >
            <ProjectsTableDefault
                data={data}
                updateProjects={handleUpdateProjects}
                deleteProject={handleDeleteProject}
                type={type}
            />
        </Card>
    );
}

export default HoursPagePrivateProjects;
