const setCustomStyle = (color = '#fff') => ({
    container: (provided) => ({
        ...provided,
        width: 100,
    }),
    control: (provided, state) => ({
        ...provided,
        outline: 'none',
        boxShadow: 'none',
        backgroundColor: 'transparent',
        opacity: state.isFocused ? '1' : '0.6',
        borderColor: color,
        '&:hover': {
            opacity: '1',
        },
    }),
    singleValue: (provided) => ({
        ...provided,
        color: color,
        display: 'flex',
        '.image-wrapper': {
            width: '16px',
            height: '16px',
            marginRight: '5px',
        },
    }),
    indicatorsContainer: (provided) => ({
        ...provided,
        color: color,
        opacity: '1',
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        color: color,
        opacity: '1',
        cursor: 'pointer',
        '&:hover': {
            color: color,
        },
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        backgroundColor: color,
        opacity: '1',
    }),
    menu: (provided) => ({
        ...provided,
        marginTop: '0',
        padding: '0',
    }),
    option: (provided, state) => ({
        ...provided,
        cursor: 'pointer',
        color: '#000',
        display: 'flex',
        backgroundColor: 'transparent',
        '&:hover': {
            backgroundColor: state.isSelected
                ? '#4C72BD'
                : 'rgba(76, 114, 189, 0.5)',
        },
        '.image-wrapper': {
            width: '16px',
            height: '16px',
            marginRight: '5px',
        },
    }),
});

export default setCustomStyle;
