import React from 'react';
import moment from 'moment';
import { EqualIcon, GreaterIcon, LessIcon } from '../../assets/svg';

export default function OverviewRatingBlock({
    previousRating,
    previousTimeperiod,
    rating,
    timeperiod,
}) {
    return (
        <div className="w-1/2 flex justify-between items-center mx-auto mb-2 px-6 py-4 rounded border border-solid border-gray-400 ">
            <div className="text-center">
                <p className="font-light text-lg text-gray-500">
                    Previous rating
                </p>
                <p className="font-light text-gray-500 text-xs">
                    {`${moment(previousTimeperiod.start).format(
                        'D MMM'
                    )} - ${moment(previousTimeperiod.end).format('D MMM')}`}
                </p>
                <p className="text-gray-700 font-bold text-lg leading-6 mt-2">
                    {previousRating}
                </p>
            </div>
            {rating === previousRating && <EqualIcon />}
            {rating > previousRating && <GreaterIcon />}
            {rating < previousRating && <LessIcon />}
            <div className="text-center">
                <p className="font-light text-lg text-gray-500">
                    Current rating
                </p>
                <p className="font-light text-gray-500 text-xs">{`${moment(
                    timeperiod.start
                ).format('D MMM')} - ${moment(timeperiod.end).format(
                    'D MMM'
                )}`}</p>
                <p className="text-gray-700 font-bold text-lg leading-6 mt-2">
                    {rating}
                </p>
            </div>
        </div>
    );
}
