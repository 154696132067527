import { PRICING } from '../constants';

export function calculatePrice(isYearly, plan, contributors, retention) {
    let pricePerPeriod = 0;

    if (isYearly) {
        pricePerPeriod =
            (PRICING.retentionPrice.annual * retention +
                PRICING.contributorPrice[plan].annual * contributors) *
            12;
    } else {
        pricePerPeriod =
            PRICING.retentionPrice.monthly * retention +
            PRICING.contributorPrice[plan].monthly * contributors;
    }

    return pricePerPeriod;
}
