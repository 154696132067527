import React from 'react';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from '../../../helpers/textFormatters';

const WorkspacesHeader = () => {
    const { t } = useTranslation();
    return (
        <div className="grid grid-cols-12 workspaces-list__header">
            <p className="col-span-3 px-4">
                {capitalizeFirstLetter(t('settings_page.kudos_tab.name'))}
            </p>
            <p className="col-span-3 px-4">
                {capitalizeFirstLetter(t('settings_page.kudos_tab.users'))}
            </p>
            <p className="col-span-3 px-4">
                {capitalizeFirstLetter(t('settings_page.kudos_tab.channels'))}
            </p>
            <p className="col-span-3 px-4">
                {capitalizeFirstLetter(t('settings_page.kudos_tab.activity'))}
            </p>
        </div>
    );
};

export default WorkspacesHeader;
