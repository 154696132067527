import React from 'react';

import Workspaces from '../workspaces';
import { KudosSettings } from '../kudos';
import WhitePanelContentWrapper from '../../white-panel-content-wrapper';

const KudosTab = () => {
    return (
        <div className="settings-tab-content kudos-tab">
            <WhitePanelContentWrapper className="mb-8">
                <Workspaces />
            </WhitePanelContentWrapper>
            <WhitePanelContentWrapper className="mb-8">
                <KudosSettings />
            </WhitePanelContentWrapper>
        </div>
    );
};

export default KudosTab;
