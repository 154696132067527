import { v4 as uuidv4 } from 'uuid';
import queryString from 'query-string';
import {
    AUTH_BASE_URL_FACEBOOK,
    AUTH_BASE_URL_GOOGLE,
    AUTH_BASE_URL_LINKEDIN,
    AUTH_BASE_URL_MICROSOFT,
    MICROSOFT_REDIRECT_URL_INVITE_SIGNUP,
    MICROSOFT_REDIRECT_URL_LOGIN,
    MICROSOFT_REDIRECT_URL_TRIAL,
    MICROSOFT_REDIRECT_URL_EXPLORE_SIGNUP,
    REDIRECT_URL_INVITE_SIGNUP,
    REDIRECT_URL_LOGIN,
    REDIRECT_URL_TRIAL,
    REDIRECT_URL_DEMO,
    STATE_PARAMS_KEY,
    STATE_PARAMS_VALUE_FACEBOOK,
    STATE_PARAMS_VALUE_GOOGLE,
    STATE_PARAMS_VALUE_LINKEDIN,
    STATE_PARAMS_VALUE_MICROSOFT,
    REDIRECT_URL_EXPLORE,
} from '../../constants';

export function createLink(social, endpoint) {
    const baseUrl = getBaseUrl(social);
    const clientIdEnvName = getClientIdName(social);
    const redirectUri = getRedirectUrl(endpoint, social);
    const stateKey = getStateKey(social);

    const generatedUuid = uuidv4();
    localStorage.setItem(stateKey, generatedUuid);

    const state = {
        st: generatedUuid,
        se: social,
    };

    const stringifiedParams = queryString.stringify(
        compileStringifiedParams(
            social,
            process.env[clientIdEnvName],
            redirectUri,
            state
        )
    );

    return `${baseUrl}?${stringifiedParams}`;
}

export function getStateKey(social) {
    switch (social) {
        case 'facebook':
            return STATE_PARAMS_VALUE_FACEBOOK;
        case 'microsoft':
            return STATE_PARAMS_VALUE_MICROSOFT;
        case 'linkedin':
            return STATE_PARAMS_VALUE_LINKEDIN;
        case 'google':
        default:
            return STATE_PARAMS_VALUE_GOOGLE;
    }
}

function getBaseUrl(social) {
    switch (social) {
        case 'facebook':
            return AUTH_BASE_URL_FACEBOOK;
        case 'microsoft':
            return AUTH_BASE_URL_MICROSOFT;
        case 'linkedin':
            return AUTH_BASE_URL_LINKEDIN;
        case 'google':
        default:
            return AUTH_BASE_URL_GOOGLE;
    }
}

function getClientIdName(social) {
    switch (social) {
        case 'facebook':
            return 'REACT_APP_FACEBOOK_CLIENT_ID';
        case 'microsoft':
            return 'REACT_APP_MICROSOFT_CLIENT_ID';
        case 'linkedin':
            return 'REACT_APP_LINKEDIN_CLIENT_ID';
        case 'google':
        default:
            return 'REACT_APP_GOOGLE_CLIENT_ID';
    }
}

function getRedirectUrl(endpoint, social) {
    switch (endpoint) {
        case 'explore':
            if (social === 'microsoft') {
                return MICROSOFT_REDIRECT_URL_EXPLORE_SIGNUP;
            }
            return REDIRECT_URL_EXPLORE;
        case 'demo':
            return REDIRECT_URL_DEMO;
        case 'invite':
            if (social === 'microsoft') {
                return MICROSOFT_REDIRECT_URL_INVITE_SIGNUP;
            }
            return REDIRECT_URL_INVITE_SIGNUP;
        case 'signup':
        case 'trial':
            if (social === 'microsoft') {
                return MICROSOFT_REDIRECT_URL_TRIAL;
            }
            return REDIRECT_URL_TRIAL;
        case 'login':
        default:
            if (social === 'microsoft') {
                return MICROSOFT_REDIRECT_URL_LOGIN;
            }
            return REDIRECT_URL_LOGIN;
    }
}

function compileStringifiedParams(social, clientId, redirectUri, state) {
    switch (social) {
        case 'facebook':
            return {
                client_id: clientId,
                redirect_uri: redirectUri,
                state: JSON.stringify(state),
                scope: 'email',
            };
        case 'microsoft':
            return {
                client_id: clientId,
                response_type: 'id_token',
                redirect_uri: redirectUri,
                scope: 'openid offline_access email',
                state: JSON.stringify(state),
                response_mode: 'form_post',
                nonce: '678910',
            };
        case 'linkedin':
            return {
                client_id: clientId,
                redirect_uri: redirectUri,
                scope: 'r_liteprofile r_emailaddress',
                response_type: 'code',
                state: JSON.stringify(state),
            };
        case 'google':
        default:
            return {
                client_id: clientId,
                redirect_uri: redirectUri,
                scope: [
                    'https://www.googleapis.com/auth/userinfo.email',
                    'https://www.googleapis.com/auth/userinfo.profile',
                ].join(' '),
                response_type: 'code',
                access_type: 'offline',
                prompt: 'consent',
                state: JSON.stringify(state),
            };
    }
}
