import React, { useEffect } from 'react';
import Select from 'react-select';

function FormikSelect({ options, field, form }) {
    if (field.value) {
        if (options.find((option) => field.value === option.value)) {
            return (
                <Select
                    name={field.name}
                    onBlur={field.onBlur}
                    onChange={({ value }) =>
                        form.setFieldValue(field.name, value)
                    }
                    options={options}
                    styles={setStyle()}
                    defaultValue={{ value: field.value, label: field.value }}
                />
            );
        } else {
            return (
                <Select
                    name={field.name}
                    onBlur={field.onBlur}
                    onChange={({ value }) =>
                        form.setFieldValue(field.name, value)
                    }
                    options={options}
                    styles={setStyle()}
                    defaultValue={{ value: 'Other', label: 'Other' }}
                />
            );
        }
    }
    return (
        <Select
            name={field.name}
            onBlur={field.onBlur}
            onChange={({ value }) => form.setFieldValue(field.name, value)}
            options={options}
            styles={setStyle()}
        />
    );
}

const setStyle = () => ({
    control: (provided) => ({
        ...provided,
        outline: 'none',
        boxShadow: 'none',
        backgroundColor: 'transparent',
        opacity: '1',
        borderColor: '#DADDE8',
        '&:hover': {
            opacity: '1',
        },
    }),
    singleValue: (provided) => ({
        ...provided,
        color: '#484a53',
    }),
    indicatorsContainer: (provided) => ({
        ...provided,
        color: '#484a53',
        opacity: '1',
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        color: '#DADDE8',
        opacity: '1',
        cursor: 'pointer',
        '&:hover': {
            color: '#484a53',
        },
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        backgroundColor: '#DADDE8',
        opacity: '1',
    }),
    menu: (provided) => ({
        ...provided,
        marginTop: '0',
        padding: '0',
    }),
    valueContainer: (provided) => ({
        ...provided,
        flexWrap: 'no-wrap',
    }),
    option: (provided, state) => ({
        ...provided,
        cursor: 'pointer',
        color: '#484a53',
        backgroundColor: 'transparent',
        '&:hover': {
            backgroundColor: state.isSelected
                ? '#4C72BD'
                : 'rgba(76, 114, 189, 0.5)',
        },
    }),
    placeholder: (provided) => ({
        ...provided,
        color: '#DADDE8',
    }),
});

export default FormikSelect;
