import React, { useState, useEffect } from 'react';
import Loader from '../loader';
import ErrorIndicator from '../error-indicator';
import SelectedRepository from './selected-repository';
import {
    getSelectedRepositoryJobs,
    getSelectedRepositoryLeaks,
    getSelectedRepositoryStock,
} from '../../api/swarm/SwarmAPI';
import { groupRepos } from '../../helpers/groupRepos';

const SelectedRepositoriesListWrapper = ({
    selectedRepositoriesState,
    selectedRepositoriesDispatch,
    addRepositoryLoading,
    setAddRepositoryLoading,
}) => {
    const [infoStates, setInfoStates] = useState([]);

    useEffect(() => {
        selectedRepositoriesState?.data?.map((provider) => {
            provider.repositories.map((repository, index) => {
                setInfoStates((prevState) => ({
                    ...prevState,
                    [index]: false,
                }));
            });
        });
    }, [selectedRepositoriesState.data]);

    if (selectedRepositoriesState.loading || addRepositoryLoading) {
        return (
            <div className="repositories-list grid grid-cols-12">
                <div className="col-span-12 flex justify-center items-center">
                    <Loader color={'#C2C7D7'} size={35} speedMultiplier={0.8} />
                </div>
            </div>
        );
    }

    if (selectedRepositoriesState.error) {
        return (
            <div className="repositories-list grid grid-cols-12">
                <div
                    className="col-span-12"
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <ErrorIndicator error={selectedRepositoriesState.error} />
                </div>
            </div>
        );
    }

    if (selectedRepositoriesState?.data?.length === 0) {
        return (
            <div className="repositories-list grid grid-cols-12">
                <div className="col-span-12">
                    <p className="text-center text-gray-500 text-xl p-4">
                        No selected repositories
                    </p>
                </div>
            </div>
        );
    }

    return (
        <ul className="">
            {selectedRepositoriesState?.data?.map((provider, providerIndex) => (
                <ProviderSelectedRepositories
                    key={provider.provider_id}
                    provider={provider}
                    selectedRepositoriesState={selectedRepositoriesState}
                    selectedRepositoriesDispatch={selectedRepositoriesDispatch}
                    setAddRepositoryLoading={setAddRepositoryLoading}
                    providerIndex={providerIndex}
                    providersAmount={selectedRepositoriesState?.data?.length}
                    infoStates={infoStates}
                    setInfoStates={setInfoStates}
                />
            ))}
        </ul>
    );
};

const ProviderSelectedRepositories = ({
    provider,
    selectedRepositoriesState,
    selectedRepositoriesDispatch,
    setAddRepositoryLoading,
    providerIndex,
    infoStates,
    setInfoStates,
    providersAmount,
}) => {
    const [stockState, setStockState] = useState({
        data: null,
        loading: true,
        error: false,
    });

    const [leaksState, setLeaksState] = useState({
        data: null,
        loading: true,
        error: false,
    });

    const [jobsState, setJobsState] = useState({
        data: null,
        loading: true,
        error: false,
    });

    const [groupedRepos, setGroupedRepos] = useState(null);

    useEffect(() => {
        if (provider?.groups?.length) {
            setGroupedRepos(groupRepos(provider));
        }

        let canceled = false;

        setStockState((prevState) => ({ ...prevState, loading: true }));
        setLeaksState((prevState) => ({ ...prevState, loading: true }));
        setJobsState((prevState) => ({ ...prevState, loading: true }));

        getSelectedRepositoryJobs(provider.provider_id)
            .then((response) => {
                if (!canceled) {
                    if (response.status === 204) {
                        setJobsState({
                            data: {},
                            loading: false,
                            error: null,
                        });
                    } else {
                        setJobsState({
                            data: response.data,
                            loading: false,
                            error: null,
                        });
                    }
                }
            })
            .catch((error) => {
                setJobsState({
                    data: null,
                    loading: false,
                    error,
                });
            });

        getSelectedRepositoryStock(provider.provider_id)
            .then((response) => {
                if (!canceled) {
                    if (response.status === 204) {
                        setStockState({
                            data: {},
                            loading: false,
                            error: null,
                        });
                    } else {
                        setStockState({
                            data: response.data,
                            loading: false,
                            error: null,
                        });
                    }
                }
            })
            .catch((error) => {
                setStockState({
                    data: null,
                    loading: false,
                    error,
                });
            });

        getSelectedRepositoryLeaks(provider.provider_id)
            .then((response) => {
                if (!canceled) {
                    if (response.status === 204) {
                        setLeaksState({
                            data: {},
                            loading: false,
                            error: null,
                        });
                    } else {
                        setLeaksState({
                            data: response.data,
                            loading: false,
                            error: null,
                        });
                    }
                }
            })
            .catch((error) => {
                setLeaksState({
                    data: null,
                    loading: false,
                    error,
                });
            });

        return () => {
            canceled = true;
        };
    }, [provider.repositories.length, provider.provider_id]);

    useEffect(() => {
        setGroupedRepos(groupRepos(provider));
    }, [selectedRepositoriesState?.data]);

    let startIndex = 0;

    for (let i = 0; i < providerIndex; i++) {
        startIndex += selectedRepositoriesState.data[i].repositories.length;
    }

    if (!provider?.groups?.length || !groupedRepos) {
        return (
            <>
                {providerIndex > 0 ? (
                    <h3 className="col-span-3 my-4 font-display text-gray-700 font-bold text-lg	leading-5">
                        {provider.provider_id}
                    </h3>
                ) : null}
                <div className="grid grid-flow-row-dense grid-cols-3 gap-x-8 gap-y-6 mb-2.5">
                    {provider.repositories.map((repository, index) => (
                        <SelectedRepository
                            data={repository}
                            stockState={stockState}
                            leaksState={leaksState}
                            jobsState={jobsState}
                            provider={{
                                provider: provider.provider,
                                provider_id: provider.provider_id,
                            }}
                            key={`${repository.url}`}
                            index={startIndex + index}
                            prevReposLength={0}
                            infoStates={infoStates}
                            setInfoStates={setInfoStates}
                            selectedRepositoriesDispatch={
                                selectedRepositoriesDispatch
                            }
                            setAddRepositoryLoading={setAddRepositoryLoading}
                            allRepositories={provider.repositories}
                        />
                    ))}
                </div>
            </>
        );
    }

    const groupedReposEntries = Object.entries(groupedRepos);

    return groupedReposEntries.map((group, ind) => {
        let prevReposLength = 0;
        for (let i = 0; i < ind; i++) {
            if (groupedReposEntries[i][1]?.length) {
                prevReposLength =
                    prevReposLength + groupedReposEntries[i][1]?.length;
            }
        }

        return group[1]?.length ? (
            <div key={group[0]}>
                <h3 className="col-span-3 my-4 font-display text-gray-700 font-bold text-lg	leading-5">
                    {providersAmount > 1 ? `${provider.provider_id}/` : null}
                    {provider.groups.find(
                        (repoGroup) =>
                            repoGroup.id === group[0] ||
                            +repoGroup.id === +group[0]
                    )?.name ?? 'Other'}
                </h3>
                <div className="grid grid-flow-row-dense grid-cols-3 gap-x-8 gap-y-6 mb-2.5">
                    {group[1].map((repository, index) => (
                        <SelectedRepository
                            data={repository}
                            stockState={stockState}
                            leaksState={leaksState}
                            jobsState={jobsState}
                            provider={{
                                provider: provider.provider,
                                provider_id: provider.provider_id,
                            }}
                            key={`${repository.url}`}
                            index={startIndex + prevReposLength + index}
                            prevReposLength={prevReposLength}
                            infoStates={infoStates}
                            setInfoStates={setInfoStates}
                            selectedRepositoriesDispatch={
                                selectedRepositoriesDispatch
                            }
                            setAddRepositoryLoading={setAddRepositoryLoading}
                            allRepositories={provider.repositories}
                        />
                    ))}
                </div>
            </div>
        ) : null;
    });
};

export default SelectedRepositoriesListWrapper;
