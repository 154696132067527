import React from 'react';
import InputText from '../../../components/form/parts/InputText';
import InputTextarea from '../../../components/form/parts/InputTextarea';

const FormField = ({
    name,
    attributeName,
    fieldType,
    placeholderText,
    error,
    touched,
    value,
    disabled,
    labelClassName,
    ignoreErrors,
    className,
}) => {
    if (fieldType === 'textarea') {
        return (
            <div>
                <label
                    className={`text-gray-400 mb-1 text-m ${labelClassName}`}
                    htmlFor={attributeName}
                >
                    {name}
                </label>

                <InputTextarea
                    id={attributeName}
                    className={className}
                    name={attributeName}
                    placeholder={placeholderText}
                    disabled={disabled}
                />

                {!ignoreErrors &&
                    (error && touched ? (
                        <div className="text-red-500 mt-1 text-xs">{error}</div>
                    ) : (
                        <div className="text-red-500 mt-1 text-xs invisible">
                            hidden
                        </div>
                    ))}
            </div>
        );
    }

    return (
        <div>
            <label
                className={`text-gray-400 mb-1 text-m ${labelClassName}`}
                htmlFor={attributeName}
            >
                {name}
                {value ? (
                    <InputText
                        id={attributeName}
                        type={fieldType}
                        name={attributeName}
                        placeholder={placeholderText}
                        value={value}
                        disabled={disabled}
                        className={className}
                    />
                ) : (
                    <InputText
                        id={attributeName}
                        className={className}
                        type={fieldType}
                        name={attributeName}
                        placeholder={placeholderText}
                        disabled={disabled}
                    />
                )}
            </label>

            {!ignoreErrors &&
                (error && touched ? (
                    <div className="text-red-500 mt-1 text-xs">{error}</div>
                ) : (
                    <div className="text-red-500 mt-1 text-xs invisible">
                        hidden
                    </div>
                ))}
        </div>
    );
};

export default FormField;
