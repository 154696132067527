import React, { useState } from 'react';
import InputText from './InputText';
import { IoMdEyeOff, IoMdEye } from 'react-icons/io';

function InputPassword(props) {
    const [showPassword, toggleShowPassword] = useState(false);

    return (
        <div className="relative w-full">
            <InputText
                id={props.id || props.name}
                autoComplete={'off'}
                type={showPassword ? 'text' : 'password'}
                {...props}
            />
            <span
                onClick={() => toggleShowPassword(!showPassword)}
                className="absolute h-full text-xl text-gray-400 right-2 z-10 py-2.5"
            >
                {showPassword ? <IoMdEyeOff /> : <IoMdEye />}
            </span>
        </div>
    );
}

export default InputPassword;
