import { instances, customInstances } from '../default-setup';

export const getSREBackends = () => instances.get('settings/slo_backend/');

export const testSREBackend = (data) =>
    instances.post('slo_backend/test_connection/', data);

export const createSREBackend = (data) =>
    instances.post('settings/slo_backend/', data);

export const deleteSREBackend = (name) =>
    instances.delete(`settings/slo_backend/${name}/`);

export const updateSREBackend = (name, data) =>
    instances.put(`settings/slo_backend/${name}/`, data);
