export const STATE_PARAMS_KEY = 'state';
export const STATE_PARAMS_VALUE_FACEBOOK = 'facebook_state';
export const STATE_PARAMS_VALUE_LINKEDIN = 'linkedin_state';
export const STATE_PARAMS_VALUE_GOOGLE = 'google_state';
export const STATE_PARAMS_VALUE_MICROSOFT = 'microsoft_state';
export const REDIRECT_URL = 'https://www.prod.agileanalytics.cloud/demo';
export const REDIRECT_URL_DEMO = 'https://www.prod.agileanalytics.cloud/demo';
export const REDIRECT_URL_EXPLORE = 'https://www.prod.agileanalytics.cloud/explore';
export const REDIRECT_URL_LOGIN = 'https://www.prod.agileanalytics.cloud/login';
export const REDIRECT_URL_TRIAL =
    'https://www.prod.agileanalytics.cloud/signup';
export const REDIRECT_URL_INVITE_SIGNUP =
    'https://www.prod.agileanalytics.cloud/invite';
export const MICROSOFT_REDIRECT_URL_DEMO =
    'https://api.prod.agileanalytics.cloud/microsoft/signup/';
export const MICROSOFT_REDIRECT_URL_LOGIN =
    'https://api.prod.agileanalytics.cloud/microsoft/login/';
export const MICROSOFT_REDIRECT_URL_TRIAL =
    'https://api.prod.agileanalytics.cloud/microsoft/oauth/callback/trial/';
export const MICROSOFT_REDIRECT_URL_INVITE_SIGNUP =
    'https://api.prod.agileanalytics.cloud/microsoft/oauth/callback/invite/';
export const MICROSOFT_REDIRECT_URL_EXPLORE_SIGNUP =
    'https://api.prod.agileanalytics.cloud/microsoft/oauth/callback/explore/';
export const AUTH_BASE_URL_FACEBOOK =
    'https://www.facebook.com/v11.0/dialog/oauth';
export const AUTH_BASE_URL_LINKEDIN =
    'https://www.linkedin.com/oauth/v2/authorization';
export const AUTH_BASE_URL_GOOGLE =
    'https://accounts.google.com/o/oauth2/v2/auth';
export const AUTH_BASE_URL_MICROSOFT =
    'https://login.microsoftonline.com/common/oauth2/v2.0/authorize';
export const DEMO_ORG_HASH = 'O27A10';

// MICROSOFT_REDIRECT_URL
// 'https://127.0.0.1:5000/microsoft_signup/'
// 'https://api.prod.agileanalytics.cloud/microsoft_signup/'
// REDIRECT_URL
// 'https://www.prod.agileanalytics.cloud/signup';
// 'https://localhost:3000/signup';
