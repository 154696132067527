import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import {
    AiOutlineCloseCircle,
    AiOutlineExclamationCircle,
} from 'react-icons/ai';

function SettingsMessage({
    messageText,
    link,
    linkText,
    renderContentFunc = null,
    type = 'blue', // also 'red' and 'yellow'
}) {
    const styles = {
        blue: {
            container: 'bg-theme-tertiary  bg-opacity-10 border-theme-tertiary',
            textColor: 'text-theme-tertiary',
            renderIcon: () => (
                <AiOutlineExclamationCircle size={40} fill={'#1FB8D3'} />
            ),
        },
        red: {
            container: 'bg-red-200 border-red-300',
            textColor: 'text-red-500',
            renderIcon: () => (
                <AiOutlineCloseCircle size={40} fill={'#8c2e33'} />
            ),
        },
        yellow: {
            container: 'bg-yellow-100 border-yellow-300',
            textColor: 'text-yellow-700',
            renderIcon: () => (
                <AiOutlineExclamationCircle size={40} fill={'#a16207'} />
            ),
        },
    };

    switch (type) {
    }

    return (
        <div
            className={`error-indicator p-3  rounded-lg border border-solid  mb-4 flex items-center gap-x-4 ${styles[type].container}`}
        >
            {styles[type].renderIcon()}
            <div className="flex flex-col gap-1">
                {messageText ? (
                    <p className={styles[type].textColor}>{messageText}</p>
                ) : null}
                {renderContentFunc ? renderContentFunc() : null}
                {link ? (
                    <HashLink
                        className={`${styles[type].textColor} underline`}
                        to={link}
                    >
                        {linkText ?? 'Settings'}
                    </HashLink>
                ) : null}
            </div>
        </div>
    );
}

export default SettingsMessage;
