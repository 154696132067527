import React, { useState, useContext, useEffect, useCallback } from 'react';
import { OrganisationsContext } from '../context/OrganisationsContext';
import { UserContext } from '../context/UserContext';
import { OrgUserListContext } from '../context/OrgUserListContext';
import UsersList from '../components/users-list';
import Loader from '../components/loader';
import ErrorIndicator from '../components/error-indicator';
import { InputGroup, SaveButton } from '../components/custom';
import classnames from 'classnames';
import { useFetchDataAndSetState } from '../helpers/useFetchDataAndSetState';
import showNotification from '../helpers/showNotification';
import { Navigate } from 'react-router-dom';
import Select from 'react-select';
import { addUserFromAdminPanel, getUsers } from '../api/settings/UserAPI';
import { getAllOrganisations } from '../api/organisation/OrganisationAPI';
import OrganisationsListRow from '../components/organizations-list-row';
import CreateOrganisationAdminForm from '../components/create-organisation-admin-form';
import setCustomStyle from '../helpers/customSelectStyles';
import AuthTokenCard from '../components/auth-token-card';
import { fetchUsersList } from '../helpers/fetchUserPhotos';

const AdminPage = () => {
    const { userState } = useContext(UserContext);

    const request = useCallback(() => getAllOrganisations(), []);
    const [updatesCounter, setUpdatesCounter] = useState(0);
    const [adminOrganisationsState] = useFetchDataAndSetState(request, [
        updatesCounter,
    ]);
    const { data, loading, error } = adminOrganisationsState;

    const triggerUpdate = () => setUpdatesCounter(updatesCounter + 1);

    const createOrganistionsList = () => {
        if (loading) {
            return (
                <div className="body__row Organisation flex justify-center">
                    <Loader color={'#C2C7D7'} size={35} speedMultiplier={0.8} />
                </div>
            );
        }

        if (error) {
            return (
                <div className="body__row Organisation flex justify-center">
                    <ErrorIndicator error={error} style={{ width: '100%' }} />
                </div>
            );
        }

        if (data && data.length > 0) {
            return data.map((org) => {
                if (org) {
                    return (
                        <OrganisationsListRow
                            data={org}
                            key={org.org_name}
                            onUpdate={triggerUpdate}
                        />
                    );
                }
            });
        } else {
            return (
                <div className="body__row Organisation Organisation-name">
                    No organisations
                </div>
            );
        }
    };

    if (userState.loading || adminOrganisationsState.loading) {
        return (
            <div
                className="container mx-auto admin-page text-center"
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '400px',
                }}
            >
                <Loader />
            </div>
        );
    }

    if (userState.error) {
        return (
            <div
                className=" container mx-auto admin-page"
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '400px',
                }}
            >
                <ErrorIndicator error={userState.error} />
            </div>
        );
    }

    if (userState.data.superadmin) {
        return (
            <div className=" container mx-auto admin-page grid grid-cols-12">
                <h1 className="h1 text-center col-span-12">Superadmin Page</h1>
                <AuthTokenCard />
                <h3 className="text-center col-span-12">Organisations List</h3>
                <div className="existion-organisations-list col-span-12">
                    <div className="existion-organisations-list__header grid grid-cols-8">
                        <div className="col-span-1 px-4">Organisation Name</div>
                        <div className="col-span-1 px-4">Status</div>
                        <div className="col-span-1 px-4">Subscription</div>
                        <div className="col-span-1 px-4">Retention days</div>
                        <div className="col-span-1 px-4">Users</div>
                    </div>
                    <div className="existion-organisations-list__body">
                        {createOrganistionsList()}
                    </div>
                </div>
                <CreateOrganisationAdminForm onCreate={triggerUpdate} />
                <UsersSection />
            </div>
        );
    }

    return <Navigate to="/" />;
};

const UsersSection = () => {
    const usersRequest = useCallback(() => getUsers(), []);

    const [usersState, usersDispatch] = useFetchDataAndSetState(usersRequest);

    return (
        <section className="users col-span-12 grid grid-cols-12">
            <h3 className="text-center col-span-12">Users List</h3>
            <UsersList usersState={usersState} usersDispatch={usersDispatch} />
            <AddUser usersState={usersState} usersDispatch={usersDispatch} />
        </section>
    );
};

const AddUser = ({ usersState, usersDispatch }) => {
    const { organisationsState } = useContext(OrganisationsContext);
    const { orgUserListState, orgUserListDispatch } =
        useContext(OrgUserListContext);

    const [userEmail, setUserEmail] = useState('');
    const [userFirstName, setUserFirstName] = useState('');
    const [userLastName, setUserLastName] = useState('');
    const [userRole, setUserRole] = useState('guest');
    const [userOrganisation, setUserOrganisation] = useState(null);
    const [userLoginOption, setUserLoginOption] = useState(null);
    const [password, setPassword] = useState('');

    useEffect(() => {
        let canceled = false;

        if (!canceled) {
            setUserOrganisation(orgList()[0]);
        }

        return () => {
            canceled = true;
        };
    }, [organisationsState.data, organisationsState.loading]);

    const statusOptions = [
        { value: 'guest', label: 'Guest' },
        { value: 'write', label: 'Write' },
        { value: 'admin', label: 'Admin' },
    ];
    const loginOptions = [
        { value: 'password', label: 'Password' },
        { value: 'facebook', label: 'Facebook' },
        { value: 'google', label: 'Google' },
        { value: 'linkedin', label: 'LinkedIn' },
        { value: 'microsoft', label: 'Microsoft' },
        { value: 'saml', label: 'SAML' },
    ];

    function orgList() {
        return organisationsState.data.map((item) => {
            return { ...item, value: item.org_hash, label: item.org_name };
        });
    }

    const createOrganisationSelector = () => {
        if (userRole === 'guest')
            return (
                <div className="select-wrapper select-organisation">
                    <p
                        className="select-title"
                        style={{
                            fontWeight: '500',
                            marginBottom: '10px',
                            color: '#484a53',
                        }}
                    >
                        User Organisation
                    </p>
                    <p className="guest-organisation">Pied Piper</p>
                </div>
            );

        if (organisationsState.loading) {
            return (
                <div className="select-wrapper select-organisation">
                    <p
                        className="select-title"
                        style={{
                            fontWeight: '500',
                            marginBottom: '10px',
                            color: '#484a53',
                        }}
                    >
                        User Organisation
                    </p>
                    <Loader color={'#C2C7D7'} size={35} speedMultiplier={0.8} />
                </div>
            );
        }

        if (organisationsState.error) {
            return (
                <div className="select-wrapper select-organisation">
                    <p
                        className="select-title"
                        style={{
                            fontWeight: '500',
                            marginBottom: '10px',
                            color: '#484a53',
                        }}
                    >
                        User Organisation
                    </p>
                    <ErrorIndicator error={organisationsState.error} />
                </div>
            );
        }

        if (organisationsState.data) {
            return organisationsState.data.length > 1 ? (
                <div className="select-wrapper select-organisation">
                    <p
                        className="select-title"
                        style={{
                            fontWeight: '500',
                            marginBottom: '10px',
                            color: '#484a53',
                        }}
                    >
                        User Role
                    </p>
                    <Select
                        isSearchable={false}
                        options={orgList()}
                        styles={setCustomStyle()}
                        value={userOrganisation}
                        onChange={(selectedOrg) =>
                            setUserOrganisation(selectedOrg)
                        }
                    />
                </div>
            ) : organisationsState.length === 0 ? (
                <div className="select-wrapper select-organisation">
                    <p
                        className="select-title"
                        style={{
                            fontWeight: '500',
                            marginBottom: '10px',
                            color: '#484a53',
                        }}
                    >
                        User Role
                    </p>
                    <p className="single-org">No Organisations</p>
                </div>
            ) : (
                <div className="select-wrapper select-organisation">
                    <p
                        className="select-title"
                        style={{
                            fontWeight: '500',
                            marginBottom: '10px',
                            color: '#484a53',
                        }}
                    >
                        User Role
                    </p>
                    <p className="single-org">
                        {organisationsState[0].org_name}
                    </p>
                </div>
            );
        } else {
            return (
                <div className="select-wrapper select-organisation">
                    <p
                        className="select-title"
                        style={{
                            fontWeight: '500',
                            marginBottom: '10px',
                            color: '#484a53',
                        }}
                    >
                        User Role
                    </p>
                    <p className="single-org">No Organisations</p>
                </div>
            );
        }
    };

    const saveNewUser = (event) => {
        if (!userLoginOption) {
            event.preventDefault();
            showNotification('Login option is reqired', 'error');
            return;
        } else {
            const data = {};
            data.email = userEmail;
            data.role = userRole;
            data.login_option = userLoginOption;
            if (userRole !== 'guest') {
                data.first_name = userFirstName ?? '';
                data.last_name = userLastName ?? '';
                data.org_hash = userOrganisation.value;
            } else {
                data.org_hash = organisationsState.data.find(
                    (org) => org.org_name === 'Pied Piper'
                ).org_hash;
            }

            if (password) data.password = password;

            addUserFromAdminPanel(data)
                .then(() => {
                    showNotification('User has been added', 'success');
                    usersDispatch({
                        type: 'SET_DATA',
                        payload: [
                            {
                                ...data,
                                roles: {
                                    superadmin: false,
                                    [data.org_hash]: data.role,
                                },
                            },
                            ...usersState.data,
                        ],
                    });
                    fetchUsersList(orgUserListDispatch);
                })
                .catch((error) => {
                    showNotification(error.response?.data || error.message);
                });

            event.preventDefault();
        }
    };

    return (
        <div className="add-user col-start-3 col-end-11">
            <h3 className="text-center">Add User</h3>
            <form
                className="add-user-form form-wrapper grid grid-cols-12"
                onSubmit={saveNewUser}
            >
                <div className="col-span-4 px-4">
                    <InputGroup
                        label={{
                            text: 'User Email',
                            for: 'new_user_email',
                        }}
                        input={{
                            required: true,
                            value: userEmail,
                            type: 'email',
                            name: 'new_user_email',
                            id: 'new_user_email',
                            placeholder: 'Enter user email',
                            onChange: (e) => setUserEmail(e.target.value),
                            style: {
                                paddingTop: '6px',
                                paddingBottom: '6px',
                                marginTop: '10px',
                            },
                        }}
                    />
                </div>
                <div
                    className="select-wrapper select-role col-span-4 px-4"
                    style={{ marginBottom: '20px' }}
                >
                    <p
                        className="select-title"
                        style={{
                            fontWeight: '500',
                            marginBottom: '10px',
                            color: '#484a53',
                        }}
                    >
                        User Role
                    </p>
                    <Select
                        id="user_role"
                        name="user_role"
                        isSearchable={false}
                        options={statusOptions}
                        styles={setCustomStyle()}
                        value={statusOptions.find(
                            (option) => option.value === userRole
                        )}
                        onChange={(option) => setUserRole(option.value)}
                    />
                </div>
                <div
                    className="select-wrapper select-role col-span-4 px-4"
                    style={{ marginBottom: '20px' }}
                >
                    <p
                        className="select-title"
                        style={{
                            fontWeight: '500',
                            marginBottom: '10px',
                            color: '#484a53',
                        }}
                    >
                        Login option
                    </p>
                    <Select
                        id="login_option"
                        name="login_option"
                        isSearchable={false}
                        options={loginOptions}
                        styles={setCustomStyle()}
                        value={loginOptions.find(
                            (option) => option.value === userLoginOption
                        )}
                        onChange={(option) => setUserLoginOption(option.value)}
                    />
                </div>
                <div
                    className={`col-span-12 grid grid-cols-12 ${classnames({
                        'disabled-row': userRole === 'guest',
                    })}`}
                >
                    {userRole === 'guest' && (
                        <div className="disabled-notification col-span-12 text-center">
                            We don't need this fields for guest user.
                        </div>
                    )}
                    <div className="col-span-4 px-4">
                        <InputGroup
                            label={{
                                text: 'User First Name',
                                for: 'new_user_first_name',
                            }}
                            input={{
                                disabled: userRole === 'guest',
                                value: userFirstName,
                                type: 'text',
                                name: 'new_user_first_name',
                                id: 'new_user_first_name',
                                placeholder: 'Enter user first name',
                                onChange: (e) =>
                                    setUserFirstName(e.target.value),
                                style: {
                                    paddingTop: '6px',
                                    paddingBottom: '6px',
                                    marginTop: '10px',
                                },
                            }}
                        />
                    </div>
                    <div className="col-span-4 px-4">
                        <InputGroup
                            label={{
                                text: 'User Last Name',
                                for: 'new_user_last_name',
                            }}
                            input={{
                                disabled: userRole === 'guest',
                                value: userLastName,
                                type: 'text',
                                name: 'new_user_last_name',
                                id: 'new_user_last_name',
                                placeholder: 'Enter user last name',
                                onChange: (e) =>
                                    setUserLastName(e.target.value),
                                style: {
                                    paddingTop: '6px',
                                    paddingBottom: '6px',
                                    marginTop: '10px',
                                },
                            }}
                        />
                    </div>
                    <div className="col-span-4 px-4">
                        {createOrganisationSelector()}
                    </div>
                </div>
                <div className={`col-start-6 col-end-8 w-full m-auto`}>
                    <SaveButton
                        style={{
                            width: '100%',
                            marginTop: '26px',
                            paddingTop: '10px',
                            paddingBottom: '10px',
                        }}
                    />
                </div>
            </form>
        </div>
    );
};

// const regexpTest = /^[A-Za-z0-9 _-]*[A-Za-z0-9][A-Za-z0-9 _-]*$/.test(
//     newValue
// );

export default AdminPage;
