import React, { useContext } from 'react';
import Select from 'react-select';
import _ from 'lodash';
import { ProjectsContext } from '../../../../../../context/ProjectsContext';
import { PrivateProjectsContext } from '../../../../../../context/PrivateProjectsContext';
import showNotification from '../../../../../../helpers/showNotification';
import { debounce } from 'lodash';

function HoursPageTimesheetOverviewBodyRowEdit({
    data,
    formValues,
    setFormValues,
    weekRange,
    selectedProjects,
    currentProject,
    userHash,
    viewerHash,
}) {
    let totalHours = 0;

    const { projectsState } = useContext(ProjectsContext);
    const { privateProjectsState } = useContext(PrivateProjectsContext);

    let allProjects = [...projectsState.data];

    if (userHash === viewerHash) {
        allProjects = [...allProjects, ...privateProjectsState.data];
    }

    const notSelectedProjects = allProjects.filter((project) => {
        const isSelected = selectedProjects?.find(
            (selectedProject) => selectedProject === project.name
        );
        if (!isSelected) {
            return project;
        }

        if (project.name === currentProject) {
            return project;
        }
    });

    const projectOptions = notSelectedProjects
        .sort((a, b) =>
            a.name.toLowerCase().localeCompare(b.name.toLowerCase())
        )
        .map(({ name }) => ({ value: name, label: name }));

    function handleChangeProject(project) {
        setFormValues({ ...formValues, project: project.value });
    }

    function handleChangeHours(event) {
        const name = event.target.name;
        let value = event.target.value;
        let weekDays = formValues.days;

        if (+value > 24) {
            showNotification(
                'Day total mustn`t exceed 24 hours',
                'error',
                'hours-warning'
            );
            return;
        }

        const dayOtherProjectslHoursArray = data.map((project) => {
            // don`t count current project previous hours
            if (!project.project || project.project === formValues.project) {
                return 0;
            } else {
                const dateHours = project.days.find((day) => day.date === name);
                return +dateHours?.hours;
            }
        });

        const dayOtherProjectslHours = dayOtherProjectslHoursArray.reduce(
            (acc, item) => (acc += +item),
            0
        );

        const dayTotalHours = +dayOtherProjectslHours + +value;

        if (value[0] === ',' || value.length > 4) {
            return;
        }

        if (dayTotalHours > 24) {
            showNotification(
                'Day total mustn`t exceed 24 hours',
                'error',
                'hours-warning'
            );
            return;
        }

        if (weekDays.find((item) => item.date === name)) {
            // Update or create
            weekDays = weekDays.map((item) => {
                if (item.date === name) {
                    return {
                        ...item,
                        hours: value
                            .replace(/[^\\,\d]/g, '')
                            .replace(',,', ','),
                    }; //deleting all symbols whish are not numbers or ,
                }

                return item;
            });
        } else {
            weekDays.push({
                date: name,
                hours: value.replace(/[^\\.\d]/g, '').replace(',,', ','),
            });
        }

        setFormValues({
            ...formValues,
            days: weekDays,
        });
    }

    return (
        <>
            <div className="w-64 px-4">
                <Select
                    name={'project'}
                    value={projectOptions.find(
                        (item) => item.value === formValues.project
                    )}
                    options={projectOptions}
                    onChange={handleChangeProject}
                />
            </div>
            <div className="flex justify-between w-128">
                {weekRange.map((day) => {
                    const dateString = day.format('YYYY-MM-DD');
                    const matched = formValues.days?.find(
                        (item) => item.date === dateString
                    );
                    totalHours +=
                        +matched?.hours.toString().replace(',', '.') || 0;

                    return (
                        <div className="px-2 w-16 text-right" key={day}>
                            <input
                                name={dateString}
                                className="p-2 placeholder-gray-300 text-gray-700 relative bg-white
             rounded text-md border border-gray-300 outline-none focus:outline-none focus:ring w-14 text-right"
                                onChange={handleChangeHours}
                                value={matched?.hours
                                    .toString()
                                    .replace('.', ',')}
                                placeholder={0}
                            />
                        </div>
                    );
                })}
                <div className="px-4 w-16 text-right text-gray-400">
                    {Number.isInteger(totalHours)
                        ? totalHours.toString().replace('.', ',')
                        : (+totalHours).toFixed(2).toString().replace('.', ',')}
                </div>
            </div>
        </>
    );
}

export default HoursPageTimesheetOverviewBodyRowEdit;
