import { useEffect } from 'react';

function useOnClickOutside(ref, handler, otherRefs = []) {
    useEffect(() => {
        const listener = (event) => {
            if (
                !ref.current ||
                ref.current.contains(event.target) ||
                otherRefs.find(
                    (nextRef) =>
                        nextRef.current === event.target ||
                        event.target ===
                            nextRef.current.childNodes[0].childNodes[0]
                )
            ) {
                return;
            }

            handler();
        };

        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);

        return () => {
            document.removeEventListener('mousedown', listener);
            document.removeEventListener('touchstart', listener);
        };
    }, [ref, handler, otherRefs]);
}

export default useOnClickOutside;
