import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const SuggestTourModalContent = ({ sendRequest = async () => {} }) => {
    const { t } = useTranslation();

    return (
        <div className="flex flex-col py-4 h-full justify-between">
            <p className="text-xl text-gray-500">
                Take a quick tour of our demo organization to familiarize
                yourself with our platform and its features. It only takes a few
                minutes!
            </p>
            <button
                type="submit"
                className={`inline-flex justify-center px-6 py-2 text-lg leading-6	font-normal text-white bg-theme-tertiary-dark border border-transparent rounded-md hover:bg-theme-tertiary-dark disabled:cursor-not-allowed disabled:opacity-50`}
                style={{ minWidth: '110px' }}
                onClick={() => sendRequest()}
            >
                Start the tour
            </button>
        </div>
    );
};

export default SuggestTourModalContent;
