import React, { Component } from 'react';
import Select, { components } from 'react-select';
import setCustomStyle from './selectStyle';
import { withTranslation } from 'react-i18next';

import ruFlag from '../../assets/flags/ru.png';
import nlFlag from '../../assets/flags/nl.png';
import enFlag from '../../assets/flags/en.png';
import { momentLocaleSwitcher } from '../../helpers/momentLocaleSwitcher';

const langList = [
    { value: 'en', label: 'en', icon: enFlag },
    { value: 'nl', label: 'nl', icon: nlFlag },
];

const { Option, SingleValue } = components;
const IconOption = (props) => (
    <Option {...props}>
        <div className="image-wrapper">
            <img
                src={props.data.icon}
                alt={props.data.label}
                style={{ width: 16, height: 16, display: 'block' }}
            />
        </div>
        {props.data.label}
    </Option>
);

const IconSingleValue = (props) => (
    <SingleValue {...props}>
        <div className="image-wrapper">
            <img
                src={props.data.icon}
                style={{ width: 16, height: 16, display: 'block' }}
                alt={props.data.label}
            />
        </div>
        {props.data.label}
    </SingleValue>
);

class ChangeLang extends Component {
    state = {
        selectedLang: null,
    };

    static getDerivedStateFromProps(nextProps) {
        const selectedLang = {
            value: nextProps.i18n.language,
            label: nextProps.i18n.language,
            icon: `${nextProps.i18n.language}.png`,
        };
        if (nextProps.i18n.language === 'ru') {
            selectedLang.icon = ruFlag;
        } else if (nextProps.i18n.language === 'en') {
            selectedLang.icon = enFlag;
        } else {
            selectedLang.icon = nlFlag;
        }

        return {
            selectedLang,
        };
    }

    handleChange = async (selectedLang) => {
        this.props.i18n.changeLanguage(selectedLang.value);
        this.props.callbackFunc(selectedLang.value);
        try {
            await momentLocaleSwitcher(selectedLang.value);
        } catch (error) {
            console.log(error);
        }
    };

    render() {
        const { selectedLang } = this.state;

        return (
            <Select
                value={selectedLang}
                onChange={this.handleChange}
                options={langList}
                components={{
                    Option: IconOption,
                    SingleValue: IconSingleValue,
                }}
                styles={setCustomStyle(this.props.color)}
                isSearchable={false}
            />
        );
    }
}

export default withTranslation()(ChangeLang);
