import React from 'react';
import { ClipLoader } from 'react-spinners';

const Loader = ({ color = '#C2C7D7', size = 35, speedMultiplier = 0.8 }) => {
    return (
        <ClipLoader
            color={color}
            size={size}
            speedMultiplier={speedMultiplier}
            data-testid="loader"
        />
    );
};

export default Loader;
