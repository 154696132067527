export function groupRepos(selectedRepositoriesStateDataProvider) {
    const grouped = selectedRepositoriesStateDataProvider?.groups?.reduce(
        (acc, group) => {
            const groupRepos =
                selectedRepositoriesStateDataProvider?.repositories?.filter(
                    (repo) => repo?.group?.id === group.id
                );
            return { ...acc, [group.id]: groupRepos };
        },
        {}
    );

    const reposWithoutGroups =
        selectedRepositoriesStateDataProvider?.repositories?.filter(
            (repo) => !repo?.group?.id
        );

    if (reposWithoutGroups?.length) {
        const groupedWithOther = { ...grouped, other: reposWithoutGroups };
        return groupedWithOther;
    }

    return grouped;
}

export function groupReposWithGroupsList(groupsList, reposList) {
    const grouped = groupsList.map((group) => {
        return {
            ...group,
            repositories: reposList.filter((repo) =>
                group.repositories.find(
                    (groupRepo) =>
                        groupRepo.url === repo.repo_name ||
                        groupRepo.url === repo.repo_url
                )
            ),
        };
    });

    const groupedReposArray = grouped.reduce((acc, group) => {
        return [...acc, group.repositories];
    }, []);
    const reposWithoutGroups = reposList.filter(
        (repo) =>
            !groupedReposArray.find(
                (groupRepo) => groupRepo.repo_url === repo.repo_repo_url
            )
    );

    if (reposWithoutGroups?.length) {
        const groupedWithOther = [
            ...grouped,
            { id: 'other', name: 'Other', repositories: reposWithoutGroups },
        ];
        return groupedWithOther;
    }

    return grouped;
}
