import React from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

const TimeSelectorTabQuick = ({
    activeQuickSelect,
    setQuickTimeRange,
    params,
}) => {
    const { t } = useTranslation();

    return (
        <ul className="flex justify-end gap-x-4 quick-select-date-range p-4">
            <li>
                <button
                    className={classnames('test-button', {
                        active: activeQuickSelect === 1,
                    })}
                    onClick={() => setQuickTimeRange(6, 'days', 1)}
                >
                    {t('header.time_period.last_7_days')}
                </button>
            </li>
            <li>
                <button
                    className={classnames('test-button', {
                        active: activeQuickSelect === 2,
                    })}
                    onClick={() => setQuickTimeRange(13, 'days', 2)}
                >
                    {t('header.time_period.last_14_days')}
                </button>
            </li>
            <li>
                <button
                    className={classnames('test-button', {
                        active: activeQuickSelect === 3,
                    })}
                    onClick={() => setQuickTimeRange(2, 'months', 3)}
                >
                    {t('header.time_period.last_2_months')}
                </button>
            </li>
            <li>
                <button
                    className={classnames('test-button', {
                        active: activeQuickSelect === 4,
                    })}
                    onClick={() => setQuickTimeRange(3, 'months', 4)}
                >
                    {t('header.time_period.last_3_months')}
                </button>
            </li>
        </ul>
    );
};

export default TimeSelectorTabQuick;
