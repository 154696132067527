import { useReducer } from 'react';

export const useDefaultStateWithReducer = () =>
    useReducer(
        (state, action) => {
            switch (action.type) {
                case 'SET_LOADING':
                    return { data: state.data, loading: true, error: null };
                case 'SET_NO_LOADING':
                    return { data: state.data, loading: false, error: null };
                case 'SET_ERROR':
                    return {
                        data: state.data,
                        loading: false,
                        error: action.payload,
                    };
                case 'SET_DATA':
                    return {
                        data: action.payload,
                        loading: false,
                        error: null,
                    };
                default:
                    return state;
            }
        },
        {
            data: null,
            loading: true,
            error: null,
        }
    );
