import React, { useContext } from 'react';
import { OrganisationsContext } from '../../../context/OrganisationsContext';

import './styles.scss';

const TextAreaGroup = ({
    label = null,
    textarea,
    key = null,
    validationFunc = () => true,
    style = {},
}) => {
    const localOnChange = (e) => {
        textarea.onChange(e);
    };

    const { organisationsState } = useContext(OrganisationsContext);

    const selectedOrganisationHash = organisationsState.data
        ? organisationsState.data.find((org) => org.active).org_hash
        : null;

    const userPermission = selectedOrganisationHash
        ? organisationsState.data.find(
              (org) => org.org_hash === selectedOrganisationHash
          ).permissions
        : 'read';

    const getReadonlyValue = () => {
        switch (userPermission) {
            case 'superadmin':
            case 'admin':
            case 'write':
                return false;
            case 'read':
            default:
                return true;
        }
    };

    if (label) {
        if (key) {
            return (
                <div className="textarea-wrapper" key={key} style={style}>
                    <label htmlFor={label.for}>{label.text}</label>
                    <textarea
                        style={textarea.style ? textarea.style : null}
                        placeholder={textarea.placeholder}
                        id={textarea.id}
                        name={textarea.name}
                        value={textarea.value}
                        onChange={localOnChange}
                        readOnly={
                            textarea.readOnly
                                ? textarea.readOnly
                                : getReadonlyValue()
                        }
                    />
                    <span className="validation-error"></span>
                </div>
            );
        } else {
            return (
                <div className="textarea-wrapper" style={style}>
                    <label htmlFor={label.for}>{label.text}</label>
                    <textarea
                        style={textarea.style ? textarea.style : null}
                        placeholder={textarea.placeholder}
                        id={textarea.id}
                        name={textarea.name}
                        value={textarea.value}
                        onChange={localOnChange}
                        readOnly={
                            textarea.readOnly
                                ? textarea.readOnly
                                : getReadonlyValue()
                        }
                    />
                    <span className="validation-error"></span>
                </div>
            );
        }
    }

    if (key) {
        return (
            <div className="textarea-wrapper" key={key} style={style}>
                <textarea
                    style={textarea.style ? textarea.style : null}
                    placeholder={textarea.placeholder}
                    id={textarea.id}
                    name={textarea.name}
                    value={textarea.value}
                    onChange={localOnChange}
                    readOnly={
                        textarea.readOnly
                            ? textarea.readOnly
                            : getReadonlyValue()
                    }
                />
                <span className="validation-error"></span>
            </div>
        );
    } else {
        return (
            <div className="textarea-wrapper" style={style}>
                <textarea
                    style={textarea.style ? textarea.style : null}
                    placeholder={textarea.placeholder}
                    id={textarea.id}
                    name={textarea.name}
                    value={textarea.value}
                    onChange={localOnChange}
                    readOnly={
                        textarea.readOnly
                            ? textarea.readOnly
                            : getReadonlyValue()
                    }
                />
                <span className="validation-error"></span>
            </div>
        );
    }
};

export default TextAreaGroup;
