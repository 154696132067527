import html2canvas from 'html2canvas';
import downloadjs from 'downloadjs';
import { jsPDF } from 'jspdf';

const GenericPdfDownloader = ({ rootElementId, downloadFileName }) => {
    const downloadPdfDocument = () => {
        const input = document.getElementById(rootElementId);
        html2canvas(input, {
            height: window.outerHeight + window.innerHeight,
            windowHeight: window.outerHeight + window.innerHeight,
            scrollY: -window.scrollY,
        }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            downloadjs(imgData, downloadFileName + '.png', 'image/png');
            // const pdf = new jsPDF();
            // let width = pdf.internal.pageSize.getWidth();
            // let height = pdf.internal.pageSize.getHeight();
            // pdf.addImage(imgData, 'JPEG', 0, 0, width, height);
            // pdf.save(`${downloadFileName}.pdf`);
        });
    };

    return (
        <button onClick={downloadPdfDocument} className="btn-white-hover-blue">
            Export
        </button>
    );
};

export default GenericPdfDownloader;
