import React from 'react';
import moment from 'moment';

function HoursPageTimesheetOverviewBodyRowView({ project, days, weekRange }) {
    let totalHours = 0;
    return (
        <>
            <div className="w-64 px-4">{project}</div>
            <div className="flex justify-between w-128">
                {weekRange.map((day, index) => {
                    const matched = days?.find(
                        (item) => item.date === day.format('YYYY-MM-DD')
                    );
                    totalHours += matched?.hours || 0;

                    return (
                        <div key={index} className="px-4 w-16 text-right">
                            {matched?.hours.toString().replace('.', ',') || 0}
                        </div>
                    );
                })}
                <div className="px-4 w-16 text-right text-gray-400">
                    {Number.isInteger(totalHours)
                        ? totalHours.toString().replace('.', ',')
                        : (+totalHours).toFixed(2).toString().replace('.', ',')}
                </div>
            </div>
        </>
    );
}

export default HoursPageTimesheetOverviewBodyRowView;
