import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import FormField from '../../pages/sre-page/components/sre-form-field';
import Loader from '../loader';

const ModalSupportFormContent = ({ userData, setModalState }) => {
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState(null);

    const handleChangeModalState = () => {
        setIsLoading(false);
        setModalState(false);
    };

    // TODO: THIS CODE ADDS ABILITY TO UPLOAD IMAGES AS ATTACHMENTS. BUT OUR EMAIL-PROVIDER IN STRAPI DOES NOT SUPPORT ATTACHMENTS
    // const handleFilesChange = (event) => {
    //     setFiles([
    //         ...files,
    //         { [event.currentTarget.name]: event.currentTarget.files[0] },
    //     ]);
    // };

    const handleSubmit = async (values, resetForm) => {
        setIsLoading(true);
        let formData = new FormData();
        // files.map((fileObj) => {
        //     const file = Object.values(fileObj)[0];
        //     formData.append('files', file, file.name);
        // });

        formData.append('values', JSON.stringify(values));

        const response = await fetch(
            'https://cms.agileanalytics.cloud/api/send-email/supportMessageFromApp',
            {
                method: 'POST',
                body: formData,
                headers: {
                    Accept: 'application/json, text/plain, */*',
                },
            }
        );
        if (response.ok) {
            setIsLoading(false);
            resetForm();
            setMessage(
                'You message was successfully sent. We`ll get back to you shortly.'
            );
            setTimeout(() => {
                setMessage(null);
                setModalState(false);
            }, 1000);
        } else {
            setIsLoading(false);
            setMessage(
                response?.body?.message ?? 'Failed to send your request'
            );
        }
    };

    // TODO: THIS CODE ADDS ABILITY TO UPLOAD IMAGES AS ATTACHMENTS. BUT OUR EMAIL-PROVIDER IN STRAPI DOES NOT SUPPORT ATTACHMENTS
    // const removeFile = (i) => {
    //     let updatedFiles = files;
    //     updatedFiles.splice(i, 1);
    //     setFiles([...updatedFiles]);
    // };

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Name is required'),
        email: Yup.string()
            .email('Invalid email address')
            .required('Email is required'),
        message: Yup.string().required('Message is required'),
    });

    return (
        <div className="px-4 py-6 overflow-y-auto flex-grow">
            <Formik
                initialValues={{
                    name: userData?.first_name ?? '',
                    email: userData?.email ?? '',
                    message: '',
                }}
                validationSchema={validationSchema}
                onSubmit={(values, { resetForm }) =>
                    handleSubmit(values, resetForm)
                }
            >
                {({ isSubmitting }) => (
                    <Form className="flex flex-col justify-between gap-4 h-full">
                        <div className="flex flex-col gap-2">
                            <div>
                                <FormField
                                    name={'Your name'}
                                    attributeName={'name'}
                                    fieldType={'name'}
                                    placeholderText={'Richard Hendriks'}
                                    labelClassName="font-light leading-5"
                                    ignoreErrors={true}
                                    value={userData?.first_name}
                                    disabled={
                                        userData?.first_name ? true : false
                                    }
                                />
                                <ErrorMessage name="name">
                                    {(error) => (
                                        <div className="text-red-500 mt-1  text-xs">
                                            {error}
                                        </div>
                                    )}
                                </ErrorMessage>
                            </div>
                            <div>
                                <FormField
                                    name={'Your email address'}
                                    attributeName={'email'}
                                    fieldType={'email'}
                                    placeholderText={'richard@piedpiper.com'}
                                    labelClassName="font-light leading-5"
                                    ignoreErrors={true}
                                    value={userData?.email}
                                    disabled={userData?.email ? true : false}
                                />
                                <ErrorMessage name="email">
                                    {(error) => (
                                        <div className="text-red-500 mt-1  text-xs">
                                            {error}
                                        </div>
                                    )}
                                </ErrorMessage>
                            </div>
                            <div>
                                <FormField
                                    name={'Your message'}
                                    attributeName={'message'}
                                    fieldType={'textarea'}
                                    placeholderText={'Your message'}
                                    labelClassName="font-light leading-5"
                                    ignoreErrors={true}
                                />
                                <ErrorMessage name="message">
                                    {(error) => (
                                        <div className="text-red-500 mt-1  text-xs">
                                            {error}
                                        </div>
                                    )}
                                </ErrorMessage>
                            </div>
                            {/* TODO: THIS CODE ADDS ABILITY TO UPLOAD IMAGES AS ATTACHMENTS. 
                            BUT OUR EMAIL-PROVIDER IN STRAPI DOES NOT SUPPORT ATTACHMENTS */}
                            {/* <div className="flex flex-col gap-1 justify-center ">
                                    <label className="upload-input text-center  border border-solid border-gray-300  bg-gray-300 text-gray-500 w-48">
                                        <input
                                            type="file"
                                            name={`attachments-${Math.floor(
                                                Math.random() * 1000000000
                                            )}`}
                                            onChange={(event) =>
                                                handleFilesChange(event)
                                            }
                                            accept="image/*"
                                        />
                                        Select images
                                    </label>

                                    {files?.length ? (
                                        <div className="flex flex-wrap gap-1">
                                            {files.map((fileObj, i) => {
                                                const file =
                                                    Object.values(fileObj)[0];
                                                const fileKey =
                                                    Object.keys(fileObj)[0];
                                                return (
                                                    <div
                                                        key={fileKey}
                                                        className=" border border-solid border-gray-300 rounded w-16 h-16 overflow-hidden relative"
                                                    >
                                                        {file.type.includes(
                                                            'image'
                                                        ) ? (
                                                            <img
                                                                src={window.URL.createObjectURL(
                                                                    file
                                                                )}
                                                                alt="attached image"
                                                            />
                                                        ) : (
                                                            <p className="break-all	  p-1 text-xs ">
                                                                {file.name}
                                                            </p>
                                                        )}
                                                        <button
                                                            className="absolute top-1 right-1"
                                                            onClick={() =>
                                                                removeFile(i)
                                                            }
                                                            type="button"
                                                        >
                                                            <CrossInCircle
                                                                fill="#EE8282"
                                                                width={18}
                                                                height={18}
                                                            />
                                                        </button>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    ) : null}
                                </div> */}
                        </div>
                        <div className="flex flex-col items-center gap-2">
                            {message ? (
                                <p className="text-center text-gray-500 text-sm">
                                    {message}
                                </p>
                            ) : null}
                            <button
                                type="submit"
                                disabled={isSubmitting}
                                className=" px-8 py-2 bg-theme-tertiary rounded text-white font-display leading-6 font-medium"
                            >
                                {isLoading ? (
                                    <Loader
                                        color={'#C2C7D7'}
                                        size={24}
                                        speedMultiplier={0.8}
                                    />
                                ) : (
                                    'Submit'
                                )}
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default ModalSupportFormContent;
