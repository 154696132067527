import React from 'react';
import TableEditableTbodyRow from '../thead/Row';
import { useTranslation } from "react-i18next";

function TableEditableTbody({
    getTableBodyProps,
    rows,
    data,
    prepareRow,
    selectedRow,
    doSetSelectedRow,
    errorMessages,
    validateForm,
    updateProjects,
    deleteProject,
    type,
}) {
    const { t } = useTranslation();

    return (
        <tbody {...getTableBodyProps} className="text-gray-500">
            {data.length ? (
                rows.map((row, index) => (
                    <TableEditableTbodyRow
                        key={index}
                        row={row}
                        prepareRow={prepareRow}
                        selectedRow={selectedRow}
                        doSetSelectedRow={doSetSelectedRow}
                        errorMessages={errorMessages}
                        validateForm={validateForm}
                        updateProjects={updateProjects}
                        deleteProject={deleteProject}
                        type={type}
                    />
                ))
            ) : (
                <tr>
                    <td
                        className="text-base font-display text-center text-gray-500 text-xl p-4"
                        colSpan={3}
                    >
                        {t('hours_page.private_projects.no_data')}
                    </td>
                </tr>
            )}
        </tbody>
    );
}

export default TableEditableTbody;
