import { instances } from '../default-setup';

export const getTasks = (startDate, endDate, offset, paramsObj, limit = 50) => {
    const paramsString = Object.entries(paramsObj).reduce((acc, param) => {
        return acc + `&${param[0]}=${param[1]}`;
    }, '');
    return instances.get(
        `si/?date_start=${startDate}&date_end=${endDate}${paramsString}&limit=${limit}&offset=${offset}`
    );
};

export const trainTasks = (key, label) =>
    instances.get(`si/user_label/?issue_key=${key}&label=${label}`);

export const getTeamTasks = (
    teamHash,
    startDate,
    endDate,
    offset,
    paramsObj,
    limit = 50
) => {
    const paramsString = Object.entries(paramsObj).reduce((acc, param) => {
        return acc + `&${param[0]}=${param[1]}`;
    }, '');
    return instances.get(
        `si/${teamHash}/?date_start=${startDate}&date_end=${endDate}${paramsString}&limit=${limit}&offset=${offset}`
    );
};
