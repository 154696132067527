import React, { useEffect, useState } from 'react';

const EditableCell = ({
    value: initValue,
    row,
    column: { id },
    updateSelectedRow,
    errorMessages,
    showEdit,
}) => {
    const [value, setValue] = useState(initValue);

    const onChange = (event) => {
        setValue(event.target.value);
    };

    const onBlur = (event) => {
        updateSelectedRow(event.target.name, value);
    };

    useEffect(() => {
        setValue(initValue);
    }, [initValue, showEdit]);

    // Show input when editable cell is active
    if (showEdit) {
        return (
            <div>
                <input
                    name={id}
                    value={value}
                    placeholder="Enter data"
                    className="p-2 placeholder-gray-300 text-gray-700 relative bg-white
             rounded text-md border border-gray-300 outline-none focus:outline-none focus:ring w-full"
                    onChange={onChange}
                    onBlur={onBlur}
                />
                <small className="text-xs text-red-600">
                    {errorMessages[id]}
                </small>
            </div>
        );
    }

    return value || <span>&nbsp;</span>;
};

export default EditableCell;
